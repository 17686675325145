import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, FormGroup, InputGroup, Label, Row, Input as ReactstrapInput } from 'reactstrap';
import { Button } from '../../../../components/Button';
import { Layout } from '../../../../components/Layout';
import { RootState, useAppDispatch } from '../../../../store';
import { Accordeon } from '../../../../components/Accordeon';
import { Attendance, getAttendance } from '../../../../slices/attendance';
import { Text } from '../../../../components/Text';
import { TummiModal, ITummiModalProps } from '../../../../components/Modal';
import initPrescriptionPatient from '../../../../utils/initPrescriptionPatient';
import { ReturnLink } from '../../../../components/ReturnLink';
import { PatientInfo } from '../../../../components/PatientInfo';
import './styles.scss';
import { Input } from '../../../../components/Input';
import { AmpalForm, getAmpalForm, saveAmpalForm } from '../../../../slices/ampal';
import { TextArea } from '../../../../components/TextArea';
import M2A1 from '../../../../resources/images/m2a1.png';
import M2A2 from '../../../../resources/images/m2a2.png';
import M2A3 from '../../../../resources/images/m2a3.png';

import { useAutosave } from 'react-autosave';

type Params = { id: string };

export const PatientAttendance = () => {
  const { id } = useParams<Params>();
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);
  const history = useHistory();

  const [attendance, setAttendance] = useState<Attendance>({
    statusAttendance: 0,
  });

  const [ampalForm, setAmpalForm] = useState<AmpalForm>({
    attendanceId: id,
  });

  const [showCancelActions, setShowCancelActions] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const dispatch = useAppDispatch();

  const initPrescription = () => {
    var script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('data-token', user.memedToken || '');
    script.src = process.env.REACT_APP_MEMED_API || '';
    script.onload = function () {
      // Obs: Essa função precisa ser implementada, seguindo o artigo de comandos para definição de paciente (Link abaixo).
      initPrescriptionPatient();
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    const fetchAttendanceData = async () => {
      const response = await dispatch(getAttendance(id));

      const todaysDate = new Date();
      const attendanceDate = new Date(response.data.date);

      const diff = (attendanceDate.getTime() - todaysDate.getTime()) / 3600000;

      setShowCancelActions(diff > 24);

      setAttendance(response.data);
    };
    fetchAttendanceData();
    initPrescription();
  }, [dispatch, id]);

  useEffect(() => {
    const fetchAmpalFormData = async () => {
      const response = await dispatch(getAmpalForm({ id: id }));

      setAmpalForm({
        ...response.data,
        attendanceId: id,
      });
    };
    fetchAmpalFormData();
  }, [dispatch, id]);

  const redirectToAttedanceCall = (attedanceId: string) => {
    window.open(`${process.env.PUBLIC_URL}/${user.role}/call/${attedanceId}`);
  };

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const handleIMC = () => {
    if (ampalForm?.module3?.m3GA?.alternativeA && ampalForm?.module3?.m3GA.alternativeB) {
      const peso = parseFloat(ampalForm?.module3?.m3GA?.alternativeA);
      const altura = parseFloat(ampalForm?.module3?.m3GA?.alternativeB) / 100;

      const imc = (peso / (altura * altura)).toFixed(1);

      setAmpalForm({
        ...ampalForm,
        module3: {
          ...ampalForm?.module3,
          m3GA: {
            ...ampalForm?.module3?.m3GA,
            alternativeC: imc.toString(),
          },
        },
      });
    }
  };

  const updateAmpalForm = useCallback(
    (data: AmpalForm) => {
      dispatch(saveAmpalForm(data || {}));
    },
    [dispatch],
  );

  useAutosave({ data: ampalForm, onSave: updateAmpalForm });

  return (
    <Layout type={user.role}>
      <div className="ampal-attendance-info">
        <Row className="mt-4">
          <Col sm={4} className="justify-content-start" style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" returnLink={() => history.goBack()} />
            <Text
              title="ATENDIMENTO"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="d-flex" style={{ justifyContent: 'flex-end' }}>
            <Button type="primary" onClick={() => redirectToAttedanceCall(id)}>
              INICIAR CHAMADA
            </Button>
            <Button
              type="secondary"
              onClick={() =>
                history.push(
                  `${process.env.PUBLIC_URL}/${user.role}/newattendance/${attendance.patientTelemedicine?.id}`,
                )
              }
            >
              MARCAR NOVO ATEND.
            </Button>
            <Button
              onClick={() =>
                history.push(`${process.env.PUBLIC_URL}/${user.role}/reschedule/${attendance.id}`)
              }
              style={{ paddingLeft: '5px' }}
              type="secondary"
            >
              REAGENDAR ATEND.
            </Button>
          </Col>
        </Row>
        <PatientInfo
          firstName={attendance.patientTelemedicine?.firstName}
          lastName={attendance.patientTelemedicine?.lastName}
          dob={attendance.patientTelemedicine?.dob}
          email={attendance.patientTelemedicine?.email}
          date={attendance.date}
          ddd={attendance.patientTelemedicine?.ddd}
          phone={attendance.patientTelemedicine?.phone}
          gender={attendance.patientTelemedicine?.gender}
        />

        <Row className="mt-4">
          <Col>
            <Accordeon text="Questionário baseado no ICOPE" startOpen={true}>
              <>
                <Row className="justify-content-center">
                  <Col sm={8}>
                    <p>Instituto de Geriatria e Gerontologia PUCRS Thummi Telemedicina</p>
                    <p>Acompanhamento Multiprofissional de Longevos VERSÃO 8 (Novembro/2021)</p>
                    <p>
                      Bom dia/Boa tarde/ Boa noite, sou do projeto AMPAL da PUCRS, do Instituto de
                      Geriatria e Gerontologia, que entrevistou o Sr(a) ___ e conversou com ele(a)
                      no dia … Estou ligando para saber como ele(a) está.
                    </p>
                    <p>
                      Como ele(a) está passando nesses dias de coronavírus? BEM? Será que
                      conseguiríamos conversar com ele(a) não precisa ser agora podemos agendar uma
                      ligação, queremos saber como ele(a) está lidando com essa nova situação e no
                      que podemos ajudar para ele(a) se sentir melhor.
                    </p>
                    <p>
                      Vai levar uns 30 minutos e vamos pedir para ele(a) fazer uns testes. Para os
                      testes, vamos precisar que o entrevistado esteja acompanhado de alguém que
                      tenha condições de ampará-lo caso necessário, para evitar alguma queda. Se
                      neste momento, o entrevistado não estiver acompanhado, remarcaremos os testes
                      físicos. Para a avaliação, vamos precisar de uma fita métrica (ou um barbante
                      e uma régua) e uma garrafa de água de 500ml. Pode ser qualquer garrafa redonda
                      e cheia contendo 500ml de líquido não perigoso.
                    </p>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12}>
                    <TextArea
                      name={''}
                      id={''}
                      label="Informações referente ao paciente"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          patientEvolutionInfo: e.target.value,
                        });
                      }}
                      value={ampalForm?.patientEvolutionInfo}
                      style={{ height: '250px' }}
                    />
                  </Col>
                </Row>
              </>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon
              text="Autopercepção de Saúde e Acontecimentos dos últimos 3 meses"
              startOpen={false}
            >
              <>
                <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                  <Col className="mt-2" sm={6}>
                    <Row sm={7}>
                      <p>A7a Em geral diria que sua saúde é (não se aplica para cuidador)</p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7a: {
                                    ...ampalForm?.introduction?.a7a,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7a?.flag === 0}
                          />
                          <span className="radio-label ml-1">ótima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7a: {
                                    ...ampalForm?.introduction?.a7a,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7a?.flag === 1}
                          />
                          <span className="radio-label ml-1">boa</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7a: {
                                    ...ampalForm?.introduction?.a7a,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7a?.flag === 2}
                          />
                          <span className="radio-label ml-1">regular</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7a: {
                                    ...ampalForm?.introduction?.a7a,
                                    flag: 3,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7a?.flag === 3}
                          />
                          <span className="radio-label ml-1">ruim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7a: {
                                    ...ampalForm?.introduction?.a7a,
                                    flag: 4,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7a?.flag === 4}
                          />
                          <span className="radio-label ml-1">péssima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4" style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                  <Col className="mt-2" sm={6}>
                    <Row sm={7}>
                      <p>
                        A7b Como classificaria sua saúde oral (dentes, dentadura, gengivas e dentro
                        de sua boca)?
                      </p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7b: {
                                    ...ampalForm?.introduction?.a7b,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7b?.flag === 0}
                          />
                          <span className="radio-label ml-1">ótima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7b: {
                                    ...ampalForm?.introduction?.a7b,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7b?.flag === 1}
                          />
                          <span className="radio-label ml-1">boa</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7b: {
                                    ...ampalForm?.introduction?.a7b,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7b?.flag === 2}
                          />
                          <span className="radio-label ml-1">regular</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7b: {
                                    ...ampalForm?.introduction?.a7b,
                                    flag: 3,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7b?.flag === 3}
                          />
                          <span className="radio-label ml-1">ruim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7b: {
                                    ...ampalForm?.introduction?.a7b,
                                    flag: 4,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7b?.flag === 4}
                          />
                          <span className="radio-label ml-1">péssima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4" style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                  <Col className="mt-2" sm={6}>
                    <Row sm={7}>
                      <p>
                        A7c Como classificaria sua mastigação (para alimentos que gostaria de comer?
                      </p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7c: {
                                    ...ampalForm?.introduction?.a7c,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7c?.flag === 0}
                          />
                          <span className="radio-label ml-1">ótima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7c: {
                                    ...ampalForm?.introduction?.a7c,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7c?.flag === 1}
                          />
                          <span className="radio-label ml-1">boa</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7c: {
                                    ...ampalForm?.introduction?.a7c,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7c?.flag === 2}
                          />
                          <span className="radio-label ml-1">regular</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7c: {
                                    ...ampalForm?.introduction?.a7c,
                                    flag: 3,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7c?.flag === 3}
                          />
                          <span className="radio-label ml-1">ruim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7c: {
                                    ...ampalForm?.introduction?.a7c,
                                    flag: 4,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7c?.flag === 4}
                          />
                          <span className="radio-label ml-1">péssima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4" style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                  <Col className="mt-2" sm={6}>
                    <Row sm={7}>
                      <p>A7d Como classificaria sua audição (mesmo usando aparelho, caso use).</p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7d: {
                                    ...ampalForm?.introduction?.a7d,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7d?.flag === 0}
                          />
                          <span className="radio-label ml-1">ótima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7d: {
                                    ...ampalForm?.introduction?.a7d,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7d?.flag === 1}
                          />
                          <span className="radio-label ml-1">boa</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7d: {
                                    ...ampalForm?.introduction?.a7d,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7d?.flag === 2}
                          />
                          <span className="radio-label ml-1">regular</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7d: {
                                    ...ampalForm?.introduction?.a7d,
                                    flag: 3,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7d?.flag === 3}
                          />
                          <span className="radio-label ml-1">ruim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7d: {
                                    ...ampalForm?.introduction?.a7d,
                                    flag: 4,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7d?.flag === 4}
                          />
                          <span className="radio-label ml-1">péssima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4" style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                  <Col className="mt-2" sm={6}>
                    <Row sm={7}>
                      <p>A7e Como classificaria sua visão (mesmo com óculos ou lentes).</p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7e: {
                                    ...ampalForm?.introduction?.a7e,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7e?.flag === 0}
                          />
                          <span className="radio-label ml-1">ótima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7e: {
                                    ...ampalForm?.introduction?.a7e,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7e?.flag === 1}
                          />
                          <span className="radio-label ml-1">boa</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7e: {
                                    ...ampalForm?.introduction?.a7e,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7e?.flag === 2}
                          />
                          <span className="radio-label ml-1">regular</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7e: {
                                    ...ampalForm?.introduction?.a7e,
                                    flag: 3,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7e?.flag === 3}
                          />
                          <span className="radio-label ml-1">ruim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7e: {
                                    ...ampalForm?.introduction?.a7e,
                                    flag: 4,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7e?.flag === 4}
                          />
                          <span className="radio-label ml-1">péssima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4" style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                  <Col className="mt-2" sm={6}>
                    <Row sm={7}>
                      <p>A7f Diria que, no geral, seu apetite ou vontade de comer tem sido...</p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7f: {
                                    ...ampalForm?.introduction?.a7f,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7f?.flag === 0}
                          />
                          <span className="radio-label ml-1">ótima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7f: {
                                    ...ampalForm?.introduction?.a7f,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7f?.flag === 1}
                          />
                          <span className="radio-label ml-1">boa</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7f: {
                                    ...ampalForm?.introduction?.a7f,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7f?.flag === 2}
                          />
                          <span className="radio-label ml-1">regular</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7f: {
                                    ...ampalForm?.introduction?.a7f,
                                    flag: 3,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7f?.flag === 3}
                          />
                          <span className="radio-label ml-1">ruim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a7f: {
                                    ...ampalForm?.introduction?.a7f,
                                    flag: 4,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a7f?.flag === 4}
                          />
                          <span className="radio-label ml-1">péssima</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4" style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                  <Col className="mt-2" sm={6}>
                    <Row sm={7}>
                      <p>a1 - Nos últimos três meses o(a) Sr(a) caiu no chão?</p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a1: {
                                    ...ampalForm?.introduction?.a1,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a1?.flag === 0}
                          />
                          <span className="radio-label ml-1">Sim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a1: {
                                    ...ampalForm?.introduction?.a1,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a1?.flag === 1}
                          />
                          <span className="radio-label ml-1">Não</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Input
                        label="a1a - Quantas vezes"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a1: {
                                ...ampalForm?.introduction?.a1,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a1?.alternativeA}
                      />
                    </Row>
                  </Col>
                  <Col className="mt-2" sm={6}>
                    <Row sm={12}>
                      <p>a2 - Precisa se segurar para não cair?</p>
                    </Row>
                    <Row sm={2}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a2: {
                                    ...ampalForm?.introduction?.a2,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a2?.flag === 0}
                          />
                          <span className="radio-label ml-1">Nunca ou quase nunca</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={3}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a2: {
                                    ...ampalForm?.introduction?.a2,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a2?.flag === 1}
                          />
                          <span className="radio-label ml-1">Frequentemente</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={2}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a2: {
                                    ...ampalForm?.introduction?.a2,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a2?.flag === 2}
                          />
                          <span className="radio-label ml-1">O tempo todo</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                  <Col className="mt-2" sm={6}>
                    <Row sm={12}>
                      <p>a3 - Foi hospitalizado(a)?</p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a3: {
                                    ...ampalForm?.introduction?.a3,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a3?.flag === 0}
                          />
                          <span className="radio-label ml-1">Sim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a3: {
                                    ...ampalForm?.introduction?.a3,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a3?.flag === 1}
                          />
                          <span className="radio-label ml-1">Não</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Input
                        label="a3a - Quantas vezes"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a3: {
                                ...ampalForm?.introduction?.a3,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a3?.alternativeA}
                      />
                    </Row>
                    <Row sm={12}>
                      <Input
                        label="a3b - Causa da hospitalização mais recente:"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a3: {
                                ...ampalForm?.introduction?.a3,
                                alternativeB: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a3?.alternativeB}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="a3c - Quantos dias foi hospitalizado(a) nessa última vez?"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a3: {
                                ...ampalForm?.introduction?.a3,
                                alternativeC: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a3?.alternativeC}
                      />
                    </Row>
                  </Col>
                  <Col className="mt-2" sm={6}>
                    <Row sm={12}>
                      <p>a4 - Atendimento médico:</p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a4: {
                                    ...ampalForm?.introduction?.a4,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a4?.flag === 0}
                          />
                          <span className="radio-label ml-1">Sim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a4: {
                                    ...ampalForm?.introduction?.a4,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a4?.flag === 1}
                          />
                          <span className="radio-label ml-1">Não</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={12} className="mt-4">
                      <p>a4a - Se sim qual o tipo de atendimento recebido?</p>
                    </Row>
                    <Row sm={12}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a4a: {
                                    ...ampalForm?.introduction?.a4a,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a4a?.flag === 0}
                          />
                          <span className="radio-label ml-1">
                            consulta agendada com antecedência
                          </span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={12}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a4a: {
                                    ...ampalForm?.introduction?.a4a,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a4a?.flag === 1}
                          />
                          <span className="radio-label ml-1">consulta marcada no mesmo dia</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={12}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a4a: {
                                    ...ampalForm?.introduction?.a4a,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a4a?.flag === 2}
                          />
                          <span className="radio-label ml-1">
                            consulta de urgência (inclui SAMU, ECCOI SALVA, etc_)
                          </span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                  <Col className="mt-2" sm={6}>
                    <Row>
                      <p>a5 - Necessitou ficar em uma instituição para idosos?</p>
                    </Row>
                    <Row>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a5: {
                                    ...ampalForm?.introduction?.a5,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a5?.flag === 0}
                          />
                          <span className="radio-label ml-1">Sim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a5: {
                                    ...ampalForm?.introduction?.a5,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a5?.flag === 1}
                          />
                          <span className="radio-label ml-1">Não</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={12} className="mt-4">
                      <p>a5a - Se sim qual o tipo de atendimento recebido?</p>
                    </Row>
                    <Row>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a5a: {
                                    ...ampalForm?.introduction?.a5a,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a5a?.flag === 0}
                          />
                          <span className="radio-label ml-1">só de dia</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a5a: {
                                    ...ampalForm?.introduction?.a5a,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a5a?.flag === 1}
                          />
                          <span className="radio-label ml-1">só para dormir</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a5a: {
                                    ...ampalForm?.introduction?.a5a,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a5a?.flag === 2}
                          />
                          <span className="radio-label ml-1">alguns dias</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                introduction: {
                                  ...ampalForm?.introduction,
                                  a5a: {
                                    ...ampalForm?.introduction?.a5a,
                                    flag: 5,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.introduction?.a5a?.flag === 5}
                          />
                          <span className="radio-label ml-1">longa permanência</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
              </>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Atividades diárias, em média" startOpen={false}>
              <>
                <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                  <Col>
                    <Row>
                      <Input
                        label="A6a - Quantas horas realiza atividades de leitura por semana"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeA}
                      />
                    </Row>
                    <Row>
                      <InputGroup
                        label="A6b - Quantos horas realiza atividades informativas (rádio, televisão) por semana"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeB: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeB}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="A6c - Quantos horas realiza tarefas domésticas por semana"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeC: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeC}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="A6d - Quantos horas cuida de pessoas (netos, pessoas), com animais ou plantas por semana"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeD: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeD}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="A6e - Quantos horas realiza atividades manuais (bordado, carpintaria etc)  por semana"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeE: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeE}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="A6f - Quantos horas caminha ou realiza outro esporte por semana"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeF: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeF}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="A6g - Quantas horas passa sentado por dia (em média)"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeG: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeG}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="A6h - Quantas horas passa deitado (mesmo sem dormir) por dia? - conta as horas
                        dormindo e de descanso"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeK: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeK}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="A6i - Quantos dias por semana sai de casa"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeI: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeI}
                      />
                    </Row>
                    <Row>
                      <Input
                        label="A6j - Com quantas pessoas diferentes conversa ou se comunica (por whatsapp ou e-mail) por semana?"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            introduction: {
                              ...ampalForm?.introduction,
                              a6: {
                                ...ampalForm?.introduction?.a6,
                                alternativeJ: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.introduction?.a6?.alternativeJ}
                      />
                    </Row>
                  </Col>
                </Row>
              </>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 1 - Capacidade Cognitiva" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <Col className="mt-2" sm={6}>
                    <Row sm={12}>
                      <p>
                        M1a - Você tem algum problema de memória ou orientação (como não saber onde
                        está ou que dia é hoje)?
                      </p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1A: {
                                    ...ampalForm?.module1?.m1A,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.module1?.m1A?.flag === 0}
                          />
                          <span className="radio-label ml-1">Sim</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1A: {
                                    ...ampalForm?.module1?.m1A,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.module1?.m1A?.flag === 1}
                          />
                          <span className="radio-label ml-1">Não</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row className="mt-4">
                      <Input
                        label="M1b - Lembrança de três palavras:
                        Peça à pessoa que se lembre de três palavras que você dirá:
                        CANETA, TAPETE, TIJOLO
                        "
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module1: {
                              ...ampalForm?.module1,
                              m1B: {
                                ...ampalForm?.module1?.m1B,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module1?.m1B?.alternativeA}
                      />
                    </Row>
                    <Row className="mt-4">
                      <p>M1c Orientação no tempo e no espaço:</p>
                    </Row>
                    <Row>
                      <Input
                        label="M1c1 - Qual é a data completa hoje? dia, mês, ano"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module1: {
                              ...ampalForm?.module1,
                              m1C: {
                                ...ampalForm?.module1?.m1C,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module1?.m1C?.alternativeA}
                      />
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <ReactstrapInput
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1C: {
                                    ...ampalForm?.module1?.m1C,
                                    alternative1:
                                      ampalForm?.module1?.m1C?.alternative1 === 'true'
                                        ? 'false'
                                        : 'true',
                                    alternative4: false,
                                  },
                                },
                              });
                            }}
                            checked={(ampalForm?.module1?.m1C?.alternative1 as string) === 'true'}
                          />
                          <span className="radio-label ml-1">acertou dia</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <ReactstrapInput
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1C: {
                                    ...ampalForm?.module1?.m1C,
                                    alternative2:
                                      ampalForm?.module1?.m1C?.alternative2 === 'true'
                                        ? 'false'
                                        : 'true',
                                    alternative4: false,
                                  },
                                },
                              });
                            }}
                            checked={(ampalForm?.module1?.m1C?.alternative2 as string) === 'true'}
                          />
                          <span className="radio-label ml-1">acertou mês</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <ReactstrapInput
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1C: {
                                    ...ampalForm?.module1?.m1C,
                                    alternative3:
                                      ampalForm?.module1?.m1C?.alternative3 === 'true'
                                        ? 'false'
                                        : 'true',
                                    alternative4: false,
                                  },
                                },
                              });
                            }}
                            checked={(ampalForm?.module1?.m1C?.alternative3 as string) === 'true'}
                          />
                          <span className="radio-label ml-1">acertou ano</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <ReactstrapInput
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1C: {
                                    ...ampalForm?.module1?.m1C,
                                    alternative4:
                                      ampalForm?.module1?.m1C?.alternative4 === 'true'
                                        ? 'false'
                                        : 'true',
                                    alternative1: false,
                                    alternative2: false,
                                    alternative3: false,
                                  },
                                },
                              });
                            }}
                            checked={(ampalForm?.module1?.m1C?.alternative4 as string) === 'true'}
                          />
                          <span className="radio-label ml-1">errou todas</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={12} className="mt-4">
                      <p>M1c2 Onde você está agora? (em casa, no posto de saúde etc)?</p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1C2: {
                                    ...ampalForm?.module1?.m1C2,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.module1?.m1C2?.flag === 0}
                          />
                          <span className="radio-label ml-1">acertou</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1C2: {
                                    ...ampalForm?.module1?.m1C2,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.module1?.m1C2?.flag === 1}
                          />
                          <span className="radio-label ml-1">errou</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={12} className="mt-4">
                      <p>
                        M1d - Recordatório de três palavras: Agora, peça à pessoa que escreva ou
                        diga para o acompanhante as três palavras que você disse.
                      </p>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1D: {
                                    ...ampalForm?.module1?.m1D,
                                    flag: 0,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.module1?.m1D?.flag === 0}
                          />
                          <span className="radio-label ml-1">Acertou 1 palavra</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1D: {
                                    ...ampalForm?.module1?.m1D,
                                    flag: 1,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.module1?.m1D?.flag === 1}
                          />
                          <span className="radio-label ml-1">acertou duas palavras</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1D: {
                                    ...ampalForm?.module1?.m1D,
                                    flag: 2,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.module1?.m1D?.flag === 2}
                          />
                          <span className="radio-label ml-1">acertou três palavras</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row sm={1}>
                      <FormGroup className="" check>
                        <Label className="radio-input" check>
                          <Input
                            type="checkbox"
                            onClick={(e: any) => {
                              setAmpalForm({
                                ...ampalForm,
                                module1: {
                                  ...ampalForm?.module1,
                                  m1D: {
                                    ...ampalForm?.module1?.m1D,
                                    flag: 3,
                                  },
                                },
                              });
                            }}
                            checked={ampalForm?.module1?.m1D?.flag === 3}
                          />
                          <span className="radio-label ml-1">errou todas</span>
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row className="mt-4">
                      <Input
                        label="M1T - Número total de acertos no MEEM __ (mínimo 0 máximo 7)"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module1: {
                              ...ampalForm?.module1,
                              m1T: {
                                ...ampalForm?.module1?.m1T,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module1?.m1T?.alternativeA}
                      />
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 2 - Capacidade Locomotora" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <p>
                    Agora vamos fazer alguns testes para ver como está a sua capacidade locomotora.
                    Para não cair, tem alguém aí para ficar cuidando?
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    <strong>BATERIA BREVE DE DESEMPENHO FÍSICO (SPPB)</strong>
                  </p>
                  <p>
                    O SPPB mede o desempenho cronometrado em três tarefas, cada uma com quatro
                    pontos. A pontuação final vai de zero (pior desempenho) a 12 (melhor
                    desempenho). Primeiro, descreva cada teste e pergunte se a pessoa se sente capaz
                    de fazê-lo_ Caso contrário, marque de acordo e passe para a próxima etapa.
                  </p>
                  <Row sm={12} className="mt-4">
                    <h5>M2A - Teste de equilíbrio:</h5>
                  </Row>
                  <Row sm={12}>
                    <p>
                      Ficar em pé por 10 segundos com os pés em três posições: pés juntos, com um
                      calcanhar junto do dedão do outro e com um pé na frente do outro. Com auxílio
                      de outra pessoa que possa segurar, caso o entrevistado perca o equilíbrio.
                      Você acha que consegue fazer? Vamos tentar?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A: {
                                  ...ampalForm?.module2?.m2A,
                                  flag: ampalForm.module2?.m2A?.flag === 0 ? 100 : 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A?.flag === 0}
                        />
                        <span className="radio-label ml-1">
                          não quis fazer, por não ter condições
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A: {
                                  ...ampalForm?.module2?.m2A,
                                  flag: ampalForm.module2?.m2A?.flag === 1 ? 200 : 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A?.flag === 1}
                        />
                        <span className="radio-label ml-1">
                          não quis fazer, por falta de vontade ou tempo
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A: {
                                  ...ampalForm?.module2?.m2A,
                                  flag: ampalForm.module2?.m2A?.flag === 2 ? 300 : 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A?.flag === 2}
                        />
                        <span className="radio-label ml-1">
                          não pode fazer por estar sozinho (reavaliar em outro momento)
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-2">
                    <p
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        document.getElementById('m2d')?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      Caso não exista alguém, passe para o teste M2D.
                    </p>
                  </Row>
                  <Row sm={12} className="mt-4">
                    <h5>M2a1 - Primeira posição: pés juntos</h5>
                  </Row>
                  <Row>
                    <img
                      style={{ borderRadius: 0 }}
                      src={M2A1}
                      alt="pés juntos"
                      title="pés juntos"
                    />
                  </Row>
                  <Row sm={2}>
                    <Col>
                      <Row className="mt-2">
                        <Input
                          label="(o mais próximo um do outro no mínimo com um punho de distância) Ficou __ __ segundos."
                          onChange={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A1: {
                                  ...ampalForm?.module2?.m2A1,
                                  alternativeA: e.target.value,
                                },
                              },
                            });
                          }}
                          value={ampalForm?.module2?.m2A1?.alternativeA}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A1: {
                                  ...ampalForm?.module2?.m2A1,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A1?.flag === 0}
                        />
                        <span className="radio-label ml-1">conseguiu 10 segundos = 1 ponto</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A1: {
                                  ...ampalForm?.module2?.m2A1,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A1?.flag === 1}
                        />
                        <span className="radio-label ml-1">não conseguiu = 0 ponto</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A1: {
                                  ...ampalForm?.module2?.m2A1,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A1?.flag === 2}
                        />
                        <span className="radio-label ml-1">Não tentou = 0 ponto</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-2">
                    <p
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        document.getElementById('m2b')?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      Se não conseguiu passa para o M2B
                    </p>
                  </Row>
                  <Row>
                    <p
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        document.getElementById('m2a2')?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      Se conseguiu, passa para o teste M2A2
                    </p>
                  </Row>
                  <Row sm={12} className="mt-4">
                    <h5 id="m2a2">
                      M2a2 - Segunda posição: Colocar o dedão do pé esquerdo ao lado do calcanhar do
                      pé direito.
                    </h5>
                  </Row>
                  <Row>
                    <img
                      style={{ borderRadius: 0, height: '120px' }}
                      src={M2A2}
                      alt="pés juntos"
                      title="pés juntos"
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="Ficou __ __ segundos."
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2A2: {
                              ...ampalForm?.module2?.m2A2,
                              alternativeA: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2A2?.alternativeA}
                    />
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A2: {
                                  ...ampalForm?.module2?.m2A2,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A2?.flag === 0}
                        />
                        <span className="radio-label ml-1">conseguiu 10 segundos = 1 ponto</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A2: {
                                  ...ampalForm?.module2?.m2A2,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A2?.flag === 1}
                        />
                        <span className="radio-label ml-1">não conseguiu = 0 ponto</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A2: {
                                  ...ampalForm?.module2?.m2A2,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A2?.flag === 2}
                        />
                        <span className="radio-label ml-1">Não tentou = 0 ponto</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-2">
                    <p
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        document.getElementById('m2b')?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      Se não conseguiu passa para o M2B
                    </p>
                  </Row>
                  <Row>
                    <p
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        document.getElementById('m2a3')?.scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      Se conseguiu, passa para o teste M2A3
                    </p>
                  </Row>
                  <Row sm={12} className="mt-4">
                    <h5 id="m2a3">M2A3 - Terceira posição: Tandem - Um pé na frente do outro</h5>
                  </Row>
                  <Row>
                    <img
                      style={{ borderRadius: 0 }}
                      src={M2A3}
                      alt="pés juntos"
                      title="pés juntos"
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="Ficou __ __ segundos."
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2A3: {
                              ...ampalForm?.module2?.m2A3,
                              alternativeA: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2A3?.alternativeA}
                    />
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A3: {
                                  ...ampalForm?.module2?.m2A3,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A3?.flag === 0}
                        />
                        <span className="radio-label ml-1">
                          Conseguiu ficar 10 segundos= 2 pontos
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A3: {
                                  ...ampalForm?.module2?.m2A3,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A3?.flag === 1}
                        />
                        <span className="radio-label ml-1">
                          Conseguiu ficar de 3 a 9,99 segundos= 1 ponto
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A3: {
                                  ...ampalForm?.module2?.m2A3,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A3?.flag === 2}
                        />
                        <span className="radio-label ml-1">
                          {'Conseguiu ficar < 3 segundos= 0 pontos'}
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2A3: {
                                  ...ampalForm?.module2?.m2A3,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2A3?.flag === 3}
                        />
                        <span className="radio-label ml-1">{'Não tentou= 0 pontos'}</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={12} className="mt-4">
                    <h5 id="m2b">M2B Teste de velocidade da marcha: </h5>
                  </Row>
                  <Row>
                    <p>
                      Peça que caminhe 4 metros em passo normal por 3 vezes. Meça o tempo em cada
                      vez. Utilize o menor tempo e assinale os pontos correspondentes.
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2B: {
                                  ...ampalForm?.module2?.m2B,
                                  flag: ampalForm.module2?.m2B?.flag === 0 ? 100 : 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2B?.flag === 0}
                        />
                        <span className="radio-label ml-1">Não conseguiu caminhar</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2B: {
                                  ...ampalForm?.module2?.m2B,
                                  flag: ampalForm.module2?.m2B?.flag === 1 ? 100 : 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2B?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não conseguiu caminhar 4 metros.</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2BA quantos metros caminhou: ± __ metros"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2B: {
                              ...ampalForm?.module2?.m2B,
                              alternativeA: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2B?.alternativeA}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2B1 Primeira tentativa: __ __ __ segundos"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2B: {
                              ...ampalForm?.module2?.m2B,
                              alternativeB: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2B?.alternativeB}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2B2 Segunda tentativa: __ __ __ segundos"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2B: {
                              ...ampalForm?.module2?.m2B,
                              alternativeC: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2B?.alternativeC}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2B3 Terceira tentativa: __ __ __ segundos"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2B: {
                              ...ampalForm?.module2?.m2B,
                              alternativeD: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2B?.alternativeD}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2B4 Melhor tempo para andar quatro metros: __ __ __ segundos"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2B: {
                              ...ampalForm?.module2?.m2B,
                              alternativeE: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2B?.alternativeE}
                    />
                  </Row>
                  <Row className="mt-2" style={{ flexDirection: 'column' }}>
                    <p>{'4,82 segundos = 4 pontos'}</p>
                    <p>{'4,82 – 6,20 segundos = 3 pontos'}</p>
                    <p>{'6,21 – 8,70 segundos = 2 pontos'}</p>
                    <p>{'>8,70 segundos =1 ponto'}</p>
                    <p>{'Incapaz de completar = 0 pontos'}</p>
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2B4t __ __  (pontuação do tempo de caminhada)"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2B: {
                              ...ampalForm?.module2?.m2B,
                              alternativeF: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2B?.alternativeF}
                    />
                  </Row>
                  <Row className="mt-4">
                    <p>M2C Teste de levantar-se da cadeira</p>
                  </Row>
                  <Row>
                    <p>
                      Peça que se levante 5 vezes da cadeira, o mais rápido possível, com as costas
                      retas e os braços cruzados. O teste inicia sentado e termina na posição em pé.
                      Meça o tempo que leva para levantar-se às 5 vezes. Lembrando que o teste deve
                      ser feito na presença de um acompanhante que fique perto para segurar o
                      participantes para não cair.
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2C: {
                                  ...ampalForm?.module2?.m2C,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2C?.flag === 0}
                        />
                        <span className="radio-label ml-1">
                          não conseguiu levantar-se da cadeira nenhuma vez
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2C: {
                                  ...ampalForm?.module2?.m2C,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2C?.flag === 1}
                        />
                        <span className="radio-label ml-1">
                          conseguiu levantar-se menos de 5 vezes
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module2: {
                                ...ampalForm?.module2,
                                m2C: {
                                  ...ampalForm?.module2?.m2C,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module2?.m2C?.flag === 2}
                        />
                        <span className="radio-label ml-1">nenhuma das anteriores</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2C1 - Quantas"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2C: {
                              ...ampalForm?.module2?.m2C,
                              alternativeA: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2C?.alternativeA}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2C2 - Tempo para levantar-se de uma cadeira cinco vezes: __ __ __ segundos"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2C: {
                              ...ampalForm?.module2?.m2C,
                              alternativeB: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2C?.alternativeB}
                    />
                  </Row>
                  <Row className="mt-2" style={{ flexDirection: 'column' }}>
                    <p>{'<11,19 segundos = 4 pontos'}</p>
                    <p>{'11,2 – 13,69 segundos = 3 pontos'}</p>
                    <p>{'13,7 – 16,69 segundos = 2 pontos'}</p>
                    <p>{'16,7 – 59,9 segundos = 1 pontos '}</p>
                    <p>{'>60 segundos ou incapaz de completar = 0 pontos'}</p>
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="Pontuação final do SPPB (M2) = soma das pontuações dos três testes acima."
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2C: {
                              ...ampalForm?.module2?.m2C,
                              alternativeD: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2C?.alternativeD}
                    />
                  </Row>
                  <Row className="mt-4">
                    <h5 id="m2d">M2D Teste da “Garrafa”</h5>
                  </Row>
                  <Row>
                    <p>
                      O teste da garrafa consiste em contar o número de vezes que o participante
                      consegue levantar uma garrafa pet em 30 segundos em cada braço. A garrafa deve
                      conter 500ml de água ou líquido não perigoso (exemplo garrafa d'água ou
                      detergente de cozinha). Inicia-se com o participante sentado com as costas
                      apoiadas no apoio da cadeira e os pés bem apoiados no solo e afastados à
                      largura dos ombros. A garrafa pet deve estar firme na mão do participante. O
                      teste inicia com o antebraço em posição inferior, ao lado da cadeira,
                      perpendicular ao solo. Ao sinal de “partida”, o participante, roda
                      gradualmente a palma da mão para cima enquanto faz a flexão do antebraço no
                      sentido completo do movimento; depois regressa à posição inicial de extensão.
                      O avaliador deve estar junto do participante do lado do braço a ser avaliado,
                      colocando os seus dedos no bíceps do executante, de modo a estabilizar o
                      antebraço e assegurar que seja realizada a extensão completa. O teste será
                      realizado também em três repetições intercaladas com 1 minuto de descanso.{' '}
                    </p>
                    <p>Teste deve ser feito com a mão dominante</p>
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2DA - Repetições na primeira tentativa"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2D: {
                              ...ampalForm?.module2?.m2D,
                              alternativeA: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2D?.alternativeA}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2DB - Repetições na segunda tentativa"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2D: {
                              ...ampalForm?.module2?.m2D,
                              alternativeB: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2D?.alternativeB}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2DC - Repetições na terceira tentativa"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2D: {
                              ...ampalForm?.module2?.m2D,
                              alternativeC: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2D?.alternativeC}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M2Dt - Melhor número de repetições foi"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module2: {
                            ...ampalForm?.module2,
                            m2D: {
                              ...ampalForm?.module2?.m2D,
                              alternativeD: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module2?.m2D?.alternativeD}
                    />
                  </Row>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 3 - Vitalidade" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <Row className="mt-2">
                    <p>M3A Perda de peso nos últimos 3 meses</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3A: {
                                  ...ampalForm?.module3?.m3A,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3A?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 = superior a três quilos</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3A: {
                                  ...ampalForm?.module3?.m3A,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3A?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 = não sabe informar</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3A: {
                                  ...ampalForm?.module3?.m3A,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3A?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 = entre um e três quilos</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3A: {
                                  ...ampalForm?.module3?.m3A,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3A?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 = sem perda de peso</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-2">
                    <p>
                      M3B Nos últimos três meses houve diminuição da ingesta alimentar devido a
                      perda de apetite, problemas digestivos ou dificuldade para mastigar ou
                      deglutir?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3B: {
                                  ...ampalForm?.module3?.m3B,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3B?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 = diminuição grave da ingesta</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3B: {
                                  ...ampalForm?.module3?.m3B,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3B?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 = diminuição moderada da ingesta</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3B: {
                                  ...ampalForm?.module3?.m3B,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3B?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 = sem diminuição da ingesta</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>Mini Avaliação Nutricional MNA®</p>
                  </Row>
                  <Row>
                    <p>
                      TRIAGEM DA MNA® para pontuar risco nutricional (pode ser respondido pelo
                      próprio longevo ou pelo cuidador/familiar, sem interferência)
                    </p>
                  </Row>
                  <Row>
                    <p>
                      M3C Nos últimos três meses houve diminuição da ingesta alimentar devido a
                      perda de apetite, problemas digestivos ou dificuldade para mastigar ou
                      deglutir?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3C: {
                                  ...ampalForm?.module3?.m3C,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3C?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 = diminuição grave da ingesta</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3C: {
                                  ...ampalForm?.module3?.m3C,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3C?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 = diminuição moderada da ingesta</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3C: {
                                  ...ampalForm?.module3?.m3C,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3C?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 = sem diminuição da ingesta</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M3D Qual a sua mobilidade?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3D: {
                                  ...ampalForm?.module3?.m3D,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3D?.flag === 0}
                        />
                        <span className="radio-label ml-1">
                          0 = restrito ao leito ou à cadeira de rodas
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3D: {
                                  ...ampalForm?.module3?.m3D,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3D?.flag === 1}
                        />
                        <span className="radio-label ml-1">
                          1 = deambula mas não é capaz de sair de casa sem auxílio
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3D: {
                                  ...ampalForm?.module3?.m3D,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3D?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 = normal</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M3E Passou por algum estresse psicológico (perda de familiar, amigo, trauma,
                      financeiro, etc.) ou doença aguda (internação ou ida à
                      emergência/urgência/UPA) nos últimos três meses?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3E: {
                                  ...ampalForm?.module3?.m3E,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3E?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 = sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3E: {
                                  ...ampalForm?.module3?.m3E,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3E?.flag === 1}
                        />
                        <span className="radio-label ml-1">2 = não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M3G Em relação ao seu peso e altura atuais ou mais recentes (dentro de 1 mês)
                      quais seriam os valores?{' '}
                    </p>
                  </Row>
                  <Row className="">
                    <Input
                      label="M3GA Peso Kg"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module3: {
                            ...ampalForm?.module3,
                            m3GA: {
                              ...ampalForm?.module3?.m3GA,
                              alternativeA: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module3?.m3GA?.alternativeA}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M3GB Altura cm- tornar o ( ) não soube responder como uma opção"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module3: {
                            ...ampalForm?.module3,
                            m3GA: {
                              ...ampalForm?.module3?.m3GA,
                              alternativeB: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module3?.m3GA?.alternativeB}
                    />
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M3GC Calcular o IMC: peso (kg)/ altura (m)²"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module3: {
                            ...ampalForm?.module3,
                            m3GA: {
                              ...ampalForm?.module3?.m3GA,
                              alternativeC: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module3?.m3GA?.alternativeC}
                    />
                    <Col sm={2} className="mt-4">
                      <Button onClick={handleIMC}>Calcular IMC</Button>
                    </Col>
                  </Row>
                  <Row className="mt-2" style={{ flexDirection: 'column' }}>
                    <p>{'0 = IMC < 19'}</p>
                    <p>{'1 = 19 ≤ IMC < 21'}</p>
                    <p>{'2 = 21 ≤ IMC < 23'}</p>
                    <p>{'3 = IMC ≥ 23'}</p>
                  </Row>
                  <Row>
                    <p>
                      M3H Circunferência da perna. Pegue uma fita métrica e meça ao redor da sua
                      panturrilha (batata da perna) na maior circunferência com o pé apoiado no
                      chão. Caso não tenha a fita métrica, pode utilizar um barbante ou fio, para
                      fazer a medida e colocar numa régua para dar a medida em centímetros.{' '}
                    </p>
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="M3HA Qual valor encontrado? cm"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module3: {
                            ...ampalForm?.module3,
                            m3H: {
                              ...ampalForm?.module3?.m3H,
                              alternativeA: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module3?.m3H?.alternativeA}
                    />
                  </Row>
                  <Row className="mt-4" style={{ flexDirection: 'column' }}>
                    <p>{'M3HB Pontuação'}</p>
                  </Row>
                  <Row style={{ flexDirection: 'column' }}>
                    <p>{'0 = PP < 31'}</p>
                    <p>{'3 = PP ≥ 31'}</p>
                  </Row>
                  <Row className="mt-2">
                    <Input
                      label="Pontuação Total do MNA® (M3t): (somatório de todas as perguntas anteriores, exceto M3HB se M3GC for>0)"
                      onChange={(e: any) => {
                        setAmpalForm({
                          ...ampalForm,
                          module3: {
                            ...ampalForm?.module3,
                            m3H: {
                              ...ampalForm?.module3?.m3H,
                              alternativeB: e.target.value,
                            },
                          },
                        });
                      }}
                      value={ampalForm?.module3?.m3H?.alternativeB}
                    />
                  </Row>
                  <Row className="mt-4">
                    <p>M3I Classificação da MNA®:</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3I: {
                                  ...ampalForm?.module3?.m3I,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3I?.flag === 0}
                        />
                        <span className="radio-label ml-1">
                          12-14 pontos: estado nutricional normal
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3I: {
                                  ...ampalForm?.module3?.m3I,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3I?.flag === 1}
                        />
                        <span className="radio-label ml-1">
                          8-11 pontos: sob risco de desnutrição
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module3: {
                                ...ampalForm?.module3,
                                m3I: {
                                  ...ampalForm?.module3?.m3I,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module3?.m3I?.flag === 2}
                        />
                        <span className="radio-label ml-1">0-7 pontos: desnutrido</span>
                      </Label>
                    </FormGroup>
                  </Row>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 4 - Capacidade Visual" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <Row>
                    <p>
                      M4A Você tem algum problema com os olhos? Dificuldade de enxergar para longe,
                      dificuldade de ler, doenças oculares ou está atualmente em tratamento médico
                      (por exemplo, para diabetes ou pressão alta)?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4A: {
                                  ...ampalForm?.module4?.m4A,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4A?.flag === 0}
                        />
                        <span className="radio-label ml-1"> sim (ou acha que sim)</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4A: {
                                  ...ampalForm?.module4?.m4A,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4A?.flag === 1}
                        />
                        <span className="radio-label ml-1">não (ou acha que não)</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M4B Exame da Visão - autopercepção - Você considera sua visão:</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4B: {
                                  ...ampalForm?.module4?.m4B,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4B?.flag === 0}
                        />
                        <span className="radio-label ml-1">Boa ou ótima</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4B: {
                                  ...ampalForm?.module4?.m4B,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4B?.flag === 1}
                        />
                        <span className="radio-label ml-1">Regular</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4B: {
                                  ...ampalForm?.module4?.m4B,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4B?.flag === 2}
                        />
                        <span className="radio-label ml-1">Ruim ou péssima</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M4B1 Quanto a sua visão atrapalha as suas atividades cotidianas?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4B1: {
                                  ...ampalForm?.module4?.m4B1,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4B1?.flag === 0}
                        />
                        <span className="radio-label ml-1">nada</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4B1: {
                                  ...ampalForm?.module4?.m4B1,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4B1?.flag === 1}
                        />
                        <span className="radio-label ml-1">pouco</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4B1: {
                                  ...ampalForm?.module4?.m4B1,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4B1?.flag === 2}
                        />
                        <span className="radio-label ml-1">mais ou menos</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4B1: {
                                  ...ampalForm?.module4?.m4B1,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4B1?.flag === 3}
                        />
                        <span className="radio-label ml-1">muito</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4B1: {
                                  ...ampalForm?.module4?.m4B1,
                                  flag: 4,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4B1?.flag === 4}
                        />
                        <span className="radio-label ml-1">cega</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M4C Usa óculos?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4C: {
                                  ...ampalForm?.module4?.m4C,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4C?.flag === 0}
                        />
                        <span className="radio-label ml-1">sim (perto ou longe)</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4C: {
                                  ...ampalForm?.module4?.m4C,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4C?.flag === 1}
                        />
                        <span className="radio-label ml-1">não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M4D Acompanhamento regular com oftalmologista, quando foi a última vez que
                      consultou o oculista (mesmo se não usar óculos) data aproximada
                    </p>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Input
                        label="M4D1 (dia , mês, ano. Não lembra)"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module4: {
                              ...ampalForm?.module4,
                              m4D: {
                                ...ampalForm?.module4?.m4D,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module4?.m4D?.alternativeA}
                      />
                    </Col>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4D: {
                                  ...ampalForm?.module4?.m4D,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4D?.flag === 0}
                        />
                        <span className="radio-label ml-1">sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module4: {
                                ...ampalForm?.module4,
                                m4D: {
                                  ...ampalForm?.module4?.m4D,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module4?.m4D?.flag === 1}
                        />
                        <span className="radio-label ml-1">não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 5 - Capacidade Auditiva" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <Row>
                    <p>Agora vou falar bem baixinho para ver se você escuta:</p>
                  </Row>
                  <Row className="mt-2">
                    <p>M5A Ouve sussurros?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5A: {
                                  ...ampalForm?.module5?.m5A,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5A?.flag === 0}
                        />
                        <span className="radio-label ml-1">sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5A: {
                                  ...ampalForm?.module5?.m5A,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5A?.flag === 1}
                        />
                        <span className="radio-label ml-1">não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M5B Vamos fazer outro teste. Quando aparecer uma bolinha verde (1000 Hz) quero
                      saber se escuta algum barulho, serão três testes:
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5B: {
                                  ...ampalForm?.module5?.m5B,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5B?.flag === 0}
                        />
                        <span className="radio-label ml-1">ouviu o teste 1</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5B: {
                                  ...ampalForm?.module5?.m5B,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5B?.flag === 1}
                        />
                        <span className="radio-label ml-1">ouviu o teste 2</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5B: {
                                  ...ampalForm?.module5?.m5B,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5B?.flag === 2}
                        />
                        <span className="radio-label ml-1">ouviu o teste 3</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M5C Vamos fazer outro teste mas com uma bolinha laranja (3000 Hz) quero saber
                      se escuta algum barulho, serão três testes:
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5C: {
                                  ...ampalForm?.module5?.m5C,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5C?.flag === 0}
                        />
                        <span className="radio-label ml-1">ouviu o teste 1</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5C: {
                                  ...ampalForm?.module5?.m5C,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5C?.flag === 1}
                        />
                        <span className="radio-label ml-1">ouviu o teste 2</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5C: {
                                  ...ampalForm?.module5?.m5C,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5C?.flag === 2}
                        />
                        <span className="radio-label ml-1">ouviu o teste 3</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M5D Como você considera sua audição:</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5D: {
                                  ...ampalForm?.module5?.m5D,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5D?.flag === 0}
                        />
                        <span className="radio-label ml-1">Boa ou ótima</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5D: {
                                  ...ampalForm?.module5?.m5D,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5D?.flag === 1}
                        />
                        <span className="radio-label ml-1">Regular</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5D: {
                                  ...ampalForm?.module5?.m5D,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5D?.flag === 2}
                        />
                        <span className="radio-label ml-1">Ruim ou péssima</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M5E Quanto a sua audição atrapalha as suas atividades cotidianas?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5E: {
                                  ...ampalForm?.module5?.m5E,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5E?.flag === 0}
                        />
                        <span className="radio-label ml-1">nada</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5E: {
                                  ...ampalForm?.module5?.m5E,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5E?.flag === 1}
                        />
                        <span className="radio-label ml-1">pouco</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5E: {
                                  ...ampalForm?.module5?.m5E,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5E?.flag === 2}
                        />
                        <span className="radio-label ml-1">mais ou menos</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5E: {
                                  ...ampalForm?.module5?.m5E,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5E?.flag === 3}
                        />
                        <span className="radio-label ml-1">muito</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module5: {
                                ...ampalForm?.module5,
                                m5E: {
                                  ...ampalForm?.module5?.m5E,
                                  flag: 4,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module5?.m5E?.flag === 4}
                        />
                        <span className="radio-label ml-1">surdo (a)</span>
                      </Label>
                    </FormGroup>
                  </Row>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 6 - Capacidade Psicológica" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <Row className="mt-2">
                    <p>M6A Sentiu-se para baixo, deprimido ou sem esperança</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6A: {
                                  ...ampalForm?.module6?.m6A,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6A?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6A: {
                                  ...ampalForm?.module6?.m6A,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6A?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M6B Ter pouco interesse ou prazer em fazer as coisas?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6B: {
                                  ...ampalForm?.module6?.m6B,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6B?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6B: {
                                  ...ampalForm?.module6?.m6B,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6B?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      PHQ9 M6C1 Dificuldade para pegar no sono ou permanecer dormindo, ou dormir
                      mais do que de costume?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C1: {
                                  ...ampalForm?.module6?.m6C1,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C1?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 - Nenhum dia</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C1: {
                                  ...ampalForm?.module6?.m6C1,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C1?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 - Menos da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C1: {
                                  ...ampalForm?.module6?.m6C1,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C1?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 - Mais da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C1: {
                                  ...ampalForm?.module6?.m6C1,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C1?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 - Quase todos os dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M6C2 Se sentir cansado ou com pouca energia?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C2: {
                                  ...ampalForm?.module6?.m6C2,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C2?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 - Nenhum dia</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C2: {
                                  ...ampalForm?.module6?.m6C2,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C2?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 - Menos da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C2: {
                                  ...ampalForm?.module6?.m6C2,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C2?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 - Mais da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C2: {
                                  ...ampalForm?.module6?.m6C2,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C2?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 - Quase todos os dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M6C3 Falta de apetite ou comendo demais?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C3: {
                                  ...ampalForm?.module6?.m6C3,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C3?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 - Nenhum dia</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C3: {
                                  ...ampalForm?.module6?.m6C3,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C3?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 - Menos da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C3: {
                                  ...ampalForm?.module6?.m6C3,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C3?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 - Mais da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C3: {
                                  ...ampalForm?.module6?.m6C3,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C3?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 - Quase todos os dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M6C4 Se sentir mal consigo mesmo, ou achar que você é um fracasso ou que
                      decepcionou sua família ou você mesmo?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C4: {
                                  ...ampalForm?.module6?.m6C4,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C4?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 - Nenhum dia</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C4: {
                                  ...ampalForm?.module6?.m6C4,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C4?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 - Menos da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C4: {
                                  ...ampalForm?.module6?.m6C4,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C4?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 - Mais da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C4: {
                                  ...ampalForm?.module6?.m6C4,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C4?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 - Quase todos os dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M6C5 Dificuldade para se concentrar nas coisas, como ler o jornal ou ver
                      televisão?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C5: {
                                  ...ampalForm?.module6?.m6C5,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C5?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 - Nenhum dia</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C5: {
                                  ...ampalForm?.module6?.m6C5,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C5?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 - Menos da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C5: {
                                  ...ampalForm?.module6?.m6C5,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C5?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 - Mais da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C5: {
                                  ...ampalForm?.module6?.m6C5,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C5?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 - Quase todos os dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M6C6 Lentidão para se movimentar ou falar, a ponto das outras pessoas
                      perceberem?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C6: {
                                  ...ampalForm?.module6?.m6C6,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C6?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 - Nenhum dia</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C6: {
                                  ...ampalForm?.module6?.m6C6,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C6?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 - Menos da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C6: {
                                  ...ampalForm?.module6?.m6C6,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C6?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 - Mais da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C6: {
                                  ...ampalForm?.module6?.m6C6,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C6?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 - Quase todos os dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M6C7 Estar tão agitado ou inquieto que você fica andando de um lado para o
                      outro muito mais do que de costume?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C7: {
                                  ...ampalForm?.module6?.m6C7,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C7?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 - Nenhum dia</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C7: {
                                  ...ampalForm?.module6?.m6C7,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C7?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 - Menos da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C7: {
                                  ...ampalForm?.module6?.m6C7,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C7?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 - Mais da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C7: {
                                  ...ampalForm?.module6?.m6C7,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C7?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 - Quase todos os dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M6C8 Pensar em se ferir de alguma maneira ou que seria melhor estar morto?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C8: {
                                  ...ampalForm?.module6?.m6C8,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C8?.flag === 0}
                        />
                        <span className="radio-label ml-1">0 - Nenhum dia</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C8: {
                                  ...ampalForm?.module6?.m6C8,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C8?.flag === 1}
                        />
                        <span className="radio-label ml-1">1 - Menos da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C8: {
                                  ...ampalForm?.module6?.m6C8,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C8?.flag === 2}
                        />
                        <span className="radio-label ml-1">2 - Mais da metade dos dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C8: {
                                  ...ampalForm?.module6?.m6C8,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C8?.flag === 3}
                        />
                        <span className="radio-label ml-1">3 - Quase todos os dias</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={6}>
                      <Input
                        label="M6Ct Total de pontos"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module6: {
                              ...ampalForm?.module6,
                              m6C8: {
                                ...ampalForm?.module6?.m6C8,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module6?.m6C8?.alternativeA}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <p>Classificação (M6C)</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C9: {
                                  ...ampalForm?.module6?.m6C9,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C9?.flag === 0}
                        />
                        <span className="radio-label ml-1">0-4 pontos – sem depressão.</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C9: {
                                  ...ampalForm?.module6?.m6C9,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C9?.flag === 1}
                        />
                        <span className="radio-label ml-1">
                          5-9 pontos – transtorno depressivo leve.
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C9: {
                                  ...ampalForm?.module6?.m6C9,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C9?.flag === 2}
                        />
                        <span className="radio-label ml-1">
                          10-14 pontos – transtorno depressivo moderado.
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C9: {
                                  ...ampalForm?.module6?.m6C9,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C9?.flag === 3}
                        />
                        <span className="radio-label ml-1">
                          15-19 pontos – transtorno depressivo moderadamente grave.
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module6: {
                                ...ampalForm?.module6,
                                m6C9: {
                                  ...ampalForm?.module6?.m6C9,
                                  flag: 4,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module6?.m6C9?.flag === 4}
                        />
                        <span className="radio-label ml-1">
                          20 a 27 pontos – transtorno depressivo grave.
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 7 - Polifarmácia" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <Row>
                    <Col sm={6}>
                      <Input
                        label="M7A Quantos medicamentos o(a) senhor(a) usa? Considerando medicamentos receitados por algum profissional de saúde ou não, que use continuamente.
                        número de medicamentos:
                        "
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module7: {
                              ...ampalForm?.module7,
                              m7A: {
                                ...ampalForm?.module7?.m7A,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module7?.m7A?.alternativeA}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={6}>
                      <Input
                        label="Poderia anotar quais são eles? (Lista da ANVISA)
                        M7a1 Quais os medicamentos que toma:
                        "
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module7: {
                              ...ampalForm?.module7,
                              m7A: {
                                ...ampalForm?.module7?.m7A,
                                alternativeB: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module7?.m7A?.alternativeB}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <p>M7B O(a) senhor(a) acha que algum dos medicamentos que toma lhe faz mal?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module7: {
                                ...ampalForm?.module7,
                                m7B: {
                                  ...ampalForm?.module7?.m7B,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module7?.m7B?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module7: {
                                ...ampalForm?.module7,
                                m7B: {
                                  ...ampalForm?.module7?.m7B,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module7?.m7B?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M7C Alguém o/a ajuda a tomar ou lembrar do seu medicamento? </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module7: {
                                ...ampalForm?.module7,
                                m7C: {
                                  ...ampalForm?.module7?.m7C,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module7?.m7C?.flag === 0}
                        />
                        <span className="radio-label ml-1">Ninguém (toma sozinho)</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module7: {
                                ...ampalForm?.module7,
                                m7C: {
                                  ...ampalForm?.module7?.m7C,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module7?.m7C?.flag === 1}
                        />
                        <span className="radio-label ml-1">Cuidador</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module7: {
                                ...ampalForm?.module7,
                                m7C: {
                                  ...ampalForm?.module7?.m7C,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module7?.m7C?.flag === 2}
                        />
                        <span className="radio-label ml-1">Familiar</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module7: {
                                ...ampalForm?.module7,
                                m7C: {
                                  ...ampalForm?.module7?.m7C,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module7?.m7C?.flag === 3}
                        />
                        <span className="radio-label ml-1">Outra pessoa</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M7D O(a) senhor(a) tem dificuldades de conseguir algum dos medicamentos
                      prescritos?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module7: {
                                ...ampalForm?.module7,
                                m7D: {
                                  ...ampalForm?.module7?.m7D,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module7?.m7D?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module7: {
                                ...ampalForm?.module7,
                                m7D: {
                                  ...ampalForm?.module7?.m7D,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module7?.m7D?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 8 - Assistência e Apoio Social" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <Row className="mt-2">
                    <p>M8A Você tem dificuldade em deslocar-se dentro de casa?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8A: {
                                  ...ampalForm?.module8?.m8A,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8A?.flag === 0}
                        />
                        <span className="radio-label ml-1">3 - não consegue</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8A: {
                                  ...ampalForm?.module8?.m8A,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8A?.flag === 1}
                        />
                        <span className="radio-label ml-1">2 - tem muita dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8A: {
                                  ...ampalForm?.module8?.m8A,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8A?.flag === 2}
                        />
                        <span className="radio-label ml-1">1 - tem alguma dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8A: {
                                  ...ampalForm?.module8?.m8A,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8A?.flag === 3}
                        />
                        <span className="radio-label ml-1">0 - Não tem dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M8B Você tem dificuldade em usar o banheiro ou (privada)?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8B: {
                                  ...ampalForm?.module8?.m8B,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8B?.flag === 0}
                        />
                        <span className="radio-label ml-1">3 - não consegue</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8B: {
                                  ...ampalForm?.module8?.m8B,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8B?.flag === 1}
                        />
                        <span className="radio-label ml-1">2 - tem muita dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8B: {
                                  ...ampalForm?.module8?.m8B,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8B?.flag === 2}
                        />
                        <span className="radio-label ml-1">1 - tem alguma dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8B: {
                                  ...ampalForm?.module8?.m8B,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8B?.flag === 3}
                        />
                        <span className="radio-label ml-1">0 - Não tem dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M8C Você tem dificuldade em se vestir?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8C: {
                                  ...ampalForm?.module8?.m8C,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8C?.flag === 0}
                        />
                        <span className="radio-label ml-1">3 - não consegue</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8C: {
                                  ...ampalForm?.module8?.m8C,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8C?.flag === 1}
                        />
                        <span className="radio-label ml-1">2 - tem muita dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8C: {
                                  ...ampalForm?.module8?.m8C,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8C?.flag === 2}
                        />
                        <span className="radio-label ml-1">1 - tem alguma dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8C: {
                                  ...ampalForm?.module8?.m8C,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8C?.flag === 3}
                        />
                        <span className="radio-label ml-1">0 - Não tem dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M8D Você tem dificuldade em tomar banho de banheira ou banheiro?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8D: {
                                  ...ampalForm?.module8?.m8D,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8D?.flag === 0}
                        />
                        <span className="radio-label ml-1">3 - não consegue</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8D: {
                                  ...ampalForm?.module8?.m8D,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8D?.flag === 1}
                        />
                        <span className="radio-label ml-1">2 - tem muita dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8D: {
                                  ...ampalForm?.module8?.m8D,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8D?.flag === 2}
                        />
                        <span className="radio-label ml-1">1 - tem alguma dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8D: {
                                  ...ampalForm?.module8?.m8D,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8D?.flag === 3}
                        />
                        <span className="radio-label ml-1">0 - Não tem dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M8E Você tem dificuldade em manter a sua aparência pessoal?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8E: {
                                  ...ampalForm?.module8?.m8E,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8E?.flag === 0}
                        />
                        <span className="radio-label ml-1">3 - não consegue</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8E: {
                                  ...ampalForm?.module8?.m8E,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8E?.flag === 1}
                        />
                        <span className="radio-label ml-1">2 - tem muita dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8E: {
                                  ...ampalForm?.module8?.m8E,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8E?.flag === 2}
                        />
                        <span className="radio-label ml-1">1 - tem alguma dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8E: {
                                  ...ampalForm?.module8?.m8E,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8E?.flag === 3}
                        />
                        <span className="radio-label ml-1">0 - Não tem dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M8F Você tem dificuldade em se alimentar?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8F: {
                                  ...ampalForm?.module8?.m8F,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8F?.flag === 0}
                        />
                        <span className="radio-label ml-1">3 - não consegue</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8F: {
                                  ...ampalForm?.module8?.m8F,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8F?.flag === 1}
                        />
                        <span className="radio-label ml-1">2 - tem muita dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8F: {
                                  ...ampalForm?.module8?.m8F,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8F?.flag === 2}
                        />
                        <span className="radio-label ml-1">1 - tem alguma dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8F: {
                                  ...ampalForm?.module8?.m8F,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8F?.flag === 3}
                        />
                        <span className="radio-label ml-1">0 - Não tem dificuldade</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={6}>
                      <Input
                        label="M8S soma da pontuação do grau de dificuldade __ __"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module8: {
                              ...ampalForm?.module8,
                              m8F: {
                                ...ampalForm?.module8?.m8F,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module8?.m8F?.alternativeA}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <p>M8G O quanto satisfeito(a) você está com o local onde mora?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8G: {
                                  ...ampalForm?.module8?.m8G,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8G?.flag === 0}
                        />
                        <span className="radio-label ml-1">satisfeito</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8G: {
                                  ...ampalForm?.module8?.m8G,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8G?.flag === 1}
                        />
                        <span className="radio-label ml-1">± satisfeito</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8G: {
                                  ...ampalForm?.module8?.m8G,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8G?.flag === 2}
                        />
                        <span className="radio-label ml-1">insatisfeito</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8G: {
                                  ...ampalForm?.module8?.m8G,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8G?.flag === 3}
                        />
                        <span className="radio-label ml-1">Não soube opinar</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M8H Você tem problemas com as suas finanças?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8H: {
                                  ...ampalForm?.module8?.m8H,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8H?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8H: {
                                  ...ampalForm?.module8?.m8H,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8H?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M8I Você se sente só?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8I: {
                                  ...ampalForm?.module8?.m8I,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8I?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sempre ou na maior parte das vezes</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8I: {
                                  ...ampalForm?.module8?.m8I,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8I?.flag === 1}
                        />
                        <span className="radio-label ml-1">às vezes</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8I: {
                                  ...ampalForm?.module8?.m8I,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8I?.flag === 2}
                        />
                        <span className="radio-label ml-1">nunca</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8I: {
                                  ...ampalForm?.module8?.m8I,
                                  flag: 3,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8I?.flag === 3}
                        />
                        <span className="radio-label ml-1">não soube opinar</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      M8J Você consegue participar de atividades de lazer, hobbies, trabalho,
                      voluntariado, ajudar sua família, atividades educacionais ou espirituais
                      importantes para você?
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8J: {
                                  ...ampalForm?.module8?.m8J,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8J?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module8: {
                                ...ampalForm?.module8,
                                m8J: {
                                  ...ampalForm?.module8?.m8J,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module8?.m8J?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Accordeon text="Módulo 9 - Cuidadores" startOpen={false}>
              <Row style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                <Col>
                  <Row className="mt-2">
                    <p>
                      M9A Para alguma das dificuldades referidas anteriormente, recebe ajuda na
                      execução dessa(s) atividade(s)
                    </p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module9: {
                                ...ampalForm?.module9,
                                m9A: {
                                  ...ampalForm?.module9?.m9A,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module9?.m9A?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module9: {
                                ...ampalForm?.module9,
                                m9A: {
                                  ...ampalForm?.module9?.m9A,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module9?.m9A?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module9: {
                                ...ampalForm?.module9,
                                m9A: {
                                  ...ampalForm?.module9?.m9A,
                                  flag: 2,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module9?.m9A?.flag === 2}
                        />
                        <span className="radio-label ml-1">
                          Não se aplica (não referiu dificuldade)
                        </span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>A partir de agora as perguntas referem-se à pessoa que mais lhe ajuda.</p>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={6}>
                      <Input
                        label="M9B Há quanto tempo essa pessoa  lhe ajuda? (tempo em meses)"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module9: {
                              ...ampalForm?.module9,
                              m9A: {
                                ...ampalForm?.module9?.m9A,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module9?.m9A?.alternativeA}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <p>M9C Essa pessoa é familiar?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module9: {
                                ...ampalForm?.module9,
                                m9C: {
                                  ...ampalForm?.module9?.m9C,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module9?.m9C?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module9: {
                                ...ampalForm?.module9,
                                m9C: {
                                  ...ampalForm?.module9?.m9C,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module9?.m9C?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <p>M9D Essa pessoa recebe por este serviço?</p>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module9: {
                                ...ampalForm?.module9,
                                m9D: {
                                  ...ampalForm?.module9?.m9D,
                                  flag: 0,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module9?.m9D?.flag === 0}
                        />
                        <span className="radio-label ml-1">Sim</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row sm={1}>
                    <FormGroup className="" check>
                      <Label className="radio-input" check>
                        <Input
                          type="checkbox"
                          onClick={(e: any) => {
                            setAmpalForm({
                              ...ampalForm,
                              module9: {
                                ...ampalForm?.module9,
                                m9D: {
                                  ...ampalForm?.module9?.m9D,
                                  flag: 1,
                                },
                              },
                            });
                          }}
                          checked={ampalForm?.module9?.m9D?.flag === 1}
                        />
                        <span className="radio-label ml-1">Não</span>
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={6}>
                      <Input
                        label="M9E Quantas horas essa pessoa fica com você? Horas por dia (em média)"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module9: {
                              ...ampalForm?.module9,
                              m9E: {
                                ...ampalForm?.module9?.m9E,
                                alternativeA: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module9?.m9E?.alternativeA}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm={6}>
                      <Input
                        label="M9E1 Quantos dias na semana?"
                        onChange={(e: any) => {
                          setAmpalForm({
                            ...ampalForm,
                            module9: {
                              ...ampalForm?.module9,
                              m9E: {
                                ...ampalForm?.module9?.m9E,
                                alternativeB: e.target.value,
                              },
                            },
                          });
                        }}
                        value={ampalForm?.module9?.m9E?.alternativeB}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <p>
                      Terminamos, obrigado pela sua participação. O(a) Sr(a) deverá receber o
                      relatório desta avaliação em alguns dias.
                    </p>
                  </Row>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        <TummiModal {...modal} />
      </div>
    </Layout>
  );
};
