import React, { useEffect } from 'react';
import { getUserStorage } from './utils/Auth';
import { Routes } from './components/Routes';
import { setUser } from './slices/user';
import { useAppDispatch } from './store';
import { useHistory } from 'react-router-dom';

export const App = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  useEffect(() => {
    const usr = getUserStorage();
    if (Object.keys(usr).length > 0) {
      dispatch(setUser(usr));
      const _history: any = history.location.state;
      if (_history && _history.from) {
        history.push(_history.from);
      } else if (history.location.pathname === '/login') {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        history.push(`${process.env.PUBLIC_URL}/${usr.role}/home`);
      }
    }
  }, [dispatch, history]);
  return <Routes />;
};

export default App;
