import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Layout } from '../../../components/Layout';
import { RootState, useAppDispatch } from '../../../store';
import { SchedulesTable } from '../../../components/SchedulesTable';
import { TummiModal, ITummiModalProps } from '../../../components/Modal';
import { Specialities } from '../../../utils/EnumSpecialties';
import { Field, Form, FormSpy } from 'react-final-form';
import { DatePicker } from '../../../components/Datepicker';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { PatientUser } from '../../../slices/admin';
import { getPatient } from '../../../slices/partner';
import {
  AvaiableSchedule,
  SearchSpecialityByDay,
  CreateAttendace,
} from '../../../slices/attendance';
import { PatientInfo } from '../../../components/PatientInfo';
import { ReturnLink } from '../../../components/ReturnLink';
import { Select } from '../../../components/Select';

import './styles.scss';
import { Company, getCompany } from '../../../slices/company';

type Params = { id: string };
interface UserFilter {
  attendanceDay?: string;
  speciality?: string;
}

export const NewAttendance = () => {
  const { id } = useParams<Params>();
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const [patient, setPatient] = useState<PatientUser>();
  const [company, setCompany] = useState<Company>();
  const [avaiableSchedule, setAvaiableSchedule] = useState<AvaiableSchedule[]>([]);

  const [userFilter, setUserFilter] = useState<UserFilter>({
    attendanceDay: new Date().toISOString(),
  });

  const [showModal, setShowModal] = useState(false);

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const handleChange = (value: any, formattedValue: any) => {
    setUserFilter({
      ...userFilter,
      attendanceDay: value ? value : null,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getPatient({ id }));
      setPatient(response.data);
    };
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    const fetchData = async () => {
      var companyId = patient?.companyTelemedicineId;
      const response = await dispatch(getCompany({ id: companyId }));
      setCompany(response.data);
    };
    fetchData();
  }, [dispatch, patient]);

  const onSubmit = async () => {
    const response = await dispatch(
      SearchSpecialityByDay({
        date: userFilter.attendanceDay,
        speciality: userFilter.speciality || '',
        doctorId: '',
        companyId: user.companyId,
      }),
    );

    setAvaiableSchedule(response.data);

    if (response.data.length === 0) {
      setModal({
        isOpen: true,
        body: 'Não foram encontrados horários disponiveis.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => toggle(),
        okButton: 'Ok',
      });
    }
  };

  const createAttendanceCallback = async (avaiableSchedule: AvaiableSchedule) => {
    setModal({
      isOpen: true,
      body: 'Só mais um pouquinho, estamos criando o seu atendimento.',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      leavingCallback: () => true,
      showCancelButton: true,
    });

    const attendance: AvaiableSchedule = {
      doctorId: avaiableSchedule.doctorId,
      patientId: avaiableSchedule.patientId,
      speciality: avaiableSchedule.speciality,
      date: avaiableSchedule.date,
      dateTimeHour: avaiableSchedule.dateTimeHour,
      paidBy: 3,
      value: 0,
    };

    const response = await dispatch(CreateAttendace(attendance));

    if (response.data.success) {
      setModal({
        isOpen: true,
        body:
          'Atendimento salvo com succeso. Por favor lembre o paciente de chegar ao posto de saúde com antecedencia.',
        className: '',
        title: 'Mensagem',
        toggle: () => history.push(`${process.env.PUBLIC_URL}/partner/patients`),
        leavingCallback: () => {
          if (user.role === 'partner') {
            history.push(`${process.env.PUBLIC_URL}/partner/patients`);
          } else {
            user.isNonMedicalCompany
              ? history.push(`${process.env.PUBLIC_URL}/doctor/home`)
              : history.push(`${process.env.PUBLIC_URL}/partner/patients`);
          }
        },
        okCallback: () => {
          if (user.role === 'partner') {
            history.push(`${process.env.PUBLIC_URL}/partner/patients`);
          } else {
            user.isNonMedicalCompany
              ? history.push(`${process.env.PUBLIC_URL}/doctor/home`)
              : history.push(`${process.env.PUBLIC_URL}/partner/patients`);
          }
        },
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body: 'Erro ao salvar atendimento, por favor tente novamente em instantes.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => toggle(),
        okCallback: () => toggle(),
        okButton: 'Ok',
      });
    }
  };

  const onCreateAttendance = async () => {
    const _avaiableSchedule = avaiableSchedule.find(element => {
      return element.checked;
    });

    const _avaiable = {
      ..._avaiableSchedule,
      patientId: id,
      date: userFilter.attendanceDay,
    };

    if (_avaiableSchedule) {
      setModal({
        isOpen: true,
        body: 'Você tem certeza que deseja salvar este horário?',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => toggle(),
        okCallback: () => createAttendanceCallback(_avaiable),
        okButton: 'Ok',
      });
    }
  };

  const onFormChange = (values: Partial<UserFilter>) => {
    setUserFilter({ ...values });
  };

  return (
    <Layout type={user.role}>
      <div className="adminstrative-new-attendance">
        <Row className="mt-4">
          <Col sm={5} className="justify-content-start" style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="ATENDIMENTO"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
        </Row>
        <PatientInfo
          firstName={patient?.firstName}
          lastName={patient?.lastName}
          dob={patient?.dob}
          email={patient?.email}
          userImage={patient?.image}
          ddd={patient?.ddd}
          phone={patient?.phone}
          gender={patient?.gender}
        />
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...userFilter }}
          render={({ handleSubmit, submitting }) => (
            <>
              <form>
                <FormSpy onChange={({ values }) => onFormChange(values)} />
              </form>
              <Row className="mt-5 mb-4 justify-content-center">
                <Col sm={2}>
                  <Field
                    name="attendanceDay"
                    validate={required}
                    render={({ input, meta }) => (
                      <DatePicker
                        input={input}
                        metaError={meta}
                        handleChange={(v: any, f: any) => handleChange(v, f)}
                        label="Dia *"
                        submitting={submitting}
                      />
                    )}
                  />
                </Col>
                {!user?.isNonMedicalCompany ? (
                  <Col sm={6}>
                    <Field
                      name="speciality"
                      validate={required}
                      render={({ input, meta }) => (
                        <Select
                          name={input.name}
                          id={input.name}
                          label="Especialidade *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          type="select"
                          disabled={submitting}
                          options={Specialities}
                          required={true}
                          metaError={meta}
                        />
                      )}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                <Col sm={4}>
                  <Button
                    className="mt-4"
                    id="controlButton"
                    onClick={() => handleSubmit()}
                    disabled={submitting}
                  >
                    PESQUISAR HORÁRIOS
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: '60px' }}>
                <SchedulesTable
                  avaiableSchedules={avaiableSchedule}
                  setAvaiableSchedule={(value: any) => setAvaiableSchedule(value)}
                  isLoading={app.isLoading}
                />
              </Row>
              <Row className="mt-5 justify-content-center">
                <Col sm={4}>
                  <Button onClick={() => onCreateAttendance()}>SALVAR ATENDIMENTO</Button>
                </Col>
              </Row>
            </>
          )}
        />
        <TummiModal {...modal} />
      </div>
    </Layout>
  );
};
