import { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, Input as ReactstrapInput, FormGroup, Label } from 'reactstrap';
import { Layout } from '../../../components/Layout';
import { Input } from '../../../components/Input';
import { RootState, useAppDispatch } from '../../../store';
import { getDoctor } from '../../../slices/partner';
import { DoctorUser, MedicalSpecialty } from '../../../slices/admin';
import { updateDoctorStatus, updateDoctorUser } from '../../../slices/doctor';
import { TummiModal, ITummiModalProps } from '../../../components/Modal';
import { Specialities } from '../../../utils/EnumSpecialties';
import { Select } from '../../../components/Select';
import { State } from '../../../utils/EnumStates';
import { DatePicker } from '../../../components/Datepicker';
import { isValidCep } from '../../../utils/ValidCep';
import { Button } from '../../../components/Button';
import { SelectMulti } from '../../../components/SelectMulti';
import { TextArea } from '../../../components/TextArea';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';
import { GenericAvatar } from '../../../components/Avatar';

import { maskCPF, maskDDD, maskPhoneNumber, maskZipCode } from '../../../utils/Mask';
import formatString from 'format-string-by-pattern';

import bancosBrasileiros from 'bancos-brasileiros';

import './styles.scss';

const { consultarCep } = require('correios-brasil');

type OptionType = {
  value?: string;
  label?: string;
};

type Params = { id: string };

export const DoctorProfile = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const user = useSelector((state: RootState) => state.user);

  const bancos = bancosBrasileiros.map(function name(params: any) {
    return {
      value: params.COMPE,
      label: `${params.LongName.toUpperCase()} - ${params.COMPE}`,
    };
  });
  const [doctor, setDoctor] = useState<DoctorUser>();
  const [bank, setBank] = useState<OptionType>();
  const [doctorSpecilitiy, setDoctorSpecility] = useState<OptionType[]>([]);

  const dispatch = useAppDispatch();

  const handleChange = (value: any, formattedValue: any) => {
    setDoctor({
      ...doctor,
      dob: value,
    });
  };

  const handleSpeciality = (specialities: any) => {
    setDoctorSpecility(specialities);

    const newSpecialities = specialities.map((spc: any) => {
      const spec: MedicalSpecialty = {
        name: spc.value,
      };
      return spec;
    });

    setDoctor({
      ...doctor,
      medicalSpecialtyTelemedicine: newSpecialities,
    });
  };

  const [buscaCepSubmit, setBuscaCepSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const handleChangeDoctorStatus = () => {
    if (doctor?.isActive) {
      setModal({
        isOpen: true,
        body:
          'Você tem certeza que deseja desativar este profissional? Com isso o mesmo ficará impossibilitado de acessar a plataforma e realizar atendimentos.',
        className: '',
        title: 'Mensagem',
        toggle: () => {
          setDoctor({
            ...doctor,
            isActive: !doctor?.isActive,
          });
          toggle();
        },
        leavingCallback: () => toggle(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body:
          'Você tem certeza que deseja ativar este profissional? Com isso o mesmo terá acesso a plataforma para realizar atendimentos.',
        className: '',
        title: 'Mensagem',
        toggle: () => {
          setDoctor({
            ...doctor,
            isActive: !doctor?.isActive,
          });
          toggle();
        },
        leavingCallback: () => toggle(),
        okButton: 'Ok',
      });
    }
  };

  const handleChangeDoctorStatusCallback = async (
    id?: string,
    status?: boolean,
    statusDescription?: string,
  ) => {
    const response = await dispatch(
      updateDoctorStatus({
        id,
        status,
        statusDescription,
      }),
    );

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Dados alterados com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => window.location.reload(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body: response.data.errorMessage,
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => history.goBack(),
        okButton: 'Tentar novamente',
      });
    }
  };

  const buscaCep = () => {
    setBuscaCepSubmit(true);
    if (doctor?.cep && isValidCep(doctor?.cep)) {
      consultarCep(doctor?.cep.replaceAll('.', '').replaceAll('-', ''))
        .then((response: any) => {
          if (response) {
            setDoctor({
              ...doctor,
              streetAddress: response.logradouro,
              complement: response.complemento,
              neighborhood: response.bairro,
              city: response.localidade,
              state: response.uf,
            });
          }
          setBuscaCepSubmit(false);
        })
        .catch((err: any) => {
          setModal({
            isOpen: true,
            body: 'Cep não existente na base dos correios.',
            className: '',
            title: 'Mensagem',
            toggle: () => toggle(),
            okButton: 'Ok',
          });
          setBuscaCepSubmit(false);
        });
    } else {
      setModal({
        isOpen: true,
        body: 'Informe um Cep Válido.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
      setBuscaCepSubmit(false);
    }
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  useEffect(() => {}, [doctorSpecilitiy]);

  const isNotDoctor = () => user.role !== 'doctor';

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getDoctor({ id: id || user.id }));

      if (response.data) {
        setDoctor({
          ...response.data,
          cep: formatString(maskZipCode, response.data.cep),
          cpf: formatString(maskCPF, response.data.cpf),
        });

        if (doctor?.bankDataTelemedicine?.bank) {
          const _bank = bancosBrasileiros.find(function name(params: any) {
            return params.COMPE === doctor?.bankDataTelemedicine?.bank;
          });

          setBank({
            value: _bank?.COMPE,
            label: `${_bank?.LongName.toUpperCase()} - ${_bank?.COMPE}`,
          });
        }

        let array: OptionType[] = [];

        await response.data.medicalSpecialtyTelemedicine.map((speciality: MedicalSpecialty) =>
          array.push({ label: speciality.name, value: speciality.name }),
        );

        setDoctorSpecility(array);
      }
    };
    fetchData();
  }, [user.id, id, dispatch, doctor?.bankDataTelemedicine?.bank]);

  const onSubmit = async (values: DoctorUser) => {
    values.cep = values.cep?.replace(/[^a-zA-Z0-9]/g, '');
    values.cpf = values.cpf?.replace(/[^a-zA-Z0-9]/g, '');
    values.crm = values.crm?.replace(/[^a-zA-Z0-9]/g, '');

    values.bankDataTelemedicine = {
      ...values.bankDataTelemedicine,
      bank: bank?.value,
    };

    const response = await dispatch(updateDoctorUser(values));

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Dados alterados com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => window.location.reload(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body: response.data.errorMessage,
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => history.goBack(),
        okButton: 'Tentar novamente',
      });
    }
  };

  const onFormChange = (values: Partial<DoctorUser>) => {
    setDoctor({ ...values });
  };

  return (
    <Layout type={user.role}>
      <>
        <div className="view-profile-page">
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
              <Text
                title="MEU PERFIL"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={1}>
              <Row>
                <GenericAvatar
                  imageStyle={{ width: '100px', height: '100px', cursor: 'pointer' }}
                  disabled={isNotDoctor()}
                  size="100"
                  avatarName={`${doctor?.firstName} ${doctor?.lastName}`}
                />
              </Row>
            </Col>
            <Col className="ml-5">
              <Form
                onSubmit={onSubmit}
                initialValues={{ ...doctor }}
                render={({ handleSubmit, submitting }) => (
                  <>
                    <form>
                      <FormSpy onChange={({ values }) => onFormChange(values)} />
                    </form>
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Dados pessoais"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={6}>
                          <Field
                            name="firstName"
                            validate={required}
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Nome *"
                                placeholder="Nome"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={6}>
                          <Field
                            name="lastName"
                            disabled={submitting}
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Sobrenome *"
                                placeholder="Sobrenome"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={6}>
                          <Field
                            name="cpf"
                            validate={required}
                            parse={formatString(maskCPF)}
                            disabled={submitting}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="CPF *"
                                placeholder="000.000.000-00"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={6}>
                          <Field
                            name="email"
                            validate={required}
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="E-mail *"
                                placeholder="email@dominio"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                type="email"
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={1}>
                          <Field
                            name="ddd"
                            disabled={submitting}
                            parse={formatString(maskDDD)}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="DDD"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="phone"
                            disabled={submitting}
                            parse={formatString(maskPhoneNumber)}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Celular"
                                placeholder="00000-0000"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                              />
                            )}
                          />
                        </Col>

                        <Col sm={3}>
                          <Field
                            name="gender"
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Genêro"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                type="select"
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                              >
                                <option value="">Selecione</option>
                                <option value="0">Masculino</option>
                                <option value="1">Feminino</option>
                                <option value="2">Não Informar</option>
                              </Input>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="dob"
                            validate={required}
                            render={({ input, meta }) => (
                              <DatePicker
                                input={input}
                                metaError={meta}
                                handleChange={(v: any, f: any) => handleChange(v, f)}
                                label="Data de Nascimento *"
                                submitting={submitting || buscaCepSubmit || isNotDoctor()}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {user.role === 'partner' && (
                        <>
                          <Row className="mt-4">
                            <Col sm={3}>
                              <FormGroup className="ml-2" check>
                                <Label check>
                                  <ReactstrapInput
                                    type="radio"
                                    name="isActive"
                                    onClick={handleChangeDoctorStatus}
                                    checked={doctor?.isActive}
                                    disabled={submitting || buscaCepSubmit}
                                  />
                                  <span>Profissional ativo</span>
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col sm={12}>
                              <Field
                                name="statusDescription"
                                disabled={submitting}
                                parse={(value: string) => value}
                                render={({ input }) => (
                                  <TextArea
                                    name={input.name}
                                    id={input.name}
                                    label="Informe o motivo se necessário"
                                    onChange={input.onChange}
                                    onBlur={input.onBlur}
                                    value={input.value}
                                    disabled={submitting || buscaCepSubmit}
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      {doctor?.doctorType === 0 || doctor?.doctorType === 1 ? (
                        <Row className="mt-4">
                          <Col sm={3}>
                            <FormGroup className="ml-2" check>
                              <Label check>
                                <ReactstrapInput
                                  type="radio"
                                  name="doctorType"
                                  onClick={e => {
                                    setDoctor({
                                      ...doctor,
                                      doctorType: 0,
                                    });
                                  }}
                                  checked={doctor?.doctorType === 0}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                />
                                <span>Médico</span>
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col sm={3}>
                            <FormGroup className="ml-2" check>
                              <Label check>
                                <ReactstrapInput
                                  type="radio"
                                  name="doctorType"
                                  onClick={e => {
                                    setDoctor({
                                      ...doctor,
                                      doctorType: 1,
                                    });
                                  }}
                                  checked={doctor?.doctorType === 1}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                />
                                <span>Psicológo</span>
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                      {doctor?.doctorType === 0 && (
                        <Row className="mt-4">
                          <Col sm={6}>
                            <Field
                              name="crm"
                              disabled={submitting}
                              parse={(value: string) => value}
                              render={({ input }) => (
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="CRM"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                />
                              )}
                            />
                          </Col>
                          <Col sm={6}>
                            <Field
                              name="specialities"
                              disabled={submitting}
                              parse={(value: string) => value}
                              render={({ input, meta }) => (
                                <SelectMulti
                                  onChange={(speciality: any) => handleSpeciality(speciality)}
                                  value={doctorSpecilitiy}
                                  options={Specialities}
                                  isDisabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  label="Especialidades"
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      )}
                      {doctor?.doctorType === 1 && (
                        <Row className="mt-4">
                          <Col sm={6}>
                            <Field
                              name="crp"
                              disabled={submitting}
                              parse={(value: string) => value}
                              render={({ input }) => (
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="CRP"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      )}
                    </div>
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Sobre mim"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <Field
                            name="aboutMe"
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <TextArea
                                name={input.name}
                                id={input.name}
                                label="Sobre mim"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {doctor?.doctorType === 0 || doctor?.doctorType === 1 ? (
                        <Row className="mt-4">
                          <Col sm={6}>
                            <Field
                              name="attendanceValue"
                              disabled={submitting}
                              parse={(value: string) => value}
                              render={({ input }) => (
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Valor da consulta"
                                  isCurrency
                                  prefix="R$ "
                                  onValueChange={(value: any) => {
                                    setDoctor({
                                      ...doctor,
                                      attendanceValue: value,
                                    });
                                  }}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  currenctValue={doctor?.attendanceValue}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </div>
                    {!isNotDoctor() && (doctor?.doctorType === 0 || doctor?.doctorType === 1) ? (
                      <div className="bordered-container">
                        <Row className="mt-2">
                          <Col>
                            <Text
                              title="Dados bancários"
                              style={{
                                fontSize: '16px',
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={6}>
                            <Field
                              name="bankDataTelemedicine.bank"
                              disabled={submitting}
                              parse={(value: string) => value}
                              render={({ input, meta }) => (
                                <SelectMulti
                                  onChange={(value: any) => {
                                    setBank(value);
                                  }}
                                  value={bank}
                                  options={bancos}
                                  isDisabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  required={true}
                                  label="Banco *"
                                  isMulti={false}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={6}>
                            <Field
                              name="bankDataTelemedicine.agency"
                              disabled={submitting}
                              parse={(value: string) => value}
                              validate={required}
                              render={({ input, meta }) => (
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Agência *"
                                  placeholder="0000"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  required={true}
                                  metaError={meta}
                                />
                              )}
                            />
                          </Col>
                          <Col sm={6}>
                            <Field
                              name="bankDataTelemedicine.account"
                              disabled={submitting}
                              parse={(value: string) => value}
                              validate={required}
                              render={({ input, meta }) => (
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Conta-corrente *"
                                  placeholder="Apenas conta corrente"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  required={true}
                                  metaError={meta}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Endereço"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={3}>
                          <Field
                            name="cep"
                            validate={required}
                            parse={formatString(maskZipCode)}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="CEP *"
                                  placeholder="CEP"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Button
                            disabled={submitting || buscaCepSubmit || isNotDoctor()}
                            className="mt-4"
                            onClick={() => buscaCep()}
                          >
                            BUSCAR CEP
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={7}>
                          <Field
                            name="streetAddress"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <div>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Logradouro *"
                                  placeholder="Logradouro"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  required={true}
                                  metaError={meta}
                                />
                              </div>
                            )}
                          />
                        </Col>
                        <Col sm={2}>
                          <Field
                            name="number"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Número *"
                                  placeholder="Número"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="complement"
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Complemento"
                                placeholder="Complemento"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4 mb-4">
                        <Col sm={3}>
                          <Field
                            name="neighborhood"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Bairro *"
                                  placeholder="Bairro"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="city"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Cidade *"
                                  placeholder="Cidade"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={4}>
                          <Field
                            name="state"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Select
                                name={input.name}
                                id={input.name}
                                label="Estado *"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                type="select"
                                disabled={submitting || buscaCepSubmit || isNotDoctor()}
                                options={State}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    {user.role !== 'partner' && (
                      <Row className="mt-4 justify-content-center">
                        <Col sm={4}>
                          <Button
                            onClick={handleSubmit}
                            disabled={submitting || buscaCepSubmit || isNotDoctor()}
                          >
                            Salvar
                          </Button>
                        </Col>
                      </Row>
                    )}
                    {user.role === 'parter' && (
                      <Row className="mt-4 justify-content-center">
                        <Col sm={4}>
                          <Button
                            onClick={() => {
                              handleChangeDoctorStatusCallback(
                                doctor?.id,
                                doctor?.isActive,
                                doctor?.statusDescription,
                              );
                            }}
                            disabled={submitting || buscaCepSubmit}
                          >
                            Salvar
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              />
            </Col>
          </Row>
        </div>
        <TummiModal {...modal} />
      </>
    </Layout>
  );
};
