import { useEffect, useState } from 'react';
import { Form, FormSpy, Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Layout } from '../../components/Layout';
import { RootState, useAppDispatch } from '../../store';
import { ReturnLink } from '../../components/ReturnLink';
import { DoctorUser } from '../../slices/admin';
import { TummiModal, ITummiModalProps } from '../../components/Modal';
import { Button } from '../../components/Button';
import { Text } from '../../components/Text';
import moment from 'moment';
import { maskCPF, maskZipCode, maskValidThru, maskCardNumber } from '../../utils/Mask';
import formatString from 'format-string-by-pattern';
import { SelectMulti } from '../../components/SelectMulti';
import { getDoctor } from '../../slices/partner';
import { Payments } from '../../utils/EnumPaymentType';
import { Input } from '../../components/Input';
import Cards from 'react-credit-cards';
import { savePaymentCard, savePaymentBoleto, savePaymentPix } from '../../slices/payment';
import {
  paymentBoletoPayload,
  paymentCardPayload,
  paymentPixPayload,
} from '../../services/apiTypes';
import { AvaiableSchedule, CreateAttendace, SearchSpecialityByDay } from '../../slices/attendance';

import 'react-credit-cards/lib/styles.scss';
interface Params {
  attendanceDay?: string;
  speciality?: string;
  doctorId?: string;
  patientId?: string;
}
interface Card {
  cardNumber?: string;
  name?: string;
  validThru?: string;
  cvc?: string;
}

export const Checkout = () => {
  const { attendanceDay, speciality, doctorId, patientId } = useParams<Params>();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);

  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');

  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [paymentType, setPaymentType] = useState(null);
  const [patientCard, setPatientCard] = useState<Card>({});
  const [cardFocused, setCardFocused] = useState<any>('number');

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const [_attendanceDay, setAttendanceDay] = useState(attendanceDay || '');
  const [doctor, setDoctor] = useState<DoctorUser>();
  const [schedules, setSchedules] = useState<any[]>([]);
  const [avaiableSchedules, setAvaiableSchedules] = useState<AvaiableSchedule[]>([]);

  useEffect(() => {
    const fetchDoctor = async () => {
      const response = await dispatch(getDoctor({ id: doctorId }));

      if (response.data) {
        setDoctor({
          ...response.data,
          cep: formatString(maskZipCode, response.data.cep),
          cpf: formatString(maskCPF, response.data.cpf),
        });

        const avaiableSchedulesResponse = await dispatch(
          SearchSpecialityByDay({
            date: attendanceDay,
            speciality: speciality,
            doctorId: doctorId,
            companyId: user.companyId,
          }),
        );

        if (avaiableSchedulesResponse.data.length > 0) {
          const _schedules: any[] = [];

          avaiableSchedulesResponse.data.map((element: AvaiableSchedule, index: number) => {
            _schedules.push({
              value: element.dateTimeHour,
              label: moment(new Date(element.dateTimeHour || '')).format('DD/MM/YYYY HH:mm'),
            });
          });

          setAttendanceDay(avaiableSchedulesResponse.data[0].dateTimeHour);
          setAvaiableSchedules(avaiableSchedulesResponse.data);
          setSchedules(_schedules);
        } else {
          setModal({
            isOpen: true,
            body: 'Este médico não possui mais horários disponíveis para este dia.',
            className: '',
            title: 'Mensagem',
            toggle: () => toggle(),
            leavingCallback: () => history.goBack(),
            okCallback: () => history.goBack(),
            okButton: 'Ok',
          });
        }
      }
    };
    fetchDoctor();
  }, []);

  const onPaymentBoleto = async () => {
    setModal({
      isOpen: true,
      body: 'Só mais um pouquinho, estamos processando o seu pagamento.',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      leavingCallback: () => true,
      showCancelButton: true,
    });

    const avaiableSchedule: paymentBoletoPayload = {
      patientId: patientId,
      doctorId: doctorId,
      attedanceDate: new Date(_attendanceDay || ''),
      speciality: speciality,
      attandanceValue: doctor?.attendanceValue,
    };

    const response = await dispatch(savePaymentBoleto(avaiableSchedule));

    if (response.data.success) {
      const attendance: AvaiableSchedule = {
        doctorId: doctorId,
        patientId: patientId,
        speciality: speciality,
        date: _attendanceDay,
        paidBy: 0,
        boletoTransaction: response.data.boleto,
        value: doctor?.attendanceValue,
      };

      const attendanceResponse = await dispatch(CreateAttendace(attendance));

      if (attendanceResponse.data.success) {
        setModal({
          isOpen: true,
          body:
            'Boleto emitido com succeso. Assim que o pagamento for confirmado, você receberá um e-mail de confirmação da sua consulta.',
          className: '',
          title: 'Mensagem',
          toggle: () => toggle(),
          leavingCallback: () =>
            history.push(
              `${process.env.PUBLIC_URL}/patient/checkoutsuccess/${attendanceResponse.data.attendanceId}`,
            ),
          okCallback: () =>
            history.push(
              `${process.env.PUBLIC_URL}/patient/checkoutsuccess/${attendanceResponse.data.attendanceId}`,
            ),
          okButton: 'Ok',
        });
      } else {
        setModal({
          isOpen: true,
          body:
            'Desculpe, tivemos um erro ao criar o seu agendamento, por favor tente novamente mais tarde ou entre em contato com a Thummi.',
          className: '',
          title: 'Mensagem',
          toggle: () => toggle(),
          leavingCallback: () => toggle(),
          okCallback: () => toggle(),
          okButton: 'Ok',
        });
      }
    } else {
      setModal({
        isOpen: true,
        body: 'Erro ao salvar emitir boleto, por favor tente novamente dentro de alguns instantes.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => toggle(),
        okCallback: () => toggle(),
        okButton: 'Ok',
      });
    }
  };

  const onNoPayment = async () => {
    setModal({
      isOpen: true,
      body: 'Só mais um pouquinho, estamos criando o seu atendimento.',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      leavingCallback: () => true,
      showCancelButton: true,
    });

    const attendance: AvaiableSchedule = {
      doctorId: doctorId,
      patientId: patientId,
      speciality: speciality,
      dateTimeHour: new Date(_attendanceDay || ''),
      paidBy: 3,
      value: doctor?.attendanceValue,
    };

    const attendanceResponse = await dispatch(CreateAttendace(attendance));

    if (attendanceResponse.data.success) {
      setModal({
        isOpen: true,
        body:
          'Atendimento criado com succeso. Em instantes, você receberá um e-mail de confirmação da sua consulta.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () =>
          history.push(
            `${process.env.PUBLIC_URL}/patient/checkoutsuccess/${attendanceResponse.data.attendanceId}`,
          ),
        okCallback: () =>
          history.push(
            `${process.env.PUBLIC_URL}/patient/checkoutsuccess/${attendanceResponse.data.attendanceId}`,
          ),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body:
          'Desculpe, tivemos um erro ao criar o seu agendamento, por favor tente novamente mais tarde ou entre em contato com a Thummi.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => toggle(),
        okCallback: () => toggle(),
        okButton: 'Ok',
      });
    }
  };

  const onPaymentCard = async () => {
    setModal({
      isOpen: true,
      body: 'Só mais um pouquinho, estamos processando o seu pagamento.',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      leavingCallback: () => true,
      showCancelButton: true,
    });

    const avaiableSchedule: paymentCardPayload = {
      patientId: patientId,
      doctorId: doctorId,
      attedanceDate: new Date(_attendanceDay || ''),
      speciality: speciality,
      attandanceValue: doctor?.attendanceValue,
      isCredit: paymentType === '2',
      CVC: patientCard.cvc,
      cardName: patientCard.name,
      cardNumber: patientCard.cardNumber,
      validThru: patientCard.validThru,
    };

    const response = await dispatch(savePaymentCard(avaiableSchedule));

    if (response.data.success) {
      const attendance: AvaiableSchedule = {
        doctorId: doctorId,
        patientId: patientId,
        speciality: speciality,
        date: _attendanceDay,
        paidBy: 2,
        cardTransaction: response.data.card,
        value: doctor?.attendanceValue,
      };

      const attendanceResponse = await dispatch(CreateAttendace(attendance));

      if (attendanceResponse.data.success) {
        setModal({
          isOpen: true,
          body:
            'Pagamento aprovado, em instantes você receberá no seu e-mail a confirmação da sua consulta.',
          className: '',
          title: 'Mensagem',
          toggle: () => toggle(),
          leavingCallback: () =>
            history.push(
              `${process.env.PUBLIC_URL}/checkoutsuccess/${attendanceResponse.data.attendanceId}`,
            ),
          okCallback: () =>
            history.push(
              `${process.env.PUBLIC_URL}/checkoutsuccess/${attendanceResponse.data.attendanceId}`,
            ),
          okButton: 'Ok',
        });
      } else {
        setModal({
          isOpen: true,
          body:
            'Desculpe, tivemos um erro ao criar o seu agendamento, por favor tente novamente mais tarde ou entre em contato com a Thummi.',
          className: '',
          title: 'Mensagem',
          toggle: () => toggle(),
          leavingCallback: () => toggle(),
          okCallback: () => toggle(),
          okButton: 'Ok',
        });
      }
    } else {
      setModal({
        isOpen: true,
        body:
          'Pagamento recusado, favor tentar novamente em instantes ou entrar em contato com a administradora do seu cartão.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => toggle(),
        okCallback: () => toggle(),
        okButton: 'Ok',
      });
    }
  };

  const onPaymentPix = async () => {
    setModal({
      isOpen: true,
      body: 'Só mais um pouquinho, estamos processando o seu pagamento.',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      leavingCallback: () => true,
      showCancelButton: true,
    });

    const avaiableSchedule: paymentPixPayload = {
      patientId: patientId,
      doctorId: doctorId,
      attedanceDate: new Date(_attendanceDay || ''),
      speciality: speciality,
      attandanceValue: doctor?.attendanceValue,
    };

    const response = await dispatch(savePaymentPix(avaiableSchedule));

    if (response.data.success) {
      const attendance: AvaiableSchedule = {
        doctorId: doctorId,
        patientId: patientId,
        speciality: speciality,
        date: _attendanceDay,
        paidBy: 1,
        pixTransaction: response.data.pix,
        value: doctor?.attendanceValue,
      };

      const attendanceResponse = await dispatch(CreateAttendace(attendance));

      if (attendanceResponse.data.success) {
        setModal({
          isOpen: true,
          body:
            'Pagamento aprovado, em instantes você receberá no seu e-mail a confirmação da sua consulta.',
          className: '',
          title: 'Mensagem',
          toggle: () => toggle(),
          leavingCallback: () =>
            history.push(
              `${process.env.PUBLIC_URL}/patient/checkoutsuccess/${attendanceResponse.data.attendanceId}`,
            ),
          okCallback: () =>
            history.push(
              `${process.env.PUBLIC_URL}/patient/checkoutsuccess/${attendanceResponse.data.attendanceId}`,
            ),
          okButton: 'Ok',
        });
      } else {
        setModal({
          isOpen: true,
          body:
            'Desculpe, tivemos um erro ao criar o seu agendamento, por favor tente novamente mais tarde ou entre em contato com a Thummi.',
          className: '',
          title: 'Mensagem',
          toggle: () => toggle(),
          leavingCallback: () => toggle(),
          okCallback: () => toggle(),
          okButton: 'Ok',
        });
      }
    } else {
      setModal({
        isOpen: true,
        body:
          'Pagamento recusado, favor tentar novamente em instantes ou entrar em contato com a administradora do seu cartão.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => toggle(),
        okCallback: () => toggle(),
        okButton: 'Ok',
      });
    }
  };

  const onFormChange = (values: Partial<Card>) => {
    setPatientCard({ ...values });
  };

  return (
    <Layout type={user.role}>
      <>
        <div className="view-profile-page">
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink
                title="VOLTAR"
                returnLink={() => history.push(`${process.env.PUBLIC_URL}/${user.role}/schedules`)}
              />
              <Text
                title="CHECKOUT ATENDIMENTO"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="bordered-container">
                <Row className="mt-4">
                  <Col>
                    <Text
                      title="Dados do atendimento"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span className="label">Médico (a)</span>
                  </Col>
                  <Col sm={4}>
                    <span className="label">Especialidade</span>
                  </Col>
                  <Col sm={3}>
                    <span className="label">Data do atendimento</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span>
                      <strong>
                        {doctor?.firstName} {doctor?.lastName}
                      </strong>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <span>
                      <strong>{speciality}</strong>
                    </span>
                  </Col>
                  <Col sm={3}>
                    <span>
                      <strong>
                        {' '}
                        {moment(new Date(_attendanceDay || '')).format('DD/MM/YYYY HH:mm')}{' '}
                      </strong>{' '}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={5}>
                    <span className="label">Valor da Consulta</span>
                  </Col>
                  <Col sm={4}>
                    <span className="label">Local</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span>
                      <strong>R$ {doctor?.attendanceValue}</strong>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <span>
                      <strong>{'Teleatendimento (Online)'}</strong>
                    </span>
                  </Col>
                </Row>
                <Row className="justify-content-center" style={{ marginTop: '50px' }}>
                  <Col sm={2}></Col>
                  <Col sm={4}>
                    <Text
                      title="Horários"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                  <Col sm={4}>
                    <Text
                      title="Dados do Pagamento"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                  <Col sm={2}></Col>
                </Row>
                <Row className="justify-content-center" style={{ marginBottom: '60px' }}>
                  <Col sm={2}></Col>
                  <Col sm={4}>
                    <SelectMulti
                      label="Selecione o horário preferencial de sua consulta"
                      isMulti={false}
                      options={schedules}
                      onChange={(e: any) => setAttendanceDay(e.value)}
                    />
                  </Col>
                  <Col sm={4}>
                    <SelectMulti
                      label="Selecione o tipo de pagamento"
                      isMulti={false}
                      options={Payments}
                      onChange={(e: any) => setPaymentType(e.value)}
                    />
                  </Col>
                  <Col sm={2}></Col>
                </Row>
                {paymentType === '1' && (
                  <Row className="mt-4 justify-content-center">
                    <Col sm={4} style={{ textAlign: 'center' }}>
                      <span>
                        <strong>Total: R$ {doctor?.attendanceValue}</strong>
                      </span>
                    </Col>
                    <Col className="mt-2" sm={4}>
                      <Button
                        onClick={() => onPaymentBoleto()}
                        disabled={app.isLoading}
                        type="primary"
                      >
                        Emitir Boleto
                      </Button>
                    </Col>
                  </Row>
                )}
                {paymentType === '6' && (
                  <Row className="mt-4 justify-content-center">
                    <Col className="mt-2" sm={4}>
                      <Button onClick={() => onNoPayment()} disabled={app.isLoading} type="primary">
                        Salvar atentimento
                      </Button>
                    </Col>
                  </Row>
                )}
                {paymentType === '5' && (
                  <Row className="justify-content-center" style={{ marginTop: '50px' }}>
                    <Col sm={4} style={{ textAlign: 'center' }}>
                      <span>
                        <strong>Total: R$ {doctor?.attendanceValue}</strong>
                      </span>
                    </Col>
                    <Col sm={4}>
                      <Button
                        onClick={() => onPaymentPix()}
                        type="primary"
                        disabled={app.isLoading}
                      >
                        Concluir pedido
                      </Button>
                    </Col>
                  </Row>
                )}
                {paymentType === '4' || paymentType === '2' ? (
                  <>
                    <Form
                      onSubmit={onPaymentCard}
                      initialValues={{ ...patientCard }}
                      render={({ handleSubmit, submitting }) => (
                        <>
                          <form>
                            <FormSpy onChange={({ values }) => onFormChange(values)} />
                          </form>
                          <Row className="mt-4 justify-content-center">
                            <Col className="mt-4" sm={4} style={{ paddingLeft: '210px' }}>
                              <Cards
                                number={patientCard.cardNumber || ''}
                                name={patientCard.name || ''}
                                expiry={patientCard.validThru || ''}
                                cvc={patientCard.cvc || ''}
                                focused={cardFocused}
                              />
                            </Col>
                            <Col>
                              <Row className="mt-2 justify-content-center">
                                <Col sm={6}>
                                  <Field
                                    name="cardNumber"
                                    validate={required}
                                    parse={formatString(maskCardNumber)}
                                    render={({ input, meta }) => (
                                      <div>
                                        <Input
                                          name={input.name}
                                          id={input.name}
                                          label="Número do Cartão"
                                          maxLength={19}
                                          placeholder="0000 0000 0000 0000"
                                          onChange={event => {
                                            input.onChange(event);
                                            setCardFocused('number');
                                          }}
                                          onBlur={event => {
                                            input.onBlur(event);
                                            setCardFocused('number');
                                          }}
                                          onFocus={event => {
                                            input.onFocus(event);
                                            setCardFocused('number');
                                          }}
                                          value={input.value}
                                          required={true}
                                          metaError={meta}
                                          disabled={submitting}
                                        />
                                      </div>
                                    )}
                                  />
                                </Col>
                              </Row>
                              <Row className="mt-2 justify-content-center">
                                <Col sm={6}>
                                  <Field
                                    name="name"
                                    validate={required}
                                    parse={(value: string) => value}
                                    render={({ input, meta }) => (
                                      <div>
                                        <Input
                                          name={input.name}
                                          id={input.name}
                                          label="Nome do titular (como está gravado no Cartão)"
                                          onChange={event => {
                                            input.onChange(event);
                                            setCardFocused('name');
                                          }}
                                          onBlur={event => {
                                            input.onBlur(event);
                                            setCardFocused('name');
                                          }}
                                          onFocus={event => {
                                            input.onFocus(event);
                                            setCardFocused('name');
                                          }}
                                          value={input.value}
                                          required={true}
                                          metaError={meta}
                                          disabled={submitting}
                                        />
                                      </div>
                                    )}
                                  />
                                </Col>
                              </Row>
                              <Row className="mt-2 justify-content-center">
                                <Col sm={3}>
                                  <Field
                                    name="validThru"
                                    validate={required}
                                    parse={formatString(maskValidThru)}
                                    render={({ input, meta }) => (
                                      <div>
                                        <Input
                                          name={input.name}
                                          id={input.name}
                                          label="Data de Validade"
                                          maxLength={5}
                                          onChange={event => {
                                            input.onChange(event);
                                            setCardFocused('expiry');
                                          }}
                                          onBlur={event => {
                                            input.onBlur(event);
                                            setCardFocused('expiry');
                                          }}
                                          onFocus={event => {
                                            input.onFocus(event);
                                            setCardFocused('expiry');
                                          }}
                                          value={input.value}
                                          required={true}
                                          metaError={meta}
                                          disabled={submitting}
                                        />
                                      </div>
                                    )}
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Field
                                    name="cvc"
                                    validate={required}
                                    parse={(value: string) => value}
                                    render={({ input, meta }) => (
                                      <div>
                                        <Input
                                          name={input.name}
                                          id={input.name}
                                          label="Código de Segurança"
                                          maxLength={3}
                                          onChange={event => {
                                            input.onChange(event);
                                            setCardFocused('cvc');
                                          }}
                                          onBlur={event => {
                                            input.onBlur(event);
                                            setCardFocused('cvc');
                                          }}
                                          onFocus={event => {
                                            input.onFocus(event);
                                            setCardFocused('cvc');
                                          }}
                                          value={input.value}
                                          required={true}
                                          metaError={meta}
                                          disabled={submitting}
                                        />
                                      </div>
                                    )}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="justify-content-center" style={{ marginTop: '60px' }}>
                            <Col sm={4} style={{ textAlign: 'center' }}>
                              <span>
                                <strong>Total: R$ {doctor?.attendanceValue}</strong>
                              </span>
                            </Col>
                            <Col sm={4}>
                              <Button
                                onClick={() => handleSubmit()}
                                type="primary"
                                disabled={app.isLoading}
                              >
                                Concluir pedido
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <TummiModal {...modal} />
      </>
    </Layout>
  );
};
