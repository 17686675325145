import { Api } from '../../services/api';
import { getUserPayload } from '../../services/apiTypes';
import { AppDispatch } from '../../store';
import { setIsloading } from '../app';

export type Company = {
  id?: string;
  email?: string;
  phone?: string;
  ddd?: string;
  companyName?: string;
  CNPJ?: string;
  streetAddress?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  cep?: string;
  sendPatientEmailOnCreate?: boolean;
  blockPatientAccessToPlataform?: boolean;
  isNonProfitCompany?: boolean;
  isNonMedicalCompany?: boolean;
  attendanceTime?: boolean;
};

export const CreateCompany = (payload: Partial<Company>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.CreateCompany(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const UpdateCompany = (payload: Partial<Company>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.UpdateCompany(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getCompany = ({ id }: Partial<getUserPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.getCompany({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};
