import React from 'react';
import { FormGroup, Input as ReactstrapInput, Label } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import CurrencyInput from 'react-currency-input-field';

import './styles.scss';

type Props = {
  label?: string;
  type?: InputType;
  labelClass?: string;
  formClass?: string;
  metaError?: any;
  mask?: any;
  tag?: any;
  isCurrency?: boolean;
  currenctValue?: number;
  onValueChange?: (value: any) => void;
  prefix?: string;
  defaultChecked?: any;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = ({
  label,
  id,
  type = 'text',
  name,
  placeholder,
  children,
  className,
  labelClass,
  metaError,
  formClass,
  isCurrency,
  currenctValue,
  onValueChange,
  prefix,
  defaultChecked,
  ...rest
}: Props) => (
  <FormGroup style={{ marginBottom: '0.2rem' }} className={`${formClass}`}>
    {label && (
      <Label
        className={`label-class ${
          metaError?.error && metaError?.touched ? 'error-label-class' : ''
        } ${labelClass}`}
        for={id}
      >
        {label}
      </Label>
    )}
    {!isCurrency ? (
      <ReactstrapInput
        autoComplete="none"
        type={type}
        name={name}
        defaultChecked={defaultChecked}
        id={id}
        placeholder={placeholder}
        {...rest}
        className={`input-class ${
          metaError?.error && metaError?.touched ? 'error-input-class' : ''
        }`}
      >
        {children}
      </ReactstrapInput>
    ) : (
      <CurrencyInput
        autoComplete="none"
        placeholder={placeholder}
        decimalSeparator=","
        groupSeparator="."
        value={rest.value as number}
        decimalsLimit={2}
        prefix={prefix}
        className={`form-control input-class ${
          metaError?.error && metaError?.touched ? 'error-input-class' : ''
        }`}
        onValueChange={(value: any) => {
          if (onValueChange) {
            onValueChange(value);
          }
        }}
        disabled={rest.disabled}
      />
    )}
    {metaError?.error && metaError?.touched && (
      <span className="error-message">{metaError.error}</span>
    )}
  </FormGroup>
);
