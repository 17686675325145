import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Api } from '../../services/api';
import { AppDispatch } from '../../store';
import { setIsloading } from '../app';

export type UserSliceState = {
  isAuthenticated: boolean;
  id: string;
  name: string;
  role: string;
  login: string;
  companyId: string;
  password: string;
  token: string;
  memedToken?: string;
  image?: any;
  isNonMedicalCompany?: boolean;
};

const initialState: UserSliceState = {
  isAuthenticated: false,
  id: '',
  name: '',
  role: '',
  login: '',
  companyId: '',
  password: '',
  token: '',
  memedToken: '',
  image: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserSliceState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;

export const login = ({ login, password }: Partial<UserSliceState>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.login({ login: String(login), password: String(password) }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const updateUserPassword = (
  login?: string,
  password?: string,
  newPassword?: string,
  role?: string,
) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.updateUserPassword(login, password, newPassword, role).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const recoverPassword = (email?: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.recoverPassword(email).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const updateUserImageProfile = (login?: string, image?: any, role?: string) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.updateUserImageProfile(login, image, role).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};
