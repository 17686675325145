import React, { useState } from 'react';
import { Form, FormSpy, Field } from 'react-final-form';
import { useAppDispatch, RootState } from '../../../store';
import { PatientUser } from '../../../slices/admin';
import { createPatientUser } from '../../../slices/partner';
import { useHistory } from 'react-router-dom';
import { TummiModal, ITummiModalProps } from '../../../components/Modal';
import { useSelector } from 'react-redux';
import { Layout } from '../../../components/Layout';
import { Row, Col } from 'reactstrap';
import { Button } from '../../../components/Button';
import { Gender } from '../../../utils/EnumGender';
import { State } from '../../../utils/EnumStates';
import { Text } from '../../../components/Text';
import { Input } from '../../../components/Input';
import { DatePicker } from '../../../components/Datepicker';
import { Select } from '../../../components/Select';
import { ReturnLink } from '../../../components/ReturnLink';

import {
  maskCPF,
  maskDateOfBirth,
  maskDDD,
  maskPhoneNumber,
  maskZipCode,
} from '../../../utils/Mask';

import { isValidCep } from '../../../utils/ValidCep';

import formatString from 'format-string-by-pattern';

import '../styles.scss';

const { consultarCep } = require('correios-brasil');
var sha1 = require('sha1');

export const CreatePatientUser = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const user = useSelector((state: RootState) => state.user);
  const [patientUser, setPatientUser] = useState<PatientUser>({
    dob: new Date().toISOString(),
  });

  const onSubmit = async (values: PatientUser) => {
    values.cep = values.cep?.replace(/[^a-zA-Z0-9]/g, '');
    values.cpf = values.cpf?.replace(/[^a-zA-Z0-9]/g, '');
    values.phone = values.phone?.replace(/[^a-zA-Z0-9]/g, '');

    values.login = values.cpf;
    values.password = sha1(values.cpf);
    values.companyTelemedicineId = user.companyId;

    const response = await dispatch(createPatientUser(values));

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Usuário criado com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => setShowModal(!showModal),
        leavingCallback: () => history.goBack(),
        okCallback: () => history.push(`${process.env.PUBLIC_URL}/${user.role}/createpatientuser`),
        okButton: 'Criar outro usuário',
      });
    } else {
      setModal({
        isOpen: true,
        body: response.data.errorMessage,
        className: '',
        title: 'Mensagem',
        toggle: () => setShowModal(!showModal),
        leavingCallback: () => history.goBack(),
        okCallback: () => toggle(),
        okButton: 'Tentar novamente',
      });
    }
  };

  const [buscaCepSubmit, setBuscaCepSubmit] = useState(false);

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const buscaCep = () => {
    setBuscaCepSubmit(true);
    if (patientUser?.cep && isValidCep(patientUser?.cep)) {
      consultarCep(patientUser?.cep.replaceAll('.', '').replaceAll('-', ''))
        .then((response: any) => {
          if (response) {
            setPatientUser({
              ...patientUser,
              streetAddress: response.logradouro,
              complement: response.complemento,
              neighborhood: response.bairro,
              city: response.localidade,
              state: response.uf,
            });
          }
          setBuscaCepSubmit(false);
        })
        .catch((err: any) => {
          setModal({
            isOpen: true,
            body: 'Cep não existente na base dos correios.',
            className: '',
            title: 'Mensagem',
            toggle: () => toggle(),
            okButton: 'Ok',
          });
          setBuscaCepSubmit(false);
        });
    } else {
      setModal({
        isOpen: true,
        body: 'Informe um Cep Válido.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
      setBuscaCepSubmit(false);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: false,
    body: '',
    className: '',
    toggle: () => setShowModal(!showModal),
    title: '',
  });

  const onFormChange = (values: Partial<PatientUser>) => {
    setPatientUser({ ...values });
  };

  return (
    <Layout type={user.role}>
      <>
        <Row className="mt-4 mb-4 d-flex justify-content-start">
          <ReturnLink title="Voltar" />
          <Text
            title="PACIENTES"
            style={{
              fontSize: '24px',
              marginBottom: '0px',
              marginTop: '5px',
              marginLeft: '15px',
            }}
          />
        </Row>
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...patientUser }}
          render={({ handleSubmit, submitting }) => (
            <div className="create-user">
              <form>
                <FormSpy onChange={({ values }) => onFormChange(values)} />
              </form>
              <Row className="mt-4">
                <Col sm={12}>
                  <Text
                    title="Cadastro de pacientes da sua clínica."
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={6}>
                  <Field
                    name="firstName"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <div>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Nome *"
                          placeholder="Nome"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          required={true}
                          metaError={meta}
                          disabled={submitting}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name="lastName"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <div>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Sobrenome"
                          placeholder="Sobrenome"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          required={true}
                          metaError={meta}
                          disabled={submitting}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={6}>
                  <Field
                    name="cpf"
                    validate={required}
                    parse={formatString(maskCPF)}
                    render={({ input, meta }) => (
                      <div>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="CPF"
                          placeholder="CPF *"
                          onBlur={input.onBlur}
                          value={input.value}
                          onChange={input.onChange}
                          disabled={submitting}
                          required={true}
                          metaError={meta}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name="rg"
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <div>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="RG"
                          placeholder="RG"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting}
                          required={true}
                          metaError={meta}
                        />
                      </div>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={6}>
                  <Field
                    name="email"
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="E-mail"
                        placeholder="E-mail"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        type="email"
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    name="ddd"
                    parse={formatString(maskDDD)}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="DDD"
                        placeholder="DDD"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="phone"
                    parse={formatString(maskPhoneNumber)}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Telefone"
                        placeholder="Telefone"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={4}>
                  <Field
                    name="dob"
                    validate={required}
                    parse={formatString(maskDateOfBirth)}
                    render={({ input, meta }) => (
                      <>
                        <DatePicker
                          input={input}
                          metaError={meta}
                          handleChange={(v, f) =>
                            setPatientUser({
                              ...patientUser,
                              dob: v,
                            })
                          }
                          label="Data de Nascimento *"
                          submitting={submitting}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    name="gender"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Select
                          name={input.name}
                          id={input.name}
                          label="Genêro *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          type="select"
                          disabled={submitting}
                          options={Gender}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={6}>
                  <Field
                    name="susnumber"
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Cartão SUS"
                          placeholder="Cartão SUS"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting}
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Text
                    title="Endereço"
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={3}>
                  <Field
                    name="cep"
                    validate={required}
                    parse={formatString(maskZipCode)}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Cep"
                          placeholder="CEP *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Button
                    className="mt-4"
                    disabled={submitting || buscaCepSubmit}
                    onClick={() => buscaCep()}
                  >
                    BUSCAR CEP
                  </Button>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={7}>
                  <Field
                    name="streetAddress"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <div>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Logradouro"
                          placeholder="Logradouro *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    name="number"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Número"
                          placeholder="Número *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="complement"
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Complemento"
                        placeholder="Complemento"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting || buscaCepSubmit}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={3}>
                  <Field
                    name="neighborhood"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Bairro"
                          placeholder="Bairro *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="city"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Cidade"
                          placeholder="Cidade *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="state"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Select
                          name={input.name}
                          id={input.name}
                          label="Estado *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          type="select"
                          disabled={submitting || buscaCepSubmit}
                          options={State}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-center">
                <Col sm={4}>
                  <Button
                    className="mt-4"
                    onClick={handleSubmit}
                    disabled={submitting || buscaCepSubmit}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        />
        <TummiModal {...modal} />
      </>
    </Layout>
  );
};
