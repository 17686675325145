import React from 'react';

import './styles.scss';
interface IIndicatorPros {
  level?: number;
}

export const Indicador = ({ level }: IIndicatorPros) => {
  const renderIndicador = (color: string) => {
    return (
      <>
        <div className={`indicator ${color}`} />
        <div className={`indicator ${color}`} />
      </>
    );
  };

  const renderIndicadores = (level?: number) => {
    return (
      <>
        {level && level >= 4 && renderIndicador('red')}
        {level && level >= 3 && renderIndicador('redish')}
        {level && level >= 2 && renderIndicador('orange')}
        {level && level >= 1 && renderIndicador('yellow')}
        {renderIndicador('green')}
      </>
    );
  };

  return <div className="contentArea">{renderIndicadores(level)}</div>;
};
