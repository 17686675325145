import { Field, Form, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import TummiLogo from '../../resources/images/tummi-logo.svg';
import { Input } from '../../components/Input';
import { login, setUser, UserSliceState } from '../../slices/user';
import { RootState, useAppDispatch } from '../../store';
import { Button } from '../../components/Button';
import { loginStorage } from '../../utils/Auth';
import { useToasts } from 'react-toast-notifications';

import './styles.scss';

export const Login = () => {
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const location = useLocation();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');

  const dispatch = useAppDispatch();

  const { addToast } = useToasts();

  const onSubmit = async (values: UserSliceState) => {
    try {
      const response = await dispatch(login(values));

      if (response.data.success) {
        const user = {
          ...values,
          token: response.data.token,
          ...response.data.user,
          isAuthenticated: true,
        };

        loginStorage(user);

        dispatch(setUser(user));

        let defaultRoute = '';

        switch (user.role) {
          case 'patient':
            defaultRoute = `/${user.role}/attendances`;
            break;
          case 'partner':
            defaultRoute = `/${user.role}/doctors`;
            break;
          default:
            defaultRoute = `/${user.role}/home`;
            break;
        }

        const { from } = (location.state as any) || { from: defaultRoute };

        history.replace(from === '/login' ? defaultRoute : from);
      } else {
        addToast(response.data.errorMessage, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } catch (err) {}
  };

  const onFormChange = (values: Partial<UserSliceState>) => {
    dispatch(setUser({ ...values }));
  };

  return (
    <div className="login-page">
      <Container fluid>
        <Row>
          <Col sm={5}>
            <Container>
              <Row className="mt-2">
                <Col>
                  <img
                    src={TummiLogo}
                    alt="Thummi Logo"
                    style={{ width: '170px', height: '75px', cursor: 'pointer' }}
                    onClick={() => history.push(`${process.env.PUBLIC_URL}`)}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-2 ml-5">
                <Col>
                  <text className="message-presentation">Olá, somos a thummi teleatendimento.</text>
                </Col>
              </Row>
              <Row className="mt-4 ml-5">
                <Col sm={8}>
                  <text className="sub-message-presentation">
                    Para acessar a plataforma digite as suas credenciais cadastradas.
                  </text>
                </Col>
              </Row>
              <Form
                onSubmit={onSubmit}
                initialValues={{ ...user }}
                render={({ handleSubmit, submitting }) => (
                  <>
                    <form>
                      <FormSpy onChange={({ values }) => onFormChange(values)} />
                    </form>
                    <Row className="mt-2 ml-5">
                      <Col sm={9}>
                        <Field
                          name="login"
                          parse={(value: string) => value}
                          validate={required}
                          render={({ input, meta }) => (
                            <Input
                              name={input.name}
                              id={input.name}
                              label="Login"
                              placeholder="Login"
                              onChange={input.onChange}
                              onBlur={input.onBlur}
                              value={input.value}
                              type="text"
                              disabled={submitting}
                              required={true}
                              metaError={meta}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row className="ml-5">
                      <Col sm={9}>
                        <Field
                          name="password"
                          parse={(value: string) => value}
                          validate={required}
                          render={({ input, meta }) => (
                            <Input
                              name={input.name}
                              id={input.name}
                              label="Senha"
                              placeholder="Senha"
                              onChange={input.onChange}
                              onBlur={input.onBlur}
                              value={input.value}
                              type="password"
                              disabled={submitting}
                              required={true}
                              metaError={meta}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row className="ml-5 mt-2">
                      <Col>
                        <Field
                          name="keepMeLogged"
                          parse={(value: string) => value}
                          render={({ input }) => (
                            <Input
                              formClass="input-form-label"
                              name={input.name}
                              id={input.name}
                              label="Mantenha-me logado"
                              onChange={input.onChange}
                              onBlur={input.onBlur}
                              value={input.value}
                              type="checkbox"
                              className="input-check"
                              labelClass="input-check-label"
                              disabled={submitting}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row className="ml-5">
                      <Col sm={9}>
                        <Button type="secondary" disabled={submitting} onClick={handleSubmit}>
                          ACESSAR
                        </Button>
                      </Col>
                    </Row>
                    <Row className="mt-2 ml-5">
                      <Col>
                        <text style={{ marginLeft: '70px' }} className="text-register">
                          Não é cadastrado?
                        </text>{' '}
                        <Link style={{ color: '#008171' }} className="text-register" to="/register">
                          Crie sua Conta
                        </Link>
                      </Col>
                    </Row>
                    <Row className="mt-4 ml-5">
                      <Col>
                        <Link
                          style={{ color: '#00A0A0', marginLeft: '70px' }}
                          className="text-register"
                          to="/recover-password"
                        >
                          Perdeu sua senha? Recupere aqui.
                        </Link>
                      </Col>
                    </Row>
                    <Row className="mt-5 ml-5">
                      <span
                        className="text-register"
                        style={{ color: '#008171', marginLeft: '55px' }}
                      >
                        © {new Date().getFullYear()} Thummi. Todos os direitos reservados.
                      </span>
                    </Row>
                  </>
                )}
              />
            </Container>
          </Col>
          <Col className="container-rigth">
            <Row className="">
              <Col></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
