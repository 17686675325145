import axios from 'axios';
import { Api } from '../../services/api';
import {
  paymentBoletoPayload,
  paymentCardPayload,
  paymentPixPayload,
} from '../../services/apiTypes';
import { AppDispatch } from '../../store';
import { setIsloading } from '../app';

export type BoletoTransaction = {
  id?: string;
  idTransaction?: number;
  status?: string;
  message?: string;
  description?: number;
  dueDate?: string;
  digitableLine?: string;
  barcode?: number;
  bankSlipUrl?: string;
};

type Debit = {
  cardNumber?: number;
  brand?: number;
};

type Credit = {
  cardNumber?: number;
  brand?: number;
  installments?: number;
};

export type CardTransaction = {
  id?: string;
  idTransaction?: number;
  token?: string;
  description?: string;
  status?: number;
  message?: string;
  creditCard?: Credit;
  creditCardId?: string;
  debitCard?: Debit;
  debitCardId?: string;
  authenticationUrl?: string;
};

export type PixTransaction = {
  id?: string;
  idTransaction?: number;
  status?: string;
  message?: string;
  description?: number;
  qrCode?: string;
  key?: Credit;
};

export const savePaymentBoleto = (payload: Partial<paymentBoletoPayload>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.savePaymentBoleto(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const savePaymentCard = (payload: Partial<paymentCardPayload>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.savePaymentCard(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const savePaymentPix = (payload: Partial<paymentPixPayload>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.savePaymentPix(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const downloadBoleto = async (boletoUrl: string) => {
  axios
    .get(boletoUrl, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'boleto.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => console.log(error));
};
