import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Form, Field, FormSpy } from 'react-final-form';
import { RootState, useAppDispatch } from '../../../store';
import { Layout } from '../../../components/Layout';
import { Input } from '../../../components/Input';
import { AttendanceTable } from '../../../components/AttendanceTable';
import { Button } from '../../../components/Button';
import { DatePicker } from '../../../components/Datepicker';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';
import { Attendance, SearchAttendances } from '../../../slices/attendance';

import './styles.scss';
interface UserFilter {
  startDate?: string;
  endDate?: string;
  patientName?: string;
  status?: number;
}

export const DoctorsAttendance = () => {
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();

  const date = new Date();
  date.setDate(date.getDate() + 7);

  const [attendances, setAttendances] = useState<Attendance[]>([]);

  const [userFilter, setUserFilter] = useState<UserFilter>({
    startDate: new Date().toISOString(),
    endDate: date.toISOString(),
    patientName: '',
    status: 2,
  });

  const handleChangeStartDate = async (value: any, formattedValue: any) => {
    setUserFilter({
      ...userFilter,
      startDate: value,
    });
  };

  const handleChangeEndDate = async (value: any, formattedValue: any) => {
    setUserFilter({
      ...userFilter,
      endDate: value,
    });
  };

  useEffect(() => {
    const fetchPatientsDoctor = async () => {
      const response = await dispatch(
        SearchAttendances({
          doctorId: user.id,
          startDate: userFilter.startDate,
          endDate: userFilter.endDate,
          patientName: userFilter.patientName,
          status: userFilter.status,
        }),
      );
      setAttendances(response.data);
    };
    fetchPatientsDoctor();
  }, [
    dispatch,
    user.id,
    userFilter.endDate,
    userFilter.patientName,
    userFilter.startDate,
    userFilter.status,
  ]);

  const onSubmit = async () => {
    const response = await dispatch(
      SearchAttendances({
        doctorId: user.id,
        startDate: userFilter.startDate,
        endDate: userFilter.endDate,
        patientName: userFilter.patientName,
        status: userFilter.status,
      }),
    );
    setAttendances(response.data);
  };

  const onFormChange = (values: Partial<UserFilter>) => {
    setUserFilter({ ...values });
  };

  return (
    <Layout type="doctor">
      <div>
        <Row className="mt-4 mb-4">
          <Col style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="ATENDIMENTOS"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
        </Row>
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...userFilter }}
          render={({ handleSubmit, submitting }) => (
            <div className="doctors-attendance">
              <form>
                <FormSpy onChange={({ values }) => onFormChange(values)} />
              </form>
              <Row className="mt-2">
                <Text title="Busque pelos seus atendimentos" style={{}} />
              </Row>
              <Row className="mt-2">
                <Col sm={2}>
                  <Field
                    name="startDate"
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <DatePicker
                        input={input}
                        metaError={meta}
                        handleChange={(v: any, f: any) => handleChangeStartDate(v, f)}
                        label="Data Inicial"
                        submitting={submitting}
                      />
                    )}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    name="endDate"
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <DatePicker
                        input={input}
                        metaError={meta}
                        handleChange={(v: any, f: any) => handleChangeEndDate(v, f)}
                        label="Data Final"
                        submitting={submitting}
                      />
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="patientName"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Paciente"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        placeholder="Ex.: José da Silva"
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="status"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Status"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        type="select"
                        disabled={submitting}
                      >
                        <option value={-1}>Todos</option>
                        <option value={2}>Agendado</option>
                        <option value={3}>Finalizado</option>
                      </Input>
                    )}
                  />
                </Col>
                <Col sm={2}>
                  <Button className="mt-4" disabled={submitting} onClick={handleSubmit}>
                    Filtrar
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <AttendanceTable
                  attendances={attendances}
                  role={user.role}
                  isNonMedicalCompany={user.isNonMedicalCompany}
                  isLoading={app.isLoading}
                />
              </Row>
            </div>
          )}
        />
      </div>
    </Layout>
  );
};
