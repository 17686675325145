import React from 'react';
import { FormGroup, Input as ReactstrapInput, Label } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

import './styles.scss';

interface IOptions {
  label: string;
  value: any;
}

type Props = {
  label: string;
  type?: InputType;
  labelClass?: string;
  metaError?: any;
  options: IOptions[];
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Select = ({
  label,
  id,
  type = 'text',
  name,
  placeholder,
  children,
  className,
  labelClass,
  metaError,
  options,
  ...rest
}: Props) => (
  <FormGroup style={{ marginBottom: '0.2rem' }}>
    <Label
      className={`label-class ${metaError?.error && metaError?.touched ? 'error-label-class' : ''}`}
      for={id}
    >
      {label}
    </Label>
    <ReactstrapInput
      autoComplete="none"
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      {...rest}
      className={`select ${metaError?.error && metaError?.touched ? 'error-input-class' : ''}`}
    >
      <option value="">Selecione</option>
      {options?.map(option => {
        return <option value={option.value}>{option.label}</option>;
      })}
    </ReactstrapInput>
    {metaError?.error && metaError?.touched && (
      <span className="error-message">{metaError.error}</span>
    )}
  </FormGroup>
);
