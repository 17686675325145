// eslint-disable-next-line no-undef
function initPrescriptionPatient(idPrescricao) {
  /* eslint-disable */
  MdSinapsePrescricao.event.add('core:moduleInit', function (moduleData) {
    if (moduleData.name === 'plataforma.prescricao') {
      // O módulo da prescrição foi iniciado.
      // eslint-disable-next-line no-undef
      MdHub.event.add('prescricaoImpressa', function (prescriptionData) {
        // No objeto "prescriptionData", é retornado as informações da prescrição gerada.
        // Implementar ações, callbacks, etc.
        document.getElementById('botaoVisualizarPrescricao').addEventListener('click', function () {
          MdHub.command.send('plataforma.prescricao', 'viewPrescription', idPrescricao);
        });
      });
    }
  });
}

export default initPrescriptionPatient;
