import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from '../../../components/Layout';
import { RootState, useAppDispatch } from '../../../store';
import { Col, Row } from 'reactstrap';
import { AttendanceCardsTable } from '../../../components/AttendanceCardsTable';
import { AttendanceCard } from '../../../components/AttendanceCard';
import Darklast from '../../../resources/images/darklast.svg';
import { getDoctor } from '../../../slices/partner';
import { getAttendancesByDoctor } from '../../../slices/doctor';
import { DoctorUser } from '../../../slices/admin';
import { Attendance } from '../../../slices/attendance';
import { useHistory } from 'react-router-dom';
import { Text } from '../../../components/Text';
import { StatusAttendance } from '../../../utils/EnumStatusAttendance';
import Skeleton from 'react-loading-skeleton';

import './styles.scss';

export const DoctorHome = () => {
  const [attendanceDay, setAttendanceDay] = useState(new Date().toISOString());
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();

  const [attendances, setAttendances] = useState<Attendance[]>([]);
  const [lastAttendances, setLastAttendances] = useState<Attendance[]>([]);
  const [doctor, setDoctor] = useState<DoctorUser>({});

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);

  const history = useHistory();

  const handleChange = async (value: any, formattedValue: any) => {
    setAttendanceDay(value);
    await searchAttendances(value);
  };

  const searchAttendances = async (day?: string) => {
    const response = await dispatch(
      getAttendancesByDoctor({
        doctorId: user.id,
        stringDate: day || attendanceDay,
        status: StatusAttendance.InProgress,
      }),
    );
    setAttendances(response.data.slice(0, 4));
  };

  const handleChangeAttendanceDay = async (value: number) => {
    const date = new Date(attendanceDay);
    date.setDate(date.getDate() + value);
    setAttendanceDay(date.toISOString());

    await searchAttendances(date.toISOString());
  };

  useEffect(() => {
    const fetchDoctor = async () => {
      const response = await dispatch(getDoctor({ id: user.id }));
      setDoctor(response.data);
    };
    const fetchAttendancesOfDay = async () => {
      const response = await dispatch(
        getAttendancesByDoctor({ doctorId: user.id, stringDate: attendanceDay, status: 2 }),
      );
      setAttendances(response.data);
    };
    const fetchLastAttendances = async () => {
      const response = await dispatch(
        getAttendancesByDoctor({ doctorId: user.id, stringDate: '', status: 2 }),
      );
      setLastAttendances(response.data);
    };
    fetchDoctor();
    fetchAttendancesOfDay();
    fetchLastAttendances();
  }, []);

  return (
    <Layout type="doctor">
      <div className="doctor-dashboard">
        <Row className="mt-4">
          <Text title="Bem vindo," style={{ fontSize: '16px' }} />
        </Row>
        <Row>
          {!app.isLoading ? (
            <Text title={`${doctor?.firstName} ${doctor?.lastName}`} style={{ fontSize: '24px' }} />
          ) : (
            <Skeleton width={250} />
          )}
        </Row>
        <Row>
          <Col className="bordered-container mt-2">
            <Row className="mt-4">
              <Col>
                <AttendanceCardsTable
                  attendances={attendances}
                  attendance={attendanceDay}
                  role={user.role}
                  pageSize={pageSize}
                  page={page}
                  setPageSize={(value: number) => setPageSize(value)}
                  setPage={(value: number) => setPage(value)}
                  handleChange={(v: any, f: any) => handleChange(v, f)}
                  handleChangeAttendanceDay={(value: number) => handleChangeAttendanceDay(value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="bordered-container mt-4">
            <Row>
              <Col>
                <img
                  src={Darklast}
                  alt="calendar"
                  style={{
                    width: '13,33px',
                    height: '14,67px',
                    marginLeft: '6px',
                    marginRight: '6px',
                  }}
                />
                <span className="last-patients-text">Últimos pacientes</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Row>
                  {
                    // eslint-disable-next-line array-callback-return
                    lastAttendances?.map((attendance, index) => {
                      if (index < 4) {
                        return (
                          <Col sm={3}>
                            <AttendanceCard
                              redirectLinkTitle="ATENDER"
                              redirectWhere={() => {
                                if (user.isNonMedicalCompany) {
                                  history.push(
                                    `${process.env.PUBLIC_URL}/${user.role}/ampal/attendance/${attendance.id}`,
                                  );
                                } else {
                                  history.push(
                                    `${process.env.PUBLIC_URL}/${user.role}/attendance/${attendance.id}`,
                                  );
                                }
                              }}
                              attendance={attendance}
                            />
                          </Col>
                        );
                      }
                    })
                  }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
