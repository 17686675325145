import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Layout } from '../../components/Layout';
import { RootState, useAppDispatch } from '../../store';
import { ReturnLink } from '../../components/ReturnLink';
import { TummiModal, ITummiModalProps } from '../../components/Modal';
import { Text } from '../../components/Text';
import { SelectMulti } from '../../components/SelectMulti';
import {
  Attendance,
  AvaiableSchedule,
  getAttendance,
  RescheduleAttendace,
  SearchSpecialityByDay,
} from '../../slices/attendance';
import { DatePicker } from '../../components/Datepicker';

import 'react-credit-cards/lib/styles.scss';
import { Button } from '../../components/Button';
import moment from 'moment';

interface Params {
  attendanceId?: string;
}

export const RescheduleCheckout = () => {
  const { attendanceId } = useParams<Params>();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);

  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const inputDatePicker = {
    name: 'attendanceDate',
    value: new Date().toISOString(),
  };

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const [attendance, setAttendance] = useState<Attendance>();
  const [newDay, setNewDay] = useState<any>(inputDatePicker);
  const [attendanceDay, setAttendanceDay] = useState('');
  const [schedules, setSchedules] = useState<any[]>([]);
  const [avaiableSchedules, setAvaiableSchedules] = useState<AvaiableSchedule[]>([]);

  useEffect(() => {
    const fetchAttendance = async () => {
      const response = await dispatch(getAttendance(attendanceId || ''));
      setAttendance(response.data);
    };
    fetchAttendance();
  }, []);

  const handleChange = async (value: any, formattedValue: any) => {
    setNewDay({
      ...inputDatePicker,
      value,
    });

    const avaiableSchedulesResponse = await dispatch(
      SearchSpecialityByDay({
        date: value,
        speciality: attendance?.speciality,
        doctorId: attendance?.doctorTelemedicine?.id,
        companyId: user.companyId,
      }),
    );

    if (avaiableSchedulesResponse.data.length > 0) {
      const _schedules: any[] = [];

      avaiableSchedulesResponse.data.map((element: AvaiableSchedule, index: number) => {
        _schedules.push({
          value: element.dateTimeHour,
          label: moment(new Date(element.dateTimeHour || '')).format('DD/MM/YYYY HH:mm'),
        });
      });
      setSchedules(_schedules);
      setAvaiableSchedules(avaiableSchedulesResponse.data);
    } else {
      setModal({
        isOpen: true,
        body: 'Este médico não possui mais horários disponíveis para este dia.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
      setSchedules([]);
    }
  };

  const onRescheduleAttendance = async () => {
    const avaiableSchedule: AvaiableSchedule = {
      attendanceId: attendance?.id,
      doctorId: attendance?.doctorTelemedicine?.id,
      patientId: attendance?.patientTelemedicine?.id,
      speciality: attendance?.speciality,
      date: attendanceDay,
    };

    const attendanceResponse = await dispatch(RescheduleAttendace(avaiableSchedule));

    if (attendanceResponse.data.success) {
      setModal({
        isOpen: true,
        body: 'Reagendamento realizado com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => {
          if (user.isNonMedicalCompany) {
            history.push(
              `${process.env.PUBLIC_URL}/doctor/ampal/attendance/${attendanceResponse.data.attendanceId}`,
            );
          } else {
            history.push(
              `${process.env.PUBLIC_URL}/patient/attendance/${attendanceResponse.data.attendanceId}`,
            );
          }
        },
        okCallback: () => {
          if (user.isNonMedicalCompany) {
            history.push(
              `${process.env.PUBLIC_URL}/doctor/ampal/attendance/${attendanceResponse.data.attendanceId}`,
            );
          } else {
            history.push(
              `${process.env.PUBLIC_URL}/patient/attendance/${attendanceResponse.data.attendanceId}`,
            );
          }
        },
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body:
          'Não foi possivel ralizar o seu reagendamento. Tente novamente mais tarde ou entre em contato com a Thummi.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () =>
          history.push(
            `${process.env.PUBLIC_URL}/patient/attendance/${attendanceResponse.data.attendanceId}`,
          ),
        okCallback: () =>
          history.push(
            `${process.env.PUBLIC_URL}/patient/attendance/${attendanceResponse.data.attendanceId}`,
          ),
        okButton: 'Ok',
      });
    }
  };

  return (
    <Layout type={user.role}>
      <>
        <div className="view-profile-page">
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" returnLink={() => history.goBack()} />
              <Text
                title="REAGENDAMENTO ATENDIMENTO"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="bordered-container">
                <Row className="mt-2">
                  <Col sm={5}>
                    <span className="label">Médico (a)</span>
                  </Col>
                  <Col sm={4}>
                    <span className="label">Especialidade</span>
                  </Col>
                  <Col sm={3}>
                    <span className="label">Nova data do atendimento</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span>
                      <strong>
                        {attendance?.doctorTelemedicine?.firstName}{' '}
                        {attendance?.doctorTelemedicine?.lastName}
                      </strong>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <span>
                      <strong>{attendance?.speciality}</strong>
                    </span>
                  </Col>
                  <Col sm={3}>
                    <DatePicker
                      input={newDay}
                      handleChange={(v: any, f: any) => handleChange(v, f)}
                      submitting={app.isLoading}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={5}>
                    <span className="label">Valor da Consulta</span>
                  </Col>
                  <Col sm={4}>
                    <span className="label">Local</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span>
                      <strong>R$ {attendance?.attendanceValue}</strong>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <span>
                      <strong>{'Teleatendimento (Online)'}</strong>
                    </span>
                  </Col>
                </Row>
                <Row className="justify-content-center" style={{ marginTop: '50px' }}>
                  <Col sm={4}>
                    <Text
                      title="Horários"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center" style={{ marginBottom: '60px' }}>
                  <Col sm={4}>
                    <SelectMulti
                      label="Selecione o horário preferencial de sua consulta"
                      isMulti={false}
                      options={schedules}
                      onChange={(e: any) => setAttendanceDay(e.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col sm={4}>
                    <Button onClick={() => onRescheduleAttendance()} disabled={app.isLoading}>
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <TummiModal {...modal} />
      </>
    </Layout>
  );
};
