import { Api } from '../../services/api';
import {
  getAttendancesDoctorPayload,
  getUserPayload,
  updateUserStatusPayload,
} from '../../services/apiTypes';
import { AppDispatch } from '../../store';

import { DoctorUser } from '../admin';
import { setIsloading } from '../app';

export const updateDoctorUser = (payload: Partial<DoctorUser>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.updateDoctorUser(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const updateDoctorStatus = (payload: Partial<updateUserStatusPayload>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.updateDoctorStatus(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const createDoctorMemed = (payload: Partial<DoctorUser>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.createDoctorMemed(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const updateDoctorUserSchedule = (payload: Partial<DoctorUser>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.updateDoctorUserSchedule(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getDoctorSchedule = ({ id }: Partial<getUserPayload>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getDoctorSchedule({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getAttendancesByDoctor = ({
  doctorId,
  stringDate,
  status,
}: Partial<getAttendancesDoctorPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getAttendancesByDoctor({ doctorId, stringDate, status }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};
