import { Api } from '../../services/api';
import { getUserPayload } from '../../services/apiTypes';
import { AppDispatch } from '../../store';
import { setIsloading } from '../app';

export interface Module1 {
  id?: string;
  m1A?: Alternative;
  m1B?: Alternative;
  m1C?: Alternative;
  m1C2?: Alternative;
  m1D?: Alternative;
  m1T?: Alternative;
}

export interface Module2 {
  id?: string;
  m2A?: Alternative;
  m2A1?: Alternative;
  m2A2?: Alternative;
  m2A3?: Alternative;
  m2B?: Alternative;
  m2C?: Alternative;
  m2D?: Alternative;
}

export interface Module3 {
  id?: string;
  m3A?: Alternative;
  m3B?: Alternative;
  m3C?: Alternative;
  m3D?: Alternative;
  m3E?: Alternative;
  m3F?: Alternative;
  m3G?: Alternative;
  m3GA?: Alternative;
  m3H?: Alternative;
  m3I?: Alternative;
}

export interface Module4 {
  id?: string;
  m4A?: Alternative;
  m4B?: Alternative;
  m4B1?: Alternative;
  m4C?: Alternative;
  m4D?: Alternative;
}

export interface Module5 {
  id?: string;
  m5A?: Alternative;
  m5B?: Alternative;
  m5C?: Alternative;
  m5D?: Alternative;
  m5E?: Alternative;
}

export interface Module6 {
  id?: string;
  m6A?: Alternative;
  m6B?: Alternative;
  m6C1?: Alternative;
  m6C2?: Alternative;
  m6C3?: Alternative;
  m6C4?: Alternative;
  m6C5?: Alternative;
  m6C6?: Alternative;
  m6C7?: Alternative;
  m6C8?: Alternative;
  m6C9?: Alternative;
}

export interface Module7 {
  id?: string;
  m7A?: Alternative;
  m7A1?: Alternative;
  m7B?: Alternative;
  m7C?: Alternative;
  m7D?: Alternative;
}

export interface Module8 {
  id?: string;
  m8A?: Alternative;
  m8B?: Alternative;
  m8C?: Alternative;
  m8D?: Alternative;
  m8E?: Alternative;
  m8F?: Alternative;
  m8G?: Alternative;
  m8H?: Alternative;
  m8I?: Alternative;
  m8J?: Alternative;
}

export interface Module9 {
  id?: string;
  m9A?: Alternative;
  m9B?: Alternative;
  m9C?: Alternative;
  m9D?: Alternative;
  m9E?: Alternative;
}

export interface Introduction {
  id?: string;
  a1?: Alternative;
  a2?: Alternative;
  a3?: Alternative;
  a4?: Alternative;
  a4a?: Alternative;
  a5?: Alternative;
  a5a?: Alternative;
  a6?: Alternative;
  a7a?: Alternative;
  a7b?: Alternative;
  a7c?: Alternative;
  a7d?: Alternative;
  a7e?: Alternative;
  a7f?: Alternative;
}

export interface Alternative {
  id?: string;
  flag?: number;
  alternativeA?: string;
  alternativeB?: string;
  alternativeC?: string;
  alternativeD?: string;
  alternativeE?: string;
  alternativeF?: string;
  alternativeG?: string;
  alternativeH?: string;
  alternativeI?: string;
  alternativeJ?: string;
  alternativeK?: string;

  alternative1?: any;
  alternative2?: any;
  alternative3?: any;
  alternative4?: any;
  alternative5?: any;
}

export interface AmpalForm {
  id?: string;
  attendanceId?: string;
  introduction?: Introduction;
  module1?: Module1;
  module2?: Module2;
  module3?: Module3;
  module4?: Module4;
  module5?: Module5;
  module6?: Module6;
  module7?: Module7;
  module8?: Module8;
  module9?: Module9;
  patientEvolutionInfo?: string;
}

export const getAmpalForm = ({ id }: Partial<getUserPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.getAmpalForm({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const saveAmpalForm = (payload: Partial<AmpalForm>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.saveAmpalForm(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};
