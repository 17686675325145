import React from 'react';
import { NavLink } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import leftArrow from '../../resources/images/leftArrow.svg';
import './styles.scss';

type IReturnLinkProps = {
  title: string;
  returnLink?: () => void;
};

export const ReturnLink = ({ title, returnLink }: IReturnLinkProps) => {
  const history = useHistory();
  return (
    <NavLink
      style={{ cursor: 'pointer' }}
      className="return-link"
      onClick={() => {
        if (!returnLink) {
          history.goBack();
        } else {
          returnLink();
        }
      }}
    >
      <img
        src={leftArrow}
        alt={'Leave Logo'}
        style={{ width: '13px', height: '13px', marginRight: '15px' }}
      />
      {title}
    </NavLink>
  );
};
