import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from 'reactstrap';

import './styles.scss';

type Props = {
  type: string;
  children: JSX.Element;
};

export const Layout = ({ type, children }: Props) => (
  <div className="layout">
    <Header type={type} />
    <Container>{children}</Container>
    <Footer />
  </div>
);
