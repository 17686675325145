import React, { useRef } from 'react';
import { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';
import { Input } from '../../components/Input';
import { useAppDispatch } from '../../store';
import { ReturnLink } from '../../components/ReturnLink';
import { TummiModal, ITummiModalProps } from '../../components/Modal';
import { Button } from '../../components/Button';
import { recoverPassword } from '../../slices/user';
import { Text } from '../../components/Text';
import ReCAPTCHA from 'react-google-recaptcha';

import './styles.scss';
interface RecoverPasswordInterface {
  email?: string;
}

export const RecoverPassword = () => {
  const history = useHistory();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const [userToUpdate, setUserToUpdate] = useState<RecoverPasswordInterface>({});

  const [captcha, setCaptcha] = useState('');

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleOnCaptcha = (value: any) => {
    if (value) {
      setCaptcha(value);
    }
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const onSubmit = async (values: RecoverPasswordInterface) => {
    const response = await dispatch(recoverPassword(values.email));

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Uma nova senha será enviada para o email informado.',
        className: '',
        title: 'Mensagem',
        // eslint-disable-next-line no-restricted-globals
        toggle: () => history.goBack(),
        okButton: 'Ok',
      });
    }
    setCaptcha('');
    recaptchaRef?.current?.reset();
  };

  const onFormChange = (values: Partial<RecoverPasswordInterface>) => {
    setUserToUpdate({ ...values });
  };

  return (
    <>
      <div className="view-profile-page">
        <Container>
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Form
                onSubmit={onSubmit}
                initialValues={{ ...userToUpdate }}
                render={({ handleSubmit, submitting }) => (
                  <>
                    <form>
                      <FormSpy onChange={({ values }) => onFormChange(values)} />
                    </form>
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Informe seu email abaixo"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={6}>
                          <Field
                            name="email"
                            validate={required}
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Email *"
                                placeholder="email@email.com"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row className="mt-4 mb-4 justify-content-center">
                      <Col sm={4} className="ml-4">
                        <ReCAPTCHA
                          ref={recaptchaRef as any}
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                          onChange={handleOnCaptcha}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 justify-content-center">
                      <Col sm={4}>
                        <Button
                          onClick={() => {
                            if (captcha) {
                              handleSubmit();
                            }
                          }}
                          disabled={submitting}
                        >
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <TummiModal {...modal} />
    </>
  );
};
