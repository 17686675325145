import { Api } from '../../services/api';
import { searchAttendancesPayload, searchSpecialityByDayPayload } from '../../services/apiTypes';
import { AppDispatch } from '../../store';
import { DoctorUser, PatientUser } from '../admin';

import { setIsloading } from '../app';
import { BoletoTransaction, CardTransaction, PixTransaction } from '../payment';

export type AvaiableSchedule = {
  speciality?: string;
  dateTimeHour?: Date;
  doctorId?: string;
  checked?: boolean;
  patientId?: string;
  date?: string;
  doctorName?: string;
  paidBy?: number;
  boletoTransaction?: BoletoTransaction;
  cardTransaction?: CardTransaction;
  pixTransaction?: PixTransaction;
  value?: number;
  attendanceId?: string;
};

export type Comorbities = {
  id?: string;
  name?: string;
};

export type Vaccinations = {
  id?: string;
  name?: string;
};

export type Allergies = {
  id?: string;
  name?: string;
};

export type Cids = {
  id?: string;
  name?: string;
};

export type Medications = {
  id?: string;
  name?: string;
  dose?: string;
  dosage?: string;
};

export type Attendance = {
  id?: string;
  speciality?: string;
  patientTelemedicine?: PatientUser;
  doctorTelemedicine?: DoctorUser;
  statusAttendance?: number;
  link?: string;
  date?: Date;
  comorbities?: Comorbities[];
  vaccinations?: Vaccinations[];
  allergies?: Allergies[];
  medications?: Medications[];
  clinicalHistory?: string;
  diuresis?: string;
  evacuation?: string;
  gaitAidEquipment?: string;
  heigth?: string;
  weigth?: string;
  pa?: string;
  heartRate?: string;
  respiratoryFrequency?: string;
  temperature?: string;
  oxygenSaturation?: string;
  generalState?: string;
  consciousnessLevel?: string;
  hydration?: string;
  pain?: number;
  mucous?: string;
  jaundice?: string;
  cianose?: string;
  suspect?: number;
  medicalReport?: number;
  purposeOfCare?: string;
  rhythm?: string;
  bulhas?: string;
  sopro?: string;
  vesicularMurmur?: string;
  cracklingStains?: string;
  examinationAbdomen?: string;
  skinInjuries?: string;
  right?: string;
  left?: string;
  history?: string;
  diagnosis?: string;
  conduct?: string;
  prescription?: string;
  comments?: string;
  smoking?: string;
  yearsSmoking?: number;
  ethics?: string;
  patientAfraidOf?: string;
  cids?: Cids[];
  das21Completed?: boolean;
  patientRecomendations?: string;
  attendanceValue?: number;
  paidBy?: number;
  cardTransaction?: CardTransaction;
  boletoTransaction?: BoletoTransaction;
  pixTransaction?: PixTransaction;
  prescriptionId?: number;
};

export const SearchSpecialityByDay = ({
  date,
  speciality,
  doctorId,
  companyId,
}: Partial<searchSpecialityByDayPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.SearchSpecialityByDay({ date, speciality, doctorId, companyId }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const SearchDoctorsSpecialityByDay = ({
  date,
  speciality,
  companyId,
}: Partial<searchSpecialityByDayPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.SearchDoctorsSpecialityByDay({ date, speciality, companyId }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const SearchAttendances = ({
  doctorId,
  startDate,
  endDate,
  patientName,
  status,
}: Partial<searchAttendancesPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.SearchAttendances({ doctorId, startDate, endDate, patientName, status }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const CreateAttendace = (payload: Partial<AvaiableSchedule>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.CreateAttendace(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const RescheduleAttendace = (payload: Partial<AvaiableSchedule>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.RescheduleAttendace(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getAttendance = (id: Partial<string>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.getAttendance(id).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getAttendancesByPatient = (id: Partial<string>, status: Partial<number>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.getAttendancesByPatient(id, status).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const SearchDoctorPatients = (doctorId: string, name?: string, cpf?: string) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.SearchDoctorPatients(doctorId, name, cpf).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const UpdateAttendanceStatus = (id: string, status: number) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.UpdateAttendanceStatus(id, status).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const UpdateAttendance = (payload: Partial<Attendance>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.UpdateAttendance(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const CancelAttendance = (payload: Partial<Attendance>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.CancelAttendance(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};
