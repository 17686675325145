import { useHistory } from 'react-router-dom';
import Eye from '../../resources/images/eye.svg';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { Attendance } from '../../slices/attendance';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import './styles.scss';
import { MDBDataTable } from 'mdbreact';

interface IAttendanceTableProps {
  attendances?: Attendance[];
  role: string;
  isLoading?: boolean;
}

export const AttendanceTable = ({ attendances, role, isLoading }: IAttendanceTableProps) => {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);

  const statusAttendance = (value?: number) => {
    switch (value) {
      case 0:
        return 'Pré-Agendado (aguardando pagamento)';
      case 1:
        return 'Cancelado';
      case 2:
        return 'Agendado';
      default:
        return 'Finalizado';
    }
  };

  const getActions = (id?: string) => {
    return (
      <div className="attendance-table-buttons">
        <span
          onClick={() => redirectViewAttendance(id)}
          style={{ cursor: 'pointer', marginRight: '150px' }}
        >
          <img
            src={Eye}
            alt="alert"
            style={{
              width: '22px',
              height: '15px',
            }}
          />
        </span>
      </div>
    );
  };

  const redirectViewAttendance = (id?: string) => {
    if (role !== 'patient') {
      if (user.isNonMedicalCompany) {
        history.push(`${process.env.PUBLIC_URL}/${role}/ampal/attendance/${id}`);
      } else {
        history.push(`${process.env.PUBLIC_URL}/${role}/attendance/${id}`);
      }
    } else {
      history.push(`${process.env.PUBLIC_URL}/${role}/attendance/${id}`);
    }
  };

  const patientsTable: any = attendances?.map((attendance: Attendance) => {
    return {
      date: attendance?.date ? moment(attendance.date).format('DD/MM/YYYY HH:mm') : '',
      doctorName: `${attendance.doctorTelemedicine?.firstName} ${attendance.doctorTelemedicine?.lastName}`,
      speciality:
        attendance.doctorTelemedicine?.doctorType === 0 ? attendance.speciality : 'Psicologo',
      status: statusAttendance(attendance?.statusAttendance),
      action: getActions(attendance.id),
    };
  });

  const data = {
    columns: [
      {
        label: 'Data/Hora',
        field: 'date',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Médico',
        field: 'doctorName',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Especialidade',
        field: 'speciality',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Acessar Atendimento',
        field: 'action',
        sort: 'asc',
        width: 200,
      },
    ],
    rows: [...patientsTable],
  };

  const rows: any = [];
  for (let i = 0; i < 3; i++) {
    rows.push({
      date: <Skeleton width={150} />,
      doctorName: <Skeleton width={150} />,
      speciality: <Skeleton width={150} />,
      status: <Skeleton width={150} />,
      action: <Skeleton width={150} />,
    });
  }

  const isLoadingData = {
    ...data,
    rows: rows,
  };

  return (
    <div className="patients-table">
      {!isLoading ? (
        <MDBDataTable
          searching
          searchLabel="Pesquisa rápida"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'médicos']}
          theadColor="indigo"
          striped
          bordered
          data={data}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      ) : (
        <MDBDataTable
          searching
          searchLabel="Pesquisa rápida"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'médicos']}
          theadColor="indigo"
          striped
          bordered
          data={isLoadingData}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      )}
    </div>
  );
};
