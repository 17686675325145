import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { Layout } from '../../../components/Layout';
import { Form, FormSpy, Field } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';
import { isValidCep } from '../../../utils/ValidCep';
import { getCompany, Tummi, UpdateCompany } from '../../../slices/tummi';
import { maskCNPJ, maskCPF, maskDDD, maskPhoneNumber, maskZipCode } from '../../../utils/Mask';
import { Input } from '../../../components/Input';
import { Select } from '../../../components/Select';
import { State } from '../../../utils/EnumStates';
import formatString from 'format-string-by-pattern';
import { Button } from '../../../components/Button';
import { SelectMulti } from '../../../components/SelectMulti';
import { TummiModal, ITummiModalProps } from '../../../components/Modal';

import bancosBrasileiros from 'bancos-brasileiros';

const { consultarCep } = require('correios-brasil');

type OptionType = {
  value?: string;
  label?: string;
};

export const AdministrativePanel = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const [company, setCompany] = useState<Tummi>();
  const user = useSelector((state: RootState) => state.user);

  const bancos = bancosBrasileiros.map(function name(params: any) {
    return {
      value: params.COMPE,
      label: `${params.LongName.toUpperCase()} - ${params.COMPE}`,
    };
  });

  const [bank, setBank] = useState<OptionType>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getCompany({ id: user.companyId }));

      if (response.data.bankDataTelemedicine?.bank) {
        const _bank = bancosBrasileiros.find(function name(params: any) {
          return params.COMPE === response.data.bankDataTelemedicine?.bank;
        });

        setBank({
          value: _bank?.COMPE,
          label: `${_bank?.LongName.toUpperCase()} - ${_bank?.COMPE}`,
        });
      }

      setCompany({
        ...response.data,
        cep: formatString(maskZipCode, response.data.cep),
      });
    };
    fetchData();
  }, [dispatch, user.companyId]);

  const onSubmit = async (values: Tummi) => {
    values.cep = values.cep?.replace(/[^a-zA-Z0-9]/g, '');
    values.cnpj = values.cnpj?.replace(/[^a-zA-Z0-9]/g, '');
    values.cpf = values.cpf?.replace(/[^a-zA-Z0-9]/g, '');

    values.bankDataTelemedicine = {
      ...values.bankDataTelemedicine,
      bank: bank?.value,
    };

    const response = await dispatch(UpdateCompany(values));
    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Dados alterados com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => setShowModal(!showModal),
        leavingCallback: () => history.goBack(),
        //eslint-disable-next-line no-restricted-globals
        okCallback: () => location.reload(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body: response.data.errorMessage,
        className: '',
        title: 'Mensagem',
        toggle: () => setShowModal(!showModal),
        leavingCallback: () => history.goBack(),
        okButton: 'Tentar novamente',
      });
    }
  };

  const [buscaCepSubmit, setBuscaCepSubmit] = useState(false);

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const buscaCep = () => {
    setBuscaCepSubmit(true);
    if (company?.cep && isValidCep(company?.cep)) {
      consultarCep(company?.cep.replaceAll('.', '').replaceAll('-', ''))
        .then((response: any) => {
          if (response) {
            setCompany({
              ...company,
              streetAddress: response.logradouro,
              complement: response.complemento,
              neighborhood: response.bairro,
              city: response.localidade,
              state: response.uf,
            });
          }
          setBuscaCepSubmit(false);
        })
        .catch((err: any) => {
          setModal({
            isOpen: true,
            body: 'Cep não existente na base dos correios.',
            className: '',
            title: 'Mensagem',
            toggle: () => toggle(),
            okButton: 'Ok',
          });
          setBuscaCepSubmit(false);
        });
    } else {
      setModal({
        isOpen: true,
        body: 'Informe um Cep Válido.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
      setBuscaCepSubmit(false);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: false,
    body: '',
    className: '',
    toggle: () => setShowModal(!showModal),
    title: '',
  });

  const onFormChange = (values: Partial<Tummi>) => {
    setCompany({ ...values });
  };

  return (
    <>
      <Layout type="admin">
        <>
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
              <Text
                title="ADMINISTRAÇÃO"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Form
            onSubmit={onSubmit}
            initialValues={{ ...company }}
            render={({ handleSubmit, submitting }) => (
              <div className="create-user">
                <form>
                  <FormSpy onChange={({ values }) => onFormChange(values)} />
                </form>
                <Row className="mt-4">
                  <Col sm={12}>
                    <Text
                      title="Cadastro das informações oficiais da Tummi. (Tenha cuidado)"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={8}>
                    <Field
                      name="name"
                      validate={required}
                      parse={(value: string) => value}
                      render={({ input, meta }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Razão social *"
                          placeholder="Nome"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          required={true}
                          disabled={submitting || buscaCepSubmit}
                          metaError={meta}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={6}>
                    <Field
                      name="cnpj"
                      validate={required}
                      parse={formatString(maskCNPJ)}
                      disabled={submitting}
                      render={({ input, meta }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="CNPJ *"
                          placeholder="000.000.000/0000-00"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          required={true}
                          disabled={submitting || buscaCepSubmit}
                          metaError={meta}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      name="email"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="E-mail"
                          placeholder="email@dominio"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          type="email"
                          disabled={submitting || buscaCepSubmit}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={1}>
                    <Field
                      name="ddd"
                      disabled={submitting}
                      parse={formatString(maskDDD)}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="DDD"
                          placeholder="00"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="phone"
                      disabled={submitting}
                      parse={formatString(maskPhoneNumber)}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Telefone"
                          placeholder="00000-0000"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={4}>
                    <Field
                      name="commission"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Comissão da Tummi em cima dos atendimentos"
                          isCurrency
                          prefix="% "
                          onValueChange={(value: any) => {
                            setCompany({
                              ...company,
                              commission: value,
                            });
                          }}
                          onBlur={input.onBlur}
                          value={input.value}
                          currenctValue={company?.commission}
                          disabled={submitting || buscaCepSubmit}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={4}>
                    <Field
                      name="attendanceTime"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Tempo dos atendimentos (em minutos)"
                          isCurrency
                          onValueChange={(value: any) => {
                            setCompany({
                              ...company,
                              attendanceTime: value,
                            });
                          }}
                          onBlur={input.onBlur}
                          value={input.value}
                          currenctValue={company?.attendanceTime}
                          disabled={submitting || buscaCepSubmit}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Text
                      title="Dados do Responsável Legal da Tummi na Safe2Pay"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <Field
                      name="reponsableName"
                      validate={required}
                      parse={(value: string) => value}
                      disabled={submitting}
                      render={({ input, meta }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Nome *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          required={true}
                          disabled={submitting || buscaCepSubmit}
                          metaError={meta}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="cpf"
                      validate={required}
                      parse={formatString(maskCPF)}
                      disabled={submitting}
                      render={({ input, meta }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="CPF *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          required={true}
                          disabled={submitting || buscaCepSubmit}
                          metaError={meta}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={4}>
                    <Field
                      name="responsableEmail"
                      validate={required}
                      parse={(value: string) => value}
                      disabled={submitting}
                      render={({ input, meta }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="E-mail *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          required={true}
                          disabled={submitting || buscaCepSubmit}
                          metaError={meta}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Text
                      title="Dados bancários (valores de commissão serão redirecionadas para esta conta)"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={8}>
                    <Field
                      name="bankDataTelemedicine.bank"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input, meta }) => (
                        <SelectMulti
                          onChange={(value: any) => {
                            setBank(value);
                          }}
                          value={bank}
                          options={bancos}
                          label="Banco *"
                          isMulti={false}
                          isDisabled={submitting || buscaCepSubmit}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={6}>
                    <Field
                      name="bankDataTelemedicine.agency"
                      disabled={submitting}
                      parse={(value: string) => value}
                      validate={required}
                      render={({ input, meta }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Agência *"
                          placeholder="0000"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      name="bankDataTelemedicine.account"
                      disabled={submitting}
                      parse={(value: string) => value}
                      validate={required}
                      render={({ input, meta }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Conta-corrente *"
                          placeholder="Apenas conta corrente"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Text
                      title="Endereço da Tummi"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={3}>
                    <Field
                      name="cep"
                      validate={required}
                      parse={formatString(maskZipCode)}
                      render={({ input, meta }) => (
                        <>
                          <Input
                            name={input.name}
                            id={input.name}
                            label="CEP *"
                            placeholder="CEP"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                            disabled={submitting || buscaCepSubmit}
                            required={true}
                            metaError={meta}
                          />
                        </>
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Button
                      className="mt-4"
                      disabled={submitting || buscaCepSubmit}
                      onClick={() => buscaCep()}
                    >
                      BUSCAR CEP
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={7}>
                    <Field
                      name="streetAddress"
                      validate={required}
                      parse={(value: string) => value}
                      render={({ input, meta }) => (
                        <div>
                          <Input
                            name={input.name}
                            id={input.name}
                            label="Logradouro"
                            placeholder="Logradouro *"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                            disabled={submitting || buscaCepSubmit}
                            required={true}
                            metaError={meta}
                          />
                        </div>
                      )}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="number"
                      validate={required}
                      parse={(value: string) => value}
                      render={({ input, meta }) => (
                        <>
                          <Input
                            name={input.name}
                            id={input.name}
                            label="Número"
                            placeholder="Número *"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                            disabled={submitting || buscaCepSubmit}
                            required={true}
                            metaError={meta}
                          />
                        </>
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="complement"
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Complemento"
                          placeholder="Complemento"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={3}>
                    <Field
                      name="neighborhood"
                      validate={required}
                      parse={(value: string) => value}
                      render={({ input, meta }) => (
                        <>
                          <Input
                            name={input.name}
                            id={input.name}
                            label="Bairro"
                            placeholder="Bairro *"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                            disabled={submitting || buscaCepSubmit}
                            required={true}
                            metaError={meta}
                          />
                        </>
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="city"
                      validate={required}
                      parse={(value: string) => value}
                      render={({ input, meta }) => (
                        <>
                          <Input
                            name={input.name}
                            id={input.name}
                            label="Cidade"
                            placeholder="Cidade *"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                            disabled={submitting || buscaCepSubmit}
                            required={true}
                            metaError={meta}
                          />
                        </>
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="state"
                      validate={required}
                      parse={(value: string) => value}
                      render={({ input, meta }) => (
                        <>
                          <Select
                            name={input.name}
                            id={input.name}
                            label="Estado *"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                            type="select"
                            disabled={submitting || buscaCepSubmit}
                            options={State}
                            required={true}
                            metaError={meta}
                          />
                        </>
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col sm={4}>
                    <Button
                      className="mt-4"
                      onClick={handleSubmit}
                      disabled={submitting || buscaCepSubmit}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          />
          <TummiModal {...modal} />
        </>
      </Layout>
    </>
  );
};
