import { useState, useEffect } from 'react';
import { Layout } from '../../../components/Layout';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { Col, Card, CardTitle, CardBody, Row } from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import { getDoctorSchedule, updateDoctorUserSchedule } from '../../../slices/doctor';
import { RootState, useAppDispatch } from '../../../store';
import { DoctorUser, ScheduleInterval, DaySchedule } from '../../../slices/admin';
import { TummiModal, ITummiModalProps } from '../../../components/Modal';
import { maskHour } from '../../../utils/Mask';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { ReturnLink } from '../../../components/ReturnLink';
import { v4 as uuidv4 } from 'uuid';

import './styles.scss';

type Params = { id: string };

export const DoctorSchedules = () => {
  const { id } = useParams<Params>();
  const dispatch = useAppDispatch();
  const [doctor, setDoctor] = useState<DoctorUser>({});
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const [monday, setMonday] = useState<ScheduleInterval[]>([]);
  const [tuesday, setTuesday] = useState<ScheduleInterval[]>([]);
  const [wednesday, setWednesday] = useState<ScheduleInterval[]>([]);
  const [thursday, setThursday] = useState<ScheduleInterval[]>([]);
  const [friday, setFriday] = useState<ScheduleInterval[]>([]);
  const [saturday, setSaturday] = useState<ScheduleInterval[]>([]);

  const [showModal, setShowModal] = useState(false);

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  useEffect(() => {}, [monday, tuesday, wednesday, thursday, friday, saturday]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getDoctorSchedule({ id: id || user.id }));

      setDoctor(response.data);

      if (
        response.data.doctorSchedule?.weekSchedule?.some(
          (element: DaySchedule) => element.dayOfWeek === 1,
        )
      ) {
        // eslint-disable-next-line array-callback-return
        const day = response.data.doctorSchedule?.weekSchedule?.filter((day: DaySchedule) => {
          if (day.dayOfWeek === 1) {
            return day.scheduleInterval;
          }
        });
        if (day[0]) {
          setMonday(day[0].scheduleInterval);
        }
      }

      if (
        response.data.doctorSchedule?.weekSchedule?.some(
          (element: DaySchedule) => element.dayOfWeek === 2,
        )
      ) {
        // eslint-disable-next-line array-callback-return
        const day = response.data.doctorSchedule?.weekSchedule?.filter((day: DaySchedule) => {
          if (day.dayOfWeek === 2) {
            return day.scheduleInterval;
          }
        });
        if (day[0]) {
          setTuesday(day[0].scheduleInterval);
        }
      }

      if (
        response.data.doctorSchedule?.weekSchedule?.some(
          (element: DaySchedule) => element.dayOfWeek === 3,
        )
      ) {
        // eslint-disable-next-line array-callback-return
        const day = response.data.doctorSchedule?.weekSchedule?.filter((day: DaySchedule) => {
          if (day.dayOfWeek === 3) {
            return day.scheduleInterval;
          }
        });
        if (day[0]) {
          setWednesday(day[0].scheduleInterval);
        }
      }

      if (
        response.data.doctorSchedule?.weekSchedule?.some(
          (element: DaySchedule) => element.dayOfWeek === 4,
        )
      ) {
        // eslint-disable-next-line array-callback-return
        const day = response.data.doctorSchedule?.weekSchedule?.filter((day: DaySchedule) => {
          if (day.dayOfWeek === 4) {
            return day.scheduleInterval;
          }
        });
        if (day[0]) {
          setThursday(day[0].scheduleInterval);
        }
      }

      if (
        response.data.doctorSchedule?.weekSchedule?.some(
          (element: DaySchedule) => element.dayOfWeek === 5,
        )
      ) {
        // eslint-disable-next-line array-callback-return
        const day = response.data.doctorSchedule?.weekSchedule?.filter((day: DaySchedule) => {
          if (day.dayOfWeek === 5) {
            return day.scheduleInterval;
          }
        });
        if (day[0]) {
          setFriday(day[0].scheduleInterval);
        }
      }

      if (
        response.data.doctorSchedule?.weekSchedule?.some(
          (element: DaySchedule) => element.dayOfWeek === 6,
        )
      ) {
        // eslint-disable-next-line array-callback-return
        const day = response.data.doctorSchedule?.weekSchedule?.filter((day: DaySchedule) => {
          if (day.dayOfWeek === 6) {
            return day.scheduleInterval;
          }
        });
        if (day[0]) {
          setSaturday(day[0].scheduleInterval);
        }
      }
    };
    fetchData();
  }, []);

  const onSubmit = async () => {
    // eslint-disable-next-line array-callback-return
    doctor.doctorSchedule?.weekSchedule?.map(weekday => {
      switch (weekday.dayOfWeek) {
        case 1:
          weekday.scheduleInterval = monday;
          break;
        case 2:
          weekday.scheduleInterval = tuesday;
          break;
        case 3:
          weekday.scheduleInterval = wednesday;
          break;
        case 4:
          weekday.scheduleInterval = thursday;
          break;
        case 5:
          weekday.scheduleInterval = friday;
          break;
        case 6:
          weekday.scheduleInterval = saturday;
          break;
        default:
          break;
      }
    });

    setDoctor({
      ...doctor,
    });

    const response = await dispatch(updateDoctorUserSchedule(doctor));

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Dados alterados com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => window.location.reload(),
        leavingCallback: () => window.location.reload(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body: response.data.errorMessage,
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => history.goBack(),
        okButton: 'Tentar novamente',
      });
    }
  };

  return (
    <div className="doctor-schedule">
      <Layout type={user.role}>
        <>
          <TummiModal {...modal} />
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
              <Text
                title="MEUS HORÁRIOS"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Text
                title={`Abaixo informe os períodos que você estará disponível para marcação de consultas, respeitando o tempo de atendimento de ${doctor.doctorSchedule?.timeOfConsult} minutos.`}
                type="gray-text"
                style={{
                  fontSize: '16px',
                }}
              />
            </Col>
          </Row>
          <div className="mt-2 row" style={{ marginLeft: '10px' }}>
            <Card className="col-3 row card-board">
              <CardBody>
                <CardTitle tag="h5">
                  <Text
                    title="Segunda"
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </CardTitle>
                <Button
                  onClick={() => {
                    const schedule = [...monday];
                    let date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    schedule?.push({
                      id: uuidv4(),
                      startTimeString: '',
                      endTimeString: '',
                    });
                    setMonday(schedule);
                  }}
                  type="primary"
                  disabled={user.role === 'partner'}
                >
                  ADICIONAR INTERVALO
                </Button>
                <Row className="mt-4">
                  <Col>
                    <>
                      {monday?.map(function (element, index) {
                        return (
                          <>
                            {!element.deleted && (
                              <Row key={index} className="mt-2">
                                <label style={{ paddingTop: '10px' }}>De:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      monday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.startTimeString = e.target.value;

                                    const schedule = [...monday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setMonday(schedule);
                                  }}
                                  value={element?.startTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                <label style={{ paddingTop: '10px' }}>Até:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      monday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.endTimeString = e.target.value;

                                    const schedule = [...monday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setMonday(schedule);
                                  }}
                                  value={element?.endTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                {user.role === 'doctor' && (
                                  <FontAwesomeIcon
                                    style={{ marginTop: '10px', cursor: 'pointer' }}
                                    icon={faTrash}
                                    color="#008171"
                                    onClick={() => {
                                      const day =
                                        monday.find(interval => {
                                          return interval.id === element.id;
                                        }) || {};

                                      day.deleted = true;

                                      const schedule = [...monday];

                                      // eslint-disable-next-line array-callback-return
                                      schedule.map((schedule: ScheduleInterval) => {
                                        if (schedule.id === element.id) {
                                          schedule = day;
                                        }
                                      });

                                      setMonday(schedule);
                                    }}
                                  />
                                )}
                              </Row>
                            )}
                          </>
                        );
                      })}
                    </>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="col-3 ml-5 row card-board">
              <CardBody>
                <CardTitle tag="h5">
                  <Text
                    title="Terça"
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </CardTitle>
                <Button
                  onClick={() => {
                    const schedule = [...tuesday];
                    let date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    schedule?.push({
                      id: uuidv4(),
                      startTimeString: '',
                      endTimeString: '',
                    });
                    setTuesday(schedule);
                  }}
                  type="primary"
                  disabled={user.role === 'partner'}
                >
                  ADICIONAR INTERVALO
                </Button>
                <Row className="mt-4">
                  <Col>
                    <>
                      {tuesday?.map(function (element, index) {
                        return (
                          <>
                            {!element.deleted && (
                              <Row key={index} className="mt-2">
                                <label style={{ paddingTop: '10px' }}>De:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      tuesday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.startTimeString = e.target.value;

                                    const schedule = [...tuesday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setTuesday(schedule);
                                  }}
                                  value={element?.startTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                <label style={{ paddingTop: '10px' }}>Até:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      tuesday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.endTimeString = e.target.value;

                                    const schedule = [...tuesday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setTuesday(schedule);
                                  }}
                                  value={element?.endTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                {user.role === 'doctor' && (
                                  <FontAwesomeIcon
                                    style={{ marginTop: '10px', cursor: 'pointer' }}
                                    icon={faTrash}
                                    color="#008171"
                                    onClick={() => {
                                      const day =
                                        tuesday.find(interval => {
                                          return interval.id === element.id;
                                        }) || {};

                                      day.deleted = true;

                                      const schedule = [...tuesday];

                                      // eslint-disable-next-line array-callback-return
                                      schedule.map((schedule: ScheduleInterval) => {
                                        if (schedule.id === element.id) {
                                          schedule = day;
                                        }
                                      });

                                      setTuesday(schedule);
                                    }}
                                  />
                                )}
                              </Row>
                            )}
                          </>
                        );
                      })}
                    </>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="col-3 ml-5 row card-board">
              <CardBody>
                <CardTitle tag="h5">
                  <Text
                    title="Quarta-feira"
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </CardTitle>
                <Button
                  onClick={() => {
                    const schedule = [...wednesday];
                    let date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    schedule?.push({
                      id: uuidv4(),
                      startTimeString: '',
                      endTimeString: '',
                    });
                    setWednesday(schedule);
                  }}
                  type="primary"
                  disabled={user.role === 'partner'}
                >
                  ADICIONAR INTERVALO
                </Button>
                <Row className="mt-4">
                  <Col>
                    <>
                      {wednesday?.map(function (element, index) {
                        return (
                          <>
                            {!element.deleted && (
                              <Row key={index} className="mt-2">
                                <label style={{ paddingTop: '10px' }}>De:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      wednesday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.startTimeString = e.target.value;

                                    const schedule = [...wednesday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setWednesday(schedule);
                                  }}
                                  value={element?.startTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                <label style={{ paddingTop: '10px' }}>Até:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      wednesday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.endTimeString = e.target.value;

                                    const schedule = [...wednesday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setWednesday(schedule);
                                  }}
                                  value={element?.endTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                {user.role === 'doctor' && (
                                  <FontAwesomeIcon
                                    style={{ marginTop: '10px', cursor: 'pointer' }}
                                    icon={faTrash}
                                    color="#008171"
                                    onClick={() => {
                                      const day =
                                        wednesday.find(interval => {
                                          return interval.id === element.id;
                                        }) || {};

                                      day.deleted = true;

                                      const schedule = [...wednesday];

                                      // eslint-disable-next-line array-callback-return
                                      schedule.map((schedule: ScheduleInterval) => {
                                        if (schedule.id === element.id) {
                                          schedule = day;
                                        }
                                      });

                                      setWednesday(schedule);
                                    }}
                                  />
                                )}
                              </Row>
                            )}
                          </>
                        );
                      })}
                    </>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div className="card-font mt-4 row" style={{ marginLeft: '10px' }}>
            <Card className="col-3 row card-board">
              <CardBody>
                <CardTitle tag="h5">
                  <Text
                    title="Quinta-feira"
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </CardTitle>
                <Button
                  onClick={() => {
                    const schedule = [...thursday];
                    let date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    schedule?.push({
                      id: uuidv4(),
                      startTimeString: '',
                      endTimeString: '',
                    });
                    setThursday(schedule);
                  }}
                  type="primary"
                  disabled={user.role === 'partner'}
                >
                  ADICIONAR INTERVALO
                </Button>
                <Row className="mt-4">
                  <Col>
                    <>
                      {thursday?.map(function (element, index) {
                        return (
                          <>
                            {!element.deleted && (
                              <Row key={index} className="mt-2">
                                <label style={{ paddingTop: '10px' }}>De:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      thursday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.startTimeString = e.target.value;

                                    const schedule = [...thursday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setThursday(schedule);
                                  }}
                                  value={element?.startTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                <label style={{ paddingTop: '10px' }}>Até:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      thursday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.endTimeString = e.target.value;

                                    const schedule = [...thursday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setThursday(schedule);
                                  }}
                                  value={element?.endTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                {user.role === 'doctor' && (
                                  <FontAwesomeIcon
                                    style={{ marginTop: '10px', cursor: 'pointer' }}
                                    icon={faTrash}
                                    color="#008171"
                                    onClick={() => {
                                      const day =
                                        thursday.find(interval => {
                                          return interval.id === element.id;
                                        }) || {};

                                      day.deleted = true;

                                      const schedule = [...thursday];

                                      // eslint-disable-next-line array-callback-return
                                      schedule.map((schedule: ScheduleInterval) => {
                                        if (schedule.id === element.id) {
                                          schedule = day;
                                        }
                                      });

                                      setThursday(schedule);
                                    }}
                                  />
                                )}
                              </Row>
                            )}
                          </>
                        );
                      })}
                    </>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="col-3 ml-5 row card-board">
              <CardBody>
                <CardTitle tag="h5">
                  <Text
                    title="Sexta-feira"
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </CardTitle>
                <Button
                  onClick={() => {
                    const schedule = [...friday];
                    let date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    schedule?.push({
                      id: uuidv4(),
                      startTimeString: '',
                      endTimeString: '',
                    });
                    setFriday(schedule);
                  }}
                  type="primary"
                  disabled={user.role === 'partner'}
                >
                  ADICIONAR INTERVALO
                </Button>
                <Row className="mt-4">
                  <Col>
                    <>
                      {friday?.map(function (element, index) {
                        return (
                          <>
                            {!element.deleted && (
                              <Row key={index} className="mt-2">
                                <label style={{ paddingTop: '10px' }}>De:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      friday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.startTimeString = e.target.value;

                                    const schedule = [...friday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setFriday(schedule);
                                  }}
                                  value={element?.startTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                <label style={{ paddingTop: '10px' }}>Até:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      friday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.endTimeString = e.target.value;

                                    const schedule = [...friday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setFriday(schedule);
                                  }}
                                  value={element?.endTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                {user.role === 'doctor' && (
                                  <FontAwesomeIcon
                                    style={{ marginTop: '10px', cursor: 'pointer' }}
                                    icon={faTrash}
                                    color="#008171"
                                    onClick={() => {
                                      const day =
                                        friday.find(interval => {
                                          return interval.id === element.id;
                                        }) || {};

                                      day.deleted = true;

                                      const schedule = [...friday];

                                      // eslint-disable-next-line array-callback-return
                                      schedule.map((schedule: ScheduleInterval) => {
                                        if (schedule.id === element.id) {
                                          schedule = day;
                                        }
                                      });

                                      setFriday(schedule);
                                    }}
                                  />
                                )}
                              </Row>
                            )}
                          </>
                        );
                      })}
                    </>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="col-3 ml-5 row card-board">
              <CardBody>
                <CardTitle tag="h5">
                  <Text
                    title="Sábado"
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </CardTitle>
                <Button
                  onClick={() => {
                    const schedule = [...saturday];
                    let date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    schedule?.push({
                      id: uuidv4(),
                      startTimeString: '',
                      endTimeString: '',
                    });
                    setSaturday(schedule);
                  }}
                  type="primary"
                  disabled={user.role === 'partner'}
                >
                  ADICIONAR INTERVALO
                </Button>
                <Row className="mt-4">
                  <Col>
                    <>
                      {saturday?.map(function (element, index) {
                        return (
                          <>
                            {!element.deleted && (
                              <Row key={index} className="mt-2">
                                <label style={{ paddingTop: '10px' }}>De:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      saturday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.startTimeString = e.target.value;

                                    const schedule = [...saturday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setSaturday(schedule);
                                  }}
                                  value={element?.startTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                <label style={{ paddingTop: '10px' }}>Até:</label>
                                <Input
                                  placeholder="00:00"
                                  mask={maskHour}
                                  tag={InputMask}
                                  onChange={(e: any) => {
                                    const day =
                                      saturday.find(interval => {
                                        return interval.id === element.id;
                                      }) || {};

                                    day.endTimeString = e.target.value;

                                    const schedule = [...saturday];

                                    // eslint-disable-next-line array-callback-return
                                    schedule.map((schedule: ScheduleInterval) => {
                                      if (schedule.id === element.id) {
                                        schedule = day;
                                      }
                                    });

                                    setSaturday(schedule);
                                  }}
                                  value={element?.endTimeString}
                                  style={{ width: '70px', margin: '0px 5px 0px 5px' }}
                                />
                                {user.role === 'doctor' && (
                                  <FontAwesomeIcon
                                    style={{ marginTop: '10px', cursor: 'pointer' }}
                                    icon={faTrash}
                                    color="#008171"
                                    onClick={() => {
                                      const day =
                                        saturday.find(interval => {
                                          return interval.id === element.id;
                                        }) || {};

                                      day.deleted = true;

                                      const schedule = [...saturday];

                                      // eslint-disable-next-line array-callback-return
                                      schedule.map((schedule: ScheduleInterval) => {
                                        if (schedule.id === element.id) {
                                          schedule = day;
                                        }
                                      });

                                      setSaturday(schedule);
                                    }}
                                  />
                                )}
                              </Row>
                            )}
                          </>
                        );
                      })}
                    </>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          {user.role === 'doctor' && (
            <Row className="mt-4 justify-content-center">
              <Col sm={4}>
                <Button onClick={() => onSubmit()}>SALVAR HORÁRIOS</Button>
              </Col>
            </Row>
          )}
        </>
      </Layout>
    </div>
  );
};
