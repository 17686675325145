import React, { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Layout } from '../../components/Layout';
import { Input } from '../../components/Input';
import { RootState, useAppDispatch } from '../../store';
import { ReturnLink } from '../../components/ReturnLink';
import { useToasts } from 'react-toast-notifications';
import { TummiModal, ITummiModalProps } from '../../components/Modal';
import { Button } from '../../components/Button';
import { updateUserPassword } from '../../slices/user';
import { Text } from '../../components/Text';

interface UpdateUserPassword {
  login?: string;
  password?: string;
  newPassword?: string;
  repeatNewPassword?: string;
  role?: string;
}

export const ChangePassword = () => {
  const history = useHistory();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const { addToast } = useToasts();

  const [userToUpdate, setUserToUpdate] = useState<UpdateUserPassword>({
    role: user.role,
    login: user.login,
  });

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const onSubmit = async (values: UpdateUserPassword) => {
    if (values.newPassword !== values.repeatNewPassword) {
      addToast('Nova senha e confirmação não conferem.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    if (values.newPassword === values.password) {
      addToast('Nova senha não pode ser igual a atual.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const response = await dispatch(
      updateUserPassword(values.login, values.password, values.newPassword, values.role),
    );

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Dados alterados com sucesso.',
        className: '',
        title: 'Mensagem',
        // eslint-disable-next-line no-restricted-globals
        toggle: () => location.reload(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body: response.data.errorMessage,
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => history.goBack(),
        okButton: 'Tentar novamente',
      });
    }
  };

  const onFormChange = (values: Partial<UpdateUserPassword>) => {
    setUserToUpdate({ ...values });
  };

  return (
    <Layout type={user.role}>
      <>
        <div className="view-profile-page">
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
              <Text
                title="ALTERAR SENHA"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Form
                onSubmit={onSubmit}
                initialValues={{ ...userToUpdate }}
                render={({ handleSubmit, submitting }) => (
                  <>
                    <form>
                      <FormSpy onChange={({ values }) => onFormChange(values)} />
                    </form>
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Dados pessoais"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={6}>
                          <Field
                            name="password"
                            validate={required}
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Senha atual"
                                placeholder="Senha"
                                type="password"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={6}>
                          <Field
                            name="newPassword"
                            validate={required}
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Nova senha"
                                placeholder="Senha"
                                type="password"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={6}>
                          <Field
                            name="repeatNewPassword"
                            validate={required}
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Confirme a nova senha"
                                placeholder="Senha"
                                type="password"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row className="mt-4 justify-content-center">
                      <Col sm={4}>
                        <Button onClick={handleSubmit} disabled={submitting}>
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              />
            </Col>
          </Row>
        </div>
        <TummiModal {...modal} />
      </>
    </Layout>
  );
};
