import { useEffect, useState } from 'react';
import { Layout } from '../../../components/Layout';
import { Chart } from 'react-google-charts';
import { Field, Form, FormSpy } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import { Text } from '../../../components/Text';
import { ReturnLink } from '../../../components/ReturnLink';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { MDBDataTable } from 'mdbreact';
import {
  getAttendancesInfo,
  BillingDashboard,
  AttendanceDashboard,
  getNonMedicalAttendancesInfo,
  NonMedicalClinicDashboardInfo,
  DoctorAttendanceInfo,
} from '../../../slices/dashboard';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';

export const optionsInfoAttendances = {
  chart: {
    title: 'Dados de atendimentos da Clínica',
    subtitle: 'Atendimentos',
  },
  colors: ['#00a0a0'],
};

export const optionsInfoBilling = {
  chart: {
    title: 'Dados de faturamento da Clínica',
    subtitle: 'Faturamento via teleatendimento',
  },
  colors: ['#00a0a0'],
};

export const data = [
  ['Year', 'Atendimentos totais', 'Atendimentos finalizados', 'Atendimentos em aberto'],
  ['2014', 1000, 400, 200],
  ['2015', 1170, 460, 250],
  ['2016', 660, 1120, 300],
  ['2017', 1030, 540, 350],
];

export const options = {
  chart: {
    title: 'Dados de atendimentos da Clínica',
    subtitle: 'Atendimentos dos ultimos meses conforme o filtro',
  },
  colors: ['#00a0a0', '#87CEFA', '#CD5C5C'],
};

interface DataFilter {
  halfyear?: string;
  year?: string;
  monthsAgo?: string;
}

export const PartnerHome = () => {
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);

  const dispatch = useAppDispatch();

  const [attendencesNonMedcialInfo, setNonMedcialAttendancesInfo] = useState<any>([
    ['Mes', 'Atendimentos'],
  ]);

  const [attendencesInfo, setAttendancesInfo] = useState<any>([['Mes', 'Atendimentos']]);

  const [billingsInfo, setBillingsInfo] = useState<any>([['Mes', 'Faturamento']]);

  const [attendanceTotalYear, setAttendanceTotalYear] = useState(0);
  const [attendanceCompletedYear, setAttendanceCompletedYear] = useState(0);
  const [attendanceNonCompletedYear, setAttendanceNonCompletedYear] = useState(0);

  const [doctorInfoTable, setDoctorsInfoTable] = useState<any>([]);

  const [filter, setFilter] = useState<DataFilter | null>({
    year: '2022',
    halfyear: '1',
    monthsAgo: '6',
  });

  const onFormChange = (values: Partial<DataFilter>) => {
    setFilter({ ...values });
  };

  useEffect(() => {
    if (user.isNonMedicalCompany) {
      onSubmitNonMedical();
    } else {
      onSubmitMedical();
    }
  }, []);

  const onSubmitNonMedical = async () => {
    const response = await dispatch(
      getNonMedicalAttendancesInfo({
        companyId: user.companyId,
        year: parseInt(filter?.year || ''),
        monthsAgo: parseInt(filter?.monthsAgo || ''),
      }),
    );

    let total = 0;
    let completed = 0;
    let nonCompleted = 0;

    const responseData = response.data.nonMedicalClinicDashboardInfo.map(
      (nonMedicalInfo: NonMedicalClinicDashboardInfo) => {
        total += nonMedicalInfo.totalAttendances || 0;
        completed += nonMedicalInfo.completedAttendances || 0;
        nonCompleted += nonMedicalInfo.nonCompletedAttendances || 0;
        return [
          nonMedicalInfo.month,
          nonMedicalInfo.totalAttendances,
          nonMedicalInfo.completedAttendances,
          nonMedicalInfo.nonCompletedAttendances,
        ];
      },
    );

    setAttendanceTotalYear(total);
    setAttendanceCompletedYear(completed);
    setAttendanceNonCompletedYear(nonCompleted);

    const doctorsTable: any = response.data.doctorAttendanceInfo?.map(
      (doctor: DoctorAttendanceInfo) => {
        return {
          ...doctor,
        };
      },
    );

    setDoctorsInfoTable(doctorsTable);

    setNonMedcialAttendancesInfo([
      ['Year', 'Atendimentos totais', 'Atendimentos finalizados', 'Atendimentos em aberto'],
      ...responseData,
    ]);
  };

  const onSubmitMedical = async () => {
    const response = await dispatch(
      getAttendancesInfo({
        companyId: user.companyId,
        year: parseInt(filter?.year || ''),
        halfyear: parseInt(filter?.halfyear || ''),
      }),
    );

    const _billings = response.data.billing;
    const _attendances = response.data.attendance;

    const data1 = _billings.map((data: BillingDashboard) => {
      return [data.month, data.billing];
    });

    const data2 = _attendances.map((data: AttendanceDashboard) => {
      return [data.month, data.attendances];
    });

    setAttendancesInfo([['Mes', 'Atendimentos'], ...data2]);
    setBillingsInfo([['Mes', 'Faturamento'], ...data1]);
  };

  useEffect(() => {}, [attendencesInfo, billingsInfo]);

  const dataTable = {
    columns: [
      {
        label: 'Nome',
        field: 'fullName',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Total de atendimentos',
        field: 'totalAttendances',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Atendimento finalizados',
        field: 'completedAttendances',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Atendimentos em aberto',
        field: 'nonCompletedAttendances',
        sort: 'asc',
        width: 100,
      },
    ],
    rows: doctorInfoTable,
  };

  return (
    <Layout type="partner">
      <>
        {!user.isNonMedicalCompany && (
          <>
            <Row className="mt-4 mb-4">
              <Col style={{ display: 'flex' }}>
                <ReturnLink title="VOLTAR" />
                <Text
                  title="DASHBOARD"
                  style={{
                    fontSize: '24px',
                    marginBottom: '0px',
                    marginTop: '5px',
                    marginLeft: '15px',
                  }}
                />
              </Col>
            </Row>
            <Form
              onSubmit={onSubmitMedical}
              initialValues={{ ...filter }}
              render={({ handleSubmit, submitting }) => (
                <div className="partner-doctors">
                  <form>
                    <FormSpy onChange={({ values }) => onFormChange(values)} />
                  </form>
                  <Row className="mt-4">
                    <Col sm={4}>
                      <Field
                        name="year"
                        disabled={submitting}
                        parse={(value: string) => value}
                        render={({ input }) => (
                          <Input
                            type="select"
                            label="Ano"
                            className="select"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                          >
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                          </Input>
                        )}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        name="halfyear"
                        disabled={submitting}
                        parse={(value: string) => value}
                        render={({ input }) => (
                          <Input
                            type="select"
                            label="Semestre"
                            className="select"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                          >
                            <option value="1">1 Semestre</option>
                            <option value="2">2 Semestre</option>
                          </Input>
                        )}
                      />
                    </Col>
                    <Col sm={4}>
                      <Button className="mt-4" disabled={submitting} onClick={handleSubmit}>
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="400px"
                      data={attendencesInfo}
                      options={optionsInfoAttendances}
                    />
                  </Row>
                  <Row className="mt-4">
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="400px"
                      data={billingsInfo}
                      options={optionsInfoBilling}
                    />
                  </Row>
                </div>
              )}
            />{' '}
          </>
        )}
        {user.isNonMedicalCompany && (
          <>
            <Row className="mt-4 mb-4">
              <Col style={{ display: 'flex' }}>
                <ReturnLink title="VOLTAR" />
                <Text
                  title="DASHBOARD"
                  style={{
                    fontSize: '24px',
                    marginBottom: '0px',
                    marginTop: '5px',
                    marginLeft: '15px',
                  }}
                />
              </Col>
            </Row>
            <Form
              onSubmit={onSubmitNonMedical}
              initialValues={{ ...filter }}
              render={({ handleSubmit, submitting }) => (
                <div className="partner-doctors">
                  <form>
                    <FormSpy onChange={({ values }) => onFormChange(values)} />
                  </form>
                  <Row className="mt-4">
                    <Col sm={4}>
                      <Field
                        name="year"
                        disabled={submitting}
                        parse={(value: string) => value}
                        render={({ input }) => (
                          <Input
                            type="select"
                            label="Ano"
                            className="select"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                          >
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                          </Input>
                        )}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        name="monthsAgo"
                        disabled={submitting}
                        parse={(value: string) => value}
                        render={({ input }) => (
                          <Input
                            type="select"
                            label="Meses atrás"
                            className="select"
                            onChange={input.onChange}
                            onBlur={input.onBlur}
                            value={input.value}
                          >
                            <option value="1">1 meses atrás</option>
                            <option value="2">2 meses atrás</option>
                            <option value="3">3 meses atrás</option>
                            <option value="4">4 meses atrás</option>
                            <option value="5">5 meses atrás</option>
                            <option value="6">6 meses atrás</option>
                            <option value="7">7 meses atrás</option>
                            <option value="8">8 meses atrás</option>
                            <option value="9">9 meses atrás</option>
                            <option value="10">10 meses atrás</option>
                            <option value="11">11 meses atrás</option>
                            <option value="12">12 meses atrás</option>
                          </Input>
                        )}
                      />
                    </Col>
                    <Col sm={4}>
                      <Button className="mt-4" disabled={submitting} onClick={handleSubmit}>
                        Filtrar
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="400px"
                      data={attendencesNonMedcialInfo}
                      options={options}
                    />
                  </Row>
                  <div className="attendances-table">
                    <Row className="mt-4">Total de atendimentos no Ano: {attendanceTotalYear}</Row>
                    <Row className="mt-1">
                      Total de atendimentos em aberto no Ano: {attendanceNonCompletedYear}
                    </Row>
                    <Row className="mt-1">
                      Total de atendimentos finalizados no Ano: {attendanceCompletedYear}
                    </Row>
                    <Row className="mt-4">
                      {!app.isLoading && (
                        <MDBDataTable
                          searching
                          searchLabel="Pesquisa rápida"
                          entriesLabel="Exibir"
                          infoLabel={['Exibindo', 'de', 'de um total de', 'horários disponíveis']}
                          theadColor="indigo"
                          striped
                          bordered
                          data={dataTable}
                          Light
                          paginationLabel={['Voltar', 'Avançar']}
                          noRecordsFoundLabel="Não foram encontrados resultados"
                        />
                      )}
                    </Row>
                  </div>
                </div>
              )}
            />
          </>
        )}
      </>
    </Layout>
  );
};
