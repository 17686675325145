import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { GenericAvatar } from '../../components/Avatar';

import './styles.scss';

let { AgeFromDateString } = require('age-calculator');

interface IPatientInfoProps {
  firstName?: string;
  lastName?: string;
  dob?: string;
  email?: string;
  date?: Date;
  userImage?: string;
  ddd?: string;
  phone?: string;
  gender?: number;
}

export const PatientInfo = ({
  userImage,
  firstName,
  lastName,
  dob,
  email,
  date,
  ddd,
  phone,
  gender,
}: IPatientInfoProps) => {
  return (
    <div className="patient-info">
      <Row className="bordless-container mt-4">
        <Col>
          <Row className="mt-2">
            <Col>
              <span className="label-pacient ml-4">Informações do paciente</span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={2} className="mt-4">
              <Row>
                <Col className="d-flex justify-content-center ">
                  <GenericAvatar size="100" avatarName={`${firstName} ${lastName}`} />
                </Col>
              </Row>
            </Col>
            <Col sm={10}>
              <Row className="mt-2">
                <Col sm={3}>
                  <span className="label">Nome</span>
                </Col>
                <Col sm={3}>
                  <span className="label">Genêro</span>
                </Col>
                <Col sm={3}>
                  <span className="label">Telefone</span>
                </Col>
                <Col sm={3}>
                  <span className="label">Data de Nascimento</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={3}>
                  <span>
                    <strong>
                      {firstName} {lastName}
                    </strong>
                  </span>
                </Col>
                <Col sm={3}>
                  <span>
                    <strong>
                      {gender === 0 ? 'Masculino' : gender === 1 ? 'Feminino' : 'Não informado'}
                    </strong>
                  </span>
                </Col>
                <Col sm={3}>
                  <span>
                    <strong>
                      {ddd} {phone}
                    </strong>
                  </span>
                </Col>
                <Col sm={3}>
                  <span>
                    <strong> {moment(dob).format('DD/MM/YYYY')} </strong>{' '}
                  </span>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={2}>
                  <span className="label">Idade</span>
                </Col>
                <Col sm={5}>
                  <span className="label">E-mail</span>
                </Col>
                {date && (
                  <Col sm={5}>
                    <span className="label">Data de Atendimento</span>
                  </Col>
                )}
              </Row>
              <Row className="mt-2">
                <Col sm={2}>
                  <span>
                    <strong> {new AgeFromDateString(dob).age} anos</strong>
                  </span>
                </Col>
                <Col sm={5}>
                  <span>
                    <strong>{email ? email : 'Não informado'}</strong>
                  </span>
                </Col>
                {date && (
                  <Col sm={5}>
                    <span>
                      <strong>{moment(date).format('DD/MM/YYYY HH:mm')}</strong>
                    </span>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
