import { Api } from '../../services/api';
import { AppDispatch } from '../../store';

import { BankData, DoctorUser, PatientUser } from '../admin';
import {
  getUserPayload,
  getDoctorsPayload,
  getPatientsPayload,
  getAdministrativePayload,
} from '../../services/apiTypes';

import { setIsloading } from '../app';
import { UserTelemedicine } from '../userTelemedicine';
import { Company } from '../company';

export interface PartnerUser extends UserTelemedicine {
  CNPJ?: string;
  passwordGenerated?: string;
  bankDataTelemedicine?: BankData;
  companyTelemedicine?: Company;
}

export const CreatePartner = (payload: Partial<PartnerUser>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.CreatePartner(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const UpdatePartner = (payload: Partial<PartnerUser>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.UpdatePartner(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getDoctor = ({ id }: Partial<getUserPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getDoctor({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getDoctors = ({
  companyId,
  name,
  speciality,
  pageSize,
  page,
}: Partial<getDoctorsPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getDoctors({ companyId, name, speciality, pageSize, page }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getPatient = ({ id }: Partial<getUserPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getPatient({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getPatients = ({
  companyId,
  name,
  susnumber,
  cpf,
}: Partial<getPatientsPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getPatients({ companyId, name, susnumber, cpf }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getAdministrative = ({ id }: Partial<getUserPayload>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getAdministrative({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getPartner = ({ id }: Partial<getUserPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getPartner({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getAdministratives = ({
  companyId,
  name,
  cpf,
  pageSize,
  page,
}: Partial<getAdministrativePayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getAdministratives({ companyId, name, cpf, pageSize, page }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const createDoctorUser = (payload: Partial<DoctorUser>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.createDoctorUser(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const createPatientUser = (payload: Partial<PatientUser>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.createPatientUser(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const createAdministrativeUser = (payload: Partial<UserTelemedicine>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.createAdministrativeUser(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};
