export const Specialities = [
  { value: 'Cliníco Geral', label: 'Cliníco Geral' },
  { value: 'Abandono Tabagismo', label: 'Abandono Tabagismo' },
  { value: 'Acupuntura', label: 'Acupuntura' },
  { value: 'Andrologia', label: 'Andrologia' },
  { value: 'Anestesiologia', label: 'Anestesiologia' },
  { value: 'Angiologia', label: 'Angiologia' },
  { value: 'Arritmias Cardiacas', label: 'Arritmias Cardiacas' },
  { value: 'Assistente Social', label: 'Assistente Social' },
  { value: 'Balão Intragastrico (procedimento)', label: 'Balão Intragastrico (procedimento)' },
  { value: 'Biologia (ativ. Apoio)', label: 'Biologia (ativ. Apoio)' },
  { value: 'Bucomaxilofacial ( Implantes )', label: 'Bucomaxilofacial ( Implantes )' },
  { value: 'Capsula Endoscopica', label: 'Capsula Endoscopica' },
  { value: 'Cardiologia', label: 'Cardiologia' },
  { value: 'Cardiologia Intervencionista', label: 'Cardiologia Intervencionista' },
  { value: 'Cardiologia Pediátrica', label: 'Cardiologia Pediátrica' },
  { value: 'Catarata e Doenças Externas', label: 'Catarata e Doenças Externas' },
  {
    value: 'Cir. Bariátrica, Metabólica Videolaparoscópica',
    label: 'Cir. Bariátrica, Metabólica Videolaparoscópica',
  },
  {
    value: 'Cir. Miopia; Catarata; Astigmatismo e Hipermetropi',
    label: 'Cir. Miopia; Catarata; Astigmatismo e Hipermetropi',
  },
  { value: 'Cirurgia a Laser de Diodo', label: 'Cirurgia a Laser de Diodo' },
  { value: 'Cirurgia Abdominal', label: 'Cirurgia Abdominal' },
  { value: 'Cirurgia Cabeça e Pescoço', label: 'Cirurgia Cabeça e Pescoço' },
  { value: 'Cirurgia Cardiovascular', label: 'Cirurgia Cardiovascular' },
  { value: 'Cirurgia Cardiovascular Pediatrica', label: 'Cirurgia Cardiovascular Pediatrica' },
  { value: 'Cirurgia Cervico Facial', label: 'Cirurgia Cervico Facial' },
  { value: 'Cirurgia da Coluna Vertebral', label: 'Cirurgia da Coluna Vertebral' },
  { value: 'Cirurgia da Mão', label: 'Cirurgia da Mão' },
  { value: 'Cirurgia da Obesidade e Metabolica', label: 'Cirurgia da Obesidade e Metabolica' },
  { value: 'Cirurgia da Tireoide', label: 'Cirurgia da Tireoide' },
  { value: 'Cirurgia de Nervos Perifericos', label: 'Cirurgia de Nervos Perifericos' },
  { value: 'Cirurgia do Aparelho Digestivo', label: 'Cirurgia do Aparelho Digestivo' },
  { value: 'Cirurgia do Joelho', label: 'Cirurgia do Joelho' },
  { value: 'Cirurgia do Nariz', label: 'Cirurgia do Nariz' },
  { value: 'Cirurgia do Ombro e Cotovelo', label: 'Cirurgia do Ombro e Cotovelo' },
  { value: 'Cirurgia do Quadril', label: 'Cirurgia do Quadril' },
  { value: 'Cirurgia do Trauma', label: 'Cirurgia do Trauma' },
  { value: 'Cirurgia Dos Nervos Perifericos', label: 'Cirurgia Dos Nervos Perifericos' },
  {
    value: 'Cirurgia e Traumatologia Bucomaxilofacial',
    label: 'Cirurgia e Traumatologia Bucomaxilofacial',
  },
  { value: 'Cirurgia Gastroenterológica', label: 'Cirurgia Gastroenterológica' },
  { value: 'Cirurgia Geral', label: 'Cirurgia Geral' },
  { value: 'Cirurgia Geral Pediátrica', label: 'Cirurgia Geral Pediátrica' },
  { value: 'Cirurgia Ginecológica', label: 'Cirurgia Ginecológica' },
  { value: 'Cirurgia Oncologica', label: 'Cirurgia Oncologica' },
  { value: 'Cirurgia Ortognatica', label: 'Cirurgia Ortognatica' },
  { value: 'Cirurgia Plastica', label: 'Cirurgia Plastica' },
  { value: 'Cirurgia Plastica Ocular', label: 'Cirurgia Plastica Ocular' },
  { value: 'Cirurgia Refrativa (olhos)', label: 'Cirurgia Refrativa (olhos)' },
  { value: 'Cirurgia Torácica', label: 'Cirurgia Torácica' },
  { value: 'Cirurgia Torácica Pediátrica', label: 'Cirurgia Torácica Pediátrica' },
  { value: 'Cirurgia Tumores', label: 'Cirurgia Tumores' },
  { value: 'Cirurgia Urológica', label: 'Cirurgia Urológica' },
  { value: 'Cirurgia Vascular', label: 'Cirurgia Vascular' },
  { value: 'Cirurgia Vascular Periférica', label: 'Cirurgia Vascular Periférica' },
  { value: 'Cirurgia Videolaparoscopica', label: 'Cirurgia Videolaparoscopica' },
  {
    value: 'Cirurgia Videolaparoscopica Pediatrica',
    label: 'Cirurgia Videolaparoscopica Pediatrica',
  },
  { value: 'Colonoscopia (exame)', label: 'Colonoscopia (exame)' },
  { value: 'Coloproctologia', label: 'Coloproctologia' },
  { value: 'Cosmiatria', label: 'Cosmiatria' },
  { value: 'Dermatologia', label: 'Dermatologia' },
  { value: 'Ecografia Gineco-obstetrica', label: 'Ecografia Gineco-obstetrica' },
  { value: 'Eletrofisiologia Invasiva', label: 'Eletrofisiologia Invasiva' },
  { value: 'Endocrinologia', label: 'Endocrinologia' },
  { value: 'Endocrinologia Pediatrica', label: 'Endocrinologia Pediatrica' },
  { value: 'Endoscopia Digestiva (exame)', label: 'Endoscopia Digestiva (exame)' },
  { value: 'Endoscopia Pediatrica (exame)', label: 'Endoscopia Pediatrica (exame)' },
  { value: 'Endoscopia Respiratória ( Exame)', label: 'Endoscopia Respiratória ( Exame)' },
  { value: 'Enfermagem ( Apoio )', label: 'Enfermagem ( Apoio )' },
  { value: 'Espirometria ( Exame )', label: 'Espirometria ( Exame )' },
  { value: 'Estomatologia', label: 'Estomatologia' },
  { value: 'Estrabismo', label: 'Estrabismo' },
  {
    value: 'Fibro Broncoscopia (endoscopia Resp) (exame)',
    label: 'Fibro Broncoscopia (endoscopia Resp) (exame)',
  },
  { value: 'Fisiatria', label: 'Fisiatria' },
  { value: 'Fisioterapeuta Dermato - Funcional', label: 'Fisioterapeuta Dermato - Funcional' },
  { value: 'Fisioterapia ( Apoio )', label: 'Fisioterapia ( Apoio )' },
  { value: 'Flebologia', label: 'Flebologia' },
  { value: 'Fonoaudiologia ( Apoio )', label: 'Fonoaudiologia ( Apoio )' },
  { value: 'Gastroenterologia', label: 'Gastroenterologia' },
  { value: 'Gastroenterologia Pediatrica', label: 'Gastroenterologia Pediatrica' },
  { value: 'Geriatria', label: 'Geriatria' },
  { value: 'Geriatria Preventiva', label: 'Geriatria Preventiva' },
  { value: 'Ginecologia', label: 'Ginecologia' },
  { value: 'Ginecologia e Obstetricia', label: 'Ginecologia e Obstetricia' },
  { value: 'Hematologia', label: 'Hematologia' },
  { value: 'Hematologia Pediatrica', label: 'Hematologia Pediatrica' },
  { value: 'Hemodinamica', label: 'Hemodinamica' },
  { value: 'Hemoterapia Pediatrica', label: 'Hemoterapia Pediatrica' },
  { value: 'Hepatologia', label: 'Hepatologia' },
  { value: 'Homeopatia', label: 'Homeopatia' },
  { value: 'Htlv', label: 'Htlv' },
  { value: 'Implante Osteointegrado', label: 'Implante Osteointegrado' },
  { value: 'Implantodontia', label: 'Implantodontia' },
  { value: 'Infectologia', label: 'Infectologia' },
  { value: 'Infectologia Pediatrica', label: 'Infectologia Pediatrica' },
  { value: 'Intensivismo', label: 'Intensivismo' },
  { value: 'Intensivo Pediatrico', label: 'Intensivo Pediatrico' },
  { value: 'Mastologia', label: 'Mastologia' },
  { value: 'Medicina do Sono (disturbios)', label: 'Medicina do Sono (disturbios)' },
  { value: 'Medicina da Família e Comunidade', label: 'Medicina da Família e Comunidade' },
  { value: 'Medicina do Trabalho / Ocupacional', label: 'Medicina do Trabalho / Ocupacional' },
  { value: 'Medicina Estetica', label: 'Medicina Estetica' },
  { value: 'Medicina Fetal', label: 'Medicina Fetal' },
  { value: 'Medicina Interna', label: 'Medicina Interna' },
  { value: 'Medicina Preventiva', label: 'Medicina Preventiva' },
  { value: 'Metabologia', label: 'Metabologia' },
  { value: 'Micro Cirurgia Reconstrutiva', label: 'Micro Cirurgia Reconstrutiva' },
  { value: 'Motilidade Esofagica', label: 'Motilidade Esofagica' },
  { value: 'Nefrologia', label: 'Nefrologia' },
  { value: 'Nefrologia Pediatrica', label: 'Nefrologia Pediatrica' },
  { value: 'Neonatologia', label: 'Neonatologia' },
  { value: 'Neurocirurgia', label: 'Neurocirurgia' },
  { value: 'Neuroimunologia', label: 'Neuroimunologia' },
  { value: 'Neurologia', label: 'Neurologia' },
  { value: 'Neurologia Pediatrica', label: 'Neurologia Pediatrica' },
  // { value: 'Neuropsicologia (apoio)', label: 'Neuropsicologia (apoio)' },
  { value: 'Psicologia', label: 'Psicologia' },
  { value: 'Nutrição ( Apoio )', label: 'Nutrição ( Apoio )' },
  {
    value: 'Nutrição Enteral e Parenteral ( Procedimento )',
    label: 'Nutrição Enteral e Parenteral ( Procedimento )',
  },
  {
    value: 'Nutricionista Materno Infantil (apoio)',
    label: 'Nutricionista Materno Infantil (apoio)',
  },
  { value: 'Nutrologia Pediatrica', label: 'Nutrologia Pediatrica' },
  { value: 'Odontologia', label: 'Odontologia' },
  { value: 'Odontologia Implantodontista', label: 'Odontologia Implantodontista' },
  { value: 'Odontopediatria', label: 'Odontopediatria' },
  { value: 'Oftalmologia', label: 'Oftalmologia' },
  { value: 'Oncologia', label: 'Oncologia' },
  { value: 'Ortodontia', label: 'Ortodontia' },
  { value: 'Ortopedia e Traumatologia', label: 'Ortopedia e Traumatologia' },
  { value: 'Ortopedia e Traumatologia Pediátrica', label: 'Ortopedia e Traumatologia Pediátrica' },
  { value: 'Ortopedia Facial', label: 'Ortopedia Facial' },
  { value: 'Otologia', label: 'Otologia' },
  { value: 'Otorrinolaringologia', label: 'Otorrinolaringologia' },
  { value: 'Otorrinolaringologia Pediátrica', label: 'Otorrinolaringologia Pediátrica' },
  { value: 'Pediatria', label: 'Pediatria' },
  { value: 'Pneumologia', label: 'Pneumologia' },
  { value: 'Pneumologia Pediátrica', label: 'Pneumologia Pediátrica' },
  { value: 'Proctologia', label: 'Proctologia' },
  // { value: 'Psicologia ( Apoio )', label: 'Psicologia ( Apoio )' },
  // { value: 'Psicologia Social (apoio)', label: 'Psicologia Social (apoio)' },
  // {
  //   value: 'Psicoterapia Indiv. Fam. e Casal (apoio)',
  //   label: 'Psicoterapia Indiv. Fam. e Casal (apoio)',
  // },
  { value: 'Psiquiatria', label: 'Psiquiatria' },
  { value: 'Radioterapia', label: 'Radioterapia' },
  { value: 'Reconstrução Mamaria', label: 'Reconstrução Mamaria' },
  { value: 'Reprodução Humana', label: 'Reprodução Humana' },
  { value: 'Reumatologia', label: 'Reumatologia' },
  { value: 'Terapia Intensiva', label: 'Terapia Intensiva' },
  { value: 'Tisiologia', label: 'Tisiologia' },
  { value: 'Transplante de Corneas', label: 'Transplante de Corneas' },
  { value: 'Transplante de Orgãos', label: 'Transplante de Orgãos' },
  { value: 'Transplante Renal', label: 'Transplante Renal' },
  { value: 'Transtorno Controle de Impulsos', label: 'Transtorno Controle de Impulsos' },
  { value: 'Tratamento da Dor (clinica da Dor)', label: 'Tratamento da Dor (clinica da Dor)' },
  { value: 'Trauma da Face', label: 'Trauma da Face' },
  { value: 'Ultrassonografia', label: 'Ultrassonografia' },
  { value: 'Urologia', label: 'Urologia' },
  { value: 'Urologia Pediátrica', label: 'Urologia Pediátrica' },
  { value: 'Video Cirurgia', label: 'Video Cirurgia' },
  { value: 'Videolaparoscopia', label: 'Videolaparoscopia' },
];
