import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import Select from 'react-select';

import './styles.scss';

type OptionType = {
  value?: string;
  label?: string;
};

type ISelectMultiProps = {
  label?: string;
  type?: InputType;
  isDisabled?: boolean;
  labelClass?: string;
  metaError?: any;
  options: OptionType[];
  onChange: (values: any) => void;
  value?: OptionType | OptionType[];
  defaultValue?: OptionType | OptionType[];
  isMulti?: boolean;
  required?: boolean;
};

export const SelectMulti = ({
  label,
  onChange,
  value,
  metaError,
  options,
  isDisabled,
  isMulti = true,
  required,
  defaultValue,
  ...rest
}: ISelectMultiProps) => (
  <FormGroup style={{ marginBottom: '0.2rem' }}>
    {label && (
      <Label
        className={`label-class ${
          metaError?.error && metaError?.touched ? 'error-label-class' : ''
        }`}
      >
        {label}
      </Label>
    )}
    <Select
      onChange={(value: any) => onChange(value)}
      isMulti={isMulti}
      value={value}
      defaultValue={defaultValue}
      options={options}
      isDisabled={isDisabled}
      required={required}
      className={`select-multi ${
        metaError?.error && metaError?.touched ? 'error-input-class' : ''
      }`}
      placeholder="Selecione"
      {...rest}
    />
    {metaError?.error && metaError?.touched && (
      <span className="error-message">{metaError.error}</span>
    )}
  </FormGroup>
);
