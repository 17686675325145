import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { App } from './App';
import { store } from './store';
import { ConfigProvider } from 'react-avatar';

import './index.scss';

ReactDOM.render(
  <ToastProvider autoDismiss autoDismissTimeout={3000} placement="top-right">
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider colors={['red', 'green', 'blue']}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </ToastProvider>,
  document.getElementById('root'),
);
