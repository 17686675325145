import React from 'react';

import './styles.scss';

interface ITextProps {
  title: string;
  style?: React.CSSProperties;
  type?: string;
}

export const Text = ({ title, style, type }: ITextProps) => {
  return (
    <p className={type || 'text'} style={{ ...style }}>
      {title}
    </p>
  );
};
