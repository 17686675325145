function initEventsMemed(
  cpf,
  nome,
  endereco,
  cidade,
  telefone,
  peso,
  altura,
  nome_mae,
  dificuldade_locomocao,
  attendanceId,
) {
  /* eslint-disable */
  MdSinapsePrescricao.event.add('core:moduleInit', function moduleInitHandler(module) {
    MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
      // Desativa a opção de excluir um paciente (obrigatório)
      deletePatient: false,
      // Desabilita a opção de remover/trocar o paciente (obrigatório)
      removePatient: false,
      // Esconde o formulário de edição do paciente (obrigatório)
      editPatient: false,
      // Esconde o histórico de prescrições
      // historyPrescription: false,
      // // Esconde o botão "Opções Receituário"
      // optionsPrescription: false,
      // // Desabilita a opção de remover a prescrição no histórico
      // removePrescription: false,
      // // Desabilita a opção para definir alergias para o paciente
      // setPatientAllergy: false,
      // // Desabilita a aba "Nomes Comerciais" do Autocomplete de medicamentos
      // autocompleteIndustrialized: false,
      // // Desabilita a aba "Fórmulas" do Autocomplete de medicamentos
      // autocompleteManipulated: false,
      // // Desabilita a aba "Nomes Genéricos" do Autocomplete de medicamentos
      // autocompleteCompositions: false,
      // // Desabilita a aba "Outros" do Autocomplete de medicamentos
      // autocompletePeripherals: false,
      // // Esconde o botão "Copiar para Prontuário" (que copia o conteúdo da prescrição para o clipboard)
      // copyMedicalRecords: false,
      // // Esconde o botão de fechar da prescrição
      // buttonClose: false,
      // // Habilita opção Nova Fórmula Manipulada no menu
      // newFormula: false,
      // // Desativa o componente de Novas Formas de envio da Receita
      // allowShareModal: false,
      // // Desativa o componente de vídeos do Onboarding
      // guidesOnboarding: false,
      // // Desabilita botão de "editar receita" do modal de conclusão
      // conclusionModalEdit: false,
      // // Desabilita a sincronização do usuário
      // dropdownSync: false,
      // // Desabilita os protocolos
      // showProtocol: false,
      // // Desabilita o menu de ajuda
      // showHelpMenu: false,
      // // Desabilita a edição das informações de identificação
      // editIdentification: false,
    });
    // O módulo da prescrição foi iniciado
    if (module.name === 'plataforma.prescricao') {
      // Registrando o evento de click no elemento

      MdHub.event.add('prescricaoExcluida', function (idPrescricao) {
        if (idPrescricao && attendanceId) {
          var xmlHttp = new XMLHttpRequest();
          var url = `${process.env.REACT_APP_API_URL}/attendance/removeprescription`;
          xmlHttp.open('POST', url, false); // false for synchronous request
          xmlHttp.setRequestHeader('Content-Type', 'application/json');
          xmlHttp.send(
            JSON.stringify({
              PrescriptionId: idPrescricao,
              AttendanceId: attendanceId,
            }),
          );
          return xmlHttp.responseText;
        }
      });

      document.getElementById('botaoShowPrescricao').addEventListener('click', function () {
        MdHub.command
          .send('plataforma.prescricao', 'setPaciente', {
            // Pode ser um documento criptografado do paciente, por exemplo
            // Usamos essa propriedade para destinguir nomes iguais
            // (obrigatório)
            idExterno: attendanceId,

            // Nome do paciente (obrigatório)
            nome: nome,

            // Endereço do paciente (opcional)
            endereco: endereco,

            // Cidade do paciente (opcional)
            cidade: cidade,

            // Telefone celular (obrigatório, DDD + digitos, somente números. NÃO ENVIAR PREFIXO "+55")
            telefone: telefone,

            // Usado no receituário de alto custo (Opcional)
            peso: peso,

            // Usado no receituário de alto custo (Opcional)
            altura: altura,

            // Usado no receituário de alto custo (Opcional)
            nome_mae: nome_mae,

            cpf: cpf,

            // Usado no receituário de alto custo (Opcional)
            dificuldade_locomocao: dificuldade_locomocao,
          })
          .then(function () {
            // Mostra o módulo de prescrição
            MdHub.module.show('plataforma.prescricao');
          });
      });
    }

    if (module.name === 'plataforma.prescricao') {
      // O módulo da prescrição foi iniciado.
      // eslint-disable-next-line no-undef
      MdHub.event.add('prescricaoImpressa', function (prescriptionData) {
        // No objeto "prescriptionData", é retornado as informações da prescrição gerada.
        // Implementar ações, callbacks, etc.
        if (prescriptionData && attendanceId) {
          var xmlHttp = new XMLHttpRequest();
          var url = `${process.env.REACT_APP_API_URL}/attendance/registerprescription`;
          xmlHttp.open('POST', url, false); // false for synchronous request
          xmlHttp.setRequestHeader('Content-Type', 'application/json');
          xmlHttp.send(
            JSON.stringify({
              PrescriptionId: prescriptionData.prescricao.id,
              AttendanceId: attendanceId,
            }),
          );
          return xmlHttp.responseText;
        }
      });
    }
  });
}

export default initEventsMemed;
