import { DoctorUser } from '../../slices/admin';
import { useHistory } from 'react-router-dom';
import Eye from '../../resources/images/eye.svg';
import Attendance from '../../resources/images/attendances.svg';
import Skeleton from 'react-loading-skeleton';
import { MDBDataTable } from 'mdbreact';

import './styles.scss';
interface IDoctorsTableProps {
  doctors?: DoctorUser[];
  role: string;
  attendanceDay?: string;
  speciality?: string;
  patientId?: string;
  isLoading?: boolean;
}

export const DoctorsTable = ({
  doctors,
  role,
  attendanceDay,
  speciality,
  patientId,
  isLoading,
}: IDoctorsTableProps) => {
  const history = useHistory();
  const scheduleAttendanceAction = (doctorId?: string) => {
    return (
      <div className="attendance-table-buttons">
        <span
          title="Agendar Consulta"
          onClick={() => {
            history.push(
              `${process.env.PUBLIC_URL}/${role}/checkout/${attendanceDay}&${speciality}&${doctorId}&${patientId}`,
            );
          }}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={Attendance}
            alt="alert"
            style={{
              width: '22px',
              height: '15px',
            }}
          />
        </span>
      </div>
    );
  };

  const getRedirectoDoctorScheduleAction = (id?: string) => {
    return (
      <div className="attendance-table-buttons">
        <span
          title="Visualizar"
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/partner/doctorschedule/${id}`);
          }}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={Eye}
            alt="alert"
            style={{
              width: '22px',
              height: '15px',
            }}
          />
        </span>
      </div>
    );
  };

  const getActions = (id?: string) => {
    return (
      <div className="attendance-table-buttons">
        <span
          title="Visualizar"
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/doctor/profile/${id}`);
          }}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={Eye}
            alt="alert"
            style={{
              width: '22px',
              height: '15px',
            }}
          />
        </span>
      </div>
    );
  };

  const doctorsTable: any = doctors?.map((doctor: DoctorUser) => {
    return {
      fullname: doctor.fullname,
      crm: doctor.crm,
      email: doctor.email,
      phone: `${doctor.ddd} ${doctor.phone}`,
      specialities:
        doctor.doctorType === 0
          ? doctor.specialities
          : doctor.doctorType === 1
          ? 'Psicólogo'
          : 'Outro profissional',
      actions: getActions(doctor.id),
      doctorSchedule: getRedirectoDoctorScheduleAction(doctor.id),
      patientAction: scheduleAttendanceAction(doctor.id),
    };
  });

  const partnerColumns =
    role === 'partner'
      ? [
          {
            label: 'E-mail',
            field: 'email',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Telefone',
            field: 'phone',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Ações',
            field: 'actions',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Agenda',
            field: 'doctorSchedule',
            sort: 'asc',
            width: 100,
          },
        ]
      : [];

  const patientColumns =
    role === 'patient'
      ? [
          {
            label: 'Agendar consulta',
            field: 'patientAction',
            sort: 'asc',
            width: 100,
          },
        ]
      : [];

  const data = {
    columns: [
      {
        label: 'Nome',
        field: 'fullname',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'CRM',
        field: 'crm',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Especialidades',
        field: 'specialities',
        sort: 'asc',
        width: 200,
      },
      ...partnerColumns,
      ...patientColumns,
    ],
    rows: [...doctorsTable],
  };

  const rows: any = [];
  for (let i = 0; i < 3; i++) {
    rows.push({
      fullname: <Skeleton width={150} />,
      crm: <Skeleton width={150} />,
      specialities: <Skeleton width={150} />,
      email: <Skeleton width={150} />,
      phone: <Skeleton width={150} />,
    });
  }

  const isLoadingData = {
    ...data,
    rows: rows,
  };

  return (
    <div className="doctors-table">
      {!isLoading ? (
        <MDBDataTable
          searching
          searchLabel="Pesquisa rápida"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'médicos']}
          theadColor="indigo"
          striped
          bordered
          data={data}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      ) : (
        <MDBDataTable
          searching
          searchLabel="Pesquisa rápida"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'médicos']}
          theadColor="indigo"
          striped
          bordered
          data={isLoadingData}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      )}
    </div>
  );
};
