import React, { useRef } from 'react';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { setUser, updateUserImageProfile } from '../../slices/user';
import Avatar from 'react-avatar';

interface IAvatarProps {
  imageStyle?: React.CSSProperties;
  imageClass?: string;
  disabled?: boolean;
  userImage?: string;
  avatarName?: string;
  size?: string;
}

export const GenericAvatar = ({
  userImage,
  imageStyle,
  imageClass,
  disabled,
  avatarName,
  size,
}: IAvatarProps) => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClickImage = () => {
    if (!disabled) {
      hiddenFileInput?.current?.click();
    }
  };

  const handleChangeImage = async (event: any) => {
    const fileUploaded = event.target.files[0];
    const image = await getBase64(fileUploaded);
    dispatch(
      setUser({
        ...user,
        image,
      }),
    );
    await dispatch(updateUserImageProfile(user.login, image, user.role));
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  return (
    <>
      <Avatar name={avatarName || user.name} round="50px" size={size || '50'} color="#008171" />
      {/* <img
        src={userImage || user.image || GenericAvatar}
        alt="profile"
        style={{ ...imageStyle }}
        onClick={() => handleClickImage()}
        className={imageClass}
      ></img>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        onChange={handleChangeImage}
      ></input> */}
    </>
  );
};
