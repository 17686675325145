export const Vaccination = [
  { value: 'Influenza', label: 'Influenza' },
  { value: 'Anti-pneumocóccica (VPC13)', label: 'Anti-pneumocóccica (VPC13)' },
  { value: 'Anti-pneumocóccica (VPP23)', label: 'Anti-pneumocóccica (VPP23)' },
  { value: 'Dupla adulto (difteriae tétano) – dT', label: 'Dupla adulto (difteriae tétano) – dT' },
  {
    value: 'Tríplice viral (sarampo,caxumba e rubéola)',
    label: 'Tríplice viral (sarampo,caxumba e rubéola)',
  },
  { value: 'Herpes zóster', label: 'Herpes zóster' },
  { value: 'Febre amarela', label: 'Febre amarela' },
  { value: 'Hepatite B', label: 'Hepatite B' },
  { value: 'Covid-19', label: 'Covid-19' },
];
