import { MDBDataTable } from 'mdbreact';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { FormGroup, Input, Label } from 'reactstrap';
import { AvaiableSchedule } from '../../slices/attendance';

import './styles.scss';

interface ISchedulesTableProps {
  avaiableSchedules?: AvaiableSchedule[];
  setAvaiableSchedule?: (value: any) => void;
  isLoading?: boolean;
}

export const SchedulesTable = ({
  avaiableSchedules,
  setAvaiableSchedule,
  isLoading,
}: ISchedulesTableProps) => {
  useEffect(() => {}, [avaiableSchedules]);

  const handleChangCheckbox = (index: number) => {
    const session = avaiableSchedules ? avaiableSchedules[index] : null;

    if (avaiableSchedules && session) {
      const _avaiableSchedule = [...(avaiableSchedules ? avaiableSchedules : [])];

      _avaiableSchedule.map(element => {
        return (element.checked = false);
      });

      session.checked = true;
      _avaiableSchedule[index] = session;

      if (setAvaiableSchedule) {
        setAvaiableSchedule(_avaiableSchedule);
      }
    }
  };

  const getSelectSchedule = (schedule: AvaiableSchedule, index: number) => {
    return (
      <FormGroup className="" check>
        <Label className="radio-input" check>
          <Input
            type="checkbox"
            checked={schedule.checked}
            onChange={() => handleChangCheckbox(index)}
            style={{ marginTop: 0 }}
          />
          <span className="radio-label ml-1">Selecionar horário</span>
        </Label>
      </FormGroup>
    );
  };

  const schedulesTable: any = avaiableSchedules?.map(
    (schedule: AvaiableSchedule, index: number) => {
      return {
        doctorName: `Dr. ${schedule.doctorName}`,
        speciality: schedule.speciality,
        dateTimeHour: schedule.dateTimeHour
          ? new Date(schedule.dateTimeHour).toLocaleTimeString()
          : '',
        actions: getSelectSchedule(schedule, index),
      };
    },
  );

  const data = {
    columns: [
      {
        label: 'Médico',
        field: 'doctorName',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Especialidade',
        field: 'speciality',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Horário Disponível',
        field: 'dateTimeHour',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Selecionar',
        field: 'actions',
        sort: 'asc',
        width: 200,
      },
    ],
    rows: schedulesTable,
  };

  const rows: any = [];
  for (let i = 0; i < 3; i++) {
    rows.push({
      doctorName: <Skeleton width={150} />,
      speciality: <Skeleton width={150} />,
      dateTimeHour: <Skeleton width={150} />,
      actions: <Skeleton width={150} />,
    });
  }

  const isLoadingData = {
    ...data,
    rows: rows,
  };

  return (
    <div className="schedules-table">
      {!isLoading ? (
        <MDBDataTable
          searching
          searchLabel="Pesquisa pelo médico"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'médicos disponíveis']}
          theadColor="indigo"
          striped
          bordered
          data={data}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      ) : (
        <MDBDataTable
          searching
          searchLabel="Pesquisa pelo médico"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'médicos disponíveis']}
          theadColor="indigo"
          striped
          bordered
          data={isLoadingData}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      )}
    </div>
  );
};
