import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Layout } from '../../components/Layout';
import { RootState, useAppDispatch } from '../../store';
import { ReturnLink } from '../../components/ReturnLink';
import { TummiModal, ITummiModalProps } from '../../components/Modal';
import { Text } from '../../components/Text';
import {
  Attendance,
  CancelAttendance as CancelAttendanceRequest,
  getAttendance,
} from '../../slices/attendance';

import 'react-credit-cards/lib/styles.scss';
import { Button } from '../../components/Button';
import moment from 'moment';

interface Params {
  attendanceId?: string;
}

export const CancelAttendance = () => {
  const { attendanceId } = useParams<Params>();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);

  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const [attendance, setAttendance] = useState<Attendance>();

  useEffect(() => {
    const fetchAttendance = async () => {
      const response = await dispatch(getAttendance(attendanceId || ''));
      setAttendance(response.data);
    };
    fetchAttendance();
  }, []);

  const onCancelAttendance = async () => {
    setModal({
      isOpen: true,
      body: 'Só um momento, estamos cancelando o seu atendimento.',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      leavingCallback: () => true,
      showCancelButton: true,
    });

    const attendanceResponse = await dispatch(CancelAttendanceRequest(attendance || {}));

    if (attendanceResponse.data.success) {
      setModal({
        isOpen: true,
        body: 'Atendimento cancelado com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => history.push(`${process.env.PUBLIC_URL}/${user.role}/attendances`),
        okCallback: () => history.push(`${process.env.PUBLIC_URL}/${user.role}/attendances`),
        okButton: 'Ok',
        showCancelButton: true,
      });
    } else {
      setModal({
        isOpen: true,
        body:
          'Não foi possivel cancelar o seu reagendamento. Tente novamente mais tarde ou entre em contato com a Thummi.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => history.push(`${process.env.PUBLIC_URL}/${user.role}/attendances`),
        okCallback: () => history.push(`${process.env.PUBLIC_URL}/${user.role}/attendances`),
        okButton: 'Ok',
        showCancelButton: true,
      });
    }
  };

  return (
    <Layout type={user.role}>
      <>
        <div className="view-profile-page">
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" returnLink={() => history.goBack()} />
              <Text
                title="CANCELAMENTO ATENDIMENTO"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="bordered-container">
                <Row className="mt-2">
                  <Col sm={5}>
                    <span className="label">Médico (a)</span>
                  </Col>
                  <Col sm={4}>
                    <span className="label">Especialidade</span>
                  </Col>
                  <Col sm={3}>
                    <span className="label">Data do atendimento</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span>
                      <strong>
                        {attendance?.doctorTelemedicine?.firstName}{' '}
                        {attendance?.doctorTelemedicine?.lastName}
                      </strong>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <span>
                      <strong>{attendance?.speciality}</strong>
                    </span>
                  </Col>
                  <Col sm={3}>
                    <span>
                      <strong>
                        {' '}
                        {moment(new Date(attendance?.date || '')).format('DD/MM/YYYY HH:mm')}{' '}
                      </strong>
                    </span>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={5}>
                    <span className="label">Valor da Consulta</span>
                  </Col>
                  <Col sm={4}>
                    <span className="label">Local</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span>
                      <strong>R$ {attendance?.attendanceValue}</strong>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <span>
                      <strong>{'Teleatendimento (Online)'}</strong>
                    </span>
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col sm={4}>
                    <Button
                      onClick={() =>
                        history.push(
                          `${process.env.PUBLIC_URL}/${user.role}/reschedule/${attendance?.id}`,
                        )
                      }
                      disabled={app.isLoading}
                    >
                      Reagendar Atendimento
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <Button onClick={() => onCancelAttendance()} disabled={app.isLoading}>
                      Cancelar Atendimento
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <TummiModal {...modal} />
      </>
    </Layout>
  );
};
