import { Row, Col, NavLink } from 'reactstrap';
import { Attendance } from '../../slices/attendance';
import moment from 'moment';
import Calendar from '../../resources/images/calendar.svg';
import Clock from '../../resources/images/clock.svg';

import './styles.scss';
import { GenericAvatar } from '../Avatar';

interface IAttendanceCardProps {
  attendance?: Attendance;
  redirectLinkTitle: string;
  redirectWhere: () => void;
}

export const AttendanceCard = ({
  attendance,
  redirectLinkTitle,
  redirectWhere,
}: IAttendanceCardProps) => {
  return (
    <div className="doctor-dashboard-card">
      <div className="bordered-container-card">
        <Row>
          <Col className="d-flex">
            <Col style={{ textAlign: 'center' }}>
              <GenericAvatar
                size="60"
                avatarName={`${attendance?.patientTelemedicine?.firstName} ${attendance?.patientTelemedicine?.lastName}`}
              />
            </Col>
            <Col>
              <Row>
                <p
                  className="patient"
                  style={{ fontSize: '13px', marginBottom: '5px', fontWeight: 'bold' }}
                >
                  Paciente
                </p>
              </Row>
              <Row>
                <p className="patient" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {attendance?.patientTelemedicine?.firstName}{' '}
                  {attendance?.patientTelemedicine?.lastName}
                </p>
              </Row>
            </Col>
          </Col>
        </Row>
        <div className="divider" />
        <Row>
          <p
            className="patient"
            style={{
              fontSize: '14px',
              marginBottom: '5px',
              fontWeight: 'bold',
              marginLeft: '20px',
            }}
          >
            Consulta
          </p>
        </Row>
        <Row className="mt-3">
          <Col style={{ paddingLeft: '10px' }}>
            <p
              className="text-card"
              style={{
                fontSize: '14px',
                width: '100%',
                textAlign: 'left',
                borderBottom: '1px solid #E5E8EB',
                lineHeight: '0.1rem',
                margin: '10px 2 20px',
              }}
            >
              <span style={{ backgroundColor: '#fff', padding: '0 10px' }}>Data</span>
            </p>
          </Col>
          <Col>
            <p
              className="text-card"
              style={{
                fontSize: '14px',
                width: '70%',
                textAlign: 'left',
                borderBottom: '1px solid #E5E8EB',
                lineHeight: '0.1rem',
                margin: '10px 2 20px',
              }}
            >
              <span style={{ backgroundColor: '#fff', padding: '0 10px' }}>Hora</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-left">
            <img
              src={Calendar}
              alt="calendar"
              style={{ width: '13,33px', height: '14,67px', marginLeft: '6px', marginRight: '6px' }}
            />
            <span
              className="text"
              style={{ fontSize: '14px', fontWeight: 'bold', color: '#000000' }}
            >
              {attendance?.date ? moment(attendance.date).format('DD/MM/YYYY') : ''}
            </span>
            <img
              src={Clock}
              alt="clock"
              style={{
                width: '13,33px',
                height: '14,67px',
                marginLeft: '32px',
                marginRight: '6px',
              }}
            />
            <span
              className="text"
              style={{ fontSize: '14px', fontWeight: 'bold', color: '#000000' }}
            >
              {attendance?.date ? moment(attendance.date).format('HH:mm') : ''}
            </span>
          </Col>
        </Row>
        <div className="divider" />
        <Row>
          <Col className="d-flex justify-content-center">
            <NavLink
              className="redirect-link"
              onClick={() => {
                redirectWhere();
              }}
            >
              {redirectLinkTitle}
            </NavLink>
          </Col>
        </Row>
      </div>
    </div>
  );
};
