import { useState, useEffect } from 'react';
import { Col, Row, Input as ReactstrapInput, Container } from 'reactstrap';
import { ChatMessage } from '../../slices/patient';
import { useToasts } from 'react-toast-notifications';
import { Button } from '../Button';

import './styles.scss';

interface IChatBotPros {
  chatMessages?: ChatMessage[];
  handleSaveEmotionalTest?: (questions: ChatMessage[]) => void;
}

export const ChatBot = ({ chatMessages, handleSaveEmotionalTest }: IChatBotPros) => {
  const [question, setQuestion] = useState<ChatMessage>({});
  const [questions, setQuestions] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState('');
  const { addToast } = useToasts();
  const [showSaveTest, setShowSaveTest] = useState(false);

  useEffect(() => {
    if (chatMessages && chatMessages?.length > 0) {
      setQuestion(
        chatMessages.find(element => {
          return element?.id === 'begin';
        }) || {},
      );
      setQuestions(chatMessages);
    }
  }, [chatMessages]);

  useEffect(() => {
    if (question.id === 'end') {
      setShowSaveTest(true);
    }
  }, [question]);

  const ChatMessage = (message?: string) => {
    const createMarkup = () => {
      return { __html: message ?? '' };
    };
    return (
      <div>
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
    );
  };

  const handleInput = () => {
    if (input) {
      setInput('');

      const _question = {
        ...question,
        value: input,
      };

      setQuestion(_question);

      const _questions = [...questions];

      // eslint-disable-next-line array-callback-return
      _questions.map((quest: ChatMessage) => {
        if (quest.id === _question.id) {
          quest.value = _question.value;
        }
      });

      setQuestions(_questions);

      handleNext();
    } else {
      addToast('Por favor, preencha os dados solicitados.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleSave = () => {
    if (handleSaveEmotionalTest) {
      handleSaveEmotionalTest(questions);
    }
  };

  const handleNext = () => {
    const option = question && question?.options ? question.options[0] : {};
    const nextquestion = chatMessages?.find(elemQuestion => {
      return (option.nextId || question.nextId) === elemQuestion.id;
    });
    setQuestion({
      ...nextquestion,
    });
  };

  const optionList = question?.options?.map(option => (
    <>
      <Button
        className="question-button"
        key={option.id}
        type="primary"
        onClick={() => {
          const _question = {
            ...question,
            value: option.propertyName,
          };

          setQuestion(_question);

          const _questions = [...questions];

          // eslint-disable-next-line array-callback-return
          _questions.map((quest: ChatMessage) => {
            if (quest.id === _question.id) {
              quest.value = _question.value;
            }
          });

          setQuestions(_questions);

          handleNext();
        }}
        style={{ backgroundColor: '#399999', borderColor: '#399999' }}
      >
        {option.title}
      </Button>
    </>
  ));

  const inputType = (question?: ChatMessage) => {
    return question?.type === 'VI' ? (
      <>
        <ReactstrapInput
          type="number"
          style={{ marginBottom: '0.5rem' }}
          onChange={(e: any) => setInput(e.target.value)}
          value={input}
          placeholder="Insira o valor aqui"
        />
        <Button
          className="question-button"
          type="primary"
          onClick={() => handleInput()}
          style={{ backgroundColor: '#399999', borderColor: '#399999' }}
        >
          Confirmar
        </Button>
      </>
    ) : question?.type === 'TI' ? (
      <>
        <ReactstrapInput
          type="text"
          style={{ marginBottom: '0.5rem' }}
          onChange={(e: any) => setInput(e.target.value)}
          value={input}
          placeholder="Insira a informação aqui"
        />
        <Button
          className="question-button"
          type="primary"
          onClick={() => handleInput()}
          style={{ backgroundColor: '#399999', borderColor: '#399999' }}
        >
          Confirmar
        </Button>
      </>
    ) : null;
  };

  return (
    <Container style={{ justifyContent: 'center', textAlign: 'center' }}>
      <Row>
        <Col sm={12}>{ChatMessage(question.text)}</Col>
      </Row>
      <Row className="mt-4">
        <Col sm={1} />
        <Col sm={10}>{optionList}</Col>
        <Col sm={1} />
      </Row>
      <Row className="mt-4">
        <Col sm={1} />
        <Col sm={10}>{inputType(question)}</Col>
        <Col sm={1} />
      </Row>
      {showSaveTest && (
        <Row className="mt-4">
          <Col sm={1} />
          <Col sm={10}>
            <Button
              className="question-button"
              type="primary"
              onClick={() => handleSave()}
              style={{ backgroundColor: '#399999', borderColor: '#399999' }}
            >
              Salvar
            </Button>
          </Col>
          <Col sm={1} />
        </Row>
      )}
    </Container>
  );
};
