import { useSelector } from 'react-redux';

import { setUser } from '../../slices/user';
import { RootState, useAppDispatch } from '../../store';

export const useAuth = () => {
  const user = useSelector((state: RootState) => state.user);

  const dispatch = useAppDispatch();

  return {
    isAuthenticated: user.isAuthenticated,
    setIsAuthenticated: () => dispatch(setUser({ isAuthenticated: true })),
    removeIsAuthenticated: () => dispatch(setUser({ isAuthenticated: false })),
    getToken: user.token,
  };
};
