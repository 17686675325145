import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Login } from '../../pages/Login';
import { PartnerHome } from '../../pages/Partner/PartnerHome';
import { PartnerDoctors } from '../../pages/Partner/PartnerDoctors';
import { PartnerPatients } from '../../pages/Partner/PartnerPatients';
import { PrivateRoute } from './PrivateRoute';
import { DoctorHome } from '../../pages/Doctor/DoctorHome';
import { DoctorProfile } from '../../pages/Doctor/DoctorProfile';
import { DoctorsAttendance } from '../../pages/Doctor/DoctorsAttendance';
import { DoctorPatients } from '../../pages/Doctor/DoctorsPatients';
import { DoctorSchedules } from '../../pages/Doctor/DoctorSchedules';
import { DoctorAttendanceInfo } from '../../pages/Attendance';
import { PatientAttendance as DoctorAttendanceAmpalInfo } from '../../pages/Patient/Ampal/Attendance';
import { AdminHome } from '../../pages/Admin/AdminHome';
import { AdministrativePanel } from '../../pages/Admin/AdminPanel';
import { PatientHistory } from '../../pages/Patient/PatientHistory';
import { PartnerProfile } from '../../pages/Partner/PartnerProfile';
import { NewAttendance } from '../../pages/Patient/NewAttendance';
import { CreateAdminUser } from '../../components/CreateUserPages/CreateAdminUser';
import { CreatePatientUser } from '../../components/CreateUserPages/CreatePatientUser';
import { CreateDoctorUser } from '../../components/CreateUserPages/CreateDoctorUser';
import { PatientAttendances } from '../../pages/Patient/Attendances';
import { TelemedicineCall } from '../../pages/TelemedicineCall';
import { Schedules } from '../../pages/Schedule';
import { AdminProfile } from '../../pages/Admin/AdminProfile';
import { PatientProfile } from '../../pages/Patient/PatientProfile';
import { LandingPage } from '../../pages/LandingPage';
import { CreateCompanySuccess } from '../../pages/CreateCompanySuccess';
import { PatientHome } from '../../pages/Patient/PatientHome';
import { PatientAttendance } from '../../pages/Patient/Attendance';
import { ChangePassword } from '../../pages/ChangePassword';
import { Checkout } from '../../pages/Checkout';
import { CheckoutSuccess } from '../../pages/CheckoutSuccess';
import { AdminUsers } from '../../pages/Admin/AdminUsers';
import { PublicRegistration } from '../../pages/PublicRegistration';
import { SuccessRegistration } from '../../pages/SuccessRegistration';
import { RecoverPassword } from '../../pages/RecoverPassword';
import { RescheduleCheckout } from '../../pages/Reschedule';
import { CancelAttendance } from '../../pages/CancelAttendance';

import { RootState } from '../../store';
import { Spinner } from 'reactstrap';

export const Routes = () => {
  const app = useSelector((state: RootState) => state.app);

  return (
    <>
      <>
        <Route exact path="/" component={Login} />
        <Route exact path="/successregistration" component={CreateCompanySuccess} />
        <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
        <Route exact path={`${process.env.PUBLIC_URL}/register`} component={PublicRegistration} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/recover-password`}
          component={RecoverPassword}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/recover-password/:code`}
          component={RecoverPassword}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/success-registration`}
          component={SuccessRegistration}
        />
        <PrivateRoute path={`${process.env.PUBLIC_URL}/admin/home`}>
          <AdminHome />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/createpatientuser`}>
          <CreatePatientUser />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/administrative/createpatientuser`}>
          <CreatePatientUser />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/createdoctoruser`}>
          <CreateDoctorUser />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/admin/administrativePanel`}>
          <AdministrativePanel />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/admin/users`}>
          <AdminUsers />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/home`}>
          <PartnerHome />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/home`}>
          <PatientHome />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/doctors`}>
          <PartnerDoctors />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/patients`}>
          <PartnerPatients />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/admin/createadminuser`}>
          <CreateAdminUser />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/profile/:id`}>
          <PartnerProfile />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/profile/:id`}>
          <DoctorProfile />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/home`}>
          <DoctorHome />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/attendances`}>
          <DoctorsAttendance />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/patients`}>
          <DoctorPatients />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/doctorschedule/:id`}>
          <DoctorSchedules />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/doctorschedule`}>
          <DoctorSchedules />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/attendance/:id`}>
          <DoctorAttendanceInfo />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/ampal/attendance/:id`}>
          <DoctorAttendanceAmpalInfo />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/ampal/attendance/:id`}>
          <DoctorAttendanceAmpalInfo />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/attendance/:id`}>
          <DoctorAttendanceInfo />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/administrative/attendance/:id`}>
          <DoctorAttendanceInfo />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/patient-history/:id`}>
          <PatientHistory />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/patient-history/:id`}>
          <PatientHistory />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/patient-history/:id`}>
          <PatientHistory />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/administrative/newattendance/:id`}>
          <NewAttendance />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/newattendance/:id`}>
          <NewAttendance />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/newattendance/:id`}>
          <NewAttendance />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/attendances`}>
          <PatientAttendances />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/attendance/:id`}>
          <PatientAttendance />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/call/:id`}>
          <TelemedicineCall />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/call/:id`}>
          <TelemedicineCall />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/schedules`}>
          <Schedules />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/schedules`}>
          <Schedules />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/profile/:id`}>
          <PatientProfile />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/admin/profile/:id`}>
          <AdminProfile />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/patient/:id`}>
          <PatientProfile />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/changepassword`}>
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/changepassword`}>
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/partner/changepassword`}>
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/admin/changepassword`}>
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/patient/checkout/:attendanceDay&:speciality&:doctorId&:patientId`}
        >
          <Checkout />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/reschedule/:attendanceId`}>
          <RescheduleCheckout />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/doctor/reschedule/:attendanceId`}>
          <RescheduleCheckout />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/checkoutsuccess/:attendanceId`}>
          <CheckoutSuccess />
        </PrivateRoute>
        <PrivateRoute path={`${process.env.PUBLIC_URL}/patient/cancelattendance/:attendanceId`}>
          <CancelAttendance />
        </PrivateRoute>
      </>
      <>
        {app.isLoading && (
          <Spinner
            style={{
              textAlign: 'center',
              fontSize: '10px',
              position: 'absolute',
              left: 'calc(50vw - 25px)',
              top: 'calc(50vh - 20px)',
              color: '#00A0A0',
            }}
          />
        )}
      </>
    </>
  );
};
