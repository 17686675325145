import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppSliceState = {
  isLoading: boolean;
};

const initialState: AppSliceState = {
  isLoading: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsloading: (state, action: PayloadAction<Partial<AppSliceState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setIsloading } = appSlice.actions;
export const appReducer = appSlice.reducer;
