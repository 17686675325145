export const Comorbidities = [
  { value: 'Hipertensão Arterial', label: 'Hipertensão Arterial' },
  { value: 'Diabetes', label: 'Diabetes' },
  { value: 'Dislipidemia', label: 'Dislipidemia' },
  { value: 'Hipotireoidismo', label: 'Hipotireoidismo' },
  { value: 'Hipertireoidismo', label: 'Hipertireoidismo' },
  { value: 'Asma', label: 'Asma' },
  {
    value: 'Doença Pulmonar Obstrutiva Crônica (enfisema)',
    label: 'Doença Pulmonar Obstrutiva Crônica (enfisema)',
  },
  { value: 'Insuficiência Cardíaca', label: 'Insuficiência Cardíaca' },
  { value: 'História de Infarto do Miocárdio', label: 'História de Infarto do Miocárdio' },
  { value: 'Arritmia', label: 'Arritmia' },
  { value: 'Osteoartrose', label: 'Osteoartrose' },
  { value: 'Osteoporose', label: 'Osteoporose' },
  { value: 'Artrite Reumatóide', label: 'Artrite Reumatóide' },
  { value: 'Glaucoma', label: 'Glaucoma' },
  { value: 'Acidente Vascular Encefálico', label: 'Acidente Vascular Encefálico' },
  { value: 'Demência de Alzheimer', label: 'Demência de Alzheimer' },
  { value: 'Doença de Parkinson', label: 'Doença de Parkinson' },
  { value: 'Epilepsia', label: 'Epilepsia' },
  { value: 'Depressão', label: 'Depressão' },
  { value: 'Cirrose hepática', label: 'Cirrose hepática' },
  { value: 'HIV', label: 'HIV' },
  { value: 'Câncer de Mama', label: 'Câncer de Mama' },
  { value: 'Câncer de Próstata', label: 'Câncer de Próstata' },
  { value: 'Câncer de Intestino', label: 'Câncer de Intestino' },
  { value: 'Câncer de Pulmão', label: 'Câncer de Pulmão' },
  { value: 'Outras neoplasias', label: 'Outras neoplasias' },
  { value: 'Hiperplasia Prostática Benigna', label: 'Hiperplasia Prostática Benigna' },
];
