import { Container, Row } from 'reactstrap';
import { ReturnLink } from '../../components/ReturnLink';
import { useHistory } from 'react-router-dom';

import './styles.scss';

export const SuccessRegistration = () => {
  const history = useHistory();
  return (
    <div className="success-registration">
      <Container>
        <Row className="mt-4 mb-4 d-flex justify-content-start">
          <ReturnLink
            title="Voltar a página inicial"
            returnLink={() => history.push(`${process.env.PUBLIC_URL}`)}
          />
        </Row>
        <h2 className="title">Parabéns, seu cadastro foi realizado com sucesso.</h2>
        <h2 className="text">
          Agora é só aguardar, que o time comercial da Thummi irá entrar em contato com a sua
          empresa com a melhor proposta para o seu negócio.
        </h2>
      </Container>
    </div>
  );
};
