export default function handleCopyAttendanceLink() {
  var copyText = document.getElementById('copy-my-contents');
  var range = document.createRange();
  var selection = window.getSelection();
  range.selectNodeContents(copyText);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand('copy');
  alert('Link copiado com sucesso!');
}
