import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { PatientUser } from '../../slices/admin';
import formatString from 'format-string-by-pattern';

import { maskCPF } from '../../utils/Mask';

import './styles.scss';
import { GenericAvatar } from '../Avatar';

let { AgeFromDateString } = require('age-calculator');
interface IPatientCardProps {
  patient?: PatientUser;
  role: string;
}

export const PatientCard = ({ patient, role }: IPatientCardProps) => {
  const history = useHistory();

  const GenderPatient = (value?: number) => {
    switch (value) {
      case 1:
        return 'Feminino';
      case 2:
        return 'Não informado';
      default:
        return 'Masculino';
    }
  };

  return (
    <div className="patient-card">
      <Row className="mt-1">
        <Col className="d-flex justify-content-center">
          <GenericAvatar size="70" avatarName={`${patient?.firstName} ${patient?.lastName}`} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <span className="text" style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {patient?.firstName} {patient?.lastName}
          </span>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <span className="text" style={{ fontSize: '14px', fontWeight: 'bold' }}>
            Sexo:{' '}
            <span className="text" style={{ fontSize: '14px', fontWeight: 'bold' }}>
              {GenderPatient(patient?.gender)} / {new AgeFromDateString(patient?.dob).age} anos
            </span>
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <span className="text" style={{ fontSize: '14px', fontWeight: 'bold' }}>
            CPF:{' '}
            <span className="text" style={{ fontSize: '14px', fontWeight: 'bold' }}>
              {formatString(maskCPF, patient?.cpf || '')}
            </span>
          </span>
        </Col>
      </Row>
      <div className="divider" />
      <Row>
        <Col className="d-flex justify-content-center">
          <span
            onClick={() =>
              history.push(`${process.env.PUBLIC_URL}/${role}/patient-history/${patient?.id}`)
            }
            className="patient-card-button"
          >
            VER HISTÓRICO
          </span>
        </Col>
      </Row>
    </div>
  );
};
