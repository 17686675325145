import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  Nav,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
} from 'reactstrap';
import { GenericAvatar } from '../Avatar';
import { useAuth } from '../../hooks/useAuth';
import TummiLogo from '../../resources/images/tummi-logo.svg';
import LeaveLogo from '../../resources/images/leave.svg';
import Dashboard from '../../resources/images/dashboard.svg';
import dashboardActive from '../../resources/images/dashboardActive.svg';
import Users from '../../resources/images/users.svg';
import usersActive from '../../resources/images/usersActive.svg';
import Attendances from '../../resources/images/attendances.svg';
import attendancesActive from '../../resources/images/attendancesActive.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { logoutStorage } from '../../utils/Auth';
import { MenuLink } from '../MenuLink';
import HistoryIcon from '../../resources/images/historyIcon.svg';

import './styles.scss';

type Props = {
  type: string;
};

export const Header = ({ type }: Props) => {
  const user = useSelector((state: RootState) => state.user);
  const { removeIsAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const basePath = `/${type}`;

  const getIsActive = (tabName: string) => {
    return location.pathname.includes(tabName);
  };

  const NavItems = () => {
    switch (type) {
      case 'doctor':
        return (
          <div className="menu-item-doctor">
            <Link to={`${basePath}/home`} style={{ textDecoration: 'none', marginRight: '20px' }}>
              <MenuLink
                logo={getIsActive('home') ? dashboardActive : Dashboard}
                title="Dashboard"
                isActive={getIsActive('home')}
              />
            </Link>
            <Link
              to={`${basePath}/attendances`}
              style={{ textDecoration: 'none', marginRight: '20px' }}
            >
              <MenuLink
                logo={getIsActive('attendances') ? attendancesActive : Attendances}
                title="Atendimentos"
                isActive={getIsActive('attendances')}
              />
            </Link>
            <Link to={`${basePath}/patients`} style={{ textDecoration: 'none' }}>
              <MenuLink
                logo={getIsActive('patients') ? usersActive : Users}
                title="Pacientes"
                isActive={getIsActive('patients')}
              />
            </Link>
            <Link
              to={`${basePath}/doctorschedule`}
              style={{ textDecoration: 'none', marginLeft: '10px' }}
            >
              <MenuLink
                logo={getIsActive('doctorschedule') ? usersActive : Users}
                title="Meus Horários"
                isActive={getIsActive('doctorschedule')}
              />
            </Link>
          </div>
        );
      case 'partner':
        return (
          <div className="menu-item-partner">
            <Link to={`${basePath}/home`} style={{ textDecoration: 'none', marginRight: '20px' }}>
              <MenuLink
                logo={getIsActive('home') ? dashboardActive : Dashboard}
                title="Dashboard"
                isActive={getIsActive('home')}
              />
            </Link>
            <Link
              to={`${basePath}/doctors`}
              style={{ textDecoration: 'none', marginRight: '20px' }}
            >
              <MenuLink
                logo={getIsActive('doctors') ? usersActive : Users}
                title="Médicos"
                isActive={getIsActive('doctors')}
              />
            </Link>
            <Link to={`${basePath}/patients`} style={{ textDecoration: 'none' }}>
              <MenuLink
                logo={getIsActive('patients') ? usersActive : Users}
                title="Pacientes"
                isActive={getIsActive('patients')}
              />
            </Link>
          </div>
        );
      case 'patient':
        return (
          <div className="menu-item-patient">
            <Link
              to={`${basePath}/patient-history/${user.id}`}
              style={{ textDecoration: 'none', marginRight: '20px' }}
            >
              <MenuLink
                logo={getIsActive('patient-history') ? HistoryIcon : HistoryIcon}
                title="Meu Histórico"
                isActive={getIsActive('patient-history')}
              />
            </Link>
            <Link
              to={`${basePath}/attendances`}
              style={{ textDecoration: 'none', marginRight: '20px' }}
            >
              <MenuLink
                logo={getIsActive('attendances') ? usersActive : Users}
                title="Meus atendimentos"
                isActive={getIsActive('attendances')}
              />
            </Link>
            <Link to={`${basePath}/schedules`} style={{ textDecoration: 'none' }}>
              <MenuLink
                logo={getIsActive('schedules') ? usersActive : Users}
                title="Agendar atendimento"
                isActive={getIsActive('schedules')}
              />
            </Link>
          </div>
        );
      case 'admin':
        return (
          <div className="menu-item-patient">
            <Link to={`${basePath}/home`} style={{ textDecoration: 'none', marginRight: '20px' }}>
              <MenuLink
                logo={getIsActive('home') ? dashboardActive : Dashboard}
                title="Dashboard"
                isActive={getIsActive('home')}
              />
            </Link>
            <Link
              to={`${basePath}/administrativepanel`}
              style={{ textDecoration: 'none', marginRight: '20px' }}
            >
              <MenuLink
                logo={getIsActive('schedules') ? usersActive : Users}
                title="Painel Administrativo"
                isActive={getIsActive('administrativepanel')}
              />
            </Link>
            <Link to={`${basePath}/users`} style={{ textDecoration: 'none' }}>
              <MenuLink
                logo={getIsActive('schedules') ? usersActive : Users}
                title="Usuários"
                isActive={getIsActive('users')}
              />
            </Link>
          </div>
        );
      default:
        return <></>;
    }
  };

  const DropdownMenuRight = () => {
    switch (type) {
      case 'doctor':
        return (
          <>
            <DropdownMenu
              left
              style={{
                backgroundColor: 'white',
                boxShadow: '0px 3px 4px 2px rgba(153, 164, 177, 0.25)',
                borderRadius: '0px 0px 6px 6px',
              }}
            >
              <DropdownItem className="drop-menu-item" onClick={onViewScheduleClick}>
                Meus Horários
              </DropdownItem>
              <DropdownItem className="drop-menu-item" onClick={onViewUpdatePasswordClick}>
                Alterar Senha
              </DropdownItem>
            </DropdownMenu>
          </>
        );
      case 'partner':
        return (
          <>
            <DropdownMenu left>
              <DropdownItem className="drop-menu-item" onClick={onViewUpdatePasswordClick}>
                Alterar Senha
              </DropdownItem>
            </DropdownMenu>
          </>
        );
      case 'patient':
        return (
          <>
            <DropdownMenu left>
              <DropdownItem className="drop-menu-item" onClick={onViewUpdatePasswordClick}>
                Alterar Senha
              </DropdownItem>
            </DropdownMenu>
          </>
        );
      case 'admin':
        return (
          <>
            <DropdownMenu left>
              <DropdownItem className="drop-menu-item" onClick={onViewUpdatePasswordClick}>
                Alterar Senha
              </DropdownItem>
            </DropdownMenu>
          </>
        );
      default:
        return <></>;
    }
  };

  const onViewUpdatePasswordClick = () => {
    history.push(`${basePath}/changepassword`);
  };

  const onViewScheduleClick = () => {
    history.push(`${basePath}/doctorschedule`);
  };

  const onLogOutClick = () => {
    logoutStorage();
    removeIsAuthenticated();

    history.push('/login');
  };

  return (
    <>
      <div className="header">
        <Navbar light color="light" expand>
          <NavbarBrand href="/" style={{ width: '25%', marginRight: 0 }}>
            <img
              src={TummiLogo}
              alt="Tummi Logo"
              style={{ width: '125px', height: '55px', marginLeft: '120px' }}
            />
          </NavbarBrand>
          <Nav navbar style={{ width: '70%' }}>
            <NavItems />
            <div
              style={{
                cursor: 'pointer',
                width: '20%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <UncontrolledDropdown nav inNavbar className="drop-no-arrow">
                <DropdownToggle nav caret>
                  <GenericAvatar imageClass="image-profile" />
                </DropdownToggle>
                <DropdownMenuRight />
              </UncontrolledDropdown>
              <Link to={`${basePath}/profile/${user.id}`} style={{ textDecoration: 'none' }}>
                <MenuLink title="Meu Perfil" isActive={getIsActive('profile')} />
              </Link>
            </div>
            <MenuLink
              logo={LeaveLogo}
              title="Sair"
              handleOnClick={onLogOutClick}
              style={{ justifyContent: 'start !important' }}
            />
          </Nav>
        </Navbar>
      </div>
    </>
  );
};
