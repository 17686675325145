export const CID = [
  {
    value: '001-057	Algumas doenças infecciosas e parasitárias	A00-B99',
    label: '001-057	Algumas doenças infecciosas e parasitárias	A00-B99',
  },
  { value: '1	Cólera	A00', label: '1	Cólera	A00' },
  { value: '2	Febres tifóide e paratifóide	A01', label: '2	Febres tifóide e paratifóide	A01' },
  { value: '3	Shiguelose	A03', label: '3	Shiguelose	A03' },
  { value: '4	Amebíase	A06', label: '4	Amebíase	A06' },
  {
    value: '5	Diarréia e gastroenterite de origem infecciosa presumível	A09',
    label: '5	Diarréia e gastroenterite de origem infecciosa presumível	A09',
  },
  {
    value: '6	Outras doenças infecciosas intestinais	A02, A04-A05, A07-A08',
    label: '6	Outras doenças infecciosas intestinais	A02, A04-A05, A07-A08',
  },
  { value: '7	Tuberculose respiratória	A15-A16', label: '7	Tuberculose respiratória	A15-A16' },
  {
    value: '007.1	Tuberculose pulmonar	A15.0-A15.3, A16.0-A16.3',
    label: '007.1	Tuberculose pulmonar	A15.0-A15.3, A16.0-A16.3',
  },
  {
    value: '007.2	Outras tuberculoses respiratórias	A15.4-A15.9, A16.4-A16.9',
    label: '007.2	Outras tuberculoses respiratórias	A15.4-A15.9, A16.4-A16.9',
  },
  { value: '8	Outras tuberculoses	A17-A19', label: '8	Outras tuberculoses	A17-A19' },
  {
    value: '008.1	Tuberculose do sistema nervoso	A17',
    label: '008.1	Tuberculose do sistema nervoso	A17',
  },
  {
    value: '008.2	Tuberculose do intestino, do peritônio e dos gânglios mesentéricos	A18.3',
    label: '008.2	Tuberculose do intestino, do peritônio e dos gânglios mesentéricos	A18.3',
  },
  {
    value: '008.3	Tuberculose óssea e das articulações	A18.0',
    label: '008.3	Tuberculose óssea e das articulações	A18.0',
  },
  {
    value: '008.4	Tuberculose do aparelho geniturinário	A18.1',
    label: '008.4	Tuberculose do aparelho geniturinário	A18.1',
  },
  { value: '008.5	Tuberculose miliar	A19', label: '008.5	Tuberculose miliar	A19' },
  {
    value: '008.9	Restante de outras tuberculoses	A18.2, A18.4-A18.8',
    label: '008.9	Restante de outras tuberculoses	A18.2, A18.4-A18.8',
  },
  { value: '9	Peste	A20', label: '9	Peste	A20' },
  { value: '10	Brucelose	A23', label: '10	Brucelose	A23' },
  { value: '11	Hanseníase [lepra]	A30', label: '11	Hanseníase [lepra]	A30' },
  { value: '12	Tétano neonatal	A33', label: '12	Tétano neonatal	A33' },
  { value: '13	Outros tétanos	A34-A35', label: '13	Outros tétanos	A34-A35' },
  { value: '14	Difteria	A36', label: '14	Difteria	A36' },
  { value: '15	Coqueluche	A37', label: '15	Coqueluche	A37' },
  { value: '16	Infecção meningocócica	A39', label: '16	Infecção meningocócica	A39' },
  { value: '17	Septicemia	A40-A41', label: '17	Septicemia	A40-A41' },
  {
    value: '18	Outras doenças bacterianas	A21-A22, A24-A28, A31-A32, A38, A42-A49',
    label: '18	Outras doenças bacterianas	A21-A22, A24-A28, A31-A32, A38, A42-A49',
  },
  {
    value: '018.1	Leptospirose icterohemorrágica	A27.0',
    label: '018.1	Leptospirose icterohemorrágica	A27.0',
  },
  {
    value: '018.2	Outras formas de leptospirose	A27.8',
    label: '018.2	Outras formas de leptospirose	A27.8',
  },
  {
    value: '018.3	Leptospirose não especificada	A27.9',
    label: '018.3	Leptospirose não especificada	A27.9',
  },
  {
    value:
      '018.9	Restante de outras doenças bacterianas	A21-A22, A24-A26, A28, A31-A32, A38, A42-A49',
    label:
      '018.9	Restante de outras doenças bacterianas	A21-A22, A24-A26, A28, A31-A32, A38, A42-A49',
  },
  { value: '19	Sífilis congênita	A50', label: '19	Sífilis congênita	A50' },
  { value: '20	Sífilis precoce	A51', label: '20	Sífilis precoce	A51' },
  { value: '21	Outras sífilis	A52-A53', label: '21	Outras sífilis	A52-A53' },
  { value: '22	Infecção gonocócica	A54', label: '22	Infecção gonocócica	A54' },
  {
    value: '23	Doenças por clamídias transmitidas por via sexual	A55-A56',
    label: '23	Doenças por clamídias transmitidas por via sexual	A55-A56',
  },
  {
    value: '24	Outras infecções com transmissão predominantemente sexual	A57-A64',
    label: '24	Outras infecções com transmissão predominantemente sexual	A57-A64',
  },
  { value: '25	Febres recorrentes	A68', label: '25	Febres recorrentes	A68' },
  { value: '26	Tracoma	A71', label: '26	Tracoma	A71' },
  { value: '27	Tifo exantemático	A75', label: '27	Tifo exantemático	A75' },
  { value: '28	Poliomielite aguda	A80', label: '28	Poliomielite aguda	A80' },
  { value: '29	Raiva	A82', label: '29	Raiva	A82' },
  { value: '30	Encefalite viral	A83-A86', label: '30	Encefalite viral	A83-A86' },
  { value: '31	Febre amarela	A95', label: '31	Febre amarela	A95' },
  {
    value: '32	Outras febre por arbovírus e febres hemorrágicas por vírus	A90-A94, A96-A99',
    label: '32	Outras febre por arbovírus e febres hemorrágicas por vírus	A90-A94, A96-A99',
  },
  { value: '032.1	Dengue [dengue clássico]	A90', label: '032.1	Dengue [dengue clássico]	A90' },
  {
    value: '032.2	Febre hemorrágica devida ao vírus da dengue	A91',
    label: '032.2	Febre hemorrágica devida ao vírus da dengue	A91',
  },
  {
    value:
      '032.9	Restante de outras febre por arbovírus e febres hemorrágicas por vírus	A92-A94, A96-A99',
    label:
      '032.9	Restante de outras febre por arbovírus e febres hemorrágicas por vírus	A92-A94, A96-A99',
  },
  { value: '33	Infecções pelo vírus do herpes	B00', label: '33	Infecções pelo vírus do herpes	B00' },
  { value: '34	Varicela e herpes zoster	B01-B02', label: '34	Varicela e herpes zoster	B01-B02' },
  { value: '35	Sarampo	B05', label: '35	Sarampo	B05' },
  { value: '36	Rubéola	B06', label: '36	Rubéola	B06' },
  { value: '37	Hepatite aguda B	B16', label: '37	Hepatite aguda B	B16' },
  {
    value: '38	Outras hepatites virais	B15, B17-B19',
    label: '38	Outras hepatites virais	B15, B17-B19',
  },
  {
    value: '39	Doença pelo vírus da imunodeficiência humana [HIV]	B20-B24',
    label: '39	Doença pelo vírus da imunodeficiência humana [HIV]	B20-B24',
  },
  { value: '40	Caxumba [parotidite epidêmica]	B26', label: '40	Caxumba [parotidite epidêmica]	B26' },
  {
    value: '41	Outras doenças virais	A81, A87-A89, B03-B04, B07-B09, B25, B27-B34',
    label: '41	Outras doenças virais	A81, A87-A89, B03-B04, B07-B09, B25, B27-B34',
  },
  { value: '041.1	Meningite viral	A87', label: '041.1	Meningite viral	A87' },
  {
    value: '041.9	Restante de outras doenças virais	A81, A88-A89, B03-B04, B07-B09, B25, B27-B34',
    label: '041.9	Restante de outras doenças virais	A81, A88-A89, B03-B04, B07-B09, B25, B27-B34',
  },
  { value: '42	Micoses	B35-B49', label: '42	Micoses	B35-B49' },
  { value: '43	Malária	B50-B54', label: '43	Malária	B50-B54' },
  {
    value: '043.1	Malária por Plasmodium falciparum	B50',
    label: '043.1	Malária por Plasmodium falciparum	B50',
  },
  { value: '043.2	Malária por Plasmodium vivax	B51', label: '043.2	Malária por Plasmodium vivax	B51' },
  {
    value: '043.3	Malária por Plasmodium malariae	B52',
    label: '043.3	Malária por Plasmodium malariae	B52',
  },
  {
    value: '043.4	Outras formas de malária confirmadas por exames parasitológicos	B53',
    label: '043.4	Outras formas de malária confirmadas por exames parasitológicos	B53',
  },
  { value: '043.5	Malária não especificada	B54', label: '043.5	Malária não especificada	B54' },
  { value: '44	Leishmaniose	B55', label: '44	Leishmaniose	B55' },
  { value: '044.1	Leishmaniose visceral	B55.0', label: '044.1	Leishmaniose visceral	B55.0' },
  { value: '044.2	Leishmaniose cutânea	B55.1', label: '044.2	Leishmaniose cutânea	B55.1' },
  {
    value: '044.3	Leishmaniose cutâneo-mucosa	B55.2',
    label: '044.3	Leishmaniose cutâneo-mucosa	B55.2',
  },
  {
    value: '044.4	Leishmaniose não especificada	B55.9',
    label: '044.4	Leishmaniose não especificada	B55.9',
  },
  { value: '45	Tripanossomíase	B56-B57', label: '45	Tripanossomíase	B56-B57' },
  { value: '46	Esquistossomose	B65', label: '46	Esquistossomose	B65' },
  {
    value: '47	Outras infestações por trematódeos	B66',
    label: '47	Outras infestações por trematódeos	B66',
  },
  { value: '48	Equinococose	B67', label: '48	Equinococose	B67' },
  { value: '49	Dracunculíase	B72', label: '49	Dracunculíase	B72' },
  { value: '50	Oncocercose	B73', label: '50	Oncocercose	B73' },
  { value: '51	Filariose	B74', label: '51	Filariose	B74' },
  { value: '52	Ancilostomíase	B76', label: '52	Ancilostomíase	B76' },
  {
    value: '53	Outras helmintíases	B68-B71, B75, B77-B83',
    label: '53	Outras helmintíases	B68-B71, B75, B77-B83',
  },
  { value: '54	Seqüelas de tuberculose	B90', label: '54	Seqüelas de tuberculose	B90' },
  { value: '55	Seqüelas de poliomielite	B91', label: '55	Seqüelas de poliomielite	B91' },
  { value: '56	Seqüelas de hanseníase [lepra]	B92', label: '56	Seqüelas de hanseníase [lepra]	B92' },
  {
    value:
      '57	Outras doenças infecciosas e parasitárias	A65-A67, A69-A70, A74, A77-A79, B58-B64, B85-B89, B94-B99',
    label:
      '57	Outras doenças infecciosas e parasitárias	A65-A67, A69-A70, A74, A77-A79, B58-B64, B85-B89, B94-B99',
  },
  {
    value: '58	Neoplasia maligna do lábio, cavidade oral e faringe	C00-C14',
    label: '58	Neoplasia maligna do lábio, cavidade oral e faringe	C00-C14',
  },
  { value: '59	Neoplasia maligna do esôfago	C15', label: '59	Neoplasia maligna do esôfago	C15' },
  { value: '60	Neoplasia maligna do estômago	C16', label: '60	Neoplasia maligna do estômago	C16' },
  { value: '61	Neoplasia maligna do cólon	C18', label: '61	Neoplasia maligna do cólon	C18' },
  {
    value: '62	Neoplasia maligna da junção retossigmóide, reto, ânus e canal anal	C19-C21',
    label: '62	Neoplasia maligna da junção retossigmóide, reto, ânus e canal anal	C19-C21',
  },
  {
    value: '63	Neoplasia maligna do fígado e das vias biliares intra-hepáticas	C22',
    label: '63	Neoplasia maligna do fígado e das vias biliares intra-hepáticas	C22',
  },
  { value: '64	Neoplasia maligna do pâncreas	C25', label: '64	Neoplasia maligna do pâncreas	C25' },
  {
    value: '65	Outras neoplasias malignas de órgãos digestivos	C17, C23-C24, C26',
    label: '65	Outras neoplasias malignas de órgãos digestivos	C17, C23-C24, C26',
  },
  { value: '66	Neoplasias malignas de laringe	C32', label: '66	Neoplasias malignas de laringe	C32' },
  {
    value: '67	Neoplasia maligna da traquéia, dos brônquios e dos pulmões	C33-C34',
    label: '67	Neoplasia maligna da traquéia, dos brônquios e dos pulmões	C33-C34',
  },
  {
    value: '68	Outras neoplasias malignas de órgãos respiratórios e intratorácicos	C30-C31, C37-C39',
    label: '68	Outras neoplasias malignas de órgãos respiratórios e intratorácicos	C30-C31, C37-C39',
  },
  {
    value: '69	Neoplasia maligna do osso e da cartilagem articular	C40-C41',
    label: '69	Neoplasia maligna do osso e da cartilagem articular	C40-C41',
  },
  { value: '70	Neoplasia maligna da pele	C43', label: '70	Neoplasia maligna da pele	C43' },
  {
    value: '71	Outras neoplasias malignas da pele	C44',
    label: '71	Outras neoplasias malignas da pele	C44',
  },
  {
    value: '72	Neoplasias malignas do tecido mesotelial e tecidos moles	C45-C49',
    label: '72	Neoplasias malignas do tecido mesotelial e tecidos moles	C45-C49',
  },
  { value: '73	Neoplasia maligna da mama	C50', label: '73	Neoplasia maligna da mama	C50' },
  {
    value: '74	Neoplasia maligna do colo do útero	C53',
    label: '74	Neoplasia maligna do colo do útero	C53',
  },
  {
    value: '75	Neoplasia maligna de outras porções e de porções não especificadas do útero	C54-C55',
    label: '75	Neoplasia maligna de outras porções e de porções não especificadas do útero	C54-C55',
  },
  {
    value: '76	Outras neoplasias malignas dos órgãos genitais femininos	C51-C52, C56-C58',
    label: '76	Outras neoplasias malignas dos órgãos genitais femininos	C51-C52, C56-C58',
  },
  { value: '77	Neoplasia maligna da próstata	C61', label: '77	Neoplasia maligna da próstata	C61' },
  {
    value: '78	Outras neoplasias malignas dos órgãos genitais masculinos	C60, C62-C63',
    label: '78	Outras neoplasias malignas dos órgãos genitais masculinos	C60, C62-C63',
  },
  { value: '79	Neoplasia maligna da bexiga	C67', label: '79	Neoplasia maligna da bexiga	C67' },
  {
    value: '80	Outras neoplasias malignas do trato urinário	C64-C66, C68',
    label: '80	Outras neoplasias malignas do trato urinário	C64-C66, C68',
  },
  {
    value: '81	Neoplasia maligna dos olhos e anexos	C69',
    label: '81	Neoplasia maligna dos olhos e anexos	C69',
  },
  { value: '82	Neoplasia maligna do encéfalo	C71', label: '82	Neoplasia maligna do encéfalo	C71' },
  {
    value: '83	Neoplasia maligna de outras partes do sistema nervoso central	C70, C72',
    label: '83	Neoplasia maligna de outras partes do sistema nervoso central	C70, C72',
  },
  {
    value:
      '84	Neoplasias malignas de outras localizações, de localização mal definida, secundárias e de localização não especificada	C73-C80, C97',
    label:
      '84	Neoplasias malignas de outras localizações, de localização mal definida, secundárias e de localização não especificada	C73-C80, C97',
  },
  { value: '85	Doença de Hodgkin	C81', label: '85	Doença de Hodgkin	C81' },
  { value: '86	Linfoma não-Hodgkin	C82-C85', label: '86	Linfoma não-Hodgkin	C82-C85' },
  { value: '87	Leucemia	C91-C95', label: '87	Leucemia	C91-C95' },
  {
    value:
      '88	Outras neoplasias malignas de tecidos linfóide, hematopoético e relacionados	C88-C90, C96',
    label:
      '88	Outras neoplasias malignas de tecidos linfóide, hematopoético e relacionados	C88-C90, C96',
  },
  {
    value: '89	Carcinoma in situ de colo do útero	D06',
    label: '89	Carcinoma in situ de colo do útero	D06',
  },
  { value: '90	Neoplasia benigna da pele	D22-D23', label: '90	Neoplasia benigna da pele	D22-D23' },
  { value: '91	Neoplasia benigna da mama	D24', label: '91	Neoplasia benigna da mama	D24' },
  { value: '92	Leiomioma do útero	D25', label: '92	Leiomioma do útero	D25' },
  { value: '93	Neoplasia benigna do ovário	D27', label: '93	Neoplasia benigna do ovário	D27' },
  {
    value: '94	Neoplasia benigna dos órgãos urinários	D30',
    label: '94	Neoplasia benigna dos órgãos urinários	D30',
  },
  {
    value: '95	Neoplasia benigna do encéfalo e de outras partes do sistema nervoso central	D33',
    label: '95	Neoplasia benigna do encéfalo e de outras partes do sistema nervoso central	D33',
  },
  {
    value:
      '96	Outras neoplasias in situ e neoplasias benignas e neoplasias de comportamento incerto ou desconhecido	D00-D05, D07-D21, D26, D28-D29, D31-D32, D34-D48',
    label:
      '96	Outras neoplasias in situ e neoplasias benignas e neoplasias de comportamento incerto ou desconhecido	D00-D05, D07-D21, D26, D28-D29, D31-D32, D34-D48',
  },
  { value: '97	Anemia por deficiência de ferro	D50', label: '97	Anemia por deficiência de ferro	D50' },
  { value: '98	Outras anemias	D51-D64', label: '98	Outras anemias	D51-D64' },
  {
    value: '99	Afecções hemorrágicas e outras doenças do sangue e dos órgãos hematopoéticos	D65-D77',
    label: '99	Afecções hemorrágicas e outras doenças do sangue e dos órgãos hematopoéticos	D65-D77',
  },
  {
    value: '100	Alguns transtornos envolvendo o mecanismo imunitário	D80-D89',
    label: '100	Alguns transtornos envolvendo o mecanismo imunitário	D80-D89',
  },
  {
    value: '101	Transtornos tireoidianos relacionados à deficiência de iodo	E00-E02',
    label: '101	Transtornos tireoidianos relacionados à deficiência de iodo	E00-E02',
  },
  { value: '102	Tireotoxicose	E05', label: '102	Tireotoxicose	E05' },
  {
    value: '103	Outros transtornos tireoidianos	E03-E04, E06-E07',
    label: '103	Outros transtornos tireoidianos	E03-E04, E06-E07',
  },
  { value: '104	Diabetes mellitus	E10-E14', label: '104	Diabetes mellitus	E10-E14' },
  { value: '105	Desnutrição	E40-E46', label: '105	Desnutrição	E40-E46' },
  { value: '106	Deficiência de vitamina A	E50', label: '106	Deficiência de vitamina A	E50' },
  {
    value: '107	Outras deficiências vitamínicas	E51-E56',
    label: '107	Outras deficiências vitamínicas	E51-E56',
  },
  {
    value: '108	Seqüelas de desnutrição e de outras deficiências nutricionais	E64',
    label: '108	Seqüelas de desnutrição e de outras deficiências nutricionais	E64',
  },
  { value: '109	Obesidade	E66', label: '109	Obesidade	E66' },
  { value: '110	Depleção de volume	E86', label: '110	Depleção de volume	E86' },
  {
    value:
      '111	Outros transtornos endócrinos, nutricionais e metabólicos	E15-E35, E58-E63, E65, E67-E85, E87-E90',
    label:
      '111	Outros transtornos endócrinos, nutricionais e metabólicos	E15-E35, E58-E63, E65, E67-E85, E87-E90',
  },
  { value: '112	Demência	F00-F03', label: '112	Demência	F00-F03' },
  {
    value: '113	Transtornos mentais e comportamentais devidos ao uso de álcool	F10',
    label: '113	Transtornos mentais e comportamentais devidos ao uso de álcool	F10',
  },
  {
    value:
      '114	Transtornos mentais e comportamentais devidos ao uso de outras substâncias psicoativas	F11-F19',
    label:
      '114	Transtornos mentais e comportamentais devidos ao uso de outras substâncias psicoativas	F11-F19',
  },
  {
    value: '115	Esquizofrenia, transtornos esquizotípicos e delirantes	F20-F29',
    label: '115	Esquizofrenia, transtornos esquizotípicos e delirantes	F20-F29',
  },
  {
    value: '116	Transtornos de humor [afetivos]	F30-F39',
    label: '116	Transtornos de humor [afetivos]	F30-F39',
  },
  {
    value:
      '117	Transtornos neuróticos, transtornos relacionados com o "stress" e transtornos somatoformes	F40-F48',
    label:
      '117	Transtornos neuróticos, transtornos relacionados com o "stress" e transtornos somatoformes	F40-F48',
  },
  { value: '118	Retardo mental	F70-F79', label: '118	Retardo mental	F70-F79' },
  {
    value: '119	Outros transtornos mentais e comportamentais	F04-F09, F50-F69, F80-F99',
    label: '119	Outros transtornos mentais e comportamentais	F04-F09, F50-F69, F80-F99',
  },
  {
    value: '120	Doenças inflamatórias do sistema nervoso central	G00-G09',
    label: '120	Doenças inflamatórias do sistema nervoso central	G00-G09',
  },
  {
    value: '120.1	Meningite bacteriana, não classificada em outra parte	G00',
    label: '120.1	Meningite bacteriana, não classificada em outra parte	G00',
  },
  {
    value: '120.2	Meningite em doenças bacterianas classificadas em outra parte	G01',
    label: '120.2	Meningite em doenças bacterianas classificadas em outra parte	G01',
  },
  {
    value:
      '120.3	Meningite em outras doenças infecciosas e parasitárias classificadas em outra parte	G02',
    label:
      '120.3	Meningite em outras doenças infecciosas e parasitárias classificadas em outra parte	G02',
  },
  {
    value: '120.4	Meningite devida a outras causas e a causas não especificadas	G03',
    label: '120.4	Meningite devida a outras causas e a causas não especificadas	G03',
  },
  {
    value: '120.9	Restante de doenças inflamatórias do sistema nervoso central	G04-G09',
    label: '120.9	Restante de doenças inflamatórias do sistema nervoso central	G04-G09',
  },
  { value: '121	Doença de Parkinson	G20', label: '121	Doença de Parkinson	G20' },
  { value: '122	Doença de Alzheimer	G30', label: '122	Doença de Alzheimer	G30' },
  { value: '123	Esclerose múltiplas	G35', label: '123	Esclerose múltiplas	G35' },
  { value: '124	Epilepsia	G40-G41', label: '124	Epilepsia	G40-G41' },
  {
    value: '125	Enxaqueca e outras síndromes de algias cefálicos	G43-G44',
    label: '125	Enxaqueca e outras síndromes de algias cefálicos	G43-G44',
  },
  {
    value: '126	Acidentes vasculares cerebrais isquêmicos transitórios e síndromes correlatas	G45',
    label: '126	Acidentes vasculares cerebrais isquêmicos transitórios e síndromes correlatas	G45',
  },
  {
    value: '127	Transtornos dos nervos, das raízes e dos plexos nervosos	G50-G59',
    label: '127	Transtornos dos nervos, das raízes e dos plexos nervosos	G50-G59',
  },
  {
    value: '128	Paralisia cerebral e outras síndromes paralíticas	G80-G83',
    label: '128	Paralisia cerebral e outras síndromes paralíticas	G80-G83',
  },
  {
    value:
      '129	Outras doenças do sistema nervoso	G10-G13, G21-G26, G31-G32, G36-G37, G46-G47, G60-G73, G90-G99',
    label:
      '129	Outras doenças do sistema nervoso	G10-G13, G21-G26, G31-G32, G36-G37, G46-G47, G60-G73, G90-G99',
  },
  { value: '130	Inflamação da pálpebra	H00-H01', label: '130	Inflamação da pálpebra	H00-H01' },
  {
    value: '131	Conjuntivite e outros transtornos da conjuntiva	H10-H13',
    label: '131	Conjuntivite e outros transtornos da conjuntiva	H10-H13',
  },
  {
    value: '132	Ceratite e outros transtornos da esclerótica e da córnea	H15-H19',
    label: '132	Ceratite e outros transtornos da esclerótica e da córnea	H15-H19',
  },
  {
    value: '133	Catarata e outros transtornos do cristalino	H25-H28',
    label: '133	Catarata e outros transtornos do cristalino	H25-H28',
  },
  {
    value: '134	Descolamentos e defeitos da retina	H33',
    label: '134	Descolamentos e defeitos da retina	H33',
  },
  { value: '135	Glaucoma	H40-H42', label: '135	Glaucoma	H40-H42' },
  { value: '136	Estrabismo	H49-H50', label: '136	Estrabismo	H49-H50' },
  {
    value: '137	Transtornos da refração e da acomodação	H52',
    label: '137	Transtornos da refração e da acomodação	H52',
  },
  { value: '138	Cegueira e visão subnormal	H54', label: '138	Cegueira e visão subnormal	H54' },
  {
    value:
      '139	Outras doenças do olho e anexos	H02-H06, H20-H22, H30-H32, H34-H36, H43-H48, H51, H53, H55-H59',
    label:
      '139	Outras doenças do olho e anexos	H02-H06, H20-H22, H30-H32, H34-H36, H43-H48, H51, H53, H55-H59',
  },
  {
    value: '140	Otite média e outros transtornos do ouvido médio e da apófise mastóide	H65-H75',
    label: '140	Otite média e outros transtornos do ouvido médio e da apófise mastóide	H65-H75',
  },
  { value: '141	Perda de audição	H90-H91', label: '141	Perda de audição	H90-H91' },
  {
    value: '142	Outras doenças do ouvido e da apófise mastóide	H60-H62, H80-H83, H92-H95',
    label: '142	Outras doenças do ouvido e da apófise mastóide	H60-H62, H80-H83, H92-H95',
  },
  { value: '143	Febre reumática aguda	I00-I02', label: '143	Febre reumática aguda	I00-I02' },
  {
    value: '144	Doença reumática crônica do coração	I05-I09',
    label: '144	Doença reumática crônica do coração	I05-I09',
  },
  {
    value: '145	Hipertensão essencial (primária)	I10',
    label: '145	Hipertensão essencial (primária)	I10',
  },
  {
    value: '146	Outras doenças hipertensivas	I11-I15',
    label: '146	Outras doenças hipertensivas	I11-I15',
  },
  { value: '147	Infarto agudo do miocárdio	I21-I22', label: '147	Infarto agudo do miocárdio	I21-I22' },
  {
    value: '148	Outras doenças isquêmicas do coração	I20, I23-I25',
    label: '148	Outras doenças isquêmicas do coração	I20, I23-I25',
  },
  { value: '149	Embolia pulmonar	I26', label: '149	Embolia pulmonar	I26' },
  {
    value: '150	Transtornos de condução e arritmias cardíacas	I44-I49',
    label: '150	Transtornos de condução e arritmias cardíacas	I44-I49',
  },
  { value: '151	Insuficiência cardíaca	I50', label: '151	Insuficiência cardíaca	I50' },
  {
    value: '152	Outras doenças do coração	I27-I43, I51-I52',
    label: '152	Outras doenças do coração	I27-I43, I51-I52',
  },
  { value: '153	Hemorragia intracraniana	I60-I62', label: '153	Hemorragia intracraniana	I60-I62' },
  { value: '154	Infarto cerebral	I63', label: '154	Infarto cerebral	I63' },
  {
    value: '155	Acidente vascular cerebral, não especificado como hemorrágico ou isquêmico	I64',
    label: '155	Acidente vascular cerebral, não especificado como hemorrágico ou isquêmico	I64',
  },
  {
    value: '156	Outras doenças cerebrovasculares	I65-I69',
    label: '156	Outras doenças cerebrovasculares	I65-I69',
  },
  { value: '157	Ateroesclerose	I70', label: '157	Ateroesclerose	I70' },
  {
    value: '158	Outras doenças vasculares periféricas	I73',
    label: '158	Outras doenças vasculares periféricas	I73',
  },
  { value: '159	Embolia e trombose arteriais	I74', label: '159	Embolia e trombose arteriais	I74' },
  {
    value: '160	Outras doenças das artérias, arteríolas e capilares	I71-I72, I77-I79',
    label: '160	Outras doenças das artérias, arteríolas e capilares	I71-I72, I77-I79',
  },
  {
    value: '161	Flebite, tromboflebite, embolia e trombose venosa	I80-I82',
    label: '161	Flebite, tromboflebite, embolia e trombose venosa	I80-I82',
  },
  {
    value: '162	Veias varicosas das extremidades inferiores	I83',
    label: '162	Veias varicosas das extremidades inferiores	I83',
  },
  { value: '163	Hemorróidas	I84', label: '163	Hemorróidas	I84' },
  {
    value: '164	Outras doenças do aparelho circulatório	I85-I99',
    label: '164	Outras doenças do aparelho circulatório	I85-I99',
  },
  {
    value: '165	Faringite aguda e amigdalite aguda	J02-J03',
    label: '165	Faringite aguda e amigdalite aguda	J02-J03',
  },
  { value: '166	Laringite e traqueíte agudas	J04', label: '166	Laringite e traqueíte agudas	J04' },
  {
    value: '167	Outras infecções agudas das vias aéreas superiores	J00-J01, J05-J06',
    label: '167	Outras infecções agudas das vias aéreas superiores	J00-J01, J05-J06',
  },
  { value: '168	Influenza [gripe]	J09-J11', label: '168	Influenza [gripe]	J09-J11' },
  { value: '169	Pneumonia	J12-J18', label: '169	Pneumonia	J12-J18' },
  {
    value: '170	Bronquite aguda e bronquiolite aguda	J20-J21',
    label: '170	Bronquite aguda e bronquiolite aguda	J20-J21',
  },
  { value: '171	Sinusite crônica	J32', label: '171	Sinusite crônica	J32' },
  {
    value: '172	Outras doenças do nariz e dos seios paranasais	J30-J31, J33-J34',
    label: '172	Outras doenças do nariz e dos seios paranasais	J30-J31, J33-J34',
  },
  {
    value: '173	Doenças crônicas das amígdalas e das adenóides	J35',
    label: '173	Doenças crônicas das amígdalas e das adenóides	J35',
  },
  {
    value: '174	Outras doenças do trato respiratório superior	J36-J39',
    label: '174	Outras doenças do trato respiratório superior	J36-J39',
  },
  {
    value: '175	Bronquite, enfisema e outras doenças pulmonares obstrutivas crônicas	J40-J44',
    label: '175	Bronquite, enfisema e outras doenças pulmonares obstrutivas crônicas	J40-J44',
  },
  { value: '176	Asma	J45-J46', label: '176	Asma	J45-J46' },
  { value: '177	Bronquiectasia	J47', label: '177	Bronquiectasia	J47' },
  { value: '178	Pneumoconiose	J60-J65', label: '178	Pneumoconiose	J60-J65' },
  {
    value: '179	Outras doenças do aparelho respiratório	J22, J66-J99',
    label: '179	Outras doenças do aparelho respiratório	J22, J66-J99',
  },
  { value: '180	Cárie dentária	K02', label: '180	Cárie dentária	K02' },
  {
    value: '181	Outros transtornos dos dentes e estruturas de suporte	K00-K01, K03-K08',
    label: '181	Outros transtornos dos dentes e estruturas de suporte	K00-K01, K03-K08',
  },
  {
    value: '182	Outras doenças da cavidade oral, glândulas salivares e dos maxilares	K09-K14',
    label: '182	Outras doenças da cavidade oral, glândulas salivares e dos maxilares	K09-K14',
  },
  { value: '183	Úlcera gástrica e duodenal	K25-K27', label: '183	Úlcera gástrica e duodenal	K25-K27' },
  { value: '184	Gastrite e duodenite	K29', label: '184	Gastrite e duodenite	K29' },
  {
    value: '185	Outras doenças do esôfago, estômago e duodeno	K20-K23, K28, K30-K31',
    label: '185	Outras doenças do esôfago, estômago e duodeno	K20-K23, K28, K30-K31',
  },
  { value: '186	Doenças do apêndice	K35-K38', label: '186	Doenças do apêndice	K35-K38' },
  { value: '187	Hérnia inguinal	K40', label: '187	Hérnia inguinal	K40' },
  { value: '188	Outras hérnias	K41-K46', label: '188	Outras hérnias	K41-K46' },
  {
    value: '189	Doença de Crohn e colite ulcerativa	K50-K51',
    label: '189	Doença de Crohn e colite ulcerativa	K50-K51',
  },
  {
    value: '190	Íleo paralítico e obstrução intestinal sem hérnia	K56',
    label: '190	Íleo paralítico e obstrução intestinal sem hérnia	K56',
  },
  {
    value: '191	Doença diverticular do intestino	K57',
    label: '191	Doença diverticular do intestino	K57',
  },
  {
    value: '192	Outras doenças dos intestinos e peritônio	K52-K55, K58-K67',
    label: '192	Outras doenças dos intestinos e peritônio	K52-K55, K58-K67',
  },
  { value: '193	Doença alcoólica do fígado	K70', label: '193	Doença alcoólica do fígado	K70' },
  { value: '194	Outras doenças do fígado	K71-K77', label: '194	Outras doenças do fígado	K71-K77' },
  { value: '195	Colelitíase e colecistite	K80-K81', label: '195	Colelitíase e colecistite	K80-K81' },
  {
    value: '196	Pancreatite aguda e outras doenças do pâncreas	K85-K86',
    label: '196	Pancreatite aguda e outras doenças do pâncreas	K85-K86',
  },
  {
    value: '197	Outras doenças do aparelho digestivo	K82-K83, K87-K93',
    label: '197	Outras doenças do aparelho digestivo	K82-K83, K87-K93',
  },
  {
    value: '198	Infecções da pele e do tecido subcutâneo	L00-L08',
    label: '198	Infecções da pele e do tecido subcutâneo	L00-L08',
  },
  {
    value: '199	Outras doenças da pele e do tecido subcutâneo	L10-L99',
    label: '199	Outras doenças da pele e do tecido subcutâneo	L10-L99',
  },
  {
    value: '200	Artrite reumatóide e outras poliartropatias inflamatórias	M05-M14',
    label: '200	Artrite reumatóide e outras poliartropatias inflamatórias	M05-M14',
  },
  { value: '201	Artrose	M15-M19', label: '201	Artrose	M15-M19' },
  {
    value: '202	Deformidades adquiridas das articulações	M20-M21',
    label: '202	Deformidades adquiridas das articulações	M20-M21',
  },
  {
    value: '203	Outros transtronos articulares	M00-M03, M22-M25',
    label: '203	Outros transtronos articulares	M00-M03, M22-M25',
  },
  {
    value: '204	Doenças sistêmicas do tecido conjuntivo	M30-M36',
    label: '204	Doenças sistêmicas do tecido conjuntivo	M30-M36',
  },
  {
    value: '205	Transtornos discais cervicais e outros transtornos discais intervertebrais	M50-M51',
    label: '205	Transtornos discais cervicais e outros transtornos discais intervertebrais	M50-M51',
  },
  {
    value: '206	Outras dorsopatias	M40-M49, M53-M54',
    label: '206	Outras dorsopatias	M40-M49, M53-M54',
  },
  { value: '207	Transtornos do tecido mole	M60-M79', label: '207	Transtornos do tecido mole	M60-M79' },
  {
    value: '208	Transtornos da densidade e da estrutura ósseas	M80-M85',
    label: '208	Transtornos da densidade e da estrutura ósseas	M80-M85',
  },
  { value: '209	Osteomielite	M86', label: '209	Osteomielite	M86' },
  {
    value: '210	Outras doenças do sistema osteomuscular e do tecido conjuntivo	M87-M99',
    label: '210	Outras doenças do sistema osteomuscular e do tecido conjuntivo	M87-M99',
  },
  {
    value: '211	Síndrome nefríticas aguda e rapidamente progressiva	N00-N01',
    label: '211	Síndrome nefríticas aguda e rapidamente progressiva	N00-N01',
  },
  {
    value: '212	Outras doenças glomerulares	N02-N08',
    label: '212	Outras doenças glomerulares	N02-N08',
  },
  {
    value: '213	Doenças renais túbulo-intersticiais	N10-N16',
    label: '213	Doenças renais túbulo-intersticiais	N10-N16',
  },
  { value: '214	Insuficiência renal	N17-N19', label: '214	Insuficiência renal	N17-N19' },
  { value: '215	Urolitíase	N20-N23', label: '215	Urolitíase	N20-N23' },
  { value: '216	Cistite	N30', label: '216	Cistite	N30' },
  {
    value: '217	Outras doenças do aparelho urinário	N25-N29, N31-N39',
    label: '217	Outras doenças do aparelho urinário	N25-N29, N31-N39',
  },
  { value: '218	Hiperplasia da próstata	N40', label: '218	Hiperplasia da próstata	N40' },
  {
    value: '219	Outros transtornos da próstata	N41-N42',
    label: '219	Outros transtornos da próstata	N41-N42',
  },
  { value: '220	Hidrocele e espermatocele	N43', label: '220	Hidrocele e espermatocele	N43' },
  {
    value: '221	Preprúcio redundante, fimose e parafimose	N47',
    label: '221	Preprúcio redundante, fimose e parafimose	N47',
  },
  {
    value: '222	Outras doenças dos órgãos genitais masculinos	N44-N46, N48-N51',
    label: '222	Outras doenças dos órgãos genitais masculinos	N44-N46, N48-N51',
  },
  { value: '223	Transtornos da mama	N60-N64', label: '223	Transtornos da mama	N60-N64' },
  { value: '224	Salpingite e ooforite	N70', label: '224	Salpingite e ooforite	N70' },
  {
    value: '225	Doença inflamatória do colo do útero	N72',
    label: '225	Doença inflamatória do colo do útero	N72',
  },
  {
    value: '226	Outras doenças inflamatórias dos órgãos pélvicos femininos	N71, N73-N77',
    label: '226	Outras doenças inflamatórias dos órgãos pélvicos femininos	N71, N73-N77',
  },
  { value: '227	Endometriose	N80', label: '227	Endometriose	N80' },
  { value: '228	Prolapso genital feminino	N81', label: '228	Prolapso genital feminino	N81' },
  {
    value:
      '229	Transtornos não-inflamatórios do ovário, da trompa de Falópio e do ligamento largo	N83',
    label:
      '229	Transtornos não-inflamatórios do ovário, da trompa de Falópio e do ligamento largo	N83',
  },
  { value: '230	Transtornos da menstruação	N91-N92', label: '230	Transtornos da menstruação	N91-N92' },
  {
    value: '231	Transtornos menopáusicos e outros transtornos perimenopáusicos	N95',
    label: '231	Transtornos menopáusicos e outros transtornos perimenopáusicos	N95',
  },
  { value: '232	Infertilidade feminina	N97', label: '232	Infertilidade feminina	N97' },
  {
    value: '233	Outros transtornos do aparelho geniturinário	N82, N84-N90, N93-N94, N96, N98-N99',
    label: '233	Outros transtornos do aparelho geniturinário	N82, N84-N90, N93-N94, N96, N98-N99',
  },
  { value: '234	Aborto espontâneo	O03', label: '234	Aborto espontâneo	O03' },
  { value: '235	Aborto por razões médicas	O04', label: '235	Aborto por razões médicas	O04' },
  {
    value: '236	Outras gravidezes que terminam em aborto	O00-O02, O05-O08',
    label: '236	Outras gravidezes que terminam em aborto	O00-O02, O05-O08',
  },
  {
    value:
      '237	Edema, proteinúria e transtornos hipertensivos na gravidez, parto e puerpério	O10-O16',
    label:
      '237	Edema, proteinúria e transtornos hipertensivos na gravidez, parto e puerpério	O10-O16',
  },
  {
    value: '238	Placenta prévia, descolamento prematuro de placenta e hemorragia anteparto	O44-O46',
    label: '238	Placenta prévia, descolamento prematuro de placenta e hemorragia anteparto	O44-O46',
  },
  {
    value:
      '239	Outros motivos de assistência à mãe relacionados à cavidade fetal e amniótica, e possíveis problemas de parto	O30-O43, O47-O48',
    label:
      '239	Outros motivos de assistência à mãe relacionados à cavidade fetal e amniótica, e possíveis problemas de parto	O30-O43, O47-O48',
  },
  {
    value: '240	Trabalho de parto obstruído	O64-O66',
    label: '240	Trabalho de parto obstruído	O64-O66',
  },
  { value: '241	Hemorragia pós-parto	O72', label: '241	Hemorragia pós-parto	O72' },
  {
    value:
      '242	Outras complicações da gravidez e do parto	O20-O29, O60-O63, O67-O71, O73-O75, O81-O84',
    label:
      '242	Outras complicações da gravidez e do parto	O20-O29, O60-O63, O67-O71, O73-O75, O81-O84',
  },
  { value: '243	Parto único espontâneo	O80', label: '243	Parto único espontâneo	O80' },
  {
    value:
      '244	Complicações predominantemente relacionadas ao puerpério e outras afecções obstétricas, não classificadas em outra parte	O85-O99',
    label:
      '244	Complicações predominantemente relacionadas ao puerpério e outras afecções obstétricas, não classificadas em outra parte	O85-O99',
  },
  {
    value:
      '245	Feto e recém-nascido afetados por fatores maternos e por complicações da gravidez, trabalho de parto e parto	P00-P04',
    label:
      '245	Feto e recém-nascido afetados por fatores maternos e por complicações da gravidez, trabalho de parto e parto	P00-P04',
  },
  {
    value:
      '246	Retardo de crescimento fetal, desnutrição fetal e transtornos relacionados à gestação curta e baixo peso ao nascer	P05-P07',
    label:
      '246	Retardo de crescimento fetal, desnutrição fetal e transtornos relacionados à gestação curta e baixo peso ao nascer	P05-P07',
  },
  {
    value: '247	Trauma durante o nascimento	P10-P15',
    label: '247	Trauma durante o nascimento	P10-P15',
  },
  {
    value: '248	Hipóxia intrauterina e asfixia ao nascer	P20-P21',
    label: '248	Hipóxia intrauterina e asfixia ao nascer	P20-P21',
  },
  {
    value: '249	Outros transtornos respiratórios originados no período perinatal	P22-P28',
    label: '249	Outros transtornos respiratórios originados no período perinatal	P22-P28',
  },
  {
    value: '250	Doenças infecciosas e parasitárias congênitas	P35-P37',
    label: '250	Doenças infecciosas e parasitárias congênitas	P35-P37',
  },
  {
    value: '251	Outras infecções específicas do período perinatal	P38-P39',
    label: '251	Outras infecções específicas do período perinatal	P38-P39',
  },
  {
    value: '252	Doença hemolítica do feto e do recém-nascido	P55',
    label: '252	Doença hemolítica do feto e do recém-nascido	P55',
  },
  {
    value: '253	Outras afecções originadas no período perinatal	P08, P29, P50-P54, P56-P96',
    label: '253	Outras afecções originadas no período perinatal	P08, P29, P50-P54, P56-P96',
  },
  { value: '254	Espinha bífida	Q05', label: '254	Espinha bífida	Q05' },
  {
    value: '255	Outras malformações congênitas do sistema nervoso	Q00-Q04, Q06-Q07',
    label: '255	Outras malformações congênitas do sistema nervoso	Q00-Q04, Q06-Q07',
  },
  {
    value: '256	Malformações congênitas do aparelho circulatório	Q20-Q28',
    label: '256	Malformações congênitas do aparelho circulatório	Q20-Q28',
  },
  {
    value: '257	Fenda labial e fenda palatina	Q35-Q37',
    label: '257	Fenda labial e fenda palatina	Q35-Q37',
  },
  {
    value: '258	Ausência, atresia e estenose do intestino delgado	Q41',
    label: '258	Ausência, atresia e estenose do intestino delgado	Q41',
  },
  {
    value: '259	Outras malformações congênitas do aparelho digestivo	Q38-Q40, Q42-Q45',
    label: '259	Outras malformações congênitas do aparelho digestivo	Q38-Q40, Q42-Q45',
  },
  { value: '260	Testiculo não-descido	Q53', label: '260	Testiculo não-descido	Q53' },
  {
    value: '261	Outras malformações do aparelho geniturinário	Q50-Q52, Q54-Q64',
    label: '261	Outras malformações do aparelho geniturinário	Q50-Q52, Q54-Q64',
  },
  {
    value: '262	Deformidades congênitas do quadril	Q65',
    label: '262	Deformidades congênitas do quadril	Q65',
  },
  {
    value: '263	Deformidades congênitas dos pés	Q66',
    label: '263	Deformidades congênitas dos pés	Q66',
  },
  {
    value: '264	Outras malformações e deformidades congênitas do aparelho osteomuscular	Q67-Q79',
    label: '264	Outras malformações e deformidades congênitas do aparelho osteomuscular	Q67-Q79',
  },
  {
    value: '265	Outras malformações congênitas	Q10-Q18, Q30-Q34, Q80-Q89',
    label: '265	Outras malformações congênitas	Q10-Q18, Q30-Q34, Q80-Q89',
  },
  {
    value: '266	Anomalias cromossômicas, não classificadas em outra parte	Q90-Q99',
    label: '266	Anomalias cromossômicas, não classificadas em outra parte	Q90-Q99',
  },
  { value: '267	Dor abdominal e pélvica	R10', label: '267	Dor abdominal e pélvica	R10' },
  { value: '268	Febre de origem desconhecida	R50', label: '268	Febre de origem desconhecida	R50' },
  { value: '269	Senilidade	R54', label: '269	Senilidade	R54' },
  {
    value:
      '270	Outros sintomas, sinais e achados anormais de exames clínicos e de laboratório, não classificados em outra parte	R00-R09, R11-R49, R51-R53, R55-R99',
    label:
      '270	Outros sintomas, sinais e achados anormais de exames clínicos e de laboratório, não classificados em outra parte	R00-R09, R11-R49, R51-R53, R55-R99',
  },
  {
    value:
      'XIX	271-289	Lesões, envenenamentos e algumas outras conseqüências de causas externas	S00-T98',
    label:
      'XIX	271-289	Lesões, envenenamentos e algumas outras conseqüências de causas externas	S00-T98',
  },
  {
    value: '271	Fratura do crânio e dos ossos da face	S02',
    label: '271	Fratura do crânio e dos ossos da face	S02',
  },
  {
    value: '272	Fratura do pescoço, tórax ou pelve	S12, S22, S32, T08',
    label: '272	Fratura do pescoço, tórax ou pelve	S12, S22, S32, T08',
  },
  { value: '273	Fratura do fêmur	S72', label: '273	Fratura do fêmur	S72' },
  {
    value: '274	Fratura de outros ossos dos membros	S42, S52, S62, S82, S92, T10, T12',
    label: '274	Fratura de outros ossos dos membros	S42, S52, S62, S82, S92, T10, T12',
  },
  {
    value: '275	Fraturas envolvendo múltiplas regiões do corpo	T02',
    label: '275	Fraturas envolvendo múltiplas regiões do corpo	T02',
  },
  {
    value:
      '276	Luxações, entorse e distensão de regiões especificadas e de regiões múltiplas do corpo	S03, S13, S23, S33, S43, S53, S63, S73, S83, S93, T03',
    label:
      '276	Luxações, entorse e distensão de regiões especificadas e de regiões múltiplas do corpo	S03, S13, S23, S33, S43, S53, S63, S73, S83, S93, T03',
  },
  {
    value: '277	Traumatismo do olho e da órbita ocular	S05',
    label: '277	Traumatismo do olho e da órbita ocular	S05',
  },
  { value: '278	Traumatismo intracraniano	S06', label: '278	Traumatismo intracraniano	S06' },
  {
    value: '279	Traumatismo de outros órgãos internos	S26-S27, S36-S37',
    label: '279	Traumatismo de outros órgãos internos	S26-S27, S36-S37',
  },
  {
    value:
      '280	Lesões por esmagamento e amputações traumáticas de regiões especificadas e de múltiplas regiões do corpo	S07-S08, S17-S18, S28, S38, S47-S48, S57-S58, S67-S68, S77-S78, S87-S88, S97-S98, T04-T05',
    label:
      '280	Lesões por esmagamento e amputações traumáticas de regiões especificadas e de múltiplas regiões do corpo	S07-S08, S17-S18, S28, S38, S47-S48, S57-S58, S67-S68, S77-S78, S87-S88, S97-S98, T04-T05',
  },
  {
    value:
      '281	Outros traumatismos de regiões especificadas e não especificadas e de regiões múltiplas do corpo	S00-S01, S04, S09-S11, S14-S16, S19-S21, S24-S25, S29-S31, S34-S35, S39-S41, S44-S46, S49-S51, S54-S56, S59-S61, S64-S66, S69-S71, S74-S76, S79-S81, S84-S86, S89-S91, S94-S96, S99, T00-T01, T06-T07, T09, T11, T13-T14',
    label:
      '281	Outros traumatismos de regiões especificadas e não especificadas e de regiões múltiplas do corpo	S00-S01, S04, S09-S11, S14-S16, S19-S21, S24-S25, S29-S31, S34-S35, S39-S41, S44-S46, S49-S51, S54-S56, S59-S61, S64-S66, S69-S71, S74-S76, S79-S81, S84-S86, S89-S91, S94-S96, S99, T00-T01, T06-T07, T09, T11, T13-T14',
  },
  {
    value: '282	Efeitos de corpo estranho que entra através de orifício natural	T15-T19',
    label: '282	Efeitos de corpo estranho que entra através de orifício natural	T15-T19',
  },
  { value: '283	Queimadura e corrosões	T20-T32', label: '283	Queimadura e corrosões	T20-T32' },
  {
    value: '284	Envenenamento por drogas e substâncias biológicas	T36-T50',
    label: '284	Envenenamento por drogas e substâncias biológicas	T36-T50',
  },
  {
    value: '285	Efeitos tóxicos de substâncias de origem principalmente não-medicinal	T51-T65',
    label: '285	Efeitos tóxicos de substâncias de origem principalmente não-medicinal	T51-T65',
  },
  { value: '286	Síndromes de maus tratos	T74', label: '286	Síndromes de maus tratos	T74' },
  {
    value:
      '287	Outros efeitos e os efeitos não especificados de causas externas	T33-T35, T66-T73, T75-T78',
    label:
      '287	Outros efeitos e os efeitos não especificados de causas externas	T33-T35, T66-T73, T75-T78',
  },
  {
    value:
      '288	Certas complicações precoces de traumatismo e complicações cirúrgicas, e da assistência médica não classificadas em outra parte	T79-T88',
    label:
      '288	Certas complicações precoces de traumatismo e complicações cirúrgicas, e da assistência médica não classificadas em outra parte	T79-T88',
  },
  {
    value:
      '289	Seqüelas de traumatismos, de envenenamento e de outras conseqüências de causas externas	T90-T98',
    label:
      '289	Seqüelas de traumatismos, de envenenamento e de outras conseqüências de causas externas	T90-T98',
  },
  {
    value: 'XX	1.096-1.103	Causas externas de morbidade e de mortalidade	V01-Y98',
    label: 'XX	1.096-1.103	Causas externas de morbidade e de mortalidade	V01-Y98',
  },
  { value: '1.096	Acidentes de transporte	V01-V99', label: '1.096	Acidentes de transporte	V01-V99' },
  { value: '1.097	Quedas	W00-W19', label: '1.097	Quedas	W00-W19' },
  {
    value: '1.098	Afogamento e submersão acidentais	W65-W74',
    label: '1.098	Afogamento e submersão acidentais	W65-W74',
  },
  {
    value: '1.099	Exposição ao fumo, ao fogo e às chamas	X00-X09',
    label: '1.099	Exposição ao fumo, ao fogo e às chamas	X00-X09',
  },
  {
    value: '1.100	Envenenamento, intoxicação por ou exposição a substâncias nocivas	X40-X49',
    label: '1.100	Envenenamento, intoxicação por ou exposição a substâncias nocivas	X40-X49',
  },
  {
    value: '1.101	Lesões autoprovocadas voluntariamente	X60-X84',
    label: '1.101	Lesões autoprovocadas voluntariamente	X60-X84',
  },
  { value: '1.102	Agressões	X85-Y09', label: '1.102	Agressões	X85-Y09' },
  {
    value: '1.103	Todas as outras causas externas	W20-W64, W75-W99, X10-X39, X50-X59, Y10-Y89',
    label: '1.103	Todas as outras causas externas	W20-W64, W75-W99, X10-X39, X50-X59, Y10-Y89',
  },
  {
    value: '290	Pessoas em contato com os serviços de saúde para exame e investigação	Z00-Z13',
    label: '290	Pessoas em contato com os serviços de saúde para exame e investigação	Z00-Z13',
  },
  {
    value: '291	Estado de infecção assintomática pelo vírus da imunodeficiência humana [HIV]	Z21',
    label: '291	Estado de infecção assintomática pelo vírus da imunodeficiência humana [HIV]	Z21',
  },
  {
    value:
      '292	Outras pessoas com riscos potenciais à saúde relacionadas com doenças transmissíveis	Z20, Z22-Z29',
    label:
      '292	Outras pessoas com riscos potenciais à saúde relacionadas com doenças transmissíveis	Z20, Z22-Z29',
  },
  { value: '293	Anticoncepção	Z30', label: '293	Anticoncepção	Z30' },
  {
    value: '294	Rastreamento ("screening") pré-natal e outras supervisões da gravidez	Z34-Z36',
    label: '294	Rastreamento ("screening") pré-natal e outras supervisões da gravidez	Z34-Z36',
  },
  {
    value: '295	Nascidos vivos segundo o local de nascimento	Z38',
    label: '295	Nascidos vivos segundo o local de nascimento	Z38',
  },
  { value: '296	Assistência e exame pós-natal	Z39', label: '296	Assistência e exame pós-natal	Z39' },
  {
    value:
      '297	Pessoas em contato com serviços de saúde para cuidados e procedimentos específicos	Z40-Z54',
    label:
      '297	Pessoas em contato com serviços de saúde para cuidados e procedimentos específicos	Z40-Z54',
  },
  {
    value: '298	Pessoas em contato com os serviços de saúde por outras razões	Z31-Z33, Z37, Z55-Z99',
    label: '298	Pessoas em contato com os serviços de saúde por outras razões	Z31-Z33, Z37, Z55-Z99',
  },
  {
    value: '901	Síndrome respiratória aguda grave (Severe acute respiratory syndrome) [SARS]	U04',
    label: '901	Síndrome respiratória aguda grave (Severe acute respiratory syndrome) [SARS]	U04',
  },
];
