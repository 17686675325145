import { useState, useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { Attendance, getAttendance } from '../../slices/attendance';

import { Row, Col } from 'reactstrap';

import { Button } from '../../components/Button';

type Params = { id: string };

export const TelemedicineCall = () => {
  const { id } = useParams<Params>();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  const [attendance, setAttendance] = useState<Attendance>({
    statusAttendance: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getAttendance(id));
      setAttendance(response.data);
    };
    fetchData();
  }, []);

  const handleOnClose = () => {
    window.close();
  };

  return (
    <Layout type={user.role}>
      <>
        <iframe
          allow="camera; microphone; display-capture"
          title="Consulta médica"
          style={{ width: 'inherit', height: '500px' }}
          src={attendance.link}
        ></iframe>
        <Row className="mt-4 justify-content-center">
          <Col sm={4}>
            <Button onClick={() => handleOnClose()}>ENCERRAR</Button>
          </Col>
        </Row>
      </>
    </Layout>
  );
};
