import { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Layout } from '../../../components/Layout';
import { Input } from '../../../components/Input';
import { RootState, useAppDispatch } from '../../../store';
import { ReturnLink } from '../../../components/ReturnLink';
import { TummiModal, ITummiModalProps } from '../../../components/Modal';
import { isValidCep } from '../../../utils/ValidCep';
import { State } from '../../../utils/EnumStates';
import { Select } from '../../../components/Select';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { Company, UpdateCompany } from '../../../slices/company';
import { maskCNPJ, maskDDD, maskPhoneNumber, maskZipCode } from '../../../utils/Mask';
import formatString from 'format-string-by-pattern';
import { getPartner, PartnerUser, UpdatePartner } from '../../../slices/partner';
import { GenericAvatar } from '../../../components/Avatar';

import './styles.scss';

const { consultarCep } = require('correios-brasil');

type Params = { id: string };
interface CompanyProfile {
  partner?: PartnerUser;
  company?: Company;
}

export const PartnerProfile = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const user = useSelector((state: RootState) => state.user);

  const [company, setCompany] = useState<CompanyProfile>();

  const dispatch = useAppDispatch();

  const [buscaCepSubmit, setBuscaCepSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const buscaCep = () => {
    setBuscaCepSubmit(true);
    if (company?.partner?.cep && isValidCep(company?.partner?.cep)) {
      consultarCep(company?.partner?.cep.replaceAll('.', '').replaceAll('-', ''))
        .then((response: any) => {
          if (response) {
            setCompany({
              ...company,
              partner: {
                ...company.partner,
                streetAddress: response.logradouro,
                complement: response.complemento,
                neighborhood: response.bairro,
                city: response.localidade,
                state: response.uf,
              },
            });
          }
          setBuscaCepSubmit(false);
        })
        .catch((err: any) => {
          setModal({
            isOpen: true,
            body: 'Cep não existente na base dos correios.',
            className: '',
            title: 'Mensagem',
            toggle: () => toggle(),
            okButton: 'Ok',
          });
          setBuscaCepSubmit(false);
        });
    } else {
      setModal({
        isOpen: true,
        body: 'Informe um Cep Válido.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
      setBuscaCepSubmit(false);
    }
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  useEffect(() => {
    const fetchPartnerData = async () => {
      const response = await dispatch(getPartner({ id }));
      setCompany({
        company: {
          ...response.data.companyTelemedicine,
          CNPJ: formatString(maskCNPJ, response.data.companyTelemedicine.cnpj),
        },
        partner: {
          ...response.data,
          cep: formatString(maskZipCode, response.data.cep),
        },
      });
    };
    fetchPartnerData();
  }, [dispatch, id]);

  const onSubmit = async (values: CompanyProfile) => {
    values = {
      company: {
        ...values.company,
        sendPatientEmailOnCreate: company?.company?.sendPatientEmailOnCreate,
        blockPatientAccessToPlataform: company?.company?.blockPatientAccessToPlataform,
        isNonMedicalCompany: company?.company?.isNonMedicalCompany,
        isNonProfitCompany: company?.company?.isNonProfitCompany,
      },
      partner: {
        ...values.partner,
        cep: values.partner?.cep?.replace(/[^a-zA-Z0-9]/g, ''),
        cpf: values.partner?.cpf?.replace(/[^a-zA-Z0-9]/g, ''),
        phone: values.partner?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
      },
    };

    const response = await dispatch(UpdatePartner(values.partner || {}));
    const responseCompany = await dispatch(UpdateCompany(values.company || {}));

    if (response.data.success && responseCompany.data.success) {
      setModal({
        isOpen: true,
        body: 'Dados alterados com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body: response.data.errorMessage,
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => history.goBack(),
        okButton: 'Tentar novamente',
      });
    }
  };

  const onFormChange = (values: Partial<CompanyProfile>) => {
    setCompany({ ...values });
  };

  return (
    <Layout type={user.role}>
      <>
        <div className="view-profile-page">
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
              <Text
                title="MEU PERFIL"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={1}>
              <Row>
                <GenericAvatar
                  size="100"
                  imageStyle={{ width: '100px', height: '100px', cursor: 'pointer' }}
                />
              </Row>
            </Col>
            <Col className="ml-5">
              <Form
                onSubmit={onSubmit}
                initialValues={{ ...company }}
                render={({ handleSubmit, submitting }) => (
                  <>
                    <form>
                      <FormSpy onChange={({ values }) => onFormChange(values)} />
                    </form>
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Dados da empresa"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={8}>
                          <Field
                            name="partner.firstName"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Razão social *"
                                placeholder="Nome"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                required={true}
                                disabled={submitting}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={6}>
                          <Field
                            name="company.CNPJ"
                            validate={required}
                            parse={formatString(maskCNPJ)}
                            disabled={submitting}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="CNPJ *"
                                placeholder="000.000.000/0000-00"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                required={true}
                                disabled={submitting}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={6}>
                          <Field
                            name="partner.email"
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="E-mail"
                                placeholder="email@dominio"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                type="email"
                                disabled={submitting}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={1}>
                          <Field
                            name="partner.ddd"
                            disabled={submitting}
                            parse={formatString(maskDDD)}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="DDD"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="partner.phone"
                            disabled={submitting}
                            parse={formatString(maskPhoneNumber)}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Telefone"
                                placeholder="00000-0000"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={12}>
                          <FormGroup className="" check>
                            <Label className="radio-input" check>
                              <Input
                                disabled={submitting}
                                type="checkbox"
                                onClick={(e: any) => {
                                  setCompany({
                                    company: {
                                      ...company?.company,
                                      sendPatientEmailOnCreate: !company?.company
                                        ?.sendPatientEmailOnCreate,
                                    },
                                    partner: {
                                      ...company?.partner,
                                    },
                                  });
                                }}
                                checked={company?.company?.sendPatientEmailOnCreate}
                              />
                              <span className="radio-label ml-1">
                                Você deseja deseja que a plataforma envie um e-mail com login e
                                senha ao cadastrar novos pacientes?
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <FormGroup className="" check>
                            <Label className="radio-input" check>
                              <Input
                                disabled={submitting}
                                type="checkbox"
                                onClick={(e: any) => {
                                  setCompany({
                                    company: {
                                      ...company?.company,
                                      blockPatientAccessToPlataform: !company?.company
                                        ?.blockPatientAccessToPlataform,
                                    },
                                    partner: {
                                      ...company?.partner,
                                    },
                                  });
                                }}
                                checked={company?.company?.blockPatientAccessToPlataform}
                              />
                              <span className="radio-label ml-1">
                                Você deseja que os seus pacientes não acessem a plataforma?
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <FormGroup className="" check>
                            <Label className="radio-input" check>
                              <Input
                                disabled={submitting}
                                type="checkbox"
                                onClick={(e: any) => {
                                  setCompany({
                                    company: {
                                      ...company?.company,
                                      isNonProfitCompany: !company?.company?.isNonProfitCompany,
                                    },
                                    partner: {
                                      ...company?.partner,
                                    },
                                  });
                                }}
                                checked={company?.company?.isNonProfitCompany}
                              />
                              <span className="radio-label ml-1">
                                Você deseja realizar cobrança das consultas pela plataforma?
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <FormGroup className="" check>
                            <Label className="radio-input" check>
                              <Input
                                disabled={submitting}
                                type="checkbox"
                                onClick={(e: any) => {
                                  setCompany({
                                    company: {
                                      ...company?.company,
                                      isNonMedicalCompany: !company?.company?.isNonMedicalCompany,
                                    },
                                    partner: {
                                      ...company?.partner,
                                    },
                                  });
                                }}
                                checked={company?.company?.isNonMedicalCompany}
                              />
                              <span className="radio-label ml-1">
                                Marque esta opção para informar que os profissionais da sua clinica
                                não são medicos ou psicólogos.
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={4}>
                          <Field
                            name="company.attendanceTime"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Tempo de consulta"
                                placeholder="tempo em minutos"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                required={true}
                                disabled={submitting}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Endereço"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={3}>
                          <Field
                            name="partner.cep"
                            validate={required}
                            parse={formatString(maskZipCode)}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Cep *"
                                  placeholder="CEP"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Button
                            disabled={submitting || buscaCepSubmit}
                            className="mt-4"
                            onClick={() => buscaCep()}
                          >
                            BUSCAR CEP
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={7}>
                          <Field
                            name="partner.streetAddress"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <div>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Logradouro *"
                                  placeholder="Logradouro"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </div>
                            )}
                          />
                        </Col>
                        <Col sm={2}>
                          <Field
                            name="partner.number"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Número *"
                                  placeholder="Número"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="partner.complement"
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Complemento"
                                placeholder="Complemento"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4 mb-4">
                        <Col sm={3}>
                          <Field
                            name="partner.neighborhood"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Bairro *"
                                  placeholder="Bairro"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="partner.city"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Cidade *"
                                  placeholder="Cidade"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={4}>
                          <Field
                            name="partner.state"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Select
                                name={input.name}
                                id={input.name}
                                label="Estado *"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                type="select"
                                disabled={submitting || buscaCepSubmit}
                                options={State}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row className="mt-4 justify-content-center">
                      <Col sm={4}>
                        <Button onClick={handleSubmit} disabled={submitting || buscaCepSubmit}>
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              />
            </Col>
          </Row>
        </div>
        <TummiModal {...modal} />
      </>
    </Layout>
  );
};
