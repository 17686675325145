import React, { useEffect } from 'react';
import { Col, Input, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Alert from '../../resources/images/alert.svg';
import PencilIcon from '../../resources/images/pencilIcon.svg';
import { UserTelemedicine } from '../../slices/userTelemedicine';

import './styles.scss';
interface IAdminUsersTableProps {
  users?: UserTelemedicine[];
  role?: string;
  pageSize: number;
  page: number;
  setPageSize: (pageSize: number) => void;
  setPage: (page: number) => void;
}

export const AdminUsersTable = ({
  users,
  role,
  pageSize,
  setPageSize,
  page,
  setPage,
}: IAdminUsersTableProps) => {
  const history = useHistory();

  // //we'll probably research when the pagination changes
  useEffect(() => {}, [page, pageSize, setPageSize, setPage]);

  const onPageSizeClick = (e: any) => {
    setPageSize(parseInt(e.target.value, 10));
  };

  const onPaginationClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageString: string,
  ) => {
    event.preventDefault();

    switch (pageString) {
      case 'first':
        setPage(1);
        break;
      case 'previous':
        setPage(page--);
        break;
      case 'next':
        //when we have the data array, we should compare with its length
        setPage(page++);
        break;
      case 'last':
        //when we have the data array, we should use its length
        setPage(10);
        break;
      default:
        setPage(Number(page));
        break;
    }
  };

  const getActions = (id?: string) => {
    return (
      <div>
        <span
          title="Perfil"
          onClick={() => history.push(`${process.env.PUBLIC_URL}/${role}/profile/${id}`)}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={PencilIcon}
            alt="alert"
            style={{
              width: '22px',
              height: '15px',
            }}
          />
        </span>
      </div>
    );
  };

  return (
    <div className="admin-users-table">
      {users && users.length > 0 ? (
        <Table striped responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Login</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => {
              return (
                <tr key={index}>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.login}</td>
                  <td>
                    <>
                      {user.ddd && user.phone ? (
                        <>
                          {' '}
                          ({user.ddd}) {user.phone}{' '}
                        </>
                      ) : null}
                    </>
                  </td>
                  <td>{getActions(user.id)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <>
          <Row className="mt-4">
            <Col className="d-flex justify-content-center">
              <img
                src={Alert}
                alt="alert"
                style={{
                  width: '55px',
                  height: '48px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4 mb-4">
            <Col className="d-flex justify-content-center">
              <span className="alert-text">Não foram encontrados resultados para sua busca</span>
            </Col>
          </Row>
        </>
      )}
      {users && users.length > 0 && (
        <Row>
          <Col sm={6}>
            <Row>
              <Col className="mt-2 d-flex justify-content-end">
                <span>Resultados</span>
              </Col>
              <Col sm={2}>
                <Input onChange={onPageSizeClick} type="select">
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Input>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex justify-content-start">
            <Pagination size="sm" aria-label="Paginação da tabela de pacientes">
              <PaginationItem>
                <PaginationLink first onClick={event => onPaginationClick(event, 'first')} />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink previous onClick={event => onPaginationClick(event, 'previous')} />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink onClick={event => onPaginationClick(event, '1')}>
                  {page}
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink next onClick={event => onPaginationClick(event, 'next')} />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink last onClick={event => onPaginationClick(event, 'last')} />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      )}
    </div>
  );
};
