import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from '../../components/Layout';
import { RootState, useAppDispatch } from '../../store';
import { Col, Row } from 'reactstrap';
import { Specialities } from '../../utils/EnumSpecialties';
import { SearchDoctorsSpecialityByDay } from '../../slices/attendance';
import { Form, Field, FormSpy } from 'react-final-form';
import { Button } from '../../components/Button';
import { DatePicker } from '../../components/Datepicker';
import { Select } from '../../components/Select';
import { ReturnLink } from '../../components/ReturnLink';
import { Text } from '../../components/Text';
import { DoctorUser } from '../../slices/admin';
import { DoctorsTable } from '../../components/DoctorsTable';

import './styles.scss';

interface UserFilter {
  attendanceDay?: string;
  speciality?: string;
}

export const Schedules = () => {
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();
  const [doctors, setDoctors] = useState<DoctorUser[]>([]);

  const [userFilter, setUserFilter] = useState<UserFilter>({
    attendanceDay: new Date().toISOString(),
  });

  const handleChange = (value: any, formattedValue: any) => {
    setUserFilter({
      ...userFilter,
      attendanceDay: value,
    });
  };

  const onSearch = async () => {
    const response = await dispatch(
      SearchDoctorsSpecialityByDay({
        date: userFilter.attendanceDay,
        speciality: userFilter.speciality,
        companyId: user.companyId,
      }),
    );
    setDoctors(response.data);
  };

  const onFormChange = (values: Partial<UserFilter>) => {
    setUserFilter({ ...values });
  };

  return (
    <Layout type={user.role}>
      <>
        <Row className="mt-4 mb-4">
          <Col style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="PESQUISA DE MÉDICOS"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
        </Row>
        <Form
          onSubmit={onSearch}
          initialValues={{ ...userFilter }}
          render={({ handleSubmit, submitting }) => (
            <div className="schedules-attendance">
              <form>
                <FormSpy onChange={({ values }) => onFormChange(values)} />
              </form>
              <Row className="mt-4">
                <Col sm={4}>
                  <Field
                    name="attendanceDay"
                    validate={required}
                    render={({ input, meta }) => (
                      <>
                        <DatePicker
                          input={input}
                          metaError={meta}
                          handleChange={(v: any, f: any) => handleChange(v, f)}
                          label="Dia"
                          submitting={submitting}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    name="speciality"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Select
                          name={input.name}
                          id={input.name}
                          label="Especialidade"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          type="select"
                          disabled={submitting}
                          options={Specialities}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Button className="mt-4" onClick={handleSubmit}>
                    PESQUISAR MÉDICOS
                  </Button>
                </Col>
              </Row>
              <Row>
                <DoctorsTable
                  doctors={doctors}
                  role={user.role}
                  patientId={user.id}
                  attendanceDay={userFilter.attendanceDay}
                  speciality={userFilter.speciality}
                  isLoading={app.isLoading}
                />
              </Row>
            </div>
          )}
        />
      </>
    </Layout>
  );
};
