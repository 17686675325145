import React, { useEffect } from 'react';
import { Col, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { AttendanceCard } from '../AttendanceCard';
import DarkCalendar from '../../resources/images/darkcalendar.svg';
import ArrowLeft from '../../resources/images/arrowLeft.svg';
import ArrowRight from '../../resources/images/arrowRight.svg';
import { Attendance } from '../../slices/attendance';

import './styles.scss';
import '../../components/Datepicker/styles.scss';

var ReactStrapDatePicker = require('reactstrap-date-picker');
interface IAttendanceCardsTableProps {
  attendances?: Attendance[];
  attendance?: string;
  role: string;
  pageSize: number;
  page: number;
  setPageSize: (pageSize: number) => void;
  setPage: (page: number) => void;
  handleChange: (v: any, f: any) => void;
  handleChangeAttendanceDay: (value: number) => void;
}

export const AttendanceCardsTable = ({
  attendances,
  attendance,
  role,
  page,
  pageSize,
  setPage,
  setPageSize,
  handleChange,
  handleChangeAttendanceDay,
}: IAttendanceCardsTableProps) => {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);

  const dayLabels = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  const monthLabels = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  //we'll probably research when the pagination changes
  useEffect(() => {}, [page, pageSize, setPageSize, setPage]);

  const onPaginationClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageString: string,
  ) => {
    event.preventDefault();

    switch (pageString) {
      case 'first':
        setPage(1);
        break;
      case 'previous':
        setPage(page--);
        break;
      case 'next':
        //when we have the data array, we should compare with its length
        setPage(page++);
        break;
      case 'last':
        //when we have the data array, we should use its length
        setPage(10);
        break;
      default:
        setPage(Number(page));
        break;
    }
  };

  return (
    <div className="attendance-cards-table">
      <Row>
        <Col>
          <img
            src={DarkCalendar}
            alt="calendar"
            style={{
              width: '13,33px',
              height: '14,67px',
              marginLeft: '6px',
              marginRight: '6px',
            }}
          />
          <span className="last-patients-text">Confira sua agenda</span>
        </Col>
        <Col sm={2} style={{ textAlign: 'end' }}>
          <span className="results-text" style={{}}>
            Busque pelo seu dia
          </span>
        </Col>
        <Col
          sm={3}
          style={{
            display: 'flex',
            background: '#E5F2F0',
            borderRadius: '26px',
            marginRight: '15px',
            bottom: '5px',
          }}
        >
          <img
            src={ArrowLeft}
            alt="previous"
            style={{
              marginTop: '10px',
              width: '11px',
              height: '16px',
              marginRight: '10px',
              cursor: 'pointer',
            }}
            onClick={() => handleChangeAttendanceDay(-1)}
          />
          <ReactStrapDatePicker
            className="datepicker"
            style={{ textAlign: 'center' }}
            onChange={(v: any, f: any) => handleChange(v, f)}
            value={attendance}
            dateFormat="DD/MM/YYYY"
            showClearButton={false}
            dayLabels={dayLabels}
            monthLabels={monthLabels}
          />
          <img
            src={ArrowRight}
            alt="previous"
            style={{
              marginTop: '10px',
              width: '11px',
              height: '16px',
              marginLeft: '10px',
              cursor: 'pointer',
            }}
            onClick={() => handleChangeAttendanceDay(1)}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Row>
            {
              // eslint-disable-next-line array-callback-return
              attendances?.map((attendance, index) => {
                if (index < 4) {
                  return (
                    <Col sm={3}>
                      <AttendanceCard
                        redirectLinkTitle="ATENDER"
                        redirectWhere={() => {
                          if (user.isNonMedicalCompany) {
                            history.push(
                              `${process.env.PUBLIC_URL}/${role}/ampal/attendance/${attendance.id}`,
                            );
                          } else {
                            history.push(
                              `${process.env.PUBLIC_URL}/${role}/attendance/${attendance.id}`,
                            );
                          }
                        }}
                        attendance={attendance}
                      />
                    </Col>
                  );
                }
              })
            }
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col className="d-flex justify-content-end">
          <span className="results-text" style={{ marginRight: '10px', marginTop: '10px' }}>
            Resultados {attendances && attendances?.length > 4 ? 4 : attendances?.length}
          </span>
          <Pagination size="sm" aria-label="Paginação da tabela de pacientes">
            <PaginationItem>
              <img
                src={ArrowLeft}
                alt="previous"
                style={{
                  marginTop: '3px',
                  width: '11px',
                  height: '16px',
                  marginRight: '15px',
                  cursor: 'pointer',
                }}
                onClick={(event: any) => onPaginationClick(event, 'previous')}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                className="links-text"
                onClick={event => onPaginationClick(event, '1')}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <img
                src={ArrowRight}
                alt="next"
                style={{
                  marginTop: '3px',
                  width: '11px',
                  height: '16px',
                  marginLeft: '15px',
                  cursor: 'pointer',
                }}
                onClick={(event: any) => onPaginationClick(event, 'next')}
              />
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </div>
  );
};
