import { useEffect } from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import { Button } from '../Button';

import './styles.scss';
export interface ITummiModalProps {
  isOpen: boolean;
  body?: any;
  title?: string;
  className?: string;
  toggle: () => void;
  leavingCallback?: () => void;
  okCallback?: () => void;
  okButton?: string;
  showCancelButton?: boolean;
}

export const TummiModal = (props: ITummiModalProps) => {
  useEffect(() => {}, [props]);
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className={`modal-tummi ${props.className}`}
      >
        <ModalBody style={{ paddingTop: '30px', textAlign: 'center' }}>{props.body}</ModalBody>
        <Row className="mt-4 justify-content-center" style={{ paddingBottom: '10px' }}>
          <Col sm={6}>
            {props.okButton && (
              <Button
                type="primary"
                onClick={() => {
                  if (props.okCallback) {
                    props.okCallback();
                  } else {
                    props.toggle();
                  }
                }}
              >
                {props.okButton}
              </Button>
            )}{' '}
          </Col>
          {!props.showCancelButton && (
            <Col sm={4}>
              <Button type="primary" onClick={props.leavingCallback}>
                Sair
              </Button>
            </Col>
          )}
        </Row>
      </Modal>
    </>
  );
};
