import { useState, useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { Input } from '../../../components/Input';
import { RootState, useAppDispatch } from '../../../store';
import { PatientsTable } from '../../../components/PatientsTable';
import { getPatients } from '../../../slices/partner';
import { PatientUser } from '../../../slices/admin';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';

import './styles.scss';
interface PatientFilter {
  name?: string;
  susnumber?: string;
  cpf?: string;
}

export const PartnerPatients = () => {
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);
  const history = useHistory();
  const [patient, setPatient] = useState<PatientFilter | null>({
    cpf: '',
    name: '',
    susnumber: '',
  });
  const [patients, setPatients] = useState<PatientUser[]>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getPatients({
          companyId: user.companyId,
          name: patient?.name,
          susnumber: patient?.susnumber,
          cpf: patient?.cpf,
        }),
      );
      setPatients(response.data);
    };
    fetchData();
  }, [dispatch, user.companyId]);

  const onSubmit = async () => {
    const response = await dispatch(
      getPatients({
        companyId: user.companyId,
        name: patient?.name,
        susnumber: patient?.susnumber,
        cpf: patient?.cpf,
      }),
    );
    setPatients(response.data);
  };

  const onFormChange = (values: Partial<PatientFilter>) => {
    setPatient({ ...values });
  };

  return (
    <Layout type="partner">
      <div className="prefecture-patients">
        <Row className="mt-4 mb-2">
          <Col style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="PACIENTES"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              type="primary"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/partner/createpatientuser`);
              }}
            >
              Cadastrar paciente
            </Button>
          </Col>
        </Row>
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...patient }}
          render={({ handleSubmit, submitting }) => (
            <div className="partner-patients">
              <form>
                <FormSpy onChange={({ values }) => onFormChange(values)} />
              </form>
              <Row className="mt-4">
                <Col sm={3}>
                  <Field
                    name="name"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Paciente"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                        placeholder="Ex.: José da Silva"
                      />
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="susnumber"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Cartão SUS"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                        placeholder="000000000000000"
                      />
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="cpf"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="CPF"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                        placeholder="000.000.000-00"
                      />
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Button className="mt-4" disabled={submitting} onClick={handleSubmit}>
                    Filtrar
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <PatientsTable patients={patients} role={user.role} isLoading={app.isLoading} />
              </Row>
            </div>
          )}
        />
      </div>
    </Layout>
  );
};
