import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Layout } from '../../../components/Layout';
import { Chrono } from 'react-chrono';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { PatientUser } from '../../../slices/admin';
import { getPatient } from '../../../slices/partner';
import formatString from 'format-string-by-pattern';
import { maskCPF } from '../../../utils/Mask';
import moment from 'moment';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';
import { Attendance, getAttendancesByPatient } from '../../../slices/attendance';
import { GenericAvatar } from '../../../components/Avatar';

import './style.scss';

let { AgeFromDateString } = require('age-calculator');
type Params = { id: string };

type TimelineCard = {
  title?: string;
  cardTitle?: string;
  cardDetailedText?: string;
  cardSubtitle?: string;
  url?: string;
};

export const PatientHistory = () => {
  const { id } = useParams<Params>();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [patient, setPatient] = useState<PatientUser>();
  const [items, setItems] = useState<TimelineCard[]>([]);
  const [lastAttendance, setLastAttendance] = useState<Attendance>({});

  useEffect(() => {}, [items, lastAttendance]);

  useEffect(() => {
    const fetchPatient = async () => {
      const response = await dispatch(getPatient({ id }));
      setPatient(response.data);
    };

    const fetchAttendancesOfPatient = async () => {
      const response = await dispatch(getAttendancesByPatient(id, -1));

      let _items: TimelineCard[] = [];

      if (response.data.length > 0) {
        setLastAttendance(response.data[response.data.length - 1]);

        // eslint-disable-next-line array-callback-return
        response.data.map(function (attendance: Attendance) {
          _items.push({
            title: attendance?.date ? moment(attendance.date).format('DD/MM/YYYY HH:mm') : '',
            cardTitle: user.isNonMedicalCompany
              ? `${attendance.doctorTelemedicine?.firstName} ${attendance.doctorTelemedicine?.lastName}`
              : `Dr. ${attendance.doctorTelemedicine?.firstName} ${attendance.doctorTelemedicine?.lastName}`,
            cardSubtitle: user.isNonMedicalCompany
              ? 'Atendimento Ampal'
              : `${attendance.speciality}`,
            cardDetailedText: detailedText(attendance.id) as any,
          });
        });
        setItems(_items);
      }
    };

    fetchAttendancesOfPatient();
    fetchPatient();
  }, [dispatch, id]);

  const detailedText = (attendanceId?: string) => {
    return (
      <a
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (user.role !== 'patient') {
            user.isNonMedicalCompany
              ? history.push(
                  `${process.env.PUBLIC_URL}/${user.role}/ampal/attendance/${attendanceId}`,
                )
              : history.push(`${process.env.PUBLIC_URL}/${user.role}/attendance/${attendanceId}`);
          } else {
            history.push(`${process.env.PUBLIC_URL}/${user.role}/attendance/${attendanceId}`);
          }
        }}
      >
        Acessar atendimento
      </a>
    );
  };

  return (
    <Layout type={user.role}>
      <div className="doctor-patient-history">
        <Row className="mt-4 mb-4">
          <Col style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="HISTÓRICO DO PACIENTE"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4" style={{ marginLeft: '100px' }}>
          <Col>
            <Text
              title="Informações do paciente"
              style={{
                fontSize: '16px',
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2" style={{ marginLeft: '60px' }}>
          <Col sm={2} className="mt-4">
            <Row>
              <Col className="d-flex justify-content-center ">
                <GenericAvatar
                  size="100"
                  avatarName={`${patient?.firstName} ${patient?.lastName}`}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={10}>
            <Row className="mt-2">
              <Col sm={4}>
                <Text
                  title="Nome"
                  type="gray-text"
                  style={{
                    fontSize: '14px',
                    marginBottom: 'unset',
                  }}
                />
              </Col>
              <Col sm={4}>
                <Text
                  title="Telefone"
                  type="gray-text"
                  style={{
                    fontSize: '14px',
                    marginBottom: 'unset',
                  }}
                />
              </Col>
              <Col sm={4}>
                <Text
                  title="CPF"
                  type="gray-text"
                  style={{
                    fontSize: '14px',
                    marginBottom: 'unset',
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={4}>
                <strong>
                  {patient?.firstName} {patient?.lastName}
                </strong>
              </Col>
              <Col sm={4}>
                <strong>{`${patient?.ddd} ${patient?.phone}`}</strong>
              </Col>
              <Col sm={4}>
                <strong>{formatString(maskCPF, patient?.cpf || '')}</strong>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={2}>
                <Text
                  title="Idade"
                  type="gray-text"
                  style={{
                    fontSize: '14px',
                    marginBottom: 'unset',
                  }}
                />
              </Col>
              <Col sm={6}>
                <Text
                  title="E-mail"
                  type="gray-text"
                  style={{
                    fontSize: '14px',
                    marginBottom: 'unset',
                  }}
                />
              </Col>
              <Col sm={4}>
                <Text
                  title="Data de nascimento"
                  type="gray-text"
                  style={{
                    fontSize: '14px',
                    marginBottom: 'unset',
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={2}>
                <strong>{new AgeFromDateString(patient?.dob).age} anos</strong>
              </Col>
              <Col sm={6}>
                <strong>{patient?.email ? patient?.email : 'Não informado'}</strong>
              </Col>
              <Col sm={4}>
                <strong>{moment(patient?.dob).format('DD/MM/YYYY')}</strong>
              </Col>
            </Row>
          </Col>
        </Row>
        {!user.isNonMedicalCompany ? (
          <Row className="mt-4">
            <Col sm={2}></Col>
            <Col sm={4} className="bordered-container card">
              <Row>
                <Col>
                  <Text
                    title="Comorbidades"
                    style={{
                      fontSize: '14px',
                      marginBottom: 'unset',
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="information">* Informações baseadas no último atendimento</p>
                </Col>
              </Row>
              <>
                {lastAttendance?.comorbities?.map((comobirty, index) => {
                  return (
                    <Row>
                      <Col> - {comobirty.name}</Col>
                    </Row>
                  );
                })}
              </>
            </Col>
            <Col sm={4} className="bordered-container card ml-4">
              <Row>
                <Col>
                  <Text
                    title="Prescrições"
                    style={{
                      fontSize: '14px',
                      marginBottom: 'unset',
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p className="information">* Informações baseadas no último atendimento</p>
                </Col>
              </Row>
            </Col>
            <Col sm={2}></Col>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          <Col>
            <Row className="mt-4">
              {items.length > 0 && (
                <Chrono
                  hideControls
                  items={items}
                  mode="HORIZONTAL"
                  theme={{ primary: '#008171', secondary: '#FFFFFF' }}
                ></Chrono>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
