import React, { useState, useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Layout } from '../../../components/Layout';
import { RootState, useAppDispatch } from '../../../store';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';
import { UserTelemedicine } from '../../../slices/userTelemedicine';
import { getAdminUsers } from '../../../slices/admin';
import { AdminUsersTable } from '../../../components/AdminUsersTable';
import { useHistory } from 'react-router-dom';

import './styles.scss';

type UserFilter = {
  name?: string;
  cpf?: string;
  login?: string;
};

export const AdminUsers = () => {
  const user = useSelector((state: RootState) => state.user);
  const [userFilter, setUserFilter] = useState<UserFilter | null>({ name: '', cpf: '', login: '' });
  const [users, setUsers] = useState<UserTelemedicine[]>();
  const history = useHistory();

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    const response = await dispatch(
      getAdminUsers({
        name: userFilter?.name,
        cpf: userFilter?.cpf,
        login: userFilter?.login,
        pageSize,
        page,
      }),
    );
    setUsers(response.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getAdminUsers({
          name: userFilter?.name,
          cpf: userFilter?.cpf,
          login: userFilter?.login,
          pageSize,
          page,
        }),
      );
      setUsers(response.data);
    };
    fetchData();
  }, [page, pageSize]);

  const onFormChange = (values: Partial<UserFilter>) => {
    setUserFilter({ ...values });
  };

  return (
    <div className="admin-users">
      <Layout type="admin">
        <>
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
              <Text
                title="USUÁRIOS"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                type="primary"
                onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/admin/createadminuser`);
                }}
              >
                Cadastrar usuário
              </Button>
            </Col>
          </Row>
          <Form
            onSubmit={onSubmit}
            initialValues={{ ...userFilter }}
            render={({ handleSubmit, submitting }) => (
              <>
                <form>
                  <FormSpy onChange={({ values }) => onFormChange(values)} />
                </form>
                <Row className="mt-4">
                  <Col sm={3}>
                    <Field
                      name="name"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Nome"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting}
                          placeholder="Ex.: José da Silva"
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="cpf"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="CPF"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting}
                          placeholder="000.000.000-00"
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="login"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Login"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Button className="mt-4" disabled={submitting} onClick={handleSubmit}>
                      Filtrar
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <AdminUsersTable
                    role={user.role}
                    users={users}
                    pageSize={pageSize}
                    page={page}
                    setPageSize={(value: number) => setPageSize(value)}
                    setPage={(value: number) => setPage(value)}
                  />
                </Row>
              </>
            )}
          />
        </>
      </Layout>
    </div>
  );
};
