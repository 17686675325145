import React, { useState, useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Layout } from '../../../components/Layout';
import { Input } from '../../../components/Input';
import { RootState, useAppDispatch } from '../../../store';
import { DoctorsTable } from '../../../components/DoctorsTable';
import { useHistory } from 'react-router-dom';
import { DoctorUser } from '../../../slices/admin';
import { getDoctors } from '../../../slices/partner';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';

import './styles.scss';

interface DoctorFilter {
  name?: string;
  speciality?: string;
}

export const PartnerDoctors = () => {
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);
  const history = useHistory();
  const [doctors, setDoctors] = useState<DoctorUser[]>([{}]);
  const [doctor, setDoctor] = useState<DoctorFilter | null>({ name: '', speciality: '' });

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getDoctors({
          companyId: user.companyId,
          name: doctor?.name,
          speciality: doctor?.speciality,
        }),
      );
      setDoctors(response.data);
    };
    fetchData();
  }, [dispatch, user.companyId]);

  const onSubmit = async () => {
    const response = await dispatch(
      getDoctors({
        companyId: user.companyId,
        name: doctor?.name,
        speciality: doctor?.speciality,
      }),
    );
    setDoctors(response.data);
  };

  const onFormChange = (values: Partial<DoctorFilter>) => {
    setDoctor({ ...values });
  };

  return (
    <Layout type="partner">
      <>
        <Row className="mt-4 mb-4">
          <Col style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="PROFISSIONAIS DE SAÚDE"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              type="primary"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/partner/createdoctoruser`);
              }}
            >
              Cadastrar profissional
            </Button>
          </Col>
        </Row>
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...doctor }}
          render={({ handleSubmit, submitting }) => (
            <div className="partner-doctors">
              <form>
                <FormSpy onChange={({ values }) => onFormChange(values)} />
              </form>
              <Row className="mt-4">
                <Col sm={4}>
                  <Field
                    name="name"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Nome"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                        placeholder="Ex.: José da Silva"
                      />
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    name="speciality"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Especialidade"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                        placeholder="Ex.: Cliníco Geral"
                      />
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Button className="mt-4" disabled={submitting} onClick={handleSubmit}>
                    Filtrar
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <DoctorsTable doctors={doctors} role={user.role} isLoading={app.isLoading} />
              </Row>
            </div>
          )}
        />
      </>
    </Layout>
  );
};
