import { Api } from '../../services/api';
import { searchAttendancesInfo, searchNonMedicalAttendancesInfo } from '../../services/apiTypes';
import { AppDispatch } from '../../store';
import { setIsloading } from '../app';

export type BillingDashboard = {
  month?: string;
  billing?: number;
};

export type AttendanceDashboard = {
  month?: string;
  attendances?: number;
};

export type NonMedicalClinicDashboard = {
  nonMedicalClinicDashboardInfo?: NonMedicalClinicDashboardInfo[];
  doctorAttendanceInfo?: DoctorAttendanceInfo[];
};

export type NonMedicalClinicDashboardInfo = {
  month?: string;
  totalAttendances?: number;
  completedAttendances?: number;
  nonCompletedAttendances?: number;
};

export type DoctorAttendanceInfo = {
  id?: string;
  fullName?: string;
  totalAttendances?: number;
  completedAttendances?: number;
  nonCompletedAttendances?: number;
};

export type MedicalClinicDashboard = {
  attendance?: AttendanceDashboard[];
  billing?: BillingDashboard[];
};

export const getAttendancesInfo = ({
  companyId,
  year,
  halfyear,
}: Partial<searchAttendancesInfo>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.SearchAttendancesInfo({ companyId, year, halfyear }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getNonMedicalAttendancesInfo = ({
  companyId,
  year,
  monthsAgo,
}: Partial<searchNonMedicalAttendancesInfo>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return api.SearchNonMedicalAttendancesInfo({ companyId, year, monthsAgo }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};
