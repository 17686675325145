import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Layout } from '../../components/Layout';
import { RootState, useAppDispatch } from '../../store';
import { ReturnLink } from '../../components/ReturnLink';
import { Button } from '../../components/Button';
import { Text } from '../../components/Text';
import moment from 'moment';
import { Attendance, getAttendance } from '../../slices/attendance';
import { downloadBoleto } from '../../slices/payment';
interface Params {
  attendanceId?: string;
}

export const CheckoutSuccess = () => {
  const { attendanceId } = useParams<Params>();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  const [attendance, setAttendance] = useState<Attendance>();

  useEffect(() => {
    const fetchDoctor = async () => {
      const response = await dispatch(getAttendance(attendanceId || ''));

      if (response.data) {
        setAttendance({
          ...response.data,
        });
      }
    };
    fetchDoctor();
  }, []);

  return (
    <Layout type={user.role}>
      <>
        <div className="view-profile-page">
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink
                title="VOLTAR"
                returnLink={() => history.push(`${process.env.PUBLIC_URL}/${user.role}/schedules`)}
              />
              <Text
                title="PEDIDO CONFIRMADO"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="bordered-container">
                <Row className="mt-2">
                  <Col>
                    <Text
                      title="Informações do seu pedido"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span className="label">Médico (a)</span>
                  </Col>
                  <Col sm={4}>
                    <span className="label">Especialidade</span>
                  </Col>
                  <Col sm={3}>
                    <span className="label">Data do atendimento</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={5}>
                    <span>
                      <strong>
                        {attendance?.doctorTelemedicine?.firstName}{' '}
                        {attendance?.doctorTelemedicine?.lastName}
                      </strong>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <span>
                      <strong>{attendance?.speciality}</strong>
                    </span>
                  </Col>
                  <Col sm={3}>
                    <span>
                      <strong>
                        {' '}
                        {moment(new Date(attendance?.date || '')).format('DD/MM/YYYY HH:mm')}{' '}
                      </strong>{' '}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-4">
                  {attendance?.paidBy !== 3 && (
                    <Col sm={5}>
                      <span className="label">Valor da Consulta</span>
                    </Col>
                  )}
                  <Col sm={4}>
                    <span className="label">Local</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  {attendance?.paidBy !== 3 && (
                    <Col sm={5}>
                      <span>
                        <strong>R$ {attendance?.attendanceValue}</strong>
                      </span>
                    </Col>
                  )}
                  <Col sm={4}>
                    <span>
                      <strong>{'Teleatendimento (Online)'}</strong>
                    </span>
                  </Col>
                </Row>
                {attendance?.paidBy === 0 && (
                  <div className="mt-4">
                    <h2 className="title" style={{ fontSize: '22px', textAlign: 'center' }}>
                      Parabéns você esta quase lá. Realize o download do seu boleto clicando no
                      botão abaixo.
                    </h2>
                    <h2 className="text mt-2" style={{ textAlign: 'center' }}>
                      Após a confirmação do pagamento, iremos confirmar o agendamento da sua
                      consulta via email.
                    </h2>
                    <Row className="mt-4 justify-content-center">
                      <Col className="mt-2" sm={4}>
                        <Button
                          onClick={() =>
                            downloadBoleto(attendance.boletoTransaction?.bankSlipUrl || '')
                          }
                          type="primary"
                        >
                          Download Boleto
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
                {attendance?.paidBy === 1 && (
                  <div className="mt-4">
                    <h2 className="title" style={{ fontSize: '22px', textAlign: 'center' }}>
                      Parabéns você esta quase lá. Realize o pagamento da sua consulta através do QR
                      code abaixo.
                    </h2>
                    <Row className="mt-2 justify-content-center">
                      <img
                        alt=""
                        src={attendance.pixTransaction?.qrCode || ''}
                        style={{ width: '30%', borderRadius: 'unset' }}
                      ></img>
                    </Row>
                    <h2 className="title mt-4" style={{ fontSize: '20px', textAlign: 'center' }}>
                      Você também pode utilizar o código do Pix abaixo para efetuar o pagemento.
                    </h2>
                    <Row className="mt-4" style={{ textAlign: 'center' }}>
                      <p>{attendance.pixTransaction?.key || ''}</p>
                    </Row>
                    <h2 className="text mt-4" style={{ textAlign: 'center' }}>
                      Sua consulta foi agendada com sucesso. Você tem até 24 horas antes do horário
                      do seu agendamento para realizar o cancelamento ou re-agendamento sem custos.
                    </h2>
                    <h2 className="text mt-4" style={{ textAlign: 'center' }}>
                      Após a realização da sua consulta, o médico(a) irá lhe enviar a sua nota
                      fiscal por e-mail.
                    </h2>
                  </div>
                )}
                {attendance?.paidBy === 2 && (
                  <div className="mt-4">
                    <h2 className="title" style={{ fontSize: '22px', textAlign: 'center' }}>
                      Parabéns, recebemos o seu pagamento.
                    </h2>
                    <h2 className="text mt-4" style={{ textAlign: 'center' }}>
                      Sua consulta foi agendada com sucesso. Você tem até 24 horas antes do horário
                      do seu agendamento para realizar o cancelamento ou re-agendamento sem custos.
                    </h2>
                    <h2 className="text mt-4" style={{ textAlign: 'center' }}>
                      Após a realização da sua consulta, o médico(a) irá lhe enviar a sua nota
                      fiscal por e-mail.
                    </h2>
                  </div>
                )}
                {attendance?.paidBy === 3 && (
                  <div className="mt-4">
                    <h2 className="title" style={{ fontSize: '22px', textAlign: 'center' }}>
                      Parabéns.
                    </h2>
                    <h2 className="text mt-4" style={{ textAlign: 'center' }}>
                      Sua consulta foi agendada com sucesso. Você tem até 24 horas antes do horário
                      do seu agendamento para realizar o cancelamento ou re-agendamento sem custos.
                    </h2>
                    <h2 className="text mt-4" style={{ textAlign: 'center' }}>
                      Após a realização da sua consulta, o médico(a) irá lhe enviar a sua nota
                      fiscal por e-mail.
                    </h2>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </>
    </Layout>
  );
};
