import { useHistory } from 'react-router-dom';
import Eye from '../../resources/images/eye.svg';
import { Attendance } from '../../slices/attendance';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { MDBDataTable } from 'mdbreact';

import './styles.scss';

interface IAttendanceTableProps {
  attendances?: Attendance[];
  role: string;
  isLoading?: boolean;
  isNonMedicalCompany?: boolean;
}

export const AttendanceTable = ({
  attendances,
  role,
  isLoading,
  isNonMedicalCompany,
}: IAttendanceTableProps) => {
  const history = useHistory();

  const statusAttendance = (value?: number) => {
    switch (value) {
      case 2:
        return 'Agendado';
      case 3:
        return 'Finalizado';
      default:
        return 'Cancelado';
    }
  };

  const getActions = (id?: string) => {
    return (
      <div className="attendance-table-buttons">
        <span onClick={() => redirectViewAttendance(id)} style={{ cursor: 'pointer' }}>
          <img
            src={Eye}
            alt="alert"
            style={{
              width: '22px',
              height: '15px',
            }}
          />
        </span>
      </div>
    );
  };

  const redirectViewAttendance = (id?: string) => {
    if (isNonMedicalCompany) {
      history.push(`${process.env.PUBLIC_URL}/${role}/ampal/attendance/${id}`);
    } else {
      history.push(`${process.env.PUBLIC_URL}/${role}/attendance/${id}`);
    }
  };

  const attendancesTable: any = attendances?.map((attendance: Attendance) => {
    return {
      date: attendance?.date ? moment(attendance.date).format('DD/MM/YYYY HH:mm') : '',
      patient: attendance.patientTelemedicine?.fullname,
      status: statusAttendance(attendance?.statusAttendance),
      actions: getActions(attendance?.id),
    };
  });

  const data = {
    columns: [
      {
        label: 'Data/Hora',
        field: 'date',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Paciente',
        field: 'patient',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Ações',
        field: 'actions',
        sort: 'asc',
        width: 100,
      },
    ],
    rows: attendancesTable,
  };

  const rows: any = [];
  for (let i = 0; i < 3; i++) {
    rows.push({
      date: <Skeleton width={150} />,
      patient: <Skeleton width={150} />,
      status: <Skeleton width={150} />,
      actions: <Skeleton width={150} />,
    });
  }

  const isLoadingData = {
    ...data,
    rows: rows,
  };

  return (
    <div className="attendances-table">
      {!isLoading ? (
        <MDBDataTable
          searching
          searchLabel="Pesquisa rápida"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'horários disponíveis']}
          theadColor="indigo"
          striped
          bordered
          data={data}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      ) : (
        <MDBDataTable
          searching
          searchLabel="Pesquisa rápida"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'horários disponíveis']}
          theadColor="indigo"
          striped
          bordered
          data={isLoadingData}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      )}
    </div>
  );
};
