import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Form, Field, FormSpy } from 'react-final-form';
import { RootState, useAppDispatch } from '../../../store';
import { Layout } from '../../../components/Layout';
import { Input } from '../../../components/Input';
import { AttendanceTable } from '../../../components/AttendancePatientTable';
import { DatePicker } from '../../../components/Datepicker';
import { Button } from '../../../components/Button';
import { Attendance } from '../../../slices/attendance';
import { SearchAttendances } from '../../../slices/patient';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';

import './styles.scss';
interface UserFilter {
  startDate?: string;
  endDate?: string;
  status?: number;
}

export const PatientAttendances = () => {
  const user = useSelector((state: RootState) => state.user);
  const app = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();

  const [totalRecords, setTotalRecords] = useState();

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 1);

  const finalDate = new Date();
  finalDate.setDate(startDate.getDate() + 7);

  const [attendances, setAttendances] = useState<Attendance[]>([]);

  const [userFilter, setUserFilter] = useState<UserFilter>({
    startDate: startDate.toISOString(),
    endDate: finalDate.toISOString(),
    status: -1,
  });

  const handleChangeStartDate = async (value: any, formattedValue: any) => {
    setUserFilter({
      ...userFilter,
      startDate: value,
    });
  };

  const handleChangeEndDate = async (value: any, formattedValue: any) => {
    setUserFilter({
      ...userFilter,
      endDate: value,
    });
  };

  useEffect(() => {
    const fetchAttendances = async () => {
      const response = await dispatch(
        SearchAttendances({
          patientId: user.id,
          startDate: userFilter.startDate,
          endDate: userFilter.endDate,
          status: userFilter.status,
        }),
      );
      setTotalRecords(response.data.totalRecords);
      setAttendances(response.data.response);
    };
    fetchAttendances();
  }, []);

  const onSubmit = async () => {
    const response = await dispatch(
      SearchAttendances({
        patientId: user.id,
        startDate: userFilter.startDate,
        endDate: userFilter.endDate,
        status: userFilter.status,
      }),
    );
    setAttendances(response.data.response);
  };

  const onFormChange = (values: Partial<UserFilter>) => {
    setUserFilter({ ...values });
  };

  return (
    <Layout type="patient">
      <>
        <Row className="mt-4 mb-4">
          <Col style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="MEUS ATENDIMENTOS"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
        </Row>
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...userFilter }}
          render={({ handleSubmit, submitting }) => (
            <div className="patients-attendance">
              <form>
                <FormSpy onChange={({ values }) => onFormChange(values)} />
              </form>
              <Row className="mt-4">
                <Col sm={3}>
                  <Field
                    name="startDate"
                    render={({ input, meta }) => (
                      <>
                        <DatePicker
                          input={input}
                          metaError={meta}
                          handleChange={(v, f) => handleChangeStartDate(v, f)}
                          label="Data Inicial"
                          submitting={submitting}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="endDate"
                    render={({ input, meta }) => (
                      <>
                        <DatePicker
                          input={input}
                          metaError={meta}
                          handleChange={(v, f) => handleChangeEndDate(v, f)}
                          label="Data Final"
                          submitting={submitting}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="status"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Status"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        type="select"
                        disabled={submitting}
                      >
                        <option value={-1}>Todos</option>
                        <option value={0}>Pré-Agendado</option>
                        <option value={1}>Cancelado</option>
                        <option value={2}>Agendado</option>
                        <option value={3}>Finalizado</option>
                      </Input>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Button className="mt-4" disabled={submitting} onClick={handleSubmit}>
                    Filtrar
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <AttendanceTable
                  attendances={attendances}
                  role={user.role}
                  isLoading={app.isLoading}
                />
              </Row>
            </div>
          )}
        />
      </>
    </Layout>
  );
};
