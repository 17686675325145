import { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Layout } from '../../../components/Layout';
import { Input } from '../../../components/Input';
import { RootState, useAppDispatch } from '../../../store';
import { ReturnLink } from '../../../components/ReturnLink';
import { PatientUser } from '../../../slices/admin';
import { TummiModal, ITummiModalProps } from '../../../components/Modal';
import { isValidCep } from '../../../utils/ValidCep';
import { Gender } from '../../../utils/EnumGender';
import { State } from '../../../utils/EnumStates';
import { Select } from '../../../components/Select';
import { DatePicker } from '../../../components/Datepicker';
import { Button } from '../../../components/Button';
import { Text } from '../../../components/Text';
import { GenericAvatar } from '../../../components/Avatar';

import { maskCPF, maskDDD, maskPhoneNumber, maskZipCode } from '../../../utils/Mask';
import formatString from 'format-string-by-pattern';

import './styles.scss';
import { getPatient } from '../../../slices/partner';
import { updatePatientUser } from '../../../slices/patient';
import { TextArea } from '../../../components/TextArea';

const { consultarCep } = require('correios-brasil');

type Params = { id: string };

export const PatientProfile = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const user = useSelector((state: RootState) => state.user);

  const [patient, setPatient] = useState<PatientUser>();

  const dispatch = useAppDispatch();

  const [buscaCepSubmit, setBuscaCepSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const buscaCep = () => {
    setBuscaCepSubmit(true);
    if (patient?.cep && isValidCep(patient?.cep)) {
      consultarCep(patient?.cep.replaceAll('.', '').replaceAll('-', ''))
        .then((response: any) => {
          if (response) {
            setPatient({
              ...patient,
              streetAddress: response.logradouro,
              complement: response.complemento,
              neighborhood: response.bairro,
              city: response.localidade,
              state: response.uf,
            });
          }
          setBuscaCepSubmit(false);
        })
        .catch((err: any) => {
          setModal({
            isOpen: true,
            body: 'Cep não existente na base dos correios.',
            className: '',
            title: 'Mensagem',
            toggle: () => toggle(),
            okButton: 'Ok',
          });
          setBuscaCepSubmit(false);
        });
    } else {
      setModal({
        isOpen: true,
        body: 'Informe um Cep Válido.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
      setBuscaCepSubmit(false);
    }
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const handleChange = (value: any, formattedValue: any) => {
    setPatient({
      ...patient,
      dob: value ? value : null,
    });
  };

  const handleChangePatientStatus = () => {
    if (patient?.isActive) {
      setModal({
        isOpen: true,
        body:
          'Você tem certeza que deseja desativar este paciente? Com isso o mesmo ficará impossibilitado de acessar a plataforma e realizar atendimentos.',
        className: '',
        title: 'Mensagem',
        toggle: () => {
          setPatient({
            ...patient,
            isActive: !patient.isActive,
          });
          toggle();
        },
        leavingCallback: () => toggle(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body:
          'Você tem certeza que deseja ativar este paciente? Com isso o mesmo poderá receber atendimentos.',
        className: '',
        title: 'Mensagem',
        toggle: () => {
          setPatient({
            ...patient,
            isActive: !patient?.isActive,
          });
          toggle();
        },
        leavingCallback: () => toggle(),
        okButton: 'Ok',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getPatient({ id }));
      setPatient({
        ...response.data,
        cep: formatString(maskZipCode, response.data.cep),
        cpf: formatString(maskCPF, response.data.cpf),
        gender: response.data.gender.toString(),
      });
    };
    fetchData();
  }, [dispatch, id]);

  const onSubmit = async (values: PatientUser) => {
    values.cep = values.cep?.replace(/[^a-zA-Z0-9]/g, '');
    values.cpf = values.cpf?.replace(/[^a-zA-Z0-9]/g, '');
    const response = await dispatch(updatePatientUser(values));

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Dados alterados com sucesso.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
    } else {
      setModal({
        isOpen: true,
        body: response.data.errorMessage,
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        leavingCallback: () => history.goBack(),
        okButton: 'Tentar novamente',
      });
    }
  };

  const onFormChange = (values: Partial<PatientUser>) => {
    setPatient({ ...values });
  };

  const redirectToPatientHistory = () => {
    history.push(`${process.env.PUBLIC_URL}/${user.role}/patient-history/${id}`);
  };

  return (
    <Layout type={user.role}>
      <>
        <div className="view-profile-page">
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
              <Text
                title={user.role === 'patient' ? 'MEU PERFIL' : 'PERFIL DO PACIENTE'}
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={1}>
              <Row>
                <GenericAvatar
                  size="100"
                  imageStyle={{ width: '100px', height: '100px', cursor: 'pointer' }}
                  avatarName={`${patient?.firstName} ${patient?.lastName}`}
                />
              </Row>
            </Col>
            <Col className="ml-5">
              <Form
                onSubmit={onSubmit}
                initialValues={{ ...patient }}
                render={({ handleSubmit, submitting }) => (
                  <>
                    <form>
                      <FormSpy onChange={({ values }) => onFormChange(values)} />
                    </form>
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Dados pessoais"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={6}>
                          <Field
                            name="firstName"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Nome *"
                                placeholder="Nome"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                required={true}
                                disabled={submitting}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={6}>
                          <Field
                            name="lastName"
                            validate={required}
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Sobrenome *"
                                placeholder="Sobrenome"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                required={true}
                                disabled={submitting}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={6}>
                          <Field
                            name="cpf"
                            validate={required}
                            parse={formatString(maskCPF)}
                            disabled={submitting}
                            render={({ input, meta }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="CPF *"
                                placeholder="000.000.000-00"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                required={true}
                                disabled={submitting}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={6}>
                          <Field
                            name="rg"
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="RG"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={6}>
                          <Field
                            name="susNumber"
                            disabled={submitting}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Cartão SUS"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={6}>
                          <Field
                            name="email"
                            disabled={submitting}
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="E-mail"
                                placeholder="email@dominio"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                type="email"
                                disabled={submitting}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={1}>
                          <Field
                            name="ddd"
                            disabled={submitting}
                            parse={formatString(maskDDD)}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="DDD"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="phone"
                            disabled={submitting}
                            parse={formatString(maskPhoneNumber)}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Celular"
                                placeholder="00000-0000"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting}
                              />
                            )}
                          />
                        </Col>

                        <Col sm={3}>
                          <Field
                            name="gender"
                            disabled={submitting}
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Select
                                name={input.name}
                                id={input.name}
                                label="Genêro *"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                type="select"
                                disabled={submitting}
                                options={Gender}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="dob"
                            validate={required}
                            render={({ input, meta }) => (
                              <DatePicker
                                input={input}
                                metaError={meta}
                                handleChange={(v: any, f: any) => handleChange(v, f)}
                                label="Data de Nascimento *"
                                submitting={submitting}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {user.role === 'partner' && (
                        <>
                          <Row className="mt-4">
                            <Col sm={3}>
                              <FormGroup className="ml-2" check>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="isActive"
                                    onClick={handleChangePatientStatus}
                                    checked={patient?.isActive}
                                    disabled={submitting || buscaCepSubmit}
                                  />
                                  <span>Paciente ativo</span>
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col sm={12}>
                              <Field
                                name="statusDescription"
                                disabled={submitting}
                                parse={(value: string) => value}
                                render={({ input }) => (
                                  <TextArea
                                    name={input.name}
                                    id={input.name}
                                    label="Informe o motivo se necessário"
                                    onChange={input.onChange}
                                    onBlur={input.onBlur}
                                    value={input.value}
                                    disabled={submitting || buscaCepSubmit}
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                    <div className="bordered-container">
                      <Row className="mt-2">
                        <Col>
                          <Text
                            title="Endereço"
                            style={{
                              fontSize: '16px',
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={3}>
                          <Field
                            name="cep"
                            validate={required}
                            parse={formatString(maskZipCode)}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Cep *"
                                  placeholder="CEP"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Button
                            disabled={buscaCepSubmit}
                            className="mt-4"
                            onClick={() => buscaCep()}
                          >
                            BUSCAR CEP
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={7}>
                          <Field
                            name="streetAddress"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <div>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Logradouro *"
                                  placeholder="Logradouro"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </div>
                            )}
                          />
                        </Col>
                        <Col sm={2}>
                          <Field
                            name="number"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Número *"
                                  placeholder="Número"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="complement"
                            parse={(value: string) => value}
                            render={({ input }) => (
                              <Input
                                name={input.name}
                                id={input.name}
                                label="Complemento"
                                placeholder="Complemento"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                disabled={submitting || buscaCepSubmit}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4 mb-4">
                        <Col sm={3}>
                          <Field
                            name="neighborhood"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Bairro *"
                                  placeholder="Bairro"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="city"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  name={input.name}
                                  id={input.name}
                                  label="Cidade *"
                                  placeholder="Cidade"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                  value={input.value}
                                  disabled={submitting || buscaCepSubmit}
                                  required={true}
                                  metaError={meta}
                                />
                              </>
                            )}
                          />
                        </Col>
                        <Col sm={4}>
                          <Field
                            name="state"
                            validate={required}
                            parse={(value: string) => value}
                            render={({ input, meta }) => (
                              <Select
                                name={input.name}
                                id={input.name}
                                label="Estado *"
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                value={input.value}
                                type="select"
                                disabled={submitting || buscaCepSubmit}
                                options={State}
                                required={true}
                                metaError={meta}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row className="mt-4 justify-content-center">
                      <Col sm={4}>
                        <Button onClick={() => redirectToPatientHistory()}>HISTÓRICO</Button>
                      </Col>
                      <Col sm={4}>
                        <Button onClick={handleSubmit} disabled={submitting || buscaCepSubmit}>
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              />
            </Col>
          </Row>
        </div>
        <TummiModal {...modal} />
      </>
    </Layout>
  );
};
