import { useRef, useState } from 'react';
import { Form, FormSpy, Field } from 'react-final-form';
import { Col, Row, Container } from 'reactstrap';
import { useAppDispatch } from '../../store';
import { Input } from '../../components/Input';
import { useHistory } from 'react-router-dom';
import { Company } from '../../slices/company';
import { CreateCompany } from '../../slices/company';
import { CreatePartner, PartnerUser } from '../../slices/partner';
import { maskZipCode, maskCNPJ, maskPhoneNumber } from '../../utils/Mask';
import formatString from 'format-string-by-pattern';
import { isValidCep } from '../../utils/ValidCep';
import { ITummiModalProps, TummiModal } from '../../components/Modal';
import { ReturnLink } from '../../components/ReturnLink';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';
import { State } from '../../utils/EnumStates';
import ReCAPTCHA from 'react-google-recaptcha';

import './styles.scss';

const { consultarCep } = require('correios-brasil');
var sha1 = require('sha1');
var generator = require('generate-password');

export const PublicRegistration = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const required = (value: any) => (value ? undefined : 'Campo Obrigatório');
  const [company, setCompany] = useState<Company>({});
  const [captcha, setCaptcha] = useState('');
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleOnCaptcha = (value: any) => {
    if (value) {
      setCaptcha(value);
    }
  };

  const onSubmit = async (values: Company) => {
    setCaptcha('');
    recaptchaRef?.current?.reset();
    values.cep = values.cep?.replace(/[^a-zA-Z0-9]/g, '');
    values.CNPJ = values.CNPJ?.replace(/[^a-zA-Z0-9]/g, '');

    const response = await dispatch(CreateCompany(values));

    if (response.data.success) {
      const password = generator.generate({
        length: 10,
        numbers: true,
      });

      const newPartner: PartnerUser = {
        ...values,
        login: values.CNPJ,
        password: sha1(password),
        passwordGenerated: password,
        firstName: values.companyName,
      };
      const partnerResponse = await dispatch(CreatePartner(newPartner));
      if (partnerResponse.data.success) {
        history.push(`${process.env.PUBLIC_URL}/success-registration`);
      }
    }
  };

  const [buscaCepSubmit, setBuscaCepSubmit] = useState(false);

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const buscaCep = () => {
    setBuscaCepSubmit(true);
    if (company?.cep && isValidCep(company?.cep)) {
      consultarCep(company?.cep.replaceAll('.', '').replaceAll('-', ''))
        .then((response: any) => {
          if (response) {
            setCompany({
              ...company,
              streetAddress: response.logradouro,
              complement: response.complemento,
              neighborhood: response.bairro,
              city: response.localidade,
              state: response.uf,
            });
          }
          setBuscaCepSubmit(false);
        })
        .catch((err: any) => {
          setModal({
            isOpen: true,
            body: 'Cep não existente na base dos correios.',
            className: '',
            title: 'Mensagem',
            toggle: () => toggle(),
            okButton: 'Ok',
          });
          setBuscaCepSubmit(false);
        });
    } else {
      setModal({
        isOpen: true,
        body: 'Informe um Cep Válido.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
      });
      setBuscaCepSubmit(false);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: false,
    body: '',
    className: '',
    toggle: () => setShowModal(!showModal),
    title: '',
  });

  const onFormChange = (values: Partial<Company>) => {
    setCompany({ ...values });
  };

  return (
    <div className="public-registration">
      <Container>
        <Row style={{ marginTop: '50px' }}>
          <Col>
            <Text
              title="Que ótimo que a sua empresa optou pela parceria com a nossa plataforma de teleatendimento!!!"
              style={{
                fontSize: '24px',
                color: '#000000',
                fontWeight: 500,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Text
              title="Por favor, realize seu cadastro que nosso time comercial irá entrar contato com você."
              style={{
                fontSize: '16px',
                color: '#000000',
                fontWeight: 500,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4 mb-4 d-flex justify-content-start">
          <ReturnLink title="Voltar" />
          <Text
            title="Cadastre-se"
            style={{
              fontSize: '24px',
              marginBottom: '0px',
              marginTop: '5px',
              marginLeft: '15px',
            }}
          />
        </Row>
        <Form
          onSubmit={onSubmit}
          initialValues={{ ...company }}
          render={({ handleSubmit, submitting }) => (
            <>
              <form>
                <FormSpy onChange={({ values }) => onFormChange(values)} />
              </form>
              <Row>
                <Col sm={7}>
                  <Field
                    name="companyName"
                    validate={required}
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Razão Social *"
                        placeholder="Razão Social"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        required={true}
                        metaError={meta}
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
                <Col sm={5}>
                  <Field
                    name="CNPJ"
                    parse={formatString(maskCNPJ)}
                    validate={required}
                    disabled={submitting}
                    render={({ input, meta }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="CNPJ *"
                        placeholder="00.000.000/0000-00"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        required={true}
                        metaError={meta}
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={7}>
                  <Field
                    name="email"
                    validate={required}
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="E-mail  *"
                        placeholder="email@dominio"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        type="email"
                        required={true}
                        metaError={meta}
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    name="ddd"
                    disabled={submitting}
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="DDD"
                        placeholder="00"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="phone"
                    disabled={submitting}
                    parse={formatString(maskPhoneNumber)}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Telefone"
                        placeholder="00000-0000"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Text
                    title="Endereço"
                    style={{
                      fontSize: '24px',
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={4}>
                  <Field
                    name="cep"
                    validate={required}
                    parse={formatString(maskZipCode)}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="CEP *"
                          placeholder="CEP"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Button
                    className="mt-4"
                    type="primary"
                    onClick={() => buscaCep()}
                    disabled={submitting || buscaCepSubmit}
                  >
                    BUSCAR CEP
                  </Button>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={7}>
                  <Field
                    name="streetAddress"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <div>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Logradouro *"
                          placeholder="Logradouro"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    name="number"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Número *"
                          placeholder="Número"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="complement"
                    parse={(value: string) => value}
                    render={({ input }) => (
                      <Input
                        name={input.name}
                        id={input.name}
                        label="Complemento"
                        placeholder="Complemento"
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        value={input.value}
                        disabled={submitting || buscaCepSubmit}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={3}>
                  <Field
                    name="neighborhood"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Bairro *"
                          placeholder="Bairro"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="city"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Cidade *"
                          placeholder="Cidade"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting || buscaCepSubmit}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="state"
                    validate={required}
                    parse={(value: string) => value}
                    render={({ input, meta }) => (
                      <>
                        <Select
                          name={input.name}
                          id={input.name}
                          label="Estado *"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          type="select"
                          disabled={submitting || buscaCepSubmit}
                          options={State}
                          required={true}
                          metaError={meta}
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-4 justify-content-center">
                <Col sm={4} className="ml-4">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                    onChange={handleOnCaptcha}
                  />
                </Col>
              </Row>
              <Row className="mt-4 mb-4 justify-content-center">
                <Col sm={4}>
                  <Button
                    type="primary"
                    className="mt-4"
                    onClick={() => {
                      if (captcha) {
                        handleSubmit();
                      }
                    }}
                    disabled={submitting || buscaCepSubmit}
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
              <TummiModal {...modal} />
            </>
          )}
        />
      </Container>
    </div>
  );
};
