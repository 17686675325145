import { useState, useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Layout } from '../../../components/Layout';
import { RootState, useAppDispatch } from '../../../store';
import { Input } from '../../../components/Input';
import { PatientCard } from '../../../components/PatientCard';
import { PatientUser } from '../../../slices/admin';
import { SearchDoctorPatients } from '../../../slices/attendance';
import { Button } from '../../../components/Button';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';

import './styles.scss';

type PatientFilter = {
  name?: string;
  cpf?: string;
};

export const DoctorPatients = () => {
  const user = useSelector((state: RootState) => state.user);
  const [patient, setPatient] = useState<PatientFilter | null>({ name: '', cpf: '' });
  const [patients, setPatients] = useState<PatientUser[]>();

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    const fetchData = async () => {
      const response = await dispatch(SearchDoctorPatients(user.id, patient?.name, patient?.cpf));
      setPatients(response.data);
    };
    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(SearchDoctorPatients(user.id, patient?.name, patient?.cpf));
      setPatients(response.data);
    };
    fetchData();
  }, []);

  const onFormChange = (values: Partial<PatientFilter>) => {
    setPatient({ ...values });
  };

  return (
    <div className="doctors-patients">
      <Layout type="doctor">
        <>
          <Row className="mt-4 mb-4">
            <Col style={{ display: 'flex' }}>
              <ReturnLink title="VOLTAR" />
              <Text
                title="PACIENTES"
                style={{
                  fontSize: '24px',
                  marginBottom: '0px',
                  marginTop: '5px',
                  marginLeft: '15px',
                }}
              />
            </Col>
          </Row>
          <Form
            onSubmit={onSubmit}
            initialValues={{ ...patient }}
            render={({ handleSubmit, submitting }) => (
              <>
                <form>
                  <FormSpy onChange={({ values }) => onFormChange(values)} />
                </form>
                <Row className="mt-4">
                  <Col sm={3}>
                    <Field
                      name="name"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="Paciente"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting}
                          placeholder="Ex.: José da Silva"
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="cpf"
                      disabled={submitting}
                      parse={(value: string) => value}
                      render={({ input }) => (
                        <Input
                          name={input.name}
                          id={input.name}
                          label="CPF"
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          value={input.value}
                          disabled={submitting}
                          placeholder="000.000.000-00"
                        />
                      )}
                    />
                  </Col>
                  <Col sm={3}>
                    <Button className="mt-4" disabled={submitting} onClick={handleSubmit}>
                      Filtrar
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          />
          <Row className="mt-3">
            <div className="doctor-patients-cards-container">
              {patients?.map((patient, index) => {
                return (
                  <div className="doctor-patients-cards">
                    <PatientCard patient={patient} role={user.role} />
                  </div>
                );
              })}
            </div>
          </Row>
        </>
      </Layout>
    </div>
  );
};
