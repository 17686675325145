import React, { useState, FC } from 'react';
import { AccordeonProps } from './interface';
import { Col, Row } from 'reactstrap';

import './styles.scss';
import ArrowDown from '../../resources/images/arrowDownCircle.svg';
import ArrowUp from '../../resources/images/arrowUpCircle.svg';

export const Accordeon: FC<AccordeonProps> = React.memo(({ text, children, startOpen }) => {
  const [isOpen, setIsOpen] = useState(startOpen || false);

  const changeAccordion = (isOpen: boolean) => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordeon">
      {isOpen && (
        <div>
          <Row>
            <Col className="bordered-container">
              <Row>
                <Col>
                  <Row>
                    <Col className="mt-2 accordeon-section-title">
                      <span>{text} </span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <img
                        src={ArrowUp}
                        alt="open accordeon"
                        style={{
                          marginTop: '10px',
                          width: '30px',
                          height: '30px',
                          marginRight: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => changeAccordion(isOpen)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>{children}</Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
      {!isOpen && (
        <div>
          <Row>
            <Col className="bordered-container">
              <Row>
                <Col className="mt-2 accordeon-section-title">
                  <span>{text} </span>
                </Col>
                <Col className="d-flex justify-content-end">
                  <img
                    src={ArrowDown}
                    alt="open accordeon"
                    style={{
                      marginTop: '10px',
                      width: '30px',
                      height: '30px',
                      marginRight: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => changeAccordion(isOpen)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
});
