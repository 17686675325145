import React from 'react';

import './styles.scss';

export const Footer = () => (
  <footer className="generic-footer">
    <div className="d-flex justify-content-end">
      <p>Powered by Thummi©</p>
    </div>
  </footer>
);
