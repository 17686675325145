import { Layout } from '../../../components/Layout';
import { Row, Col } from 'reactstrap';
import { ReturnLink } from '../../../components/ReturnLink';
import { Text } from '../../../components/Text';

export const AdminHome = () => (
  <>
    <Layout type="admin">
      <>
        <Row className="mt-4 mb-4">
          <Col style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="DASHBOARD"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
        </Row>
      </>
    </Layout>
  </>
);
