import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Button } from '../../../components/Button';
import { Layout } from '../../../components/Layout';
import { RootState, useAppDispatch } from '../../../store';
import { Accordeon } from '../../../components/Accordeon';
import { Attendance, getAttendance } from '../../../slices/attendance';
import { ChatBot } from '../../../components/ChatBot';
import { Indicador } from '../../../components/Indicator';
import { Text } from '../../../components/Text';
import { TummiModal, ITummiModalProps } from '../../../components/Modal';
import initPrescriptionPatient from '../../../utils/initPrescriptionPatient';
import {
  ChatMessage,
  getEmotionalQuestions,
  GetPrescriptionLink,
  SaveEmotionalQuestions,
} from '../../../slices/patient';
import { ReturnLink } from '../../../components/ReturnLink';
import { PatientInfo } from '../../../components/PatientInfo';
import { TextArea } from '../../../components/TextArea';
import { downloadBoleto } from '../../../slices/payment';
import { StatusAttendance } from '../../../utils/EnumStatusAttendance';
import './styles.scss';

type Params = { id: string };

export const PatientAttendance = () => {
  const { id } = useParams<Params>();
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();

  const [attendance, setAttendance] = useState<Attendance>({
    statusAttendance: 0,
  });

  const [showCancelActions, setShowCancelActions] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [showResultEmotinalTest, setShowResultEmotinalTest] = useState(false);
  const [showChatBotTest, setShowChatBot] = useState(true);

  const dispatch = useAppDispatch();

  const handleSaveEmotionalTest = async (questions: ChatMessage[]) => {
    const response = await dispatch(
      SaveEmotionalQuestions({ attendanceId: attendance.id, messages: questions }),
    );
    if (response.data.success) {
      setShowResultEmotinalTest(true);
      setShowChatBot(false);
    }
  };

  const handleGetPrescriptionLink = async () => {
    const response = await dispatch(GetPrescriptionLink({ attendanceId: attendance.id }));
    if (response.data) {
      window.open(response.data);
    }
  };

  const initPrescription = () => {
    var script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('data-token', user.memedToken || '');
    script.src = process.env.REACT_APP_MEMED_API || '';
    script.onload = function () {
      // Obs: Essa função precisa ser implementada, seguindo o artigo de comandos para definição de paciente (Link abaixo).
      initPrescriptionPatient();
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    const fetchAttendanceData = async () => {
      const response = await dispatch(getAttendance(id));

      const todaysDate = new Date();
      const attendanceDate = new Date(response.data.date);

      const diff = (attendanceDate.getTime() - todaysDate.getTime()) / 3600000;

      setShowCancelActions(diff > 24);

      setAttendance(response.data);
      if (response.data.doctorTelemedicine.doctorType === 1) {
        setShowResultEmotinalTest(response.data.das21Completed);
        setShowChatBot(!response.data.das21Completed);
      }
    };
    const fetchEmotionalQuestions = async () => {
      const response = await dispatch(getEmotionalQuestions());
      setChatMessages(response.data);
    };
    fetchAttendanceData();
    fetchEmotionalQuestions();
    initPrescription();
  }, [dispatch, id]);

  const redirectToAttedanceCall = (attedanceId: string) => {
    window.open(`${process.env.PUBLIC_URL}/${user.role}/call/${attedanceId}`);
  };

  const pixPayment = () => {
    return (
      <>
        <Row className="mt-2 justify-content-center">
          <img
            alt=""
            src={attendance.pixTransaction?.qrCode || ''}
            style={{ width: '40%', borderRadius: 'unset' }}
          ></img>
        </Row>
        <h2
          className="title mt-4"
          style={{
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          Você também pode utilizar o código do Pix abaixo para efetuar o pagemento.
        </h2>
        <Row className="mt-4" style={{ textAlign: 'center' }}>
          <p
            style={{ textAlign: 'center', width: '-webkit-fill-available', wordWrap: 'break-word' }}
          >
            {attendance.pixTransaction?.key || ''}
          </p>
        </Row>
      </>
    );
  };

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const handleOpenModalPix = () => {
    setModal({
      isOpen: true,
      body: pixPayment(),
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      okCallback: () => toggle(),
      leavingCallback: () => toggle(),
      okButton: 'Ok',
    });
  };

  return (
    <Layout type={user.role}>
      <div className="doctor-attendance-info">
        <Row className="mt-4">
          <Col sm={4} className="justify-content-start" style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" returnLink={() => history.goBack()} />
            <Text
              title="ATENDIMENTO"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="d-flex" style={{ justifyContent: 'flex-end' }}>
            {attendance.paidBy !== 3 && (
              <Button
                disabled={
                  attendance?.statusAttendance === 3 ||
                  attendance?.statusAttendance === 0 ||
                  attendance?.statusAttendance === 1 ||
                  false
                }
                type="primary"
                onClick={() => redirectToAttedanceCall(id)}
              >
                INICIAR CHAMADA
              </Button>
            )}
            {attendance.paidBy === 3 && (
              <Button type="primary" onClick={() => redirectToAttedanceCall(id)}>
                INICIAR CHAMADA
              </Button>
            )}
            {attendance.paidBy === 0 && attendance.statusAttendance === 0 && (
              <Button
                style={{ paddingLeft: '5px' }}
                type="secondary"
                onClick={() => downloadBoleto(attendance.boletoTransaction?.bankSlipUrl || '')}
              >
                DOWNLOAD BOLETO
              </Button>
            )}
            {attendance.paidBy === 1 && attendance.statusAttendance === 0 && (
              <Button
                onClick={() => handleOpenModalPix()}
                style={{ paddingLeft: '5px' }}
                type="secondary"
              >
                PAGAR PIX
              </Button>
            )}
            {(attendance.statusAttendance === StatusAttendance.Scheduled ||
              attendance.statusAttendance === StatusAttendance.InProgress) &&
              showCancelActions && (
                <Button
                  onClick={() =>
                    history.push(
                      `${process.env.PUBLIC_URL}/${user.role}/reschedule/${attendance.id}`,
                    )
                  }
                  style={{ paddingLeft: '5px' }}
                  type="secondary"
                >
                  REAGENDAR ATEND.
                </Button>
              )}
            {(attendance.statusAttendance === StatusAttendance.Scheduled ||
              attendance.statusAttendance === StatusAttendance.InProgress) &&
              showCancelActions && (
                <Button
                  onClick={() =>
                    history.push(
                      `${process.env.PUBLIC_URL}/${user.role}/cancelattendance/${attendance.id}`,
                    )
                  }
                  style={{ paddingLeft: '5px' }}
                  type="secondary"
                >
                  CANCELAR ATEND.
                </Button>
              )}
          </Col>
        </Row>
        <PatientInfo
          firstName={attendance.patientTelemedicine?.firstName}
          lastName={attendance.patientTelemedicine?.lastName}
          dob={attendance.patientTelemedicine?.dob}
          email={attendance.patientTelemedicine?.email}
          date={attendance.date}
          ddd={attendance.patientTelemedicine?.ddd}
          phone={attendance.patientTelemedicine?.phone}
          gender={attendance.patientTelemedicine?.gender}
        />
        {attendance.doctorTelemedicine?.doctorType === 1 && (
          <Row className="mt-4">
            <Col>
              <Accordeon text="Teste Emocional" startOpen={true}>
                <>
                  {showChatBotTest && (
                    <ChatBot
                      chatMessages={chatMessages}
                      handleSaveEmotionalTest={questions => handleSaveEmotionalTest(questions)}
                    />
                  )}
                  {showResultEmotinalTest && (
                    <Row className="box">
                      <Col sm={4}>
                        <Indicador level={10} />
                        <h5 className="label">Ansiedade</h5>
                      </Col>
                      <Col sm={4}>
                        <Indicador level={5} />
                        <h5 className="label">Estresse</h5>
                      </Col>
                      <Col sm={4}>
                        <Indicador level={2} />
                        <h5 className="label">Depressão</h5>
                      </Col>
                    </Row>
                  )}
                </>
              </Accordeon>
            </Col>
          </Row>
        )}
        {attendance.doctorTelemedicine?.doctorType === 1 && (
          <Row className="mt-4">
            <Col>
              <Accordeon text="Recomendações do Psicólogo" startOpen={true}>
                <Row className="mt-3">
                  <Col className="ml-3" sm={6}>
                    <TextArea
                      disabled={attendance?.statusAttendance === 3 || false}
                      label="Recomendações do Psicólogo"
                      name="text"
                      value={attendance.patientRecomendations}
                    />
                  </Col>
                </Row>
              </Accordeon>
            </Col>
          </Row>
        )}
        {attendance.prescriptionId && attendance.prescriptionId > 0 ? (
          <Row className="mt-4">
            <Col>
              <Accordeon text="Prescrições do Médico" startOpen={true}>
                <>
                  <Row className="mt-2 justify-content-center">
                    <Col sm={4}>
                      <Button type="primary" className="mr-2" onClick={handleGetPrescriptionLink}>
                        Visualizar Prescrição
                      </Button>
                    </Col>
                  </Row>
                </>
              </Accordeon>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <TummiModal {...modal} />
      </div>
    </Layout>
  );
};
