const Trim = (strTexto: string) => {
  // Substitúi os espaços vazios no inicio e no fim da string por vazio.
  return strTexto.replace(/^s+|s+$/g, '');
};

// Função para validação de CEP.
export const isValidCep = (cep?: string) => {
  // Caso o CEP não esteja nesse formato ele é inválido!
  const objER = /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/;

  if (!cep) {
    return false;
  }

  cep = Trim(cep);
  if (cep.length > 0) {
    if (objER.test(cep)) return true;
    else return false;
  }
};
