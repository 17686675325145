import React from 'react';
import { CSSProperties } from 'react';
import { FormGroup, Button as ReactstrapButton } from 'reactstrap';

import './styles.scss';

type Props = {
  id?: string;
  type?: string;
  style?: CSSProperties;
  onClick?: () => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Button = ({ id, children, className, disabled, onClick, type, style }: Props) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const getButtonType = () => {
    switch (type) {
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'secondary';
      case 'landing':
        return 'landing';
      default:
        return 'default';
    }
  };

  return (
    <FormGroup>
      <ReactstrapButton
        id={id}
        className={`${getButtonType()} ${className}`}
        disabled={disabled}
        onClick={handleOnClick}
        style={style}
      >
        <text>{children}</text>
      </ReactstrapButton>
    </FormGroup>
  );
};
