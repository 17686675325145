import React from 'react';

import './styles.scss';

interface ITtitleHeaderProps {
  title: string;
  style?: React.CSSProperties;
  isActive?: boolean;
}

export const TitleHeader = ({ title, style, isActive }: ITtitleHeaderProps) => {
  return (
    <p className="title-header" style={{ color: isActive ? '#008171' : '#003030', ...style }}>
      {title}
    </p>
  );
};
