import { PatientUser } from '../../slices/admin';
import { useHistory } from 'react-router-dom';
import AttendanceIcon from '../../resources/images/attendanceIcon.svg';
import HistoryIcon from '../../resources/images/historyIcon.svg';
import PencilIcon from '../../resources/images/pencilIcon.svg';
import Skeleton from 'react-loading-skeleton';
import { MDBDataTable } from 'mdbreact';

import './styles.scss';
interface IPatientsTableProps {
  patients?: PatientUser[];
  role?: string;
  isLoading?: boolean;
}

export const PatientsTable = ({ patients, role, isLoading }: IPatientsTableProps) => {
  const history = useHistory();

  const getActions = (id?: string, isActive?: boolean) => {
    return (
      <div className="attendance-table-buttons">
        {isActive && (
          <span
            title="Criar Atendimento"
            onClick={() => history.push(`${process.env.PUBLIC_URL}/${role}/newattendance/${id}`)}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          >
            <img
              src={AttendanceIcon}
              alt="alert"
              style={{
                width: '22px',
                height: '15px',
              }}
            />
          </span>
        )}
        <span
          title="Perfil"
          onClick={() => history.push(`${process.env.PUBLIC_URL}/${role}/patient/${id}`)}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={PencilIcon}
            alt="alert"
            style={{
              width: '22px',
              height: '15px',
            }}
          />
        </span>
        <span
          title="Histórico"
          onClick={() => history.push(`${process.env.PUBLIC_URL}/${role}/patient-history/${id}`)}
          style={{ marginLeft: '10px', cursor: 'pointer' }}
        >
          <img
            src={HistoryIcon}
            alt="alert"
            style={{
              width: '22px',
              height: '15px',
            }}
          />
        </span>
      </div>
    );
  };

  const patientsTable: any = patients?.map((patient: PatientUser) => {
    return {
      fullname: patient?.fullname,
      sus: patient?.susNumber,
      email: patient?.email,
      state: patient?.state,
      city: patient?.city,
      phone: `${patient?.ddd} ${patient?.phone}`,
      actions: getActions(patient?.id, patient.isActive),
    };
  });

  const data = {
    columns: [
      {
        label: 'Nome',
        field: 'fullname',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Número SUS',
        field: 'sus',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Telefone',
        field: 'phone',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Estado',
        field: 'state',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Cidade',
        field: 'city',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Ações',
        field: 'actions',
        sort: 'asc',
        width: 100,
      },
    ],
    rows: patientsTable,
  };

  const rows: any = [];
  for (let i = 0; i < 3; i++) {
    rows.push({
      fullname: <Skeleton width={150} />,
      sus: <Skeleton width={150} />,
      phone: <Skeleton width={150} />,
      city: <Skeleton width={150} />,
      actions: <Skeleton width={150} />,
    });
  }

  const isLoadingData = {
    ...data,
    rows: rows,
  };

  return (
    <div className="patients-table">
      {!isLoading ? (
        <MDBDataTable
          searching
          searchLabel="Pesquisa rápida"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'médicos']}
          theadColor="indigo"
          striped
          bordered
          data={data}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      ) : (
        <MDBDataTable
          searching
          searchLabel="Pesquisa rápida"
          entriesLabel="Exibir"
          infoLabel={['Exibindo', 'de', 'de um total de', 'médicos']}
          theadColor="indigo"
          striped
          bordered
          data={isLoadingData}
          Light
          paginationLabel={['Voltar', 'Avançar']}
          noRecordsFoundLabel="Não foram encontrados resultados"
        />
      )}
    </div>
  );
};
