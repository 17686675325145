import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

import './styles.scss';

var ReactStrapDatePicker = require('reactstrap-date-picker');

type Props = {
  label?: string;
  type?: InputType;
  labelClass?: string;
  metaError?: any;
  input: any;
  submitting?: boolean;
  handleChange: (v: any, f: any) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const DatePicker = ({ label, id, metaError, input, submitting, handleChange }: Props) => {
  const dayLabels = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  const monthLabels = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  return (
    <FormGroup style={{ marginBottom: '0.2rem' }}>
      {label && (
        <Label
          className={`label-class ${
            metaError?.error && metaError?.touched ? 'error-label-class' : ''
          }`}
          for={id}
        >
          {label}
        </Label>
      )}
      <ReactStrapDatePicker
        className={`datepicker ${
          metaError?.error && metaError?.touched ? 'error-input-class' : ''
        }`}
        name={input.name}
        id={input.name}
        onChange={(v: any, f: any) => handleChange(v, f)}
        onBlur={input.onBlur}
        value={input.value}
        disabled={submitting}
        dateFormat="DD/MM/YYYY"
        showClearButton={false}
        dayLabels={dayLabels}
        monthLabels={monthLabels}
        cellPadding="8px"
      />
      {metaError?.error && metaError?.touched && (
        <span className="error-message">{metaError.error}</span>
      )}
    </FormGroup>
  );
};
