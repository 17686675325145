import React from 'react';
import { TitleHeader } from '../TitleHeader';

interface IMenuLinkProps {
  logo?: string;
  handleOnClick?: (event?: any) => void;
  title: string;
  style?: React.CSSProperties;
  logoStyle?: React.CSSProperties;
  isActive?: boolean;
}

export const MenuLink = ({
  logo,
  handleOnClick,
  title,
  style,
  logoStyle,
  isActive,
}: IMenuLinkProps) => {
  return (
    <div
      onClick={() => {
        if (handleOnClick) {
          handleOnClick();
        }
      }}
      style={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      {logo && (
        <img
          src={logo}
          alt={'Leave Logo'}
          style={{ width: '13px', height: '13px', ...logoStyle }}
        />
      )}
      <TitleHeader title={title} isActive={isActive} />
    </div>
  );
};
