import axios from 'axios';

import {
  LoginPayload,
  getDoctorsPayload,
  getPatientsPayload,
  getUserPayload,
  getAdministrativePayload,
  searchSpecialityByDayPayload,
  getAttendancesDoctorPayload,
  searchAttendancesPayload,
  searchAttendancesByPatientPayload,
  getAdminUsersPayload,
  paymentBoletoPayload,
  paymentCardPayload,
  paymentPixPayload,
  updateUserStatusPayload,
  searchAttendancesInfo,
  searchNonMedicalAttendancesInfo,
} from './apiTypes';

import { DoctorUser, PatientUser } from '../slices/admin';
import { Attendance, AvaiableSchedule } from '../slices/attendance';

import { getUserStorage } from '../utils/Auth';
import { Company } from '../slices/company';
import { PartnerUser } from '../slices/partner';
import { ChatMessage } from '../slices/patient';
import { UserTelemedicine } from '../slices/userTelemedicine';
import { Tummi } from '../slices/tummi';
import { AmpalForm } from '../slices/ampal';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
  if (!config?.baseURL?.endsWith('login') || !config?.baseURL?.endsWith('')) {
    const user = getUserStorage();
    config.headers.Authorization = `Bearer ${user.token}`;
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response?.status) {
      window.location.href = '/login';
    } else {
      return Promise.reject(error);
    }
  },
);

var sha1 = require('sha1');

export class Api {
  getSomething = async (): Promise<{ someProp: string }> => axios.get('/api/route/endpoint');

  login = async ({ login, password }: LoginPayload) => {
    const encryptedPassword = sha1(password);
    return axios.post('/authenticate', { login, password: encryptedPassword });
  };

  updateUserPassword = async (
    login?: string,
    password?: string,
    newPassword?: string,
    role?: string,
  ) => {
    return axios.post('/authenticate/updateuserpassword', {
      login: login,
      password: sha1(password),
      newPassword: sha1(newPassword),
      role,
    });
  };

  recoverPassword = async (email?: string) => {
    return axios.post('/authenticate/recoverpassword', {
      email: email,
    });
  };

  updateUserImageProfile = async (login?: string, image?: any, role?: string) => {
    return axios.post('/authenticate/updateuserimageprofile', {
      login,
      image,
      role,
    });
  };

  getAdminUsers = async ({ name, cpf, login, pageSize, page }: getAdminUsersPayload) => {
    return axios.get(
      `/admin/getadminusers?&name=${name}&cpf=${cpf}&login=${login}&pageSize=${pageSize}&page=${page}`,
    );
  };

  getAdmin = async ({ id }: getUserPayload) => {
    return axios.get(`/admin/getadmin?id=${id}`);
  };

  getAmpalForm = async ({ id }: getUserPayload) => {
    return axios.get(`/ampal?id=${id}`);
  };

  getCompany = async ({ id }: getUserPayload) => {
    return axios.get(`/company/?id=${id}`);
  };

  getTummiCompany = async ({ id }: getUserPayload) => {
    return axios.get(`/company/tummi?id=${id}`);
  };

  getAttendance = async (id: string) => {
    return axios.get(`/attendance/getattendance?id=${id}`);
  };

  getAttendancesByPatient = async (id: string, status: number) => {
    return axios.get(`/attendance/getattendacesbypatient?patientId=${id}&status=${status}`);
  };

  getDoctors = async ({ companyId, name, speciality }: getDoctorsPayload) => {
    return axios.get(
      `/partner/doctor?companyId=${companyId}&name=${name}&speciality=${speciality}`,
    );
  };

  getDoctor = async ({ id }: getUserPayload) => {
    return axios.get(`/doctor/doctor?id=${id}`);
  };

  getDoctorSchedule = async ({ id }: getUserPayload) => {
    return axios.get(`/partner/doctor/getdoctorschedule?id=${id}`);
  };

  getAttendancesByDoctor = async ({
    doctorId,
    stringDate,
    status,
  }: getAttendancesDoctorPayload) => {
    return axios.get(
      `/attendance/getattendacesbydoctor?doctorId=${doctorId}&stringDate=${stringDate}&status=${status}`,
    );
  };

  getAdministratives = async ({
    companyId,
    name,
    cpf,
    pageSize,
    page,
  }: getAdministrativePayload) => {
    return axios.get(
      `/partner/administrative?companyId=${companyId}&page=${page}&pageSize=${pageSize}&name=${name}&cpf=${cpf}`,
    );
  };

  getAdministrative = async ({ id }: getUserPayload) => {
    return axios.get(`/partner/administrative/getadministrative?id=${id}`);
  };

  getPartner = async ({ id }: getUserPayload) => {
    return axios.get(`/partner/?id=${id}`);
  };

  getPatients = async ({ companyId, name, susnumber, cpf }: getPatientsPayload) => {
    return axios.get(
      `/patient?companyId=${companyId}&name=${name}&susnumber=${susnumber}&cpf=${cpf}`,
    );
  };

  getPatient = async ({ id }: getUserPayload) => {
    return axios.get(`/patient/getpatient?id=${id}`);
  };

  getEmotionalQuestions = async () => {
    return axios.get(`/journalcoviddialog`);
  };

  SearchDoctorPatients = async (doctorId: string, name?: string, cpf?: string) => {
    return axios.get(
      `/attendance/searchdoctorpatients?doctorId=${doctorId}&name=${name}&cpf=${cpf}`,
    );
  };

  SearchSpecialityByDay = async ({
    date,
    speciality,
    doctorId,
    companyId,
  }: searchSpecialityByDayPayload) => {
    return axios.get(
      `/attendance?date=${date}&speciality=${speciality}&doctorId=${doctorId}&companyId=${companyId}`,
    );
  };

  SearchDoctorsSpecialityByDay = async ({
    date,
    speciality,
    companyId,
  }: searchSpecialityByDayPayload) => {
    return axios.get(
      `/attendance/getdoctorsbydayandspeciality?date=${date}&speciality=${speciality}&companyId=${companyId}`,
    );
  };

  SearchAttendances = async ({
    doctorId,
    startDate,
    endDate,
    patientName,
    status,
  }: searchAttendancesPayload) => {
    return axios.get(
      `/attendance/searchattendances?doctorId=${doctorId}&startDate=${startDate}&endDate=${endDate}&patientName=${patientName}&status=${status}`,
    );
  };

  SearchAttendancesInfo = async ({ companyId, year, halfyear }: searchAttendancesInfo) => {
    return axios.get(`/dashboard?companyId=${companyId}&year=${year}&halfyear=${halfyear}`);
  };

  SearchNonMedicalAttendancesInfo = async ({
    companyId,
    year,
    monthsAgo,
  }: searchNonMedicalAttendancesInfo) => {
    return axios.get(
      `/dashboard/nonmedical?companyId=${companyId}&year=${year}&monthsAgo=${monthsAgo}`,
    );
  };

  SearchAttendancesByPatient = async ({
    patientId,
    startDate,
    endDate,
    status,
  }: searchAttendancesByPatientPayload) => {
    return axios.get(
      `/attendance/searchattendancesbypatient?patientId=${patientId}&startDate=${startDate}&endDate=${endDate}&status=${status}`,
    );
  };

  CreatePartner = async (payload: PartnerUser) => {
    return axios.post(`/partner`, payload);
  };

  UpdatePartner = async (payload: PartnerUser) => {
    return axios.put(`/partner`, payload);
  };

  SaveEmotionalQuestions = async (payload: { attendanceId?: string; messages?: ChatMessage[] }) => {
    return axios.post(`/journalcoviddialog`, payload);
  };

  GetPrescriptionLink = async (payload: { attendanceId?: string }) => {
    return axios.get(`/attendance/getprescription?attendanceId=${payload.attendanceId}`);
  };

  CreateCompany = async (payload: Company) => {
    return axios.post(`/company`, payload);
  };

  UpdateCompany = async (payload: Company) => {
    return axios.put(`/company`, payload);
  };

  CreateTummiCompany = async (payload: Tummi) => {
    return axios.post(`/company/tummi`, payload);
  };

  UpdateTummiCompany = async (payload: Tummi) => {
    return axios.put(`/company/tummi`, payload);
  };

  CreateAttendace = async (payload: AvaiableSchedule) => {
    return axios.post(`/attendance`, payload);
  };

  RescheduleAttendace = async (payload: AvaiableSchedule) => {
    return axios.post(`/attendance/rescheduleattendance`, payload);
  };

  UpdateAttendanceStatus = async (id: string, status: number) => {
    return axios.post(`/attendance/updateattendancestatus`, { id, status });
  };

  UpdateAttendance = async (payload: Attendance) => {
    return axios.post(`/attendance/updateattendance`, payload);
  };

  CancelAttendance = async (payload: Attendance) => {
    return axios.post(`/attendance/cancelattendance`, payload);
  };

  createDoctorUser = async (payload: DoctorUser) => {
    return axios.post(`/partner/doctor/createdoctoruser`, payload);
  };

  updateDoctorUser = async (payload: DoctorUser) => {
    return axios.post(`/doctor/doctor`, payload);
  };

  updateDoctorStatus = async (payload: updateUserStatusPayload) => {
    return axios.patch(`/doctor/doctor`, payload);
  };

  updatePatientUser = async (payload: PatientUser) => {
    return axios.post(`/patient/updatepatientuser`, payload);
  };

  updatePatientStatus = async (payload: updateUserStatusPayload) => {
    return axios.patch(`/patient/updatepatientuser`, payload);
  };

  savePaymentBoleto = async (payload: paymentBoletoPayload) => {
    return axios.post(`/payment/boleto`, payload);
  };

  savePaymentCard = async (payload: paymentCardPayload) => {
    return axios.post(`/payment/card`, payload);
  };

  savePaymentPix = async (payload: paymentPixPayload) => {
    return axios.post(`/payment/pix`, payload);
  };

  updateAdminUser = async (payload: UserTelemedicine) => {
    return axios.put(`/admin/updateadminuser`, payload);
  };

  createAdminUser = async (payload: UserTelemedicine) => {
    return axios.post(`/admin/createadminuser`, payload);
  };

  saveAmpalForm = async (payload: AmpalForm) => {
    return axios.post(`/ampal`, payload);
  };

  createDoctorMemed = async (payload: DoctorUser) => {
    return axios.post(`/doctor/memed`, payload);
  };

  updateDoctorUserSchedule = async (payload: DoctorUser) => {
    return axios.post(`/doctor/schedule`, payload);
  };

  createPatientUser = async (payload: PatientUser) => {
    return axios.post(`/patient/createpatientuser`, payload);
  };

  createAdministrativeUser = async (payload: UserTelemedicine) => {
    return axios.post(`/partner/administrative/createadministrativeuser`, payload);
  };
}
