import React from 'react';

export const CreateCompanySuccess = () => {
  return (
    <div>
      Sua empresa foi cadastrada com sucesso na nossa plataforma. Em seguida enviaremos para o email
      cadastrado as suas credenciais.
    </div>
  );
};
