import { Api } from '../../services/api';
import { AppDispatch } from '../../store';
import {
  getUserPayload,
  searchAttendancesByPatientPayload,
  updateUserStatusPayload,
} from '../../services/apiTypes';
import { setIsloading } from '../app';
import { PatientUser } from '../admin';

export interface ChatMessage {
  id?: string;
  title?: string;
  text?: string;
  type?: string;
  decimals?: number;
  icon?: string;
  isIncoming?: boolean;
  value?: string;
  propertyName?: string;
  mustSave?: boolean;
  nextId?: string;
  journalId?: string;
  journalType?: string;
  messageDateTime?: Date;
  options?: ChatMessage[];
}

export const getPatient = ({ id }: Partial<getUserPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getPatient({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getEmotionalQuestions = () => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getEmotionalQuestions().then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const SaveEmotionalQuestions = (
  payload: Partial<{ attendanceId?: string; messages?: ChatMessage[] }>,
) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api
    .SaveEmotionalQuestions({ attendanceId: payload.attendanceId, messages: payload.messages })
    .then(data => {
      dispatch(setIsloading({ isLoading: false }));
      return data;
    });
};

export const GetPrescriptionLink = (payload: Partial<{ attendanceId?: string }>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.GetPrescriptionLink({ attendanceId: payload.attendanceId }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const updatePatientUser = (payload: Partial<PatientUser>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.updatePatientUser(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const updatePatientStatus = (payload: Partial<updateUserStatusPayload>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.updatePatientUser(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const SearchAttendances = ({
  patientId,
  startDate,
  endDate,
  status,
}: Partial<searchAttendancesByPatientPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api
    .SearchAttendancesByPatient({ patientId, startDate, endDate, status })
    .then(data => {
      dispatch(setIsloading({ isLoading: false }));
      return data;
    });
};
