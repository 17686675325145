import { Api } from '../../services/api';
import { AppDispatch } from '../../store';
import { UserTelemedicine } from '../userTelemedicine';
import { getUserPayload, getAdminUsersPayload } from '../../services/apiTypes';

import { setIsloading } from '../app';

export type MedicalSpecialty = {
  id?: string;
  name?: string;
  description?: string;
};

export enum UserType {
  Doctor,
  Patient,
  Prefecture,
  Administrative,
}

export type BankData = {
  id?: string;
  bank?: string;
  agency?: string;
  account?: string;
};

export interface DoctorUser extends UserTelemedicine {
  crm?: string;
  specialities?: string;
  medicalSpecialtyTelemedicine?: MedicalSpecialty[];
  doctorSchedule?: DoctorSchedule;
  DoctorScheduleId?: string;
  doctorType?: number;
  CRP?: string;
  bankDataTelemedicine?: BankData;
  attendanceValue?: number;
  aboutMe?: string;
}

export interface PatientUser extends UserTelemedicine {
  susNumber?: string;
}

export type Partner = {
  id: string;
  doctors?: DoctorUser[];
  patients?: PatientUser[];
};

export type DoctorSchedule = {
  id?: string;
  timeOfConsult?: number;
  weekSchedule?: DaySchedule[];
};

export type DaySchedule = {
  id?: string;
  dayOfWeek?: number;
  scheduleInterval?: ScheduleInterval[];
};

export type ScheduleInterval = {
  id?: string;
  startTimeString?: string;
  endTimeString?: string;
  deleted?: boolean;
};

export const getAdmin = ({ id }: Partial<getUserPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getAdmin({ id }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const createAdminUser = (payload: Partial<PatientUser>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.createAdminUser(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const updateAdminUser = (payload: Partial<UserTelemedicine>) => async (
  dispatch: AppDispatch,
) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.updateAdminUser(payload).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};

export const getAdminUsers = ({
  name,
  cpf,
  login,
  pageSize,
  page,
}: Partial<getAdminUsersPayload>) => async (dispatch: AppDispatch) => {
  dispatch(setIsloading({ isLoading: true }));
  const api = new Api();
  return await api.getAdminUsers({ name, cpf, login, pageSize, page }).then(data => {
    dispatch(setIsloading({ isLoading: false }));
    return data;
  });
};
