/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, FormGroup, Label, CustomInput } from 'reactstrap';
import { Button } from '../../components/Button';
import { Text } from '../../components/Text';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { Accordeon } from '../../components/Accordeon';
import { Comorbidities } from '../../utils/EnumComorbidities';
import { CID } from '../../utils/EnumCid';
import { Vaccination } from '../../utils/EnumVaccination';
import { Allergies } from '../../utils/EnumAllergies';
import { Indicador } from '../../components/Indicator';
import { TextArea } from '../../components/TextArea';
import { SelectMulti } from '../../components/SelectMulti';
import { Input } from '../../components/Input';
import 'react-quill/dist/quill.snow.css';
import {
  Attendance,
  getAttendance,
  UpdateAttendanceStatus,
  UpdateAttendance,
} from '../../slices/attendance';
import initEventsMemed from '../../utils/initEventsMemed';
import { ITummiModalProps, TummiModal } from '../../components/Modal';
import { ReturnLink } from '../../components/ReturnLink';
import { PatientInfo } from '../../components/PatientInfo';

import './styles.scss';
import { GetPrescriptionLink } from '../../slices/patient';
import handleCopyAttendanceLink from '../../utils/copyClipboard';

type Params = { id: string };

export const DoctorAttendanceInfo = () => {
  const { id } = useParams<Params>();
  const user = useSelector((state: RootState) => state.user);
  const [attendance, setAttendance] = useState<Attendance>({
    statusAttendance: 0,
  });
  const [sliderName, setSliderValue] = useState(attendance?.pain || 0);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const updateTextInput = (event: any) => {
    setSliderValue(event.target.value);
    setAttendance({
      ...attendance,
      pain: parseInt(event.target.value, 10),
    });
  };

  const redirectToAttedanceCall = (attedanceId: string) => {
    window.open(`${process.env.PUBLIC_URL}/${user.role}/call/${attedanceId}`);
  };

  const redirectToPatientHistory = () => {
    history.push(
      `${process.env.PUBLIC_URL}/${user.role}/patient-history/${attendance.patientTelemedicine?.id}`,
    );
  };

  const initMemed = (attendance: Attendance) => {
    var script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('data-color', '#576cff');
    script.setAttribute('data-token', user.memedToken || '');
    script.src = process.env.REACT_APP_MEMED_API || '';
    script.onload = function () {
      // Obs: Essa função precisa ser implementada, seguindo o artigo de comandos para definição de paciente (Link abaixo).
      initEventsMemed(
        attendance.patientTelemedicine?.cpf,
        `${attendance.patientTelemedicine?.firstName || ''} ${
          attendance.patientTelemedicine?.lastName || ''
        }`,
        `${attendance.patientTelemedicine?.streetAddress}, ${attendance.patientTelemedicine?.number}`,
        attendance.patientTelemedicine?.city,
        `${attendance.patientTelemedicine?.ddd}${attendance.patientTelemedicine?.phone}`,
        0,
        0,
        '',
        false,
        attendance.id,
      );
    };
    document.body.appendChild(script);
  };

  const handleGetPrescriptionLink = async () => {
    const response = await dispatch(GetPrescriptionLink({ attendanceId: attendance.id }));
    if (response.data) {
      window.open(response.data);
    }
  };

  useEffect(() => {}, [attendance.statusAttendance]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getAttendance(id));

      if (response.data.comorbities?.length === 0) {
        response.data.comorbities.push({ id: '', name: '' });
      }

      if (response.data.vaccinations?.length === 0) {
        response.data.vaccinations.push({ id: '', name: '' });
      }

      if (response.data.medications?.length === 0) {
        response.data.medications.push({ id: '', name: '', dosage: '' });
      }

      if (response.data.allergies?.length === 0) {
        response.data.allergies.push({ id: '', name: '' });
      }

      if (response.data.cids?.length === 0) {
        response.data.cids.push({ id: '', name: '' });
      }

      setAttendance(response.data);
      initMemed(response.data);
      setSliderValue(response.data.pain);
    };
    fetchData();
  }, []);

  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const [modal, setModal] = useState<ITummiModalProps>({
    isOpen: showModal,
    body: '',
    toggle: () => setShowModal(!showModal),
    className: '',
    title: '',
  });

  const isAttendanceFinished = () => {
    return (attendance?.statusAttendance && attendance?.statusAttendance !== 2) || false;
  };

  const showDoctorAttendance = () => {
    if (
      (user.role === 'doctor' && attendance.doctorTelemedicine?.doctorType === 0) ||
      user.role === 'partner'
    ) {
      return true;
    }
    return false;
  };

  const savePreAttendance = async () => {
    const response = await dispatch(UpdateAttendance(attendance));

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Atendimento salvo com sucesso!',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
        leavingCallback: () => history.goBack(),
        okCallback: () => {
          toggle();
        },
      });
    } else {
      setModal({
        isOpen: true,
        body: 'Erro ao salvar atendimento, tente novamente dentro de alguns minutos.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Sim',
        leavingCallback: () => toggle(),
        okCallback: () => {
          toggle();
        },
      });
    }
  };

  const saveAttendance = async (isPrescription?: boolean) => {
    const response = await dispatch(UpdateAttendance(attendance));

    if (isPrescription) {
      await dispatch(UpdateAttendanceStatus(id, 2));
    }

    if (response.data.success) {
      setModal({
        isOpen: true,
        body: 'Atendimento salvo com sucesso!',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Ok',
        leavingCallback: () => history.goBack(),
        okCallback: () => {
          setAttendance({
            ...attendance,
            statusAttendance: 2,
          });
          history.goBack();
        },
      });
    } else {
      setModal({
        isOpen: true,
        body: 'Erro ao salvar atendimento, tente novamente dentro de alguns minutos.',
        className: '',
        title: 'Mensagem',
        toggle: () => toggle(),
        okButton: 'Sim',
        leavingCallback: () => toggle(),
        okCallback: () => {
          toggle();
        },
      });
    }
  };

  const handleSavePreAttendance = () => {
    setModal({
      isOpen: true,
      body: 'Tem certeza que deseja salvar o Pré-atendimento deste paciente?',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      okButton: 'Sim',
      leavingCallback: () => toggle(),
      okCallback: () => {
        savePreAttendance();
        toggle();
      },
    });
  };

  const handleSaveAttendance = () => {
    setModal({
      isOpen: true,
      body: 'Tem certeza que deseja salvar o Atendimento deste paciente?',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      okButton: 'Sim estou ciente.',
      leavingCallback: () => toggle(),
      okCallback: () => {
        saveAttendance();
        toggle();
      },
    });
  };

  const handleSavePrescriptionAttendance = () => {
    setModal({
      isOpen: true,
      body:
        'Tem certeza que deseja salvar a Prescrição deste paciente? Após este passo não será possivel alterar as informações preenchidas ateriormente deste atendimento.',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      okButton: 'Sim estou ciente.',
      leavingCallback: () => toggle(),
      okCallback: () => {
        saveAttendance(true);
        toggle();
      },
    });
  };

  const handleSaveCommentsAttendance = () => {
    setModal({
      isOpen: true,
      body:
        'As observações podem ser usadas paras adicionar informações ao atendimento do paciente.',
      className: '',
      title: 'Mensagem',
      toggle: () => toggle(),
      okButton: 'Sim estou ciente.',
      leavingCallback: () => toggle(),
      okCallback: () => {
        saveAttendance();
        toggle();
      },
    });
  };

  return (
    <Layout type={user.role}>
      <div className="doctor-attendance-info">
        <TummiModal {...modal} />
        <Row className="mt-4">
          <Col sm={6} className="justify-content-start" style={{ display: 'flex' }}>
            <ReturnLink title="VOLTAR" />
            <Text
              title="ATENDIMENTO"
              style={{
                fontSize: '24px',
                marginBottom: '0px',
                marginTop: '5px',
                marginLeft: '15px',
              }}
            />
          </Col>
          <Col className="justify-content-end" sm={3}>
            {attendance.paidBy !== 3 && (
              <Button
                disabled={attendance.statusAttendance !== 2}
                type="primary"
                className="mr-2"
                onClick={() => redirectToAttedanceCall(id)}
              >
                INICIAR CHAMADA
              </Button>
            )}
            {attendance.paidBy === 3 && (
              <Button type="primary" className="mr-2" onClick={() => redirectToAttedanceCall(id)}>
                INICIAR CHAMADA
              </Button>
            )}
          </Col>
          <Col className="justify-content-end" sm={3}>
            <Button className="mr-2" type="secondary" onClick={() => redirectToPatientHistory()}>
              HISTÓRICO DO PACIENTE
            </Button>
          </Col>
          <Col className="justify-content-end" sm={3}>
            <Button className="mr-2" type="secondary" onClick={() => handleCopyAttendanceLink()}>
              COPIAR LINK DA CHAMADA
            </Button>
            <a href={attendance.link} target="_blank" id="copy-my-contents">
              {attendance.link}
            </a>
          </Col>
        </Row>
        <PatientInfo
          firstName={attendance.patientTelemedicine?.firstName}
          lastName={attendance.patientTelemedicine?.lastName}
          dob={attendance.patientTelemedicine?.dob}
          email={attendance.patientTelemedicine?.email}
          date={attendance.date}
          userImage={attendance.patientTelemedicine?.image}
          ddd={attendance.patientTelemedicine?.ddd}
          phone={attendance.patientTelemedicine?.phone}
          gender={attendance.patientTelemedicine?.gender}
        />
        {showDoctorAttendance() && (
          <Row className="mt-4">
            <Col>
              <Accordeon text="Pré-Atendimento" startOpen={false}>
                <div className="ml-3">
                  <Row>
                    <Col className="mb-4">
                      <span className="section-title">Comorbidades</span>
                    </Col>
                  </Row>
                  <>
                    {attendance.comorbities?.map(function (element, index) {
                      return (
                        <Row>
                          <Col sm={4}>
                            <SelectMulti
                              onChange={e => {
                                const comorbity = attendance?.comorbities
                                  ? attendance.comorbities[index]
                                  : null;

                                if (comorbity) {
                                  comorbity.name = e?.value;
                                  const comorbities = [...(attendance.comorbities || [])];
                                  comorbities[index] = comorbity;
                                  setAttendance({
                                    ...attendance,
                                    comorbities,
                                  });
                                }
                              }}
                              isMulti={false}
                              options={Comorbidities}
                              isDisabled={isAttendanceFinished()}
                              label="Adicione as comorbidades do paciente"
                              value={{ value: element.id, label: element.name }}
                            />
                          </Col>
                          <Col className="d-flex justify-content-start mt-4" sm={2}>
                            <Button
                              disabled={isAttendanceFinished()}
                              onClick={() => {
                                const comorbities = [...(attendance.comorbities || [])];
                                comorbities.push({ id: '', name: '' });
                                setAttendance({
                                  ...attendance,
                                  comorbities,
                                });
                              }}
                            >
                              Adicionar
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                  <Row>
                    <Col className="divider mt-2 ml-4 mr-4 justify-content-center"></Col>
                  </Row>
                  <Row className="mt-4">
                    <Col className="mt-2 mb-4">
                      <span className="section-title">Hábitos de vida</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        type="select"
                        label="Tabagismo"
                        placeholder="Ex.: Diabetes"
                        className="select"
                        value={attendance.smoking}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            smoking: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1">Nunca fumou</option>
                        <option value="2">Tabagista</option>
                        <option value="3">Ex-tabagista</option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Anos Maço"
                        type="number"
                        className="input"
                        value={attendance.yearsSmoking}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            yearsSmoking: parseInt(e.target.value, 10),
                          });
                        }}
                      ></Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Etilismo"
                        type="select"
                        className="select"
                        value={attendance.ethics}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            ethics: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1">Nega consumo regular de álcool</option>
                        <option value="2">Etilista</option>
                        <option value="3">Ex-etilista</option>
                      </Input>
                    </Col>
                  </Row>
                  <>
                    {attendance.vaccinations?.map(function (element, index) {
                      return (
                        <Row>
                          <Col sm={4}>
                            <SelectMulti
                              isDisabled={isAttendanceFinished()}
                              label="Vacinação"
                              isMulti={false}
                              options={Vaccination}
                              onChange={e => {
                                const vacination = attendance?.vaccinations
                                  ? attendance.vaccinations[index]
                                  : null;

                                if (vacination) {
                                  vacination.name = e?.value;
                                  const vaccinations = [...(attendance.vaccinations || [])];
                                  vaccinations[index] = vacination;
                                  setAttendance({
                                    ...attendance,
                                    vaccinations,
                                  });
                                }
                              }}
                              value={{ value: element.id, label: element.name }}
                            />
                          </Col>
                          <Col className="d-flex justify-content-start" sm={2}>
                            <Button
                              disabled={isAttendanceFinished()}
                              className="mt-4"
                              onClick={() => {
                                const vaccinations = [...(attendance.vaccinations || [])];
                                vaccinations.push({ id: '', name: '' });

                                setAttendance({
                                  ...attendance,
                                  vaccinations,
                                });
                              }}
                            >
                              Adicionar
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                  <>
                    {attendance.allergies?.map(function (element, index) {
                      return (
                        <Row>
                          <Col sm={4}>
                            <SelectMulti
                              isDisabled={isAttendanceFinished()}
                              label="Alergias"
                              isMulti={false}
                              options={Allergies}
                              onChange={e => {
                                const allergy = attendance?.allergies
                                  ? attendance.allergies[index]
                                  : null;

                                if (allergy) {
                                  allergy.name = e?.value;
                                  const allergies = [...(attendance.allergies || [])];
                                  allergies[index] = allergy;
                                  setAttendance({
                                    ...attendance,
                                    allergies,
                                  });
                                }
                              }}
                              value={{ value: element.id, label: element.name }}
                            />
                          </Col>
                          <Col className="d-flex justify-content-start" sm={2}>
                            <Button
                              disabled={isAttendanceFinished()}
                              className="mt-4"
                              onClick={() => {
                                const allergies = [...(attendance.allergies || [])];
                                allergies.push({ id: '', name: '' });
                                setAttendance({
                                  ...attendance,
                                  allergies,
                                });
                              }}
                            >
                              Adicionar
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                  <div id="Allergies0"></div>
                  <Row className="mt-3">
                    <Col className="mt-2">
                      <span className="label-container">Medicações em uso</span>
                    </Col>
                  </Row>

                  <div id="Medications0"></div>
                  {attendance.medications?.map(function (element, index) {
                    return (
                      <Row>
                        <Col sm={3}>
                          <Input
                            label="Medicação"
                            placeholder="Ex.: Dipirona"
                            onChange={e => {
                              const medication = attendance?.medications
                                ? attendance.medications[index]
                                : null;

                              if (medication) {
                                medication.name = e.target.value;
                                const medications = [...(attendance.medications || [])];
                                medications[index] = medication;
                                setAttendance({
                                  ...attendance,
                                  medications,
                                });
                              }
                            }}
                            value={element.name}
                            disabled={isAttendanceFinished()}
                          />
                        </Col>
                        <Col sm={3}>
                          <Input
                            label="Dose"
                            placeholder="Ex.: 1g. 1cp"
                            onChange={e => {
                              const medication = attendance?.medications
                                ? attendance.medications[index]
                                : null;

                              if (medication) {
                                medication.dose = e.target.value;
                                const medications = [...(attendance.medications || [])];
                                medications[index] = medication;
                                setAttendance({
                                  ...attendance,
                                  medications,
                                });
                              }
                            }}
                            value={element.dose}
                            disabled={isAttendanceFinished()}
                          />
                        </Col>
                        <Col sm={3}>
                          <Input
                            disabled={isAttendanceFinished()}
                            type="select"
                            label="Posologia"
                            value={element.dosage}
                            onChange={e => {
                              const medication = attendance?.medications
                                ? attendance.medications[index]
                                : null;

                              if (medication) {
                                medication.dosage = e.target.value;
                                const medications = [...(attendance.medications || [])];
                                medications[index] = medication;
                                setAttendance({
                                  ...attendance,
                                  medications,
                                });
                              }
                            }}
                          >
                            <option value="">Selecione</option>
                            <option value="1">1x dia</option>
                            <option value="2">2x dia</option>
                            <option value="3">3x dia</option>
                            <option value="4">4x dia</option>
                            <option value="5">5x dia</option>
                            <option value="6">1x semana</option>
                            <option value="7">2x semana</option>
                            <option value="8">3x semana</option>
                            <option value="9">1x ao mês</option>
                            <option value="10">6/6 meses</option>
                            <option value="11">1x ao ano</option>
                          </Input>
                        </Col>
                        <Col className="mt-4" sm={3}>
                          <Button
                            disabled={isAttendanceFinished()}
                            onClick={() => {
                              const medications = [...(attendance.medications || [])];
                              medications.push({ id: '', name: '', dosage: '' });

                              setAttendance({
                                ...attendance,
                                medications,
                              });
                            }}
                          >
                            Adicionar
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <Col className="divider mt-2 ml-4 mr-4 justify-content-center"></Col>
                  </Row>
                  <Row className="mt-4 mb-4">
                    <Col className="mt-2">
                      <span className="section-title">
                        <strong>História Clínica</strong>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <TextArea
                        disabled={isAttendanceFinished()}
                        type="textarea"
                        label="História Clínica"
                        name="text"
                        value={attendance.clinicalHistory}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            clinicalHistory: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={3}>
                      {' '}
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Diurese"
                        type="select"
                        value={attendance.diuresis}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            diuresis: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="0">Diurese espontânea - paciente continente</option>
                        <option value="1">Diurese espontânea - paciente incontinente</option>
                        <option value="2">Sondagem Vesical de alívio</option>
                        <option value="3">Sondagem Vesical de Demora</option>
                        <option value="4">Cistostomia</option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        type="select"
                        label="Evacuação"
                        value={attendance.evacuation}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            evacuation: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="0">Hábito intestinal regular</option>
                        <option value="1">Constipação</option>
                        <option value="2">Diarréia</option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        type="select"
                        label="Equipamento de auxílio á marcha"
                        value={attendance.gaitAidEquipment}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            gaitAidEquipment: e.target.value,
                          });
                        }}
                      >
                        <option value="0">Selecione</option>
                        <option value="1"> Não deambula (acamado) </option>
                        <option value="2"> Cadeira de rodas </option>
                        <option value="3"> Andador </option>
                        <option value="4"> Bengala </option>
                        <option value="5"> Deambula sem auxílio </option>
                      </Input>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        type="text"
                        label="Altura"
                        placeholder="Ex.: 1,80m"
                        value={attendance.heigth}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            heigth: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        type="text"
                        label="Peso"
                        placeholder="Ex.: 70kg"
                        value={attendance.weigth}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            weigth: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <span className="label-container">
                        <strong>Sinais Vitais</strong>
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="PA (mmHG)"
                        placeholder="Ex.: 120/80 mmHG"
                        type="text"
                        value={attendance.pa}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            pa: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Frequência cardíaca"
                        type="text"
                        value={attendance.heartRate}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            heartRate: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        type="text"
                        label="Frequência respiratória"
                        value={attendance.respiratoryFrequency}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            respiratoryFrequency: e.target.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row className="mt-1">
                        <Col sm={3}>
                          <Input
                            disabled={isAttendanceFinished()}
                            label="Temperatura (ºC)"
                            type="text"
                            value={attendance.temperature}
                            onChange={e => {
                              setAttendance({
                                ...attendance,
                                temperature: e.target.value,
                              });
                            }}
                          />
                        </Col>
                        <Col sm={3}>
                          <Input
                            disabled={isAttendanceFinished()}
                            label="Saturação de Oxigênio"
                            type="text"
                            value={attendance.oxygenSaturation}
                            onChange={e => {
                              setAttendance({
                                ...attendance,
                                oxygenSaturation: e.target.value,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Estado geral"
                        type="select"
                        value={attendance.generalState}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            generalState: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1"> Bom estado geral </option>
                        <option value="2"> Regular estado geral </option>
                        <option value="3"> Estado geral ruim </option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Nível de consiência"
                        type="select"
                        value={attendance.consciousnessLevel}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            consciousnessLevel: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1"> Vigil </option>
                        <option value="2"> Sonolento(a) </option>
                        <option value="3"> Rebaixado(a) </option>
                        <option value="4"> Comatoso(a) </option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Hidratação"
                        type="select"
                        value={attendance.hydration}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            hydration: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1"> Hidratado </option>
                        <option value="2"> Desidratado </option>
                      </Input>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <span className="label-container">
                        <strong>DOR (0- sem dor, 10-dor de forte intensidade)</strong>
                      </span>
                    </Col>
                    <Col>
                      {sliderName ? (
                        <strong className="label-container">Selecionado: {sliderName}</strong>
                      ) : (
                        <span className="label-container">Por favor selecione</span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <CustomInput
                        disabled={isAttendanceFinished()}
                        min={0}
                        max={10}
                        type="range"
                        id="sliderName"
                        name="customRange"
                        onChange={updateTextInput}
                        value={attendance.pain}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Mucosas"
                        type="select"
                        value={attendance.mucous}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            mucous: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1"> Corado(a) </option>
                        <option value="2"> Descorado(a) </option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Icterícia"
                        type="select"
                        value={attendance.jaundice}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            jaundice: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1"> Ictérico(a) </option>
                        <option value="2"> Anictérico(a) </option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Input
                        disabled={isAttendanceFinished()}
                        label="Cianose"
                        type="select"
                        value={attendance.cianose}
                        onChange={e => {
                          setAttendance({
                            ...attendance,
                            cianose: e.target.value,
                          });
                        }}
                      >
                        <option value="">Selecione</option>
                        <option value="1"> Cianótico(a) </option>
                        <option value="2"> Acianótico(a) </option>
                      </Input>
                    </Col>
                  </Row>
                  <Row className="mt-4 justify-content-center">
                    <Col sm={4}>
                      <Button
                        disabled={isAttendanceFinished()}
                        onClick={() => handleSavePreAttendance()}
                      >
                        Salvar Pré-Atendimento
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Accordeon>
            </Col>
          </Row>
        )}

        {showDoctorAttendance() && (
          <Row className="mt-3">
            <Col>
              <Accordeon text="Atendimento" startOpen={true}>
                <Row className="mt-2">
                  <Col>
                    <span className="section-title mb-4 ml-3">Epidemia/Surto</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="ml-3" sm={4}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Paciente com receio de:"
                      type="select"
                      value={attendance.patientAfraidOf}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          patientAfraidOf: e.target.value,
                        });
                      }}
                    >
                      <option>Selecione</option>
                      <option>Covid-19</option>
                      <option>Sarampo</option>
                      <option>Dengue</option>
                      <option>Zika</option>
                      <option>Chikungunya</option>
                      <option>Malária</option>
                      <option>Febre Amarela</option>
                    </Input>
                  </Col>
                  <Col sm={2}></Col>
                  <Col sm={5}>
                    <Row>
                      <Col>
                        <span className="label-container">Relato Médico</span>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={3}>
                        <FormGroup className="ml-2" check>
                          <Label className="radio-input" check>
                            <Input
                              disabled={isAttendanceFinished()}
                              type="checkbox"
                              name="radio1MP"
                              onClick={e => {
                                setAttendance({
                                  ...attendance,
                                  medicalReport: 0,
                                });
                              }}
                              checked={attendance.medicalReport === 0}
                            />
                            <span className="radio-label">Orientação</span>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup className="ml-2" check>
                          <Label className="radio-input" check>
                            <Input
                              disabled={isAttendanceFinished()}
                              type="checkbox"
                              name="radio2MP"
                              onClick={e => {
                                setAttendance({
                                  ...attendance,
                                  medicalReport: 1,
                                });
                              }}
                              checked={attendance.medicalReport === 1}
                            />
                            <span className="radio-label">Suspeito</span>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup check>
                          <Label className="radio-input" check>
                            <Input
                              disabled={isAttendanceFinished()}
                              type="checkbox"
                              name="radio3MP"
                              onClick={e => {
                                setAttendance({
                                  ...attendance,
                                  medicalReport: 2,
                                });
                              }}
                              checked={attendance.medicalReport === 2}
                            />
                            <span className="radio-label">Confirmado</span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="ml-3" sm={3}>
                    <Input
                      label="Cep do paciente:"
                      value={attendance.patientTelemedicine?.cep}
                      disabled
                    ></Input>
                  </Col>
                  <Col sm={3}></Col>
                  <Col className="mt-2" sm={5}>
                    <Row>
                      <Col>
                        <span className="label-container mt-2">Sexo do paciente</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <FormGroup className="ml-2" check>
                          <Label className="radio-input" check>
                            <Input
                              type="checkbox"
                              name="radio1Gender"
                              disabled={true}
                              checked={attendance.patientTelemedicine?.gender === 0}
                            />
                            <span className="radio-label">Masculino</span>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup className="ml-2" check>
                          <Label className="radio-input" check>
                            <Input
                              type="checkbox"
                              name="radio2Gender"
                              disabled={true}
                              checked={attendance.patientTelemedicine?.gender === 1}
                            />
                            <span className="radio-label">Feminino</span>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup check>
                          <Label className="radio-input" check>
                            <Input
                              type="checkbox"
                              name="radio3Gender"
                              disabled={true}
                              checked={attendance.patientTelemedicine?.gender === 2}
                            />
                            <span className="radio-label">Outro</span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="divider mt-4 ml-4 mr-4 justify-content-center"></Col>
                </Row>
                <Row className="mt-3">
                  <Col className="ml-3">
                    <span className="section-title mb-4">Impressão diagnóstica</span>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="ml-3" sm={4}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Objetivo do atendimento"
                      type="select"
                      value={attendance.purposeOfCare}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          purposeOfCare: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Orientação</option>
                      <option value="2">Atestado</option>
                      <option value="3">Afastado</option>
                      <option value="4">Consulta de Rotina</option>
                      <option value="5">Retorno de Consulta</option>
                      <option value="6">Consulta de Urgência padrão</option>
                      <option value="7">Consulta de Monitoramento Covid pós PS</option>
                      <option value="8">Consulta de Monitoramento pós Internação</option>
                      <option value="9">Consulta de Reavaliação/Follow-up</option>
                      <option value="10">Consulta Covid Pré-Cirúrgico</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-3 mt-4" sm={6}>
                    <TextArea
                      style={{ minHeight: '200px' }}
                      label="História/Exame Físico/Medicamentos em Uso"
                      type="textarea"
                      value={attendance.history}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          history: e.target.value,
                        });
                      }}
                    ></TextArea>
                  </Col>
                </Row>
                <Row>
                  <Col className="divider mt-4 ml-4 mr-4 justify-content-center"></Col>
                </Row>
                <Row className="mt-4 ">
                  <Col className="ml-3">
                    <span className="section-title mb-4">
                      <strong>Ausculta Cardíaca</strong>
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="ml-3" sm={3}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Ritmo"
                      type="select"
                      value={attendance.rhythm}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          rhythm: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Regular</option>
                      <option value="2">Irregular</option>
                    </Input>
                  </Col>
                  <Col sm={3}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Bulhas"
                      type="select"
                      value={attendance.bulhas}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          bulhas: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Hipofonéticas</option>
                      <option value="2">Hiperfonéticas</option>
                      <option value="3">Normofonéticas</option>
                    </Input>
                  </Col>
                  <Col sm={3}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Sopro"
                      type="select"
                      value={attendance.sopro}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          sopro: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Sem Sopro</option>
                      <option value="2">Com Sopro</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col className="divider mt-4 ml-4 mr-4 justify-content-center"></Col>
                </Row>
                <Row className="mt-4">
                  <Col className="ml-3">
                    <span className="section-title mb-4">
                      <strong>Ausculta Pulmonar</strong>
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="ml-3" sm={4}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Murmúrio vesicular"
                      type="select"
                      value={attendance.vesicularMurmur}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          vesicularMurmur: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Presente e simétrico</option>
                      <option value="2">Globalmente reduzido</option>
                      <option value="3">Reduzido à esquerda</option>
                      <option value="4">Reduzido à direita</option>
                    </Input>
                  </Col>
                  <Col sm={1}></Col>
                  <Col sm={4}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Estertores Crepitantes"
                      type="select"
                      value={attendance.cracklingStains}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          cracklingStains: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Sem estertores crepitantes</option>
                      <option value="2">Sibilos bilaterais</option>
                      <option value="3">Sibilos à direita</option>
                      <option value="4">Sibilos à esquerda</option>
                      <option value="5">Estertores bilaterais</option>
                      <option value="6">Estertores à direita</option>
                      <option value="7">Estertores à esquerda</option>
                      <option value="8">Roncos bilaterais</option>
                      <option value="9">Roncos à direita</option>
                      <option value="10">Roncos à esquerda</option>
                    </Input>
                  </Col>
                  <Col sm={3}></Col>
                </Row>
                <Row className="mt-2">
                  <Col className="ml-3" sm={4}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Exame do Abdome"
                      type="select"
                      value={attendance.examinationAbdomen}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          examinationAbdomen: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Plano</option>
                      <option value="2">Globoso</option>
                      <option value="3">Escavado</option>
                      <option value="4">Ascitico</option>
                    </Input>
                  </Col>
                  <Col sm={1} />
                  <Col sm={4}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Lesões de Pele"
                      type="select"
                      value={attendance.skinInjuries}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          skinInjuries: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Sim</option>
                      <option value="2">Não</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col className="divider mt-4 ml-4 mr-4 justify-content-center"></Col>
                </Row>
                <Row className="mt-4">
                  <Col className="ml-3">
                    <span className="section-title mb-4">
                      <strong>Membros Inferiores</strong>
                    </span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="ml-3" sm={4}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Direito"
                      type="select"
                      value={attendance.right}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          right: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Sem Edema</option>
                      <option value="2">Edema 1+/4+</option>
                      <option value="3">Edema 2+/4+</option>
                      <option value="4">Edema 3+/4+</option>
                      <option value="5">Edema 4+/4+</option>
                    </Input>
                  </Col>
                  <Col sm={1} />
                  <Col className="ml-3" sm={4}>
                    <Input
                      disabled={isAttendanceFinished()}
                      label="Esquerdo"
                      type="select"
                      value={attendance.left}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          left: e.target.value,
                        });
                      }}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Sem Edema</option>
                      <option value="2">Edema 1+/4+</option>
                      <option value="3">Edema 2+/4+</option>
                      <option value="4">Edema 3+/4+</option>
                      <option value="5">Edema 4+/4+</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col className="divider mt-4 ml-4 mr-4 justify-content-center"></Col>
                </Row>
                <Col>
                  {attendance.cids?.map(function (element, index) {
                    return (
                      <Row className="mt-2">
                        <Col className="mt-2" sm={6}>
                          <SelectMulti
                            isDisabled={isAttendanceFinished()}
                            label="Pesquise por CIDs"
                            isMulti={false}
                            options={CID}
                            onChange={e => {
                              const cid = attendance?.cids ? attendance.cids[index] : null;

                              if (cid) {
                                cid.name = e?.value;
                                const cids = [...(attendance.cids || [])];
                                cids[index] = cid;
                                setAttendance({
                                  ...attendance,
                                  cids,
                                });
                              }
                            }}
                            value={{ value: element.id, label: element.name }}
                          />
                        </Col>
                        <Col className="mt-4" sm={2}>
                          <Button
                            disabled={isAttendanceFinished()}
                            className="mt-2"
                            onClick={() => {
                              const cids = [...(attendance.cids || [])];
                              cids.push({ id: '', name: '' });
                              setAttendance({
                                ...attendance,
                                cids,
                              });
                            }}
                          >
                            Adicionar
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Row className="mt-2">
                  <Col className="mt-2 ml-3" sm={6}>
                    <TextArea
                      disabled={isAttendanceFinished()}
                      label="Diagnóstico"
                      style={{ minHeight: '200px' }}
                      type="textarea"
                      placeholder="Complemente o diagnóstico se preciso"
                      value={attendance.diagnosis}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          diagnosis: e.target.value,
                        });
                      }}
                    ></TextArea>
                  </Col>
                </Row>
                <Row className="mt-3 ">
                  <Col className="ml-3" sm={6}>
                    <TextArea
                      disabled={isAttendanceFinished()}
                      style={{ minHeight: '200px' }}
                      label="Conduta"
                      type="textarea"
                      value={attendance.conduct}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          conduct: e.target.value,
                        });
                      }}
                    ></TextArea>
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col sm={4}>
                    <Button
                      disabled={isAttendanceFinished()}
                      onClick={() => handleSaveAttendance()}
                    >
                      Salvar Atendimento
                    </Button>
                  </Col>
                </Row>
              </Accordeon>
            </Col>
          </Row>
        )}

        {showDoctorAttendance() && (
          <Row className="mt-3">
            <Col>
              <Accordeon text="Prescrição" startOpen={true}>
                <Row className="mt-3">
                  <Col className="ml-4" sm={6}>
                    <TextArea
                      disabled={isAttendanceFinished()}
                      style={{ minHeight: '200px' }}
                      label="Prescrição"
                      type="textarea"
                      value={attendance.prescription}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          prescription: e.target.value,
                        });
                      }}
                    ></TextArea>
                  </Col>
                  <Col className="ml-4 mt-4" sm={4}>
                    * Após salvar ou excluir sua prescrição, atualizar a página para recarregar os
                    dados do atendimento.
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-center">
                  <Col sm={4}>
                    <Button
                      style={{ marginRight: '10px' }}
                      disabled={isAttendanceFinished()}
                      id="botaoShowPrescricao"
                    >
                      prescrição MEMED
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <Button
                      disabled={isAttendanceFinished()}
                      onClick={() => handleSavePrescriptionAttendance()}
                    >
                      Salvar Prescrição
                    </Button>
                  </Col>
                </Row>
                {attendance.prescriptionId && attendance.prescriptionId > 0 ? (
                  <Row className="mt-4 justify-content-center">
                    <Col sm={4}>
                      <Button disabled={isAttendanceFinished()} onClick={handleGetPrescriptionLink}>
                        Visualizar Prescrição
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Accordeon>
            </Col>
          </Row>
        )}

        {attendance.doctorTelemedicine?.doctorType === 1 && (
          <Row className="mt-3">
            <Col>
              <Accordeon text="Resultado Teste Emocional" startOpen={true}>
                {attendance.das21Completed && (
                  <Row>
                    <Col sm={4}>
                      <Indicador level={10} />
                      <h5 style={{ textAlign: 'center' }}>Ansiedade</h5>
                    </Col>
                    <Col sm={4}>
                      <Indicador level={5} />
                      <h5 style={{ textAlign: 'center' }}>Estresse</h5>
                    </Col>
                    <Col sm={4}>
                      <Indicador level={2} />
                      <h5 style={{ textAlign: 'center' }}>Depressão</h5>
                    </Col>
                  </Row>
                )}
              </Accordeon>
            </Col>
          </Row>
        )}

        <Row className="mt-3">
          <Col>
            <Accordeon text="Observações" startOpen={true}>
              <Row className="mt-3">
                <Col className="ml-4" sm={6}>
                  <TextArea
                    style={{ minHeight: '200px' }}
                    label="Observações"
                    type="textarea"
                    value={attendance.comments}
                    onChange={e => {
                      setAttendance({
                        ...attendance,
                        comments: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-center">
                <Col sm={4}>
                  <Button onClick={() => handleSaveCommentsAttendance()}>Salvar Observações</Button>
                </Col>
              </Row>
            </Accordeon>
          </Col>
        </Row>

        {attendance.doctorTelemedicine?.doctorType === 1 && (
          <Row className="mt-3">
            <Col>
              <Accordeon text="Recomendações ao paciente (visível ao paciente)" startOpen={true}>
                <Row className="mt-3">
                  <Col className="ml-3" sm={6}>
                    <TextArea
                      style={{ minHeight: '200px' }}
                      type="textarea"
                      label="Observações"
                      value={attendance.patientRecomendations}
                      onChange={e => {
                        setAttendance({
                          ...attendance,
                          patientRecomendations: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="d-flex justify-content-center">
                    <Button onClick={() => handleSaveCommentsAttendance()}>
                      Salvar Recomendações
                    </Button>
                  </Col>
                </Row>
              </Accordeon>
            </Col>
          </Row>
        )}
      </div>
    </Layout>
  );
};
